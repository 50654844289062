<div *ngIf="limitReached && inProTier" class="d-flex flex-column text-muted">
    <ng-container *ngIf="hasFinancialAccess; else notAdmin">
        <span>Resource limit reached:</span>
        <span class="d-flex align-items-center">
            <a class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                href="/billing" type="button">
                Manage here
            </a>
            <i class="ms-auto fa-solid fa-lock text-muted"></i>
        </span>
    </ng-container>

    <ng-template #notAdmin>
        <span>Resource limit reached: Contact your</span>
        <span>administrator to increase capacity
            <i class="fa-solid fa-lock"></i>
        </span>
    </ng-template>
</div>