import { Component, Input } from '@angular/core'

@Component({
  selector: 'shared-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent {
  @Input() link: string
  @Input() logoUrl: string = "assets/logo.png"
}