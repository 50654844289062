import { Component } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { RightSidebarService } from '@services/right-sidebar.service'
import { ToastColor } from '@services/toast.service'
import { Modal } from 'bootstrap'

@Component({
  selector: 'shared-modal-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})

export class DeleteComponent {
  public sanitizedMessage: SafeHtml

  public cancelText = 'Cancel'
  public _message = 'Modal Message'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Submit'
  public title = 'Modal Title'

  public onCancel: CallableFunction
  public onSubmit: CallableFunction

  get message() { return this._message}
  set message(content: string) {
    const safeHTML = this._domSanitizer.bypassSecurityTrustHtml(content)

    this.sanitizedMessage = safeHTML

    this._rightSidebarService.prepareHTML("modal-body")
  }

  constructor(private _rightSidebarService: RightSidebarService, private _domSanitizer: DomSanitizer) { }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }

    this.modal?.hide()
  }
}