import { Component, ElementRef } from '@angular/core'
import { ToastAction, ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  public actionButton: ToastAction = { color: 'green' }
  public color: ToastColor = 'green'
  public count = 1
  public message: string = ''
  public percentDone = 1
  public showActionButton = false
  public showProgressBar = false
  public title: string = ''

  get isFinished() {
    return this.showProgressBar == false || this.percentDone >= 100
  }

  constructor(public elementRef: ElementRef) { }
}