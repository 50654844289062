import { Component } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'shared-resource-limit-alert',
  templateUrl: './resource-limit-alert.component.html',
  styleUrls: ['./resource-limit-alert.component.css']
})
export class ResourceLimitAlertComponent {
  get at360Limit() { return this._subscriptionService.at360Limit }
  get atDocumentLimit() { return this._subscriptionService.atDocumentLimit }
  get atModelLimit() { return this._subscriptionService.atModelLimit }
  get hasFinancialAccess() { return this._authService.hasFinancialAccess }
  get inProTier() { return this._subscriptionService.inProTier }
  get limitReached() { return this.at360Limit || this.atModelLimit || this.atDocumentLimit }

  constructor(private _authService: AuthenticationService, private _subscriptionService: SubscriptionService) { }
}