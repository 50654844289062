import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { TransformControls } from '@classes/TransformControls'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { TooltipService } from '@services/tooltip.service'

var nextId = 0

@Component({
  selector: 'shared-control-btns',
  templateUrl: './control-btns.component.html',
  styleUrls: ['./control-btns.component.css']
})
export class ControlBtnsComponent implements OnChanges {
  @ViewChild('translate') translateRef: ElementRef
  @ViewChild('rotate') rotateRef: ElementRef
  @ViewChild('scale') scaleRef: ElementRef

  @Input() id = `ctrl-radio-id-${nextId++}`
  @Input() transformControls: TransformControls
  @Input() rotation: boolean = true
  @Input() scale: boolean = true
  @Input() translation: boolean = true

  get transformController() { return this.envManager.sceneManager.transformControls }

  get rotateCheckbox(): HTMLInputElement { return this.rotateRef?.nativeElement }
  get scaleCheckbox(): HTMLInputElement { return this.scaleRef?.nativeElement }
  get translateCheckbox(): HTMLInputElement { return this.translateRef?.nativeElement }

  constructor(
    private _tooltipService: TooltipService,
    public envManager: EnvironmentManagerService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this._tooltipService.intializeTooltips()

    if (this.translation) this.setMode('translate')
    else if (this.rotation) this.setMode('rotate')
    else if (this.scale) this.setMode('scale')
  }

  setMode(mode: 'translate' | 'rotate' | 'scale') {
    if (this.transformControls) this.transformControls.setMode(mode)
    else this.transformController?.setMode(mode)

    if (mode == 'translate' && this.translateCheckbox) this.translateCheckbox.checked = true
    else if (mode == 'rotate' && this.rotateCheckbox) this.rotateCheckbox.checked = true
    else if (mode == 'scale' && this.scaleCheckbox) this.scaleCheckbox.checked = true
  }
}