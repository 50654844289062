import { Modal } from 'bootstrap'

import { Component, ElementRef, EventEmitter } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { FeatureService } from '@services/feature.service'
import { ModalBody, ModalOptions } from '@services/modal.service'

@Component({
  selector: 'shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  public componentBody: any
  public events = new EventEmitter<'close' | 'submit'>()
  public modal: Modal
  public options: ModalOptions

  get color() { return this.options.submitBtnColor ?? "green" }
  get currentModule() { return this.authenticationService.currentModule }
  get inputs() { return { events: this.events, modal: this.modal, ...this.options?.bodyInputs } as ModalBody }
  get modalSize() { return this.options.size ?? "medium" }

  constructor(
    public authenticationService: AuthenticationService,
    public elementRef: ElementRef,
    public featureService: FeatureService
  ) {
    elementRef.nativeElement.addEventListener('hidden.bs.modal', () => this.close())
  }

  close() {
    this.events.emit('close')
  }

  submit() {
    this.events.emit('submit')
  }
}