<div *ngIf="selectedAsset" class="p-2 d-flex flex-column align-items-center">
    <div class="d-flex gap-4 align-items-center">
        <label class="fs-5 fw-bold">Selected Asset</label>
        <button class="btn btn-sm btn-danger" (click)="removeSelectedAsset()">Remove</button>
    </div>
    <div class="list-group m-1" style="width: fit-content">
        <!-- Asset Type -->
        <div class="list-group-item d-flex gap-1 bg-light">
            <strong>Asset Type:</strong>
            <span>{{typeForSelectedAsset?.name}}</span>
        </div>

        <!-- Asset ID -->
        <div class="list-group-item d-flex gap-1 bg-light">
            <strong>Asset ID:</strong>
            <span>{{selectedAsset.id}}</span>
        </div>

        <!-- Dynamic Fields -->
        <div class="list-group-item d-flex gap-1 bg-light" *ngFor="let datum of compiledAssetData">
            <strong>{{datum.fieldName}}:</strong>
            <div>
                <ng-container [ngSwitch]="datum.type">
                    <ng-container *ngSwitchCase="'trueFalse'">
                        <span>{{datum.value == 0 ? 'True' : 'False'}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'location'">
                        <span class="text-truncate">
                            {{datum.value[1]}}, {{datum.value[0]}}
                        </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'chooseMultiple'">
                        <div class="d-flex flex-column">
                            <span class="text-truncate">{{datum.value.join(', ')}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'images'">
                        <span *ngIf="!datum.value || datum.value == ''">No Files Selected</span>
                        <span *ngIf="datum.value?.length > 0">{{datum.value}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'files'">
                        <span *ngIf="datum.value == ''">No Files Selected</span>
                        <span *ngIf="datum.value.length > 0">{{datum.value}}</span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>{{datum.value}}</span>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column gap-2" style="max-height:500px">
    <div class="d-flex" style="max-height:400px;">
        <div class="d-flex flex-column gap-1 mt-1 me-1">
            <div class="input-group input-group-sm">
                <span class="input-group-text">Asset Types</span>
                <input type="search" class="form-control form-control-sm" [(ngModel)]="assetTypeSearch"
                    (focusout)="searchAssetTypes()">
            </div>
            <div class="d-flex flex-column p-2 list-group list-group-flush" style="overflow-y: scroll;">
                <div *ngIf="assetTypes.length == 0"> No Asset Types</div>
                <div *ngFor="let assetType of assetTypes"
                    class="d-flex list-group-item list-group-item-action justify-content-between"
                    [ngClass]="{'active':(selectedAssetType$ | async)?.id == assetType.id}" style="cursor: pointer;"
                    (click)="selectAssetType(assetType)">
                    <div class="d-flex gap-1 align-items-center">
                        {{assetType.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex border-start flex-grow-1 w-100" style="overflow-x: auto; overflow-y: auto">
            <div class="d-flex justify-content-center align-items-center flex-grow-1">
                <span *ngIf="!(selectedAssetType$ | async)" class="fs-3">No Asset Type Selected</span>
                <div *ngIf='selectedAssetType$ | async' class='h-100 w-100' [ngClass]="{'bg-light bg-gradient': true}"
                    (scroll)="onTableScroll($event)">
                    <table class="table table-striped table-hover w-100">
                        <thead>
                            <tr>
                                <th style="height:50px">
                                    <div class="text-nowrap">
                                        <span>Asset ID</span>
                                        <i [ngClass]="{'ms-1 fa': true, 'fa-caret-down': sortOrder == 'ASC' && !sortByFieldID, 'fa-caret-up': (sortOrder == 'DESC' && !sortByFieldID) || sortByFieldID}"
                                            style="cursor: pointer;">
                                        </i>
                                    </div>
                                </th>
                                <th *ngFor="let field of assetFields; index as i" style="height:50px">
                                    <ng-container [ngSwitch]="field.type">
                                        <ng-container *ngSwitchCase="'images'">
                                            <div class="d-flex align-items-center">
                                                <span>{{field.name}}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'files'">
                                            <div class="d-flex align-items-center">
                                                <span>{{field.name}}</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <div
                                                class="d-flex align-items-center input-group input-group-sm flex-nowrap">
                                                <div class="input-group-text">
                                                    <span>{{field.name}}</span>
                                                    <i [ngClass]="{
                                            'ms-1 fa': true, 
                                            'fa-caret-down': sortByFieldID == field.id && sortOrder == 'ASC', 
                                            'fa-caret-up': sortByFieldID != field.id || (sortByFieldID == field.id && sortOrder == 'DESC')
                                        }" (click)="sortByField(field)" style="cursor: pointer;">
                                                    </i>
                                                </div>
                                                <input type="search" class='form-control flex-grow-1'
                                                    style="flex-shrink:0; min-width: 100px;"
                                                    [value]="filterMap.get(field.id) ? filterMap.get(field.id) : ''"
                                                    (focusout)="filter(field, $event.target.value)"
                                                    placeholder="Filter">
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let row of assetTableRows" [style.cursor]='"pointer"'
                                [ngClass]="{'table-primary': selectedAsset?.id == row.assetID}"
                                (click)="selectAsset(row.assetID)">
                                <td>
                                    <div class="d-flex gap-1 align-items-center">
                                        <span>{{row.assetID}}</span>
                                    </div>
                                </td>
                                <td *ngFor="let value of row.values; index as i">
                                    <div class="d-flex align-items-center gap-1">
                                        <ng-container [ngSwitch]="getValueType(i)">
                                            <ng-container *ngSwitchCase="'trueFalse'">
                                                <span>{{value == 0 ? 'True' : 'False'}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'location'">
                                                <span class="text-truncate">
                                                    {{value[1]}}, {{value[0]}}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'chooseMultiple'">
                                                <div class="d-flex flex-column">
                                                    <span *ngFor="let option of value">{{option}}</span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'images'">
                                                <span *ngIf="!value || value == ''">No Files Selected</span>
                                                <ng-container *ngIf="value?.length > 0">
                                                    <span>{{value}}</span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'files'">
                                                <span *ngIf="value == ''">No Files Selected</span>
                                                <ng-container *ngIf="value.length > 0">
                                                    <span>{{value}}</span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <span>{{value}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </td>

                            </tr>

                            <div *ngIf="assetTableRows.length == 0 && loading" class="p-3 fs-5 text-muted">
                                Loading...
                            </div>
                            <div *ngIf="assetTableRows.length == 0 && !loading" class="p-3 fs-5 text-muted">
                                No Entries
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>