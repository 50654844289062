<input class="form-control-color" style="appearance: none;" type="color" list="presetColors"
    [(ngModel)]="value" (change)="updateValue($event)">
<datalist id="presetColors">
    <option>#FFFFFF</option>
    <option>#FF8AD8</option>
    <option>#FF0000</option>
    <option>#C00000</option>
    <option>#FF7417</option>
    <option>#FFC000</option>
    <option>#FFFF00</option>
    <option>#92D050</option>
    <option>#00B050</option>
    <option>#385723</option>
    <option>#002060</option>
    <option>#0070C0</option>
    <option>#00B0F0</option>
    <option>#7030A0</option>
    <option>#000000</option>
</datalist>