import { Component } from '@angular/core'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'billing-edit-payment-info',
  templateUrl: './edit-payment-info.component.html',
  styleUrls: ['./edit-payment-info.component.css']
})
export class EditPaymentInfoComponent {
  public isOpening: boolean

  constructor(private _subscriptionService: SubscriptionService) { }

  openPortal() {
    this.isOpening = true

    this._subscriptionService.openSubscriptionBillingPortal().subscribe(
      () => this.isOpening = false,
      () => this.isOpening = false
    )
  }
}