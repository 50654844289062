import { User } from "./User"

export type ModelPermissionOptions = {
    user?: User,
}

export class ModelPermission {
    public userID: string
    public modelID: number
    public permission: string
    public user: User

    constructor(userID: string, modelID: number, permission: string, options?: ModelPermissionOptions) {
        this.userID = userID
        this.modelID = +modelID
        this.permission = permission
        this.user = options ? options.user : null
    }

    equals(perm: ModelPermission): unknown {
        return this.userID == perm.userID
            && this.modelID == perm.modelID
            && this.permission == perm.permission
    }
}