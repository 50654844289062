import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ConnectionProperty } from '@classes/Connection';
import { ConnectionService } from '@services/connection.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'shared-edit-connection-custom-field',
  templateUrl: './edit-connection-custom-field.component.html',
  styleUrls: ['./edit-connection-custom-field.component.css']
})
export class EditConnectionCustomFieldComponent implements OnInit {
  @Input() customField: ConnectionProperty
  @Output() showConnectionDataPicker: EventEmitter<CallableFunction> = new EventEmitter()
  @Output() delete: EventEmitter<boolean> = new EventEmitter()
  public valueType: string
  public value: string
  public urlIsValid$ = this._connectionService.urlStatus$.pipe(
    tap(_ => this.cd.detectChanges())
  ) 

  get valueTooltip() { return 'Specify value; if type "Path", specify location of data accessed by root path. Access nested data using "/"; ex: attribute/nestedAttribute"'}
  constructor(private _connectionService: ConnectionService, private cd: ChangeDetectorRef) { }

  prepareCustomField() {
    const customFieldValue = JSON.parse(this.customField.value)
    this.valueType = customFieldValue.valueType
    this.value = customFieldValue.value
  }

  ngOnInit(): void { this.prepareCustomField() }
  ngOnChanges(): void { this.prepareCustomField() }

  updateCustomField() {
    const stringifiedValue = JSON.stringify({ valueType: this.valueType, value: this.value })
    this.customField.value = stringifiedValue
    this._connectionService.updateConnectionProperty(this.customField).subscribe()
  }

  deleteCustomField() {
    this.delete.emit(true)
  }

  openConnectionDataPicker() {
    const updateCustomFieldCallback = (key: string, value: any, path: string) => {
      this.value = key
      this.updateCustomField()
    }

    this.showConnectionDataPicker.emit(updateCustomFieldCallback)
  }
}
