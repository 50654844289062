<form [formGroup]='connectionForm' class='form-group container-fluid pt-2' style="user-select: none;">
    <div class='d-grid gap-3'>
        <!-- Connection Name -->
        <div class='input-group input-group-sm d-flex'>
            <label class='input-group-text'>Name</label>
            <input class='form-control' type="text" formControlName='name'>
        </div>

        <!-- Connection URL -->
        <div>
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text'>URL</span>
                <input class="form-control" [ngClass]="{
                    'is-valid': urlStatus == 'good', 
                    'is-invalid': urlStatus == 'bad'
                }" type="text" formControlName='url'>

                <!-- Test URL Button -->
                <span class='input-group-text' (click)="validateURL(url.value)">
                    <i *ngIf='urlStatus != "loading"' class="fa fa-refresh" [ngClass]="{
                                'refresh-button text-success': urlStatus == 'good' || urlStatus == 'bad', 
                                'refresh-button-active text-secondary rotate': urlStatus == 'loading'}">
                    </i>
                    <i *ngIf='urlStatus == "loading"'
                        class='fa-solid fa-spinner-third refresh-button-active rotate'></i>
                </span>

                <!-- Connection Status -->
                <span *ngIf='urlStatus == "good"' class="valid-feedback">
                    Successful Connection
                </span>
                <span *ngIf='urlStatus == "bad"' class="invalid-feedback">
                    Failed Connection. Please check URL and/or Headers
                </span>
            </div>
            <p *ngIf='urlStatus == "loading"' class="text-secondary">
                Fetching...
            </p>
        </div>

        <div class="p-2 bg-white border d-grid gap-4">
            <!-- Connection Headers -->
            <div class="d-flex flex-column d-grid gap-2">
                <ng-container *ngIf="headers?.controls?.length == 0; else addingHeaders">
                    <a class="btn btn-success btn-sm w-25" (click)="addHeader()">
                        + Header
                    </a>
                </ng-container>
                <ng-template #addingHeaders>
                    <div class="d-flex align-items-center d-grid gap-2">
                        <a class="btn btn-success btn-sm py-0 px-1" (click)="addHeader()">
                            <i class="fas fa-plus"></i>
                        </a>
                        <span>Headers</span>
                    </div>
                </ng-template>

                <div *ngFor="let headerGroup of headers?.controls; let i = index" class='input-group input-group-sm'
                    [formGroup]="headerGroup">
                    <!-- Key -->
                    <span class='input-group-text'>Key</span>
                    <input class='form-control' type="text" formControlName="key">

                    <!-- Value -->
                    <span class='input-group-text'>Value</span>
                    <input class='form-control' type="text" formControlName="value">

                    <a class="btn btn-sm btn-danger ms-1" (click)="removeFormGroup(i, headers)">
                        <i class="fa fa-trash"></i>
                    </a>
                </div>
            </div>

            <!-- Connection Parameters -->
            <div class="d-flex flex-column d-grid gap-2">
                <ng-container *ngIf="parameters?.controls?.length == 0; else addingParameters">
                    <a class="btn btn-success btn-sm w-25" (click)="addParameter()">
                        + Parameter
                    </a>
                </ng-container>
                <ng-template #addingParameters>
                    <div class="d-flex align-items-center d-grid gap-2">
                        <a class="btn btn-success btn-sm py-0 px-1" (click)="addParameter()">
                            <i class="fas fa-plus"></i>
                        </a>
                        <span>Parameters</span>
                    </div>
                </ng-template>

                <div *ngFor="let parameter of parameters?.controls; let i = index"
                    class='d-flex flex-column d-grid gap-1' [formGroup]="parameter">
                    <div class="input-group input-group-sm d-flex">
                        <!-- Key -->
                        <span class='input-group-text'>Key</span>
                        <input class='form-control' type="text" formControlName="key" placeholder="Ex. component_id">

                        <!-- Value -->
                        <span class='input-group-text'>Value</span>
                        <ng-container *ngIf="!parameter.value.dynamic; else isDynamic">
                            <input class='form-control' type="text" formControlName="value" placeholder="Ex. 456abc">
                        </ng-container>

                        <!-- Dynamic Value -->
                        <ng-template #isDynamic>
                            <select *ngIf="parameter.value.type == 'time'" class="form-select" formControlName="value">
                                <option value="today" selected>Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="last-week">Last Week</option>
                                <option value="last-month">Last Month</option>
                                <option value="last-year">Last Year</option>
                            </select>
                        </ng-template>

                        <!-- TRASH -->
                        <a class="btn btn-sm btn-danger ms-1" type="button" (click)="removeFormGroup(i, parameters)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>

                    <!-- Dynamic Switch -->
                    <div class="d-flex align-items-center input-group input-group-sm d-grid gap-1">
                        <div class="form-switch">
                            <input (change)="updateDynamic(parameter, $event.target.checked)" class='form-check-input'
                                type="checkbox" formControlName="dynamic" style="cursor: pointer;">
                        </div>
                        <label class='form-check-label'>
                            Use Dynamic Value
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Connection Type -->
        <div class="input-group input-group-sm d-flex">
            <span class='input-group-text'>Type</span>
            <select class='form-select form-select-sm' formControlName='createFeatures'
                (change)="updateCreateFeatures($event.target.value)">
                <option [value]="true" [hidden]="!isMap">
                    Create new Features
                </option>
                <option [value]="false" [hidden]='(features$ | async).length == 0'>
                    Add data to existing Feature
                </option>
            </select>
        </div>

        <!-- Referenced Feature -->
        <div *ngIf="createFeatures.value == 'false'" class="input-group input-group-sm d-flex">
            <span class='input-group-text'>Feature</span>
            <select class='form-select' formControlName="featureID">
                <option *ngFor="let feature of (features$ | async)" [value]="feature.id">{{feature.name}}</option>
            </select>
        </div>
    </div>
</form>