import { saveAs } from 'file-saver'
import { take } from 'rxjs/operators'

import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { FeatureProperty } from '@classes/FeatureProperty'
import { ConnectionService } from '@services/connection.service'
import { FeatureService } from '@services/feature.service'

@Component({
  selector: 'shared-edit-remote-attachment',
  templateUrl: './edit-remote-attachment.component.html',
  styleUrls: ['./edit-remote-attachment.component.css']
})
export class EditRemoteAttachmentComponent implements OnInit, OnChanges {
  @Input() attachment: FeatureProperty
  @Output() remove = new EventEmitter()

  public attachmentFile: Blob | File
  public displayName: string = ''
  public headers: Array<{ key: string, value: string }> = []
  public url: string = ''
  public urlStatus: 'bad' | 'good' | 'loading'
  public showInDescription: boolean

  constructor(
    private _connectionService: ConnectionService,
    private _http: HttpClient,
    public featureService: FeatureService,
  ) { }

  ngOnInit(): void {
    this.parseAttachment()
  }

  ngOnChanges(): void {
    this.parseAttachment()
  }

  parseAttachment() {
    const { headers, url, displayName, showInDescription } = JSON.parse(this.attachment.value)

    this.headers = headers ?? []
    this.url = url ?? ''
    this.displayName = displayName ?? ''
    this.showInDescription = showInDescription ?? false
  }

  updateAttachment() {
    this.attachment.value = JSON.stringify({ headers: this.headers, url: this.url, displayName: this.displayName, showInDescription: this.showInDescription })

    this.featureService.updateFeatureProperty(this.attachment).subscribe()
  }

  downloadAttachment() {
    if (this.attachmentFile) {
      saveAs(this.attachmentFile, this.displayName)
    } else {
      const headers = this.getFormattedHeaders()

      this._connectionService.downloadAttachment(this.url, this.displayName, headers)
    }
  }

  removeAttachment() {
    this.remove.emit('remove')
  }

  validateURL() {
    const headers = this.getFormattedHeaders()

    this.attachmentFile = null
    this.urlStatus = 'loading'

    if (this.url) {
      this._http.get(this.url, { headers: headers, responseType: 'blob' })
        .pipe(take(1))
        .subscribe(
          (file) => {
            this.urlStatus = 'good'
            this.attachmentFile = file
          },
          (error) => this.urlStatus = 'bad'
        )
    } else {
      this.urlStatus = 'bad'
    }
  }

  public getFormattedHeaders() {
    const headers: any = {}

    this.headers
      .filter(header => header.key != null && header.key != '')
      .forEach((header: any) => headers[header.key] = header.value)

    return headers
  }

  public addHeader() {
    this.headers.push({ key: null, value: null })

    this.updateAttachment()
  }

  public deleteHeader(index: number) {
    this.headers.splice(index, 1)

    this.updateAttachment()
  }
}