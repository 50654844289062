<div class="d-flex flex-wrap gap-1">
    <ng-container *ngFor="let model of models">
        <div class="btn p-2 d-flex flex-column align-items-center justify-content-center rounded" [ngClass]="{ 
                'selected-model-button': selectedID == model.id, 
                'border border enlarged shadow': hoveredID == model.id,
                'disabled': !isOwner(model) && !inBuilder 
            }" style="width:fit-content" (mouseover)="setHovered(model)" (mouseout)="setHovered(undefined)"
            (click)="selectModel(model, $event)">

            <!-- Thumbnail -->
            <div class="thumbnail border rounded-circle d-flex align-items-center justify-content-center">
                <img *ngIf="model.thumbnail" [src]="model.thumbnail" style="display: block; height: 250%">
                <i *ngIf="!model.thumbnail" class="fa-regular fa-cube fa-lg"></i>
            </div>

            <!-- Name -->
            <div class="model-title pt-1" style="font-size:0.7rem">
                {{ model.name }}
            </div>
        </div>
    </ng-container>
</div>