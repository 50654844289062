import * as rfdc from 'rfdc'

export interface FilterableData {
    id: number
    name: string
    description?: string
    properties?: Property[]
}

export interface Property {
    id: number
    type: string
    key: string
    value: any
}

export type MathOperation = 'average' | 'count' | 'max' | 'median' | 'min' | 'standardDeviation' | 'sum'

export function calculateStatistic(values: number[], operation: MathOperation): number {
    let result: number

    switch (operation) {
        case 'sum':
            result = values.reduce((a, b) => a + b, 0)
            break
        case 'min':
            result = Math.min(...values)
            break
        case 'max':
            result = Math.max(...values)
            break
        case 'average':
            result = values.reduce((a, b) => a + b, 0) / values.length
            break
        case 'median':
            const sorted = values.slice().sort((a, b) => a - b)
            const mid = Math.floor(sorted.length / 2)
            result = sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2
            break
        case 'standardDeviation':
            const mean = values.reduce((a, b) => a + b, 0) / values.length
            const variance = values.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / values.length
            result = Math.sqrt(variance)
            break
        case 'count':
            result = values.length
            break
        default: // default to mean if operation is not recognized
            result = values.reduce((a, b) => a + b, 0) / values.length
            break
    }

    return result
}

/** @returns A string describing the primitive data type of a given value */
export function getType(value: any): 'array' | 'boolean' | 'null' | 'number' | 'object' | 'text' {
    if (Array.isArray(value)) return 'array'
    if (value === null || value === undefined) return 'null'
    const valueType = typeof value
    switch (valueType) {
        case 'boolean':
            return 'boolean'
        case 'number':
            return 'number'
        case 'object':
            return 'object'
        case 'string':
            return 'text'
        default:
            return 'text'
    }
}

export function getStringType(value: any): 'array' | 'boolean' | 'null' | 'number' | 'object' | 'text' {
    try {
        if (value === "null") return 'null' // Check for the string "null"

        const parsedValue = JSON.parse(value)

        if (Array.isArray(parsedValue)) return 'array'
        if (typeof parsedValue === 'boolean') return 'boolean'
        if (typeof parsedValue === 'number') return 'number'
        if (typeof parsedValue === 'object') return 'object'
        return 'text' // If JSON.parse succeeds, but it's not a recognized type
    } catch (e) {
        return 'text' // Return 'text' if parsing fails
    }
}

export function describeString(value: any): 'array' | 'boolean' | 'email' | 'null' | 'number' | 'object' | 'text' | 'url' {
    const type = getStringType(value)

    // Handle cases specific to strings
    if (type === 'text') {
        try {
            if (value.startsWith("http://") || value.startsWith("https://")) return 'url' // If the URL constructor doesn't throw, it's a URL
            else throw new Error("Not a url")
        } catch (urlError) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (emailPattern.test(value)) {
                return 'email' // If it matches the email pattern, it's an email
            }
        }
    }

    return type // Return the type determined by getStringType
}

export const clone = rfdc({ proto: true, circles: true })

export function itemInList(item: FilterableData, list: FilterableData[]) {
    return list.some(member => member.id == item.id)
}

export function itemNotInList(item: FilterableData, list: FilterableData[]) {
    return !itemInList(item, list)
}

export function positionInList(item: FilterableData, list: FilterableData[]) {
    return list.findIndex(member => member.id == item.id)
}

export function normalizeValues(values: number[], params?: { min?: number, max?: number }): number[] {
    if (values.length === 0) {
        return [] // Return an empty array if no values are provided
    }

    const min = params?.min ?? Math.min(...values)
    const max = params?.max ?? Math.max(...values)

    // Check if all values are the same (min equals max), return array of ones if true
    if (min === max) {
        return new Array(values.length).fill(1)
    }

    // Normalize the values to a range of 0 to 1
    return values.map(value => (value - min) / (max - min))
}

/**
 * Converts a kebab-case string to camelCase.
 * @param text - The string to convert.
 * @returns The camelCased string.
 */
export function kebabToCamel(text: string): string {
    return text.split('-').map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    ).join('')
}

export function simpleHash(str: string): string {
    let hash = 0

    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash |= 0 // Convert to 32bit integer
    }

    return hash.toString()
}