<div class="card-header d-grid gap-2 p-2 bg-white">
    <div class="input-group input-group-sm">
        <span class="input-group-text">
            <i class="fas fa-search"></i>
        </span>
        <input #search type="search" class="form-control" placeholder="Search" [(ngModel)]="searchValue">
        <a class="btn btn-sm" (click)="refresh()">
            <i class="fas fa-arrows-rotate fa-fw"></i>
        </a>
    </div>

    <div *ngIf="inProject" class="form-check form-switch">
        <input class="form-check-input" role="button" type="checkbox" [(ngModel)]="showProjectsModels"
            id="limitToProject" style="cursor: pointer;">
        <label class="form-check-label" role="button" for="limitToProject">
            Used in Project
        </label>
    </div>
</div>

<div class="d-flex flex-column card-body h-100" [ngClass]="{'loading-overlay': !loaded}" style="overflow-y: scroll">
    <shared-model-grid #modelGrid *ngIf='showProjectsModels'
        [models]="projectsModels | searchSortByKeys: ['name', 'description'] : searchValue"
        (onClick)="pickModel($event)"></shared-model-grid>

    <shared-model-grid #modelGrid *ngIf='!showProjectsModels'
        [models]="models | searchSortByKeys: ['name', 'description'] : searchValue"
        (onClick)="pickModel($event)"></shared-model-grid>
</div>