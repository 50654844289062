import * as levenshtein from 'damerau-levenshtein'

import { Pipe, PipeTransform } from '@angular/core'

const SUBSTRING_WEIGHT = 100

@Pipe({
  name: 'searchSortByKeys'
})
export class SearchSortByKeysPipe implements PipeTransform {

  transform(items: any[], keys: string[], searchTerm: string): any[] {
    if (searchTerm == null || items == null || items.length == 0) {
      return items
    }

    return items.slice().sort((itemA, itemB) =>
      this.getWeight(itemA, keys, searchTerm) - this.getWeight(itemB, keys, searchTerm)
    )
  }

  /** @return A value that can be used to sort an item in a list */
  getWeight(item: any, keys: string[], term: string) {
    if (item && term != '') {
      const stepsWeight = Math.min(...keys.map(key => this.getSteps(item[key], term)))
      const substringWeight = Math.min(...keys.map(key => this.getSubstringWeight(item[key]?.toLowerCase(), term.toLowerCase())))

      return stepsWeight + substringWeight
    }
  }

  /** @return Number of character modifications to transform the term into the target */
  getSteps(target: string, term: string) {
    if (target == '') {
      return 100
    } else {
      return levenshtein(term, target)?.steps
    }
  }

  getSubstringWeight(target: string, term: string) {
    let weight = 0

    if (target.includes(term)) {
      weight -= SUBSTRING_WEIGHT
    }

    if (target.startsWith(term)) {
      weight -= SUBSTRING_WEIGHT
    }

    return weight
  }
}