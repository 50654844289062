<div *ngIf="tiers" class="d-grid gap-2">
    <div id="interval" class="d-flex align-items-center justify-content-center gap-2 m-2 user-select-none">
        <span (click)="interval = 'month'" class="text-center" style="width: 85px"
            [ngClass]="{'bg-primary text-white': interval == 'month'}">
            Monthly
        </span>

        <div class="form-check form-switch d-flex align-items-center justify-content-center">
            <input [(ngModel)]="intervalCheckbox" class="form-check-input" type="checkbox" role="switch">
        </div>

        <span (click)="interval = 'year'" class="text-center" style="width: 85px"
            [ngClass]="{'bg-primary text-white': interval == 'year'}">
            Yearly
        </span>
    </div>

    <div class="d-flex gap-2">
        <!-- Free Tier -->
        <div (click)="chooseTier('free')" class="card shadow"
            [ngClass]="{'bg-secondary text-light active': freeSelected}" role="button">
            <div class="card-header d-flex flex-column text-center p-4 w-100 gap-2">
                <span class="fs-3 fw-bold">Free Tier</span>
                <span class="fs-6">$0</span>
            </div>

            <div class="card-body w-100">
                <ul class="list-group list-group-flush text-center">
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': freeSelected }">
                        <strong>7-day Access</strong> to explore our features
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': freeSelected }">
                        Access to demo Virtual Tour
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': freeSelected }">
                        Visualize, Store & Manage your Documents & 360° Images
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': freeSelected }">
                        <strong>Upgrade anytime</strong> - during or after the trial
                    </li>
                </ul>
            </div>

            <span *ngIf="freeSelected" class="selected-badge">
                Selected
            </span>
        </div>

        <!-- Right Arrow -->
        <div class="d-flex align-items-center m-1">
            <i class="text-secondary fa-light fa-arrow-right-from-line fa-2xl"></i>
        </div>

        <!-- Pro Tier -->
        <div (click)="chooseTier('pro')" class="card shadow" [ngClass]="{'bg-secondary text-light active': proSelected}"
            role="button">
            <div class="card-header d-flex flex-column text-center p-4 w-100 gap-2">
                <span class="fs-3 fw-bold">Pro Tier</span>
                <span class="fs-6">{{ proPrice }}</span>
            </div>

            <div class="card-body w-100">
                <ul class="list-group list-group-flush text-center">
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': proSelected }">
                        Create your own Virtual Tour scenes
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': proSelected }">
                        Collaborate and share projects
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': proSelected }">
                        Access, manage, and visualize your asset data
                    </li>
                    <li class="list-group-item bg-transparent" [ngClass]="{'text-light': proSelected }">
                        Pay only for what you need with flexible resource limits
                    </li>
                </ul>
            </div>

            <span *ngIf="proSelected" class="selected-badge">
                Selected
            </span>
        </div>
    </div>
</div>