export class User {
    public id: string
    public name: string
    public username: string
    public email: string
    public company: string
    public organizationID: string
    public picture: string
    public lastLogin: string
    public createdOn: string

    constructor(
        id: string,
        name: string,
        username: string,
        email: string,
        company: string,
        organizationID: string,
        picture: string,
        lastLogin?: string,
        createdOn?: string
    ) {
        this.id = id
        this.name = name
        this.username = username
        this.email = email
        this.company = company
        this.organizationID = organizationID
        this.picture = picture
        this.lastLogin = lastLogin ?? new Date().toLocaleString()
        this.createdOn = createdOn
    }
}