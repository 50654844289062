import * as bootstrap from 'bootstrap'

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { RightSidebarService } from '@services/right-sidebar.service'

@Component({
  selector: 'billing-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css']
})
export class RightPanelComponent implements AfterViewInit {
  @ViewChild('panel') private panel: ElementRef
  public offcanvas: bootstrap.Offcanvas

  constructor(private rightSidebarService: RightSidebarService) { }

  ngAfterViewInit(): void {
    this.offcanvas = new bootstrap.Offcanvas(this.panel.nativeElement)
    this.rightSidebarService.offCanvasReference = this
  }
}