import { Observable, of, ReplaySubject, Subscription } from 'rxjs'
import { distinctUntilKeyChanged, filter, switchMap, takeUntil } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Action, ActionKeys, ActionOptions } from '@classes/Action'
import { Interaction, InteractionOptions, InteractionType } from '@classes/Interaction'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { InteractionService } from '@services/interaction.service'
import { SceneService } from '@services/scene.service'
import { VirtualTourService } from '@services/virtual-tour.service'

@Component({
  selector: 'shared-edit-interactions',
  templateUrl: './edit-interactions.component.html',
  styleUrls: ['./edit-interactions.component.css']
})
export class EditInteractionsComponent {
  public otherFeatures = this.featureService.features.filter(f => f.id != this.featureService.selectedFeatureID)
  public sortedFeatures = []
  public modelFeatures = []
  public otherScenes = []
  public interactionTypes: InteractionType[] = ['click', 'deselect', 'hover', 'panel-click']
  public idKeys: ActionKeys[] = ['focus', 'openScene', 'hide', 'show', 'toggleHidden', 'loadOut', 'loadIn', 'toggleLoad']
  public stringKeys: ActionKeys[] = ['openTab', 'showDetails']
  public allActionKeys: ActionKeys[] = this.idKeys.concat(this.stringKeys, 'moveTo')
  public submitting: boolean = false

  get camera() { return this.modelSpace?.camera }
  get map() { return this.envManager.map }
  get modelSpace() { return this.envManager.modelSpace }
  get orbitControls() { return this.modelSpace?.orbitControls }
  get selectedScene() { return this.sceneService.selectedScene }
  get selectedFeature() { return this.featureService.selectedFeature }

  get cameraPosition() { return this.camera?.position?.clone() }
  get controlsPosition() { return this.orbitControls?.target?.clone() }

  get bearing() { return this.map?.getBearing() }
  get longitude() { return this.map?.getCenter()?.lng }
  get latitude() { return this.map?.getCenter()?.lat }
  get pitch() { return this.map?.getPitch() }
  get zoom() { return this.map?.getZoom() }

  get inViewpointMode() { return this.virtualTourService.viewpointMode }
  get currentViewPointFeatureID() { return this.virtualTourService.currentViewpoint?.userData.featureID }

  private _destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>()
  private _subscriptions: Subscription[] = []
  public interactions: Interaction[]

  public selectedFeaturesInteractions$: Observable<Interaction[]> = this.featureService.selectedFeature$.pipe(
    takeUntil(this._destroyed$),
    filter(f => f !== undefined),
    distinctUntilKeyChanged("id"),
    switchMap(feat => {
      if (!feat || !feat.interactions) return of([])
      return of(feat.interactions)
    })
  )

  constructor(
    private _interactionService: InteractionService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public featureService: FeatureService,
    public sceneService: SceneService,
    public virtualTourService: VirtualTourService
  ) {
    this.otherFeatures.forEach(feat => {
      let modifiedFeature = {
        id: feat.id,
        type: feat.type,
        parentID: feat.parentID,
        name: feat.name,
        sortValue: feat.name
      }

      let oldestParentID = feat.parentID

      while (oldestParentID) {
        const parentFeature = this.otherFeatures.find(f => f.id == oldestParentID)
        if (parentFeature) {
          modifiedFeature.sortValue = parentFeature.name + modifiedFeature.sortValue
          oldestParentID = parentFeature.parentID
          // add unicode space
          modifiedFeature.name = "\u00A0\u00A0" + modifiedFeature.name
        }
      }

      this.sortedFeatures.push(modifiedFeature)
    })

    this.sortedFeatures.sort((a, b) => a.sortValue.localeCompare(b.sortValue))
    this.modelFeatures = this.sortedFeatures.filter(f => f.type == '3D')
    this.otherScenes = this.sceneService.scenes.filter(s => s.id != this.sceneService?.selectedSceneID)

    this.sortedFeatures.unshift({ id: this.selectedFeature.id, name: 'This Feature' })
    if (this.selectedFeature.type == '3D') this.modelFeatures.unshift({ id: this.selectedFeature.id, name: 'This Feature' })

    this._subscriptions.push(
      this.selectedFeaturesInteractions$.subscribe(interactions => {
        this.interactions = interactions
      })
    )
  }

  public updateInteraction(inter: Interaction) {
    this._interactionService.updateInteraction(inter).subscribe()
  }

  deleteInteraction(inter: Interaction) {
    this._interactionService.deleteInteraction(inter).subscribe(() => {
      const intInd: number = this.interactions.findIndex(i => i.id == inter.id)
      this.interactions.splice(intInd, 1)
    })
  }

  createAction(inter: Interaction) {
    if (this.submitting) {
      return
    }
    this.submitting = true

    let action = new Action(
      'showDetails',
      { string: 'modal', size: 'medium' },
      { interactionID: inter.id } as ActionOptions
    )

    this._interactionService.createAction(action, inter).subscribe(_ => this.submitting = false)

    return true
  }

  public updateAction(action: Action, inter: Interaction, keyChanged: boolean = false) {
    if (keyChanged) action = this.updateKeyChange(action)
    this._interactionService.updateAction(action, inter).subscribe()
  }

  updateKeyChange(action: any) {
    // TODO: Make these 'remembered values' into cookies
    const id: number = action.value?.id
    const string: string = action.value?.string ?? ''
    const cameraPosition: THREE.Vector3 = action.value?.cameraPosition ?? this.cameraPosition
    let controlsPosition: THREE.Vector3 = action.value?.controlsPosition ?? this.controlsPosition
    const bearing: number = action.value?.bearing ?? this.bearing
    const longitude: number = action.value?.longitude ?? this.longitude
    const latitude: number = action.value?.latitude ?? this.latitude
    const pitch: number = action.value?.pitch ?? this.pitch
    const zoom: number = action.value?.zoom ?? this.zoom
    const inViewpointMode: boolean = action.value?.inViewpointMode ?? this.inViewpointMode
    const currentViewPointFeatureID: boolean = action.value?.currentViewPointFeatureID ?? this.currentViewPointFeatureID
    if (inViewpointMode) {
      controlsPosition = this.calculateNewCameraControlsTarget()
    }

    if (this.idKeys.includes(action.key)) {
      action.value.id = id
    } else if (action.key == 'openTab') {
      action.value.string = ''
    } else if (action.key == 'showDetails') {
      action.value.string = 'modal'
      action.value.size = 'medium'
    } else if (this.stringKeys.includes(action.key)) {
      action.value.string = string
    } else if (action.key == 'moveTo') {
      action.value.cameraPosition = cameraPosition
      action.value.controlsPosition = controlsPosition
      action.value.bearing = bearing
      action.value.longitude = longitude
      action.value.latitude = latitude
      action.value.pitch = pitch
      action.value.zoom = zoom
      action.value.inViewpointMode = inViewpointMode
      action.value.currentViewPointFeatureID = currentViewPointFeatureID
    }

    return action
  }

  updatePosition(action: Action, inter: Interaction) {
    let controlsPosition
    if (this.inViewpointMode) {
      controlsPosition = this.calculateNewCameraControlsTarget()
    } else controlsPosition = this.controlsPosition

    action.value = {
      cameraPosition: this.cameraPosition,
      controlsPosition: controlsPosition,
      bearing: this.bearing,
      longitude: this.longitude,
      latitude: this.latitude,
      pitch: this.pitch,
      zoom: this.zoom,
      inViewpointMode: this.inViewpointMode,
      currentViewPointFeatureID: this.currentViewPointFeatureID
    }

    this.updateAction(action, inter)
  }

  deleteAction(aid: number, inter: Interaction) {
    let action: Action = inter.actions.find(a => a.id == aid)

    this._interactionService.deleteAction(action).subscribe(() => {
      const actCtrlInd: number = inter.actions.findIndex(action => action.id == aid)
      inter.actions.splice(actCtrlInd, 1)
      if (inter.actions.length == 0) this.deleteInteraction(inter)
    })
  }

  calculateNewCameraControlsTarget() {
    const position = this.modelSpace.camera.position.clone()
    const target = this.modelSpace.orbitControls.target.clone()
    const distance = 2
    const direction = position.clone().sub(target).normalize().multiplyScalar(distance)
    return target.sub(direction)
  }

  submit(): boolean {
    if (this.submitting) {
      return
    }
    this.submitting = true

    let interaction = new Interaction(
      'click',
      [new Action('showDetails', { string: "modal", size: "medium" })],
      { featureID: this.featureService.selectedFeatureID } as InteractionOptions
    )

    this._interactionService.createInteraction(interaction).subscribe(inter => {
      this.submitting = false
      this.interactions.push(inter)
    })

    return true
  }

  transformKey(key: string): string {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true)
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}
