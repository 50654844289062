import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'shared-upgrade-tier-button',
  templateUrl: './upgrade-tier-button.component.html',
  styleUrls: ['./upgrade-tier-button.component.css']
})
export class UpgradeTierButtonComponent {
  constructor(private _router: Router) { }

  upgrade() {
    this._router.navigate(['upgrade'])
  }
}