import { tap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Feature } from '@classes/Feature'
import { Scene } from '@classes/Scene'
import { DeleteComponent } from '@modal/delete/delete.component'
import { TypeToConfirmComponent } from '@modal/type-to-confirm/type-to-confirm.component'
import { ContextMenuService } from '@services/context-menu.service'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { LoadingService } from '@services/loading.service'
import { ModalService } from '@services/modal.service'
import { SceneService } from '@services/scene.service'
import { UndoService } from '@services/undo.service'

@Component({
  selector: 'shared-leftside-menu',
  templateUrl: './leftside-menu.component.html',
  styleUrls: ['./leftside-menu.component.css']
})
export class LeftSideMenuComponent {
  get clickInfo() { return this.contextMenuService.clickInfo }
  get mapSpace() { return this.envManager.mapSpace }
  get sceneNotProcessing() {
    if (this.clickInfo?.type == "scene") {
      const scene = this.clickInfo?.data as Scene

      return scene?.state != "processing" && scene?.state != "failed"
    }

    return true
  }

  constructor(
    private _copyService: CopyService,
    private _featureService: FeatureService,
    private _loadingService: LoadingService,
    private _modalService: ModalService,
    private _sceneService: SceneService,
    private _undoService: UndoService,
    public contextMenuService: ContextMenuService,
    public envManager: EnvironmentManagerService,
  ) { }

  copy() {
    if (this.clickInfo?.type == 'feature') {
      const feature = this.clickInfo.data as Feature

      this._copyService.copyFeatureToClipboard(feature)
    } else if (this.clickInfo?.type == 'scene') {
      const scene = this.clickInfo.data as Scene

      this._copyService.copySceneToClipboard(scene)
    }

    this.contextMenuService.closeContext()
  }

  delete() {
    const type = this.clickInfo?.type

    if (type == 'feature') this._deleteFeature()
    else if (type == 'scene') this._deleteScene()

    this.contextMenuService.closeContext()
  }

  _deleteFeature() {
    const feature = this.clickInfo.data as Feature

    const name = feature.name
    const onSubmit = () => {
      this.envManager.sceneManager.removeFeatureFromScene(feature)
      this._featureService.deleteFeature(feature).pipe(
        // TODO: Don't clear when undoing deleteFeature is allowed
        tap(() => this._undoService.clear.bind(this._undoService)())
      ).subscribe()
    }

    this._showDeleteFeatureModal(name, onSubmit)
  }

  _deleteScene() {
    const scene = this.clickInfo.data as Scene
    const name = scene.name
    const onSubmit = () => this._sceneService.deleteScene(scene).subscribe()

    this._showDeleteSceneModal(name, onSubmit)
  }

  _showDeleteFeatureModal(name: string, onSubmit: CallableFunction) {
    const message = `"${name}" and all of its data will be deleted`

    this._modalService.showAsModal<DeleteComponent>(DeleteComponent).then(componentRef => {
      componentRef.instance.title = `Delete ${name}`
      componentRef.instance.message = message
      componentRef.instance.submitText = 'Delete'
      componentRef.instance.submitColor = 'red'
      componentRef.instance.onSubmit = onSubmit.bind(this)
    })
  }

  _showDeleteSceneModal(name: string, onSubmit: CallableFunction) {
    this._modalService.showAsModal<TypeToConfirmComponent>(TypeToConfirmComponent)
      .then((componentRef) => {
        componentRef.instance.title = `Delete ${name}`
        componentRef.instance.message = `"${name}" and all of its data will be deleted. <strong>THIS ACTION CANNOT BE UNDONE.</strong>`
        componentRef.instance.submitText = 'Delete'
        componentRef.instance.submitColor = 'red'
        componentRef.instance.confirmWord = 'DELETE SCENE'
        componentRef.instance.onSubmit = onSubmit.bind(this)
      })
  }

  duplicate() {
    if (this.clickInfo?.type == 'feature') {
      const feature = this.clickInfo.data as Feature

      this._loadingService.await(
        this._copyService.duplicateFeature(feature.id, this._sceneService.selectedScene.id)
      )
    } else if (this.clickInfo?.type == 'scene') {
      const scene = this.clickInfo.data as Scene

      this._copyService.duplicateScene(scene.id, this._sceneService.selectedScene.projectID).subscribe()
    }

    this.contextMenuService.closeContext()
  }

  removeFromGroup() {
    const feature = this._featureService.getFeature(this.clickInfo.data.id)
    const parent = this._featureService.getFeature(feature.parentID)

    this._featureService.removeFeatureFromGroup(feature, { newParentID: parent.parentID }).subscribe()
    this.contextMenuService.closeContext()
  }

  ungroup() {
    const group = this._featureService.getFeature(this.clickInfo.data.id)
    this._featureService.removeFeaturesFromGroup(group).subscribe()
    this.contextMenuService.closeContext()
  }
}