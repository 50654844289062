import { Component, Input, OnInit } from '@angular/core'
import { FeatureProperty } from '@classes/FeatureProperty'
import { ConnectionService } from '@services/connection.service'

@Component({
  selector: 'shared-view-remote-attachment',
  templateUrl: './view-remote-attachment.component.html',
  styleUrls: ['./view-remote-attachment.component.css']
})
export class ViewRemoteAttachmentComponent implements OnInit {
  @Input() attachment: FeatureProperty
  public headers: { [header: string]: string | string[] } = {}
  public url: string = ''
  public displayName: string = ''

  constructor(private _connectionService: ConnectionService) { }

  ngOnInit(): void {
    const { headers, url, displayName } = JSON.parse(this.attachment.value)

    headers
      .filter(header => header.key != null && header.key != '')
      .forEach((header: any) => this.headers[header.key] = header.value)

    this.url = url ?? ''
    this.displayName = displayName ?? ' '
  }

  downloadAttachment() {
    this._connectionService.downloadAttachment(this.url, this.displayName, this.headers)
  }
}