import Stripe from 'stripe'

import { Component, Input } from '@angular/core'

@Component({
  selector: 'billing-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent {
  @Input() invoice: Stripe.Invoice
  @Input() subtitle: string = 'Pro Tier Upgrade'
  @Input() title: string = 'Payment Confirmation'
}