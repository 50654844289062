import { Component, OnDestroy } from '@angular/core';
import { ConnectionProperty } from '@classes/Connection';
import { ConnectionService, selectedConnection$ } from '@services/connection.service';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'shared-connection-attachment-editor',
  templateUrl: './connection-attachment-editor.component.html',
  styleUrls: ['./connection-attachment-editor.component.css']
})
export class ConnectionAttachmentEditor implements OnDestroy {
  private _subscriptions: Subscription[] = []

  public parsedURL = this._connectionService.parsedURL

  public showConnectionDataPicker: boolean = false
  public attachmentIndexForPicker: number = undefined
  public attachments$ = selectedConnection$.pipe(
    startWith(this._connectionService.selectedConnection),
    filter(conn => conn !== undefined),
    map(connection => connection.attachments)
  )

  public attachments: ConnectionProperty[]

  constructor(private _connectionService: ConnectionService) {
    this._subscriptions.push(
      this.attachments$.subscribe(attachments => this.attachments = attachments)
    )
  }

  addAttachment() {
    const value = JSON.stringify({ displayName: '', headers: this.parsedURL.headers, url: '' })
    const attachment = new ConnectionProperty('string', 'attachment', value, { connectionID: this._connectionService.selectedConnection.id })

    this._connectionService.createConnectionProperty(attachment).subscribe()
  }

  deleteAttachment(property: ConnectionProperty, i: number) {
    this.closeConnectionDataPicker()
    this.attachments.splice(i, 1)
    this._connectionService.deleteConnectionProperty(property, 'Attachment Deleted').subscribe()
  }

  /** Shows/hides ConnectionDataPicker on button click
    * @param updateAttachmentCallback function provided by a child custom-field component to update itself on data selection in ConnectionDataPicker
    * @param attachmentIndexForPicker index of customField to know where a ConnectionDataPicker instance should show in the view
  */
  toggleConnectionDataPicker(updateAttachmentCallback: CallableFunction, attachmentIndexForPicker: number) {
    if (!this._connectionService.connectionResponse) return

    if (this.showConnectionDataPicker && attachmentIndexForPicker == this.attachmentIndexForPicker) {
      this.closeConnectionDataPicker()
    } else {
      this._connectionService.setConnectionDataPickerCallback((key, value, path) => {
        updateAttachmentCallback(key, value, path)
        this.closeConnectionDataPicker()
      })
      this.showConnectionDataPicker = true
      this.attachmentIndexForPicker = attachmentIndexForPicker
    }
  }

  closeConnectionDataPicker() {
    this.attachmentIndexForPicker = undefined
    this.showConnectionDataPicker = false
    this._connectionService.clearConnectionDataPickerCallback()
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())

  }
}
