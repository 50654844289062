<div>
    <div class="form-label" [ngClass]="{'text-muted': field.type == 'images' || field.type == 'files' }">
        <span>{{field.name}}</span>
        <span *ngIf="field.description"> - {{field.description}}</span>
    </div>

    <ng-container [ngSwitch]="field.type">
        <!-- Edit ChooseOne Type -->
        <ng-container *ngSwitchCase="'chooseOne'">
            <div class="input-group d-flex">
                <select [formControl]="value" class="form-select">
                    <option *ngFor="let option of field.dropdownOptions" [value]="option">
                        {{option}}
                    </option>
                </select>
            </div>
        </ng-container>

        <!-- Edit ChooseMultiple Type -->
        <ng-container *ngSwitchCase="'chooseMultiple'">
            <div class="input-group d-flex">
                <select [formControl]="value" class="form-select" multiple>
                    <option *ngFor="let option of field.dropdownOptions" [value]="option">
                        {{option}}
                    </option>
                </select>
            </div>
        </ng-container>

        <!-- Edit TrueFalse Type -->
        <ng-container *ngSwitchCase="'trueFalse'">
            <div class="btn-group d-flex bg-white">
                <input (click)="updateTrueFalse('True')" class="btn-check" [id]='"true-btn" + field.id' type="radio"
                    value="True">
                <label class="btn btn-outline-success" [for]='"true-btn" + field.id'>True</label>

                <input (click)="updateTrueFalse('False')" class="btn-check" [id]="'false-btn' + field.id" type="radio"
                    value="False">
                <label class="btn btn-outline-danger" [for]="'false-btn' + field.id">False</label>
            </div>
        </ng-container>

        <!-- Edit Location Type -->
        <ng-container *ngSwitchCase="'location'">
            <div class="d-flex flex-column gap-2 w-100">
                <shared-mini-map [center]="{lng: field?.defaultValue?.[0], lat: field?.defaultValue?.[1]}" [zoom]="15"
                    [markerLocations]="[{ location: {lng: value.value?.[0], lat: value.value?.[1]} }]"
                    (mapClick)="handleMapClick($event)">
                </shared-mini-map>

                <div class="row g-3">
                    <!-- Longitude -->
                    <div class="col-md-6">
                        <input type="number" [(ngModel)]="value.value[0]"
                            [ngClass]="{'is-invalid': value.value?.[0] != '' && !validLongitude && value.touched }"
                            class="form-control" (input)="updateLngLat($event.target.value, 0)" min="-180" max="180"
                            placeholder="Longitude" title="Longitude">

                        <div class="invalid-feedback">
                            Longitude must be between -180 and 180
                        </div>
                    </div>

                    <!-- Latitude -->
                    <div class="col-md-6">
                        <input type="number" [(ngModel)]="value.value[1]"
                            [ngClass]="{'is-invalid': field.value?.[1] != '' && !validLatitude && value.touched }"
                            class="form-control" (input)="updateLngLat($event.target.value, 1)" min="-90" max="90"
                            placeholder="Latitude" title="Latitude">

                        <div class="invalid-feedback">
                            Latitude must be between -90 and 90
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Edit Images Type -->
        <ng-container *ngSwitchCase="'images'">
            <hr>
        </ng-container>

        <!-- Edit Files Type -->
        <ng-container *ngSwitchCase="'files'">
            <hr>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div class="input-group d-flex">
                <input [formControl]="value" class="form-control" [type]="field.type">
            </div>
        </ng-container>
    </ng-container>
</div>