import Stripe from 'stripe'

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SubscriptionService, SubscriptionTiers, TierPrices } from '@services/subscription.service'

@Component({
  selector: 'billing-select-tier',
  templateUrl: './select-tier.component.html',
  styleUrls: ['./select-tier.component.css']
})
export class SelectTierComponent {
  @Input() interval: Stripe.Price.Recurring.Interval = 'month'
  @Input() selectable: ['free' | 'individual' | 'pro'] = ['pro']
  @Input() selected: 'free' | 'individual' | 'pro' = 'pro'
  @Output() selectTier = new EventEmitter<SubscriptionTiers>()

  public tiers: TierPrices

  get freeSelected() { return this.selected == 'free' }
  get individualSelected() { return this.selected == 'individual' }
  get intervalCheckbox() { return this.interval == 'year' }
  set intervalCheckbox(checked: boolean) { this.interval = checked ? 'year' : 'month' }
  get proSelected() { return this.selected == 'pro' }
  get proPrice() {
    if (this.interval == 'year') {
      return "$" + (this.tiers.pro_tier.yearly_price.unit_amount / 100) + "/yr"
    } else {
      return "$" + (this.tiers.pro_tier.monthly_price.unit_amount / 100) + "/mo"
    }
  }

  constructor(public subscriptionService: SubscriptionService) {
    this.subscriptionService.getTiers().subscribe(tiers => this.tiers = tiers)
  }

  chooseTier(tier: SubscriptionTiers) {
    if (this.selectable.includes(tier)) {
      this.selected = tier
      this.selectTier.emit(this.selected)
    }
  }
}