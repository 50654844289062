import { Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core'
import { connectionResponse$, ConnectionService } from '@services/connection.service'

/**
 * Builds a JSON-looking HTML component from a connection's API response. User can 'pick' a property name, and use it to update a form control.
 */
@Component({
  selector: 'shared-connection-data-picker',
  templateUrl: './connection-data-picker.component.html',
  styleUrls: ['./connection-data-picker.component.css']
})
export class ConnectionDataPicker implements OnInit, OnDestroy {
  @Output() close: EventEmitter<boolean> = new EventEmitter()
  private _subscriptions: Subscription[] = []
  public array
  public jsonToShow

  get isMore() {
    return this.jsonToShow?.length > this.array?.length
  }

  constructor(private connectionService: ConnectionService) { }

  ngOnInit(): void {
    this._subscriptions.push(
      connectionResponse$.subscribe(response => {
        this.jsonToShow = response.data

        if (response.dataFromRoot instanceof Array) {
          if (response.dataFromRoot.length > 100) {
            this.array = response.dataFromRoot.slice(0, 99)
          } else {
            this.array = response.dataFromRoot
          }
        }

        this.jsonToShow = response.dataFromRoot
      })
    )
  }

  showMore() {
    this.array = this.jsonToShow
  }

  /** Handles the 'pick' event from child JSONObject component */
  picked(key: any = '', value: any = '', path: any) {
    this.connectionService.selectionCallback(key, value, path)
    this.connectionService.clearConnectionDataPickerCallback()
  }

  closePicker() {
    this.close.emit(true)
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}