<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <div class='container-fluid d-flex p-4 modal-body'>
                <textarea class='form-control align-center' id="urlParameters">{{ link }}</textarea>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn" [ngClass]="{
                    'btn-primary': submitColor == 'blue' || submitColor == null,
                    'btn-success': submitColor == 'green',
                    'btn-danger': submitColor == 'red',
                    'btn-warning': submitColor == 'yellow'
                }" (click)="_submit()">{{submitText}}</button>
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>