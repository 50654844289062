<div class="table-container" (scroll)="onTableScroll($event)" [style.max-height.vh]="isMobile ? 60 : 70">
    <table [hidden]="assetFields?.length == 0" class="table table-striped m-0"
        [ngClass]="{'table-hover': hasEditAccess}">
        <thead>
            <tr>
                <th *ngIf="assetTableRows?.length > 0 && interactable">
                    <input class="btn btn-lg" type="checkbox" aria-label="Select all assets"
                        [checked]="selectedAssets.size > 0" (change)="selectAllAssets($event.target.checked)">
                </th>

                <th>
                    <div class="text-nowrap">
                        <span>Asset ID</span>
                        <i (click)="toggleSortByAssetID()"
                            [ngClass]="{'ms-1 fa': true, 'fa-caret-down': sortOrder == 'ASC' && !sortByFieldID, 'fa-caret-up': (sortOrder == 'DESC' && !sortByFieldID) || sortByFieldID}"
                            style="cursor: pointer;">
                        </i>
                    </div>
                </th>

                <th *ngFor="let field of assetFields; index as i">
                    <ng-container [ngSwitch]="field.type">
                        <ng-container *ngSwitchCase="'images'">
                            <div class="d-flex align-items-center">
                                <span>{{field.name}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'files'">
                            <div class="d-flex align-items-center">
                                <span>{{field.name}}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="input-group input-group-sm">
                                <span class="input-group-text" [title]="field.name">
                                    <span class="text-truncate" [style.max-width]="'100px'">{{field.name}}</span>
                                    <i [ngClass]="{
                                            'ms-1 fa': true, 
                                            'fa-caret-down': sortByFieldID == field.id && sortOrder == 'ASC', 
                                            'fa-caret-up': sortByFieldID != field.id || (sortByFieldID == field.id && sortOrder == 'DESC')
                                        }" (click)="sortByField(field)" style="cursor: pointer">
                                    </i>
                                </span>
                                <input type="search" class="form-control" [style.min-width]="'100px'"
                                    [value]="filterMap.get(field.id) ? filterMap.get(field.id) : ''"
                                    (focusout)="filter(field, $event.target.value)" placeholder="Filter">
                            </div>
                        </ng-container>
                    </ng-container>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let row of assetTableRows" (click)="openEditAssetPanel(row.assetID)"
                [style.cursor]="hasEditAccess ? 'pointer' : 'auto'">
                <td *ngIf="interactable" (click)="$event.stopPropagation()">
                    <input class="btn btn-lg" type="checkbox" [checked]="selectedAssets.has(row.assetID)"
                        (change)="selectAsset(row.assetID, $event.target.checked)">
                </td>

                <td>
                    <div class="d-flex gap-1 align-items-center">
                        <span>{{row.assetID}}</span>
                        <span>
                            <i class="far fa-clock icon-hover" (click)="showAssetHistory($event, row.assetID)"></i>
                        </span>
                    </div>
                </td>

                <td *ngFor="let value of row.values; index as i">
                    <div class="d-flex align-items-center gap-1">
                        <ng-container [ngSwitch]="getValueType(i)">
                            <ng-container *ngSwitchCase="'location'">
                                <span *ngIf="value" class="text-truncate">
                                    {{value[0] | number: '1.8-8'}}, {{value[1] | number: '1.8-8'}}
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'chooseMultiple'">
                                <span class="text-truncate">
                                    {{ isArray(value) ? value.join(', ') : value }}
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'images'">
                                <span *ngIf="!value || value == ''">No Files Selected</span>
                                <ng-container *ngIf="value?.length > 0">
                                    <span>{{value}}</span>
                                    <span>
                                        <i class="fa-regular fa-file-magnifying-glass text-muted icon-hover"
                                            (click)="openFilesInModal($event, row, i)"></i>
                                    </span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'files'">
                                <span *ngIf="!value || value == ''">No Files Selected</span>
                                <ng-container *ngIf="value?.length > 0">
                                    <span>{{value}}</span>
                                    <span>
                                        <i class="fa-regular fa-file-magnifying-glass text-muted icon-hover"
                                            (click)="openFilesInModal($event, row, i)"></i>
                                    </span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="'date'">
                                <span>{{value | date: 'MM-dd-yyyy'}}</span>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <span>{{value}}</span>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </tr>

            <div *ngIf="assetTableRows?.length == 0" class="p-3 fs-5 text-muted">No Entries</div>
        </tbody>
    </table>

    <div *ngIf="assetEntryCount > assetTableRows?.length"
        class="position-absolute top-100 start-50 translate-middle d-flex gap-1 pb-5">
        <button (click)="loadAllAssets()" class="btn btn-primary">Load All</button>
    </div>

    <div *ngIf="loading" class="position-absolute top-50 start-50 translate-middle d-flex gap-1 pb-5">
        <div *ngFor="let x of [].constructor(5)" class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>