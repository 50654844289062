import { concat, EMPTY, forkJoin, Observable, pipe, throwError, UnaryFunction } from 'rxjs'
import { catchError, last, map, retry, retryWhen, takeUntil, tap, toArray } from 'rxjs/operators'

import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { ToastOptions, ToastService } from './toast.service'

export type ErrorOptions = {
  log?: boolean // DEFAULT: true
  operation?: string
  // retry?: boolean // DEFAULT: false
  status?: {
    code?: number
    message?: string
  }
  toast?: boolean // DEFAULT: false
  retry?: boolean
  throwError?: boolean
}

export type HttpOptions = {
  body?: any
  headers?: HttpHeaders
  observe?: any
  params?: HttpParams
  reportProgress?: boolean
  responseType?: any
}

export type EndpointOptions = {
  error?: ErrorOptions,
  httpOptions?: HttpOptions
  multipart?: boolean
  progressBar?: ToastOptions // DEFAULT: { color: 'blue' }
  successToast?: ToastOptions // DEFAULT: { color: 'green' }
  takeUntil?: Observable<any>
  errorFunction?: Function
}

export type ManyCRUDOptions = EndpointOptions & {
  simultaneous?: boolean // DEFAULT: false
}

/**
 * TODO:
 * - Handle Emitting results
 * - Handle Editing source arrays (update, splice, push)
 * - Handle progress bars for many requests
 * - Verify that error toasts are working
 * - Implement error logging
 * - Handle optional request retrying
 * - Replace all old request methods with this service
 */
@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(
    private _http: HttpClient,
    private _toastService: ToastService,
  ) { }

  private getPipelineFromOptions<T>(options: EndpointOptions): UnaryFunction<Observable<T>, Observable<T>> {
    let pipeline = pipe() as UnaryFunction<Observable<T>, Observable<T>>

    if (options.takeUntil) {
      pipeline = pipe(
        takeUntil(options.takeUntil),
        pipeline
      )
    }

    if (options.progressBar) {
      pipeline = pipe(
        this.getProgressPipe<T>(options),
        pipeline
      )
    } else if (options.error?.toast) {
      pipeline = pipe(
        this.getToastPipe<T>(options),
        pipeline
      )
    }

    if (options.error?.retry) {
      pipeline = pipe(
        this.getRetryPipe<T>(),
        pipeline
      )
    }

    return pipeline
  }

  private getToastPipe<T>(options: EndpointOptions): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      catchError(error => {
        if (options.errorFunction) options.errorFunction()
        return this.handleError<T>(options.error)(error)
      }),
      tap(() => this._toastService.toast(options.successToast)),
    )
  }

  private getRetryPipe<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      retryWhen(errors => errors.pipe(
        retry(3),
        catchError(error => {
          return throwError(error)
        })
      )))
  }

  private getProgressPipe<T>(options: EndpointOptions): UnaryFunction<Observable<T>, Observable<T>> {
    const { next, error, reference } = this._toastService.trackProgress(options.progressBar)

    if (options.multipart) {
      return pipe(
        tap((event: any | HttpResponse<HttpEvent<any>>) => {
          progressHandler(event, next)

          if (event?.type == HttpEventType.Response) {
            this._toastService.dispose(reference)
          }
        }),
        catchError((errorMsg) => {
          error(errorMsg)
          return this.handleError<T>(options.error)(errorMsg)
        })
      )
    } else {
      return pipe(
        tap((event: any) => progressHandler(event, next)),
        catchError((errorMsg) => {
          error(errorMsg)
          return this.handleError<T>(options.error)(errorMsg)
        }),
        last(),
        map((event: HttpResponse<T>) => event.body),
        tap(() => {
          this._toastService.dispose(reference)
          this._toastService.toast(options.successToast)
        }),
      )
    }
  }

  private getManyProgressPipe<T>(options: EndpointOptions, total: number): (index: number) => UnaryFunction<Observable<T>, Observable<T>> {
    const { next, error, reference } = this._toastService.trackProgress(options.progressBar)
    let loaded: number = 0
    let completed: number = 0
    let percentages = {}

    return (index) => pipe(
      tap((event: any) => {
        if (event?.type == HttpEventType.UploadProgress) {
          const previous = percentages[index] as number ?? 0
          const percentDone = Math.ceil(100 * event.loaded / (event.total ?? 0))
          loaded += (percentDone - previous) / total
          percentages[index] = percentDone
          next(loaded)
        } else if (event?.type == HttpEventType.Response) {
          ++completed
          if (total == completed) {
            next(100)
            this._toastService.dispose(reference)
          }
        }
      }),
      catchError((errorMsg) => {
        error(errorMsg)
        return this.handleError<T>(options.error)(errorMsg)
      }),
      last()
    )
  }

  create<T>(url: string, body: any, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)
    const hasProgressBar = options.progressBar != null

    if (hasProgressBar) {
      httpOptions.reportProgress = true
      httpOptions.observe = 'events'
    }

    return this._http.post<T>(url, body, httpOptions).pipe(
      this.getPipelineFromOptions(options)
    )
  }

  delete<T>(url: string, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)

    return this._http.delete<T>(url, httpOptions)
      .pipe(this.getPipelineFromOptions(options))
  }

  get<T>(url: string, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)
    const hasProgressBar = options.progressBar != null

    if (hasProgressBar) {
      httpOptions.reportProgress = true
      httpOptions.observe = 'events'
    }

    return this._http.get<T>(url, httpOptions)
      .pipe(this.getPipelineFromOptions(options))
  }

  update<T>(url: string, body: any, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)
    const hasProgressBar = options.progressBar != null

    if (hasProgressBar) {
      httpOptions.reportProgress = true
      httpOptions.observe = 'events'
    }

    return this._http.put<T>(url, body, httpOptions)
      .pipe(this.getPipelineFromOptions(options))
  }

  createMany<T>(url: string, body: any[], options: ManyCRUDOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)
    const toastPipe = this.getToastPipe<T[]>(options)

    const hasProgressBar = options.progressBar != null
    if (hasProgressBar) {
      httpOptions.reportProgress = true
      httpOptions.observe = 'events'
    }
    const progressPipe = this.getManyProgressPipe(options, body.length)
    const creations$ = body.map((item, i) => this._http.post<T>(url, item, httpOptions).pipe(progressPipe(i)))

    if (options.simultaneous) { // Requests will occur simultaneously
      return forkJoin(creations$).pipe(map((events: HttpResponse<T>[]) => events.map(event => event.body)), toastPipe)
    } else { // Requests will occur sequentially
      return concat(...creations$).pipe(toArray(), map((events: HttpResponse<T>[]) => events.map(event => event.body)), toastPipe)
    }
  }

  /** @internal IMPORTANT NOTE: Not implemented */
  deleteMany<T>(url: string, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)

    return this._http.delete<T[]>(url, httpOptions)
      .pipe(this.getToastPipe<T[]>(options))
  }

  /** @internal IMPORTANT NOTE: Not implemented */
  getMany<T>(url: string, options: EndpointOptions = {}) {
    const httpOptions = applyDefaultHeaders(options.httpOptions)

    return this._http.get<T[]>(url, httpOptions)
      .pipe(this.getToastPipe<T[]>(options))
  }

  /** TODO: Handle progress bar */
  updateMany<T>(urls: string[], body: any[], options: ManyCRUDOptions = {}): Observable<T[]> {
    const httpOptions = applyDefaultHeaders(options.httpOptions)
    const updates$ = body.map((item, i) => this._http.put<T>(urls[i], item, httpOptions))
    const pipeFunctions = this.getToastPipe<T[]>(options)

    if (options.simultaneous) { // Requests will occur simultaneously
      return forkJoin(updates$).pipe(pipeFunctions)
    } else { // Requests will occur sequentially
      return concat(...updates$).pipe(toArray(), pipeFunctions)
    }
  }

  /**
   * Handle failed Http operation and log it. Then let the app continue.
   * @param options - Options for what to do with the error.
   */
  private handleError<T>(options: ErrorOptions = {}) {
    return (errorResponse: HttpErrorResponse): Observable<T> => {
      if (options.log ?? true) {
        // TODO: send the error to remote logging infrastructure
        console.error(`${options.operation} failed: ${errorResponse.statusText}`)
      }

      if (options.toast) { // Apply defaults when toast options are not provided.
        const toastOptions = {
          color: 'red',
          title: `${options.operation} failed`,
          message: errorResponse.error ?? errorResponse.statusText,
        } as ToastOptions

        this._toastService.toast(toastOptions)
      }

      if (options?.throwError) return throwError(errorResponse)
      else return EMPTY
    }
  }
}

function applyDefaultHeaders(options: HttpOptions = {}): HttpOptions {
  const accessToken = localStorage.getItem('accessToken')
  const params = options?.params
  var headers = options?.headers ?? new HttpHeaders()


  if (accessToken) {
    headers = headers.set('access-token', accessToken)
  }

  return { ...options, headers, params } as HttpOptions
}

function progressHandler(event: HttpEvent<any> | undefined, next: (value: number) => void) {
  if (event?.type == HttpEventType.ResponseHeader) {
    if (event.status == 415) {
      throw event.statusText
    }
  } else if (event?.type == HttpEventType.UploadProgress || event?.type == HttpEventType.DownloadProgress) { /** Compute and show the % done: */
    const percentDone = Math.ceil(100 * event.loaded / (event.total ?? 0))

    // TODO: Handle case where ceil results in early 100%
    next(percentDone)
  } else if (event?.type == HttpEventType.Response) {
    next(100)
  }
}