import { Subscription } from 'rxjs'

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core'
import { FeatureProperty } from '@classes/FeatureProperty'
import { FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { filter, map, startWith } from 'rxjs/operators'

@Component({
  selector: 'shared-edit-attachment-list',
  templateUrl: './edit-attachment-list.component.html',
  styleUrls: ['./edit-attachment-list.component.css']
})
export class EditAttachmentListComponent implements OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef
  private _subscriptions: Subscription[] = []
  public selectedFeature$ = this._featureService.selectedFeature$

  get atDocumentLimit() { return this._subscriptionService.atDocumentLimit }
  get remoteAttachments() { return this.attachments.filter(attachment => attachment.fileReference == null) }
  get selectedFeatureID() { return this._featureService.selectedFeatureID }
  public attachments: FeatureProperty[]
  public attachments$ = this._featureService.selectedFeature$.pipe(
    startWith(this._featureService.selectedFeature),
    filter(f => f !== undefined),
    map(feature => {
      return feature.attachments
        .sort((a, b) => {
          const aValue: string = a.value?.toString() || ''
          const bValue: string = b.value?.toString() || ''
          if (aValue > bValue) return 1
          else if (aValue < bValue) return -1
          else return 0
        })
    })
  )
  constructor(
    private _featureService: FeatureService,
    private _fileReferenceService: FileReferenceService,
    private _modelService: ModelService,
    private _projectService: ProjectService,
    private _subscriptionService: SubscriptionService,
  ) {
    this.attachments$.subscribe(as => this.attachments = as)
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  downloadAttachment(attachmentProperty: FeatureProperty) {
    const fileReference = attachmentProperty.fileReference
    this._fileReferenceService.downloadFile(fileReference, this._projectService.currentProject.id).subscribe()
  }

  removeAttachment(property: FeatureProperty) {
    this.attachments.splice(this.attachments.indexOf(property), 1)
    this.fileInput.nativeElement.value = ''
    this._featureService.deleteFeatureProperty(property).subscribe()
  }

  addLocalAttachments(files: FileList) {
    this._featureService.addAttachments(this.selectedFeatureID, Array.from(files)).subscribe(_ => {
      this.fileInput.nativeElement.value = ''
    })
  }

  addRemoteAttachment() {
    const value = JSON.stringify({ headers: [], url: '', displayName: '' })
    const property = new FeatureProperty('string', 'attachment', value, { featureID: this.selectedFeatureID })

    this.attachments.push(property)
    this._featureService.createFeatureProperty(property).subscribe()
  }

  getIcon(attachmentProperty: FeatureProperty) {
    return this._modelService.iconClasses(attachmentProperty?.fileReference?.filename)
  }
}