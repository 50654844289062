<form [formGroup]="form" class="d-grid gap-2">
    <!-- Name Input -->
    <div>
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" formControlName="name" class="form-control"
            [class.is-invalid]="name.invalid && name.touched">
        <div class="invalid-feedback" *ngIf="name.invalid">Name is required.</div>
    </div>

    <!-- File Input -->
    <div>
        <label for="e57File" class="form-label">File</label>
        <input type="file" accept=".e57" id="e57File" class="form-control" (change)="setFile($event.target.files[0])"
            [class.is-invalid]="file.invalid && file.touched">
        <div class="invalid-feedback" *ngIf="file.errors?.invalidFileType">File is invalid.</div>
        <div class="invalid-feedback" *ngIf="file.errors?.required">File is required.</div>
    </div>

    <!-- Select Model -->
    <div>
        <label class="form-label">
            Model
        </label>

        <shared-model-picker *ngIf="models$ | async as models" [models]="models" class="card"
            (onSelect)="setModel($event.model)" [class.is-invalid]="model.invalid && model.touched">
        </shared-model-picker>

        <input [hidden]="true" formControlName="model">

        <div class="invalid-feedback" *ngIf="model.touched && model.invalid">Model is required.</div>
    </div>
</form>