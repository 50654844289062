<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>

                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <form class="container p-4 modal-body d-grid gap-3" [formGroup]="memberForm">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="firstName" formControlName="firstName"
                            placeholder="ex. John"
                            [ngClass]="{'is-valid': firstName.valid, 'is-invalid': firstName.invalid && firstName.touched }">
                    </div>

                    <div class="col-md-6">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="lastName" formControlName="lastName"
                            placeholder="ex. Smith"
                            [ngClass]="{'is-valid': lastName.valid, 'is-invalid': lastName.invalid && lastName.touched }">
                    </div>
                </div>

                <div>
                    <label for="email" class="form-label">Email</label>
                    <input type="email" class="form-control" id="email" formControlName="email"
                        placeholder="ex. johnsmith@gmail.com"
                        [ngClass]="{'is-valid': email.valid, 'is-invalid': email.invalid && email.touched }">
                </div>

                <div>
                    <label for="role" class="form-label">Role</label>

                    <div class="d-flex">
                        <select class='form-select' type="text" formControlName="role"
                            [ngClass]="{'is-valid': role.valid, 'is-invalid': role.invalid && role.touched }">
                            <option *ngFor='let role of organizationRoles' [value]="role.id">
                                {{role.name | titlecase}}
                            </option>
                        </select>
                    </div>

                    <div class="d-flex align-items-center justify-content-end gap-2 pt-2">
                        <div *ngIf='seatsAvailable == 0' role="button" (click)="redirectToBillingDashboard()">
                            <span class="text-success">Add More Seats</span>
                        </div>

                        <div class="card p-1 rounded-pill" [ngClass]="seatsAvailableBackgroundColor">
                            <span class="mx-1">{{seatsAvailable}} {{seatsAvailable == 1 ? 'Seat' : 'Seats' }} Available</span>
                        </div>
                    </div>
                </div>
            </form>

            <div class="modal-footer">
                <button (click)="_submit()" [disabled]="seatsAvailable < 1" type="button" class="btn" [ngClass]="{
                        'btn-primary': submitColor == 'blue' || submitColor == null,
                        'btn-success': submitColor == 'green',
                        'btn-danger': submitColor == 'red',
                        'btn-warning': submitColor == 'yellow'
                    }">
                    {{submitText}}
                </button>

                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>