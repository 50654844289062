<label class="eye-icon-checkbox m-1" (click)='$event.stopPropagation()'>
    <ng-container *ngIf="feature.type == 'group' || isMapFeature(feature)">
        <input [disabled]="isFilteredOut" *ngIf="loaded" [hidden]="true" [(ngModel)]="feature.visible" type="checkbox"
            (change)='toggleVisibility(feature, $event.target)'>
        <i class="fa-solid fa-fw" [ngClass]="{
            'fa-eye': feature.visible, 
            'fa-eye-slash unchecked': !feature.visible || !loaded}">
        </i>
    </ng-container>
    <ng-container *ngIf="isThreeFeature(feature) && model != null">
        <input [disabled]="isFilteredOut" *ngIf="loaded" [hidden]="true" [(ngModel)]="model.visible"
            type="checkbox" (change)='toggleVisibility(feature, $event.target)'>
        <i class="fa-solid fa-fw" [ngClass]="{
            'fa-eye': model?.visible && !feature.unloaded, 
            'fa-eye-slash unchecked': !model?.visible || feature.unloaded}">
        </i>
    </ng-container>
    <ng-container *ngIf="isThreeFeature(feature) && model == null">
        <input [disabled]="isFilteredOut" [hidden]="true" [(ngModel)]="feature.visible" type="checkbox"
            (change)='loadThenMakeVisible(feature)'>
        <i class="fa-solid fa-fw fa-eye-slash unchecked"></i>
    </ng-container>
</label>