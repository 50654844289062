<div *ngIf="item" class="d-flex gap-1 align-items-center">
    <span class="badge rounded-pill" [ngClass]="{
            'bg-primary': usagePercent < .95,
            'bg-warning': usagePercent >= .95 && usagePercent < 1,
            'bg-danger': usagePercent >= 1
          }">
        {{usage | number}} / {{limit | number}}
    </span>
    <span>
        {{name}}
    </span>
</div>