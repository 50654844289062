<div class="d-grid gap-3">
    <!-- One or Many Radio button group -->
    <div class="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" name="btnradio" id="create-one-radio-btn" [value]="true"
            [(ngModel)]="createOne">
        <label class="btn btn-outline-primary" for="create-one-radio-btn">
            Create One
        </label>

        <input type="radio" class="btn-check" name="btnradio" id="create-many-radio-btn" [value]="false"
            [(ngModel)]="createOne">
        <label class="btn btn-outline-primary" for="create-many-radio-btn">
            Create Multiple
        </label>
    </div>

    <!-- Marker Icon Upload -->
    <div class="d-flex">
        <label class="form-control btn btn-success btn-sm" style="width: 110px">
            + Marker Icon
            <input [hidden]="true" type="file" accept="image/*" (change)="uploadIcon($event.target.files[0])" #icon>
        </label>

        <small *ngIf="markerIcon == null && iconTouched" class="form-text text-danger ms-2">
            Marker icon is required.
        </small>

        <ng-container *ngIf="markerIcon">
            <div class="d-flex align-items-center border rounded-end">
                <span class="px-3 me-auto text-truncate">{{ markerIcon.name }}</span>
                <a class="btn btn-sm btn-close mx-1" (click)="markerIcon = undefined; icon.value = ''"></a>
            </div>

            <!-- Use-the-File's-Coordinates-Metadata Button -->
            <div *ngIf="iconGPSPosition && createOne" class="ms-auto btn btn-sm btn-primary"
                (click)="setPosition(marker, iconGPSPosition)">
                <i class="fa-regular fa-map-location-dot"></i>
                Use Icon's GPS Position
            </div>

            <div class="d-flex mt-2">
                <!-- Upload to Description -->
                <label *ngIf="!embeddedFile" class="btn btn-success btn-sm">
                    <i class="fa-solid fa-upload"></i> Embed Image
                    <input [hidden]="true" type="file" accept="image/*" (change)="embedImage(0, $event.target.files[0])"
                        #embeddedImage>
                </label>

                <!-- Embedded Image -->
                <button *ngIf="embeddedFile" type="button" class="badge rounded-pill bg-info"
                    (click)="removeEmbeddedImage(0)">
                    <i class="fa-solid fa-image"></i> 🗙
                </button>

                <!-- Use-embedded-File's-Coordinates-Metadata Button -->
                <div *ngIf="gpsPosition" class="ms-auto btn btn-sm text-primary p-0"
                    (click)="setPosition(marker, gpsPosition)">
                    <i class="fa-regular fa-map-location-dot"></i>
                </div>
            </div>

        </ng-container>
    </div>

    <!-- Create One Marker -->
    <ng-container *ngIf="createOne; else createMultiple">
        <!-- Name -->
        <div>
            <label class="form-label" for="marker-name">Name</label>
            <input type="text" id="marker-name" class="form-control" required [(ngModel)]="marker.name" #name="ngModel">

            <small *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-danger">
                Name is required.
            </small>
        </div>

        <!-- Coordinates -->
        <div class="flex-grow-1">
            <div class="d-flex">
                <label class="form-label" for="marker-longitude-input">
                    Longitude
                </label>

                <div class="ms-auto">
                    <a *ngIf="coordinates?.length > 0" class="btn text-primary p-0 mx-2"
                        (click)="pasteCoordinates(marker)">
                        <i class="fas fa-paste fa-fw"></i>
                    </a>

                    <div class="btn btn-sm text-warning p-0" (click)="resetPosition(marker)">
                        <i class="fa-sharp fa-solid fa-location-dot-slash"></i>
                    </div>
                </div>
            </div>
            <input type="text" id="marker-longitude-input" class="form-control" required
                [(ngModel)]="marker.position[0]" #longitude="ngModel">

            <small *ngIf="longitude.invalid && (longitude.dirty || longitude.touched)" class="form-text text-danger">
                Longitude is required.
            </small>
            <small *ngIf="marker.position[0] < -180 || marker.position[0] > 180" class="form-text text-danger">
                Longitude must be between -180 and 180.
            </small>
        </div>

        <div class="flex-grow-1">
            <label class="form-label" for="marker-latitude-input">
                Latitude
            </label>
            <input type="text" id="marker-latitude-input" class="form-control" required [(ngModel)]="marker.position[1]"
                #latitude="ngModel">

            <small *ngIf="latitude.invalid && (latitude.dirty || latitude.touched)" class="form-text text-danger">
                Latitude is required.
            </small>
            <small *ngIf="marker.position[1] < -90 || marker.position[1] > 90" class="form-text text-danger">
                Latitude must be between -90 and 90.
            </small>
        </div>

        <!-- Description -->
        <div>
            <label class="form-label" for="marker-longitude-input">
                Description
            </label>

            <!-- Editable Description -->
            <textarea class="form-control" rows="5" id="marker-description-input" [(ngModel)]="marker.description">
            </textarea>

            <div class="d-flex mt-2">
                <!-- Upload to Description -->
                <label *ngIf="!embeddedFile" class="btn btn-success btn-sm">
                    <i class="fa-solid fa-upload"></i> Embed Image
                    <input [hidden]="true" type="file" accept="image/*" (change)="embedImage(0, $event.target.files[0])"
                        #embeddedImage>
                </label>

                <!-- Embedded Image -->
                <button *ngIf="embeddedFile" type="button" class="badge rounded-pill bg-info"
                    (click)="removeEmbeddedImage(0)">
                    <i class="fa-solid fa-image"></i> 🗙
                </button>

                <!-- Use-embedded-File's-Coordinates-Metadata Button -->
                <div *ngIf="gpsPosition" class="ms-auto btn btn-sm text-primary p-0"
                    (click)="setPosition(marker, gpsPosition)">
                    <i class="fa-regular fa-map-location-dot"></i>
                </div>
            </div>
        </div>

        <div class="btn btn-success" (click)="createMarkers$()">SUBMIT</div>
    </ng-container>

    <!-- Create Multiple Markers -->
    <div #createMultiple>
        <!-- Markers to be Created -->
        <label class="form-label">Markers</label>

        <div class="card bg-light d-grid gap-3">
            <ng-container *ngFor="let info of markerInformation; index as i">
                <div class="bg-white rounded d-flex d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <input [(ngModel)]="info.marker.name" type="text" class="form-control">

                        <!-- Embedded Image -->
                        <a class="btn text-secondary border d-flex align-items-center" (click)="removeEmbeddedImage(i)">
                            <i class="fa-solid fa-image"></i> 🗙
                        </a>
                    </div>

                    <div class="d-flex align-items-center">
                        <input type="text" class="form-control" required [(ngModel)]="info.marker.position[0]"
                            #longitude="ngModel" placeholder="longitude">
                        <input type="text" class="form-control" required [(ngModel)]="info.marker.position[1]"
                            #latitude="ngModel" placeholder="latitude">
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>