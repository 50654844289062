import Stripe from 'stripe'

import { Component } from '@angular/core'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'billing-edit-add-ons',
  templateUrl: './edit-add-ons.component.html',
  styleUrls: ['./edit-add-ons.component.css']
})
export class EditAddOnsComponent {
  public addons: Stripe.Price[]

  get interval() { return this._subscriptionService.interval }
  get items() { return this._subscriptionService.items }

  constructor(private _subscriptionService: SubscriptionService) {
    this._subscriptionService.getAddonPackages(this.interval)
      .subscribe(addons => this.addons = addons.filter(addon => addon.product['name'] != 'Unlimited Resources'))
  }

  hasAddon(addonProduct: Stripe.Product) {
    const subscriptionProducts = this.items.map(item => item.price.product as Stripe.Product)

    return subscriptionProducts.some(product => product.id == addonProduct.id)
  }

  mailtoLink(addon: Stripe.Product): string {
    const toEmail = 'support@kartorium.com'
    const subject = `Request to Add ${addon.name} Product`
    const body = `I am interested in adding the ${addon.name} product to my subscription. Could you please assist me with this process? \n\nThank you!`

    // Properly encode the body to ensure compatibility with mail clients
    const encodedBody = encodeURIComponent(body).replace(/%0D%0A/g, '%0D%0A')

    return `mailto:${toEmail}?subject=${encodeURIComponent(subject)}&body=${encodedBody}`
  }
}