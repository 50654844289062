import { Toast } from 'bootstrap'

import { HttpEvent, HttpEventType } from '@angular/common/http'
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core'
import { ToastComponent } from '@shared/toast/toast.component'

export type ToastReference = {
  componentRef: ComponentRef<ToastComponent>
  toast: Toast
}

export type ToastColor = 'blue' | 'green' | 'red' | 'yellow'

export type ToastAction = {
  callback?: CallableFunction
  color?: ToastColor
  title?: string
}

export type ToastOptions = {
  animation?: boolean
  actionButton?: ToastAction
  autohide?: boolean
  color?: ToastColor
  delay?: number
  message?: string
  title?: string
}

export const httpProgressOptions: any = { reportProgress: true, observe: 'events' }

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _componentFactory: ComponentFactory<ToastComponent>
  private _references: ToastReference[] = []

  public allowToasts = true
  public toastContainer: ViewContainerRef

  constructor(resolver: ComponentFactoryResolver) {
    this._componentFactory = resolver.resolveComponentFactory(ToastComponent)
  }

  private _getToast(title: string) {
    return this._references.find(toast => toast.componentRef.instance.title == title)
  }

  private _createToast(options: ToastOptions) {
    const componentRef = this.toastContainer.createComponent(this._componentFactory)
    const element = componentRef.instance.elementRef.nativeElement.children[0]

    /** Configure default toast behavior */
    const behavior: Toast.Options = {
      animation: options.animation ?? true,
      autohide: options.autohide ?? true,
      delay: options.delay ?? 5000
    }

    /** Configure toast component style */
    if (options.actionButton != null) {
      componentRef.instance.actionButton = options.actionButton
      componentRef.instance.showActionButton = true
    }
    componentRef.instance.color = options.color ?? 'green'
    componentRef.instance.message = options.message
    componentRef.instance.title = options.title

    const toast = new Toast(element, behavior)

    this._references.push({ componentRef, toast })

    return { componentRef, toast } as ToastReference
  }

  toast(options: ToastOptions): ToastReference {
    if (this.toastContainer == null) {
      return console.warn('Toast container missing.') as any
    }

    if (this.allowToasts && options != null) {
      const reference = this._getToast(options.title)

      if (reference != null) {
        reference.componentRef.instance.count += 1
        reference.toast.show()

        return reference
      } else {
        const reference = this._createToast(options)
        const { componentRef, toast } = reference
        const element = componentRef.instance.elementRef.nativeElement.children[0]

        element.addEventListener('hidden.bs.toast', () => {
          const index = this._references.findIndex(reference => reference.toast == toast)

          this._references.splice(index, 1)
        })

        toast.show()

        return reference
      }
    }
  }

  /** Return distinct message for sent, upload progress, & response events */
  public createProgressHandler = (options: ToastOptions, files: File[]) => {
    options.title = options.title ?? `Uploading ${files.length > 1 ? 'Files' : 'File'}`
    options.message = options.message ?? `Please wait while the 
      ${files.length > 1 ? 'files' : 'file'} 
      ${files.length > 1 ? 'finish' : 'finshes'} 
      uploading`
    options.color = options.color ?? 'blue'
    options.autohide = options.autohide ?? false

    const { componentRef, toast } = this.toast(options)

    componentRef.instance.showProgressBar = true

    return (event: HttpEvent<any> | undefined) => {
      if (event?.type == HttpEventType.Sent) {
        let filesSize = 0
        files.forEach(file => filesSize += file.size)

        componentRef.instance.message = `Uploading ${files.length > 1 ? 'files' : 'file'} of size ${filesSize}.`

        setTimeout(() => {
          componentRef.instance.title = options.title
          componentRef.instance.message = 'This may take a little bit -- please stay on the page'
        }, 500);
      } else if (event?.type == HttpEventType.ResponseHeader) {
        if (event.status == 415) {
          componentRef.instance.message = 'Insufficient data provided in image'
          componentRef.instance.color = 'red'
        }
      } else if (event?.type == HttpEventType.UploadProgress) { /** Compute and show the % done: */
        const percentDone = Math.round(100 * event.loaded / (event.total ?? 0))

        componentRef.instance.percentDone = percentDone
        componentRef.instance.message = `${files.length > 1 ? 'Files' : 'File'} ${files.length > 1 ? 'are' : 'is'} ${percentDone}% uploaded.`
      } else if (event?.type == HttpEventType.Response) {
        componentRef.instance.percentDone = 100
        componentRef.instance.title = 'Process Completed'
        componentRef.instance.color = 'green'
        componentRef.instance.message = undefined
        componentRef.instance.showActionButton = true

        setTimeout(() => toast.hide(), 2500)
      }
    }
  }

  public trackProgress(options: ToastOptions = {}) {
    options.autohide = options.autohide ?? false
    options.animation = options.animation ?? false
    const reference = this._createToast(options)
    const { componentRef, toast } = reference
    const component = componentRef.instance

    component.showProgressBar = true
    component.showActionButton = options.actionButton != null
    component.actionButton = options.actionButton
    component.color = options.color ?? 'blue'
    component.title = options.title ?? 'Uploading'
    component.message = options.message ?? 'Please wait while the file finishes uploading'

    toast.show()

    return {
      next: (percent: number) => componentRef.instance.percentDone = percent,
      error: (message: string) => this.dispose(reference),
      reference
    }
  }

  /**
  * Sets up a beforeunload listener to prevent page navigation.
  * Returns a function that, when called, removes the beforeunload listener.
  *
  * @returns {Function} A function to call to remove the beforeunload listener.
  */
  setupNavigationWarning() {
    const listener = (event: BeforeUnloadEvent) => {
      // Prevents the navigation. Note: Custom message will not be shown in modern browsers.
      event.preventDefault()
      // Setting returnValue to a non-empty string displays the default browser warning.
      event.returnValue = ''
    }

    // Add the beforeunload event listener
    window.addEventListener('beforeunload', listener)

    // Return a function that removes the listener
    return () => window.removeEventListener('beforeunload', listener)
  }

  dispose(reference: ToastReference) {
    const { componentRef, toast } = reference
    const index = this._references.findIndex(reference => reference.componentRef.instance.title == componentRef.instance.title)

    this._references.splice(index, 1)

    toast.hide()
    toast.dispose()
    componentRef.destroy()
  }
}