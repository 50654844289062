<ul class="d-flex list-group">
    <ng-container *ngIf="customFields.length > 0">
        <li *ngFor="let field of customFields" class="list-group-item p-3" style="overflow: hidden;">
            <div class="d-flex">
                <div class="me-2" style="max-width: 50%; word-wrap: break-word;" [title]="field.key">
                    <span class="fw-bold">{{field.key}}</span>
                </div>
                <div style="max-width: max-content; overflow: hidden; word-wrap: break-word;;" [title]="field.value">
                    <span *ngIf="!isLink(field.value)" style="user-select: text;">{{field.value}}</span>
                    <a *ngIf="isLink(field.value)" href="{{field.value}}" target="_blank">{{field.value}}</a>
                </div>
            </div>
        </li>
    </ng-container>
</ul>