import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { AssetFieldValue } from '@classes/AssetFieldValue';
import { FileReference } from '@classes/FileReference';
import { AssetService } from '@services/asset.service';
import { AuthenticationService } from '@services/authentication.service';
import { FileReferenceService } from '@services/file-reference.service';
import { Modal } from 'bootstrap';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-show-asset-files',
  templateUrl: './show-asset-files.component.html',
  styleUrls: ['./show-asset-files.component.css']
})
export class ShowAssetFilesComponent {
  public cancelText = 'Cancel'
  public modal: Modal
  public title = 'Modal Title'

  private _assetFieldValue: AssetFieldValue
  get assetFieldValue() { return this._assetFieldValue }
  set assetFieldValue(afv: AssetFieldValue) { 
    this._assetFieldValue = afv
    this.fileReferences = afv.fileReferences
  }

  private _fileReferences: FileReference[]
  get fileReferences() { return this._fileReferences}
  set fileReferences(fileReferences: FileReference[]) {
    this._fileReferences = fileReferences
    this.registerFileURL(this.fileReferences)
  }

  private fileURLMap: { [key: string]: Observable<string> } = {}
  public fileType: 'images' | 'files'

  public onCancel: CallableFunction

  constructor(private authenticationService: AuthenticationService, private fileReferenceService: FileReferenceService, private http: HttpClient) { }

  registerFileURL(fileReferences: FileReference[]) {
    fileReferences.forEach(fileReference => {
      const key = fileReference.id
      this.fileURLMap[key] = this.getUrlForFileReference$(fileReference)
    })
  }

  getUrlForFileReference$(fileReference: FileReference) {
    return this.authenticationService.getToken().pipe(
      map(token => {
        return this.fileReferenceService.getUrlForFileDownload(fileReference.id, this.assetFieldValue.assetID, fileReference.hash, token)
      })
    )
  }

  openFile(fileReference: FileReference) {
    const imageTypes = ['.jpg', '.jpeg', '.png',]
    if (imageTypes.includes(fileReference.extension)) {
      this.openImageInNewTab(fileReference)
    } else if (fileReference.extension == '.pdf') {
      this.fileURLMap[fileReference.id].pipe(
        switchMap(url => {
          return this.http.get(url, { responseType: 'blob' }).pipe(map(res => new Blob([res], { type: 'application/pdf' })))
        })
      ).subscribe(blob => {
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL, '_blank');
      })
    } else {
      this.fileReferenceService.downloadFile(fileReference, this.assetFieldValue.assetID).subscribe()
    }
  }

  openImageInNewTab(fileReference: FileReference) {
    this.fileURLMap[fileReference.id].subscribe(url => {
      // Create a new window/tab
      const newWindow = window.open('', '_blank')

      // Write content to the new window/tab
      newWindow.document.write(`<html><head><title>${fileReference.filename}</title></head><body><img src="${url}" style='max-width:500px;'></body></html>`)

      // Close the document to allow interaction
      newWindow.document.close()
    })
  }


  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}
