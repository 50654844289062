import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { simpleHash } from '@utils/Objects'

const hashlist = [
  "-85699133",
  "-540994590",
  "-738500033",
  "-895608488",
  "-966320248",
  "-1121816528",
  "-1346452443",
  "-1555794487",
  "-1712579057",
  "-1980283031",
  "101304458",
  "953544467",
]

type FontAwesomeIcon = { class: string, prefix: string, iconName: string, icon: any[] }

export const fontAwesomeIcons: FontAwesomeIcon[] = Array.from(
  new Map(
    Object.values(fas)
      .filter(icon => !hashlist.includes(simpleHash(icon.iconName)))
      .map(icon => {
        const iconClass = `${icon.prefix} fa-${icon.iconName}`
        return [iconClass, { class: iconClass, ...icon }] as [string, FontAwesomeIcon]
      })
      .sort(([, iconA], [, iconB]) => iconA.iconName.localeCompare(iconB.iconName))
  ).values()
)

@Component({
  selector: 'shared-font-awesome-icon-picker',
  templateUrl: './font-awesome-icon-picker.component.html',
  styleUrls: ['./font-awesome-icon-picker.component.css']
})
export class FontAwesomeIconPickerComponent {
  @Input() selected: string = ''
  @Output() picked = new EventEmitter<string>()

  public icons = fontAwesomeIcons
  public search = ''

  selectIcon(icon: FontAwesomeIcon) {
    this.selected = icon.class
    this.picked.emit(icon.class)
  }
}