<!-- Editing Subscription -->
<div [hidden]="state != 'editing'" class="h-100">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Item</th>
                <th scope="col" class="align-middle text-center">Quantity</th>
                <th scope="col" class="align-middle text-center">Change</th>
            </tr>
        </thead>
        <tbody>
            <tr [edit-item] #editItems *ngFor="let item of items" [item]="item"></tr>
        </tbody>
    </table>

    <div class="d-flex align-items-center gap-1 p-2 w-100" *ngIf="changesPresent">
        <span>Net Adjustment:</span>
        <span [ngClass]="{'text-success': costDifference < 0, 'text-danger':  costDifference > 0}">
            {{ costDifference > 0 ? '+' : '' }}{{ costDifference | currency}} / {{interval}}
        </span>
        <button class="btn btn-outline-secondary ms-auto" (click)="clear()">Clear</button>
        <button class="btn btn-primary" (click)="processChanges()">Process Changes</button>
    </div>
</div>

<div *ngIf="state != 'editing'" class="h-100 d-flex flex-column gap-2 justify-content-center align-items-center">
    <!-- Calculating Invoice Cost -->
    <h1 *ngIf="state == 'processing'" class="fw-bold text-secondary fs-1">
        Processing your request...
    </h1>

    <!-- Preview Invoice -->
    <div *ngIf="state == 'previewing'" class="card shadow-sm d-flex flex-column p-3 gap-4">
        <div class="d-flex align-items-center gap-3">
            <span> Amount Due Today: </span>
            <span class="fw-bold fs-3">{{invoiceAmount | currency}}</span>
        </div>
        <div class="d-flex justify-content-end gap-2">
            <button class="btn btn-outline-secondary" (click)="cancelChanges()">Cancel</button>
            <button class="btn btn-primary" (click)="confirmChanges()">Confirm</button>
        </div>
    </div>

    <!-- Making Subscription Changes -->
    <h1 *ngIf="state == 'confirming'" class="fw-bold text-secondary fs-1">
        Processing Payment...
    </h1>

    <!-- Final Invoice -->
    <ng-container *ngIf='state == "summary"'>
        <billing-view-invoice [invoice]="invoice" [title]="'Payment Confirmed'" [subtitle]="'Subscription Updated'">
        </billing-view-invoice>

        <div class="mt-3 d-flex justify-content-end">
            <a (click)="close()" class="link-primary" type="button">
                Click here to close
            </a>
        </div>
    </ng-container>

    <!-- Errors -->
    <ng-container *ngIf='state == "error"'>
        <span class="fw-bold text-danger fs-1 payment-status-text"> Update Failed! </span>
        <span class="text-muted fs-6 payment-status-text"> {{failedMessage}} </span>
    </ng-container>
</div>