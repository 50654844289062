import { Modal } from 'bootstrap'
import { forkJoin, of, Subscription } from 'rxjs'
import { distinctUntilKeyChanged, filter, map, startWith, switchMap } from 'rxjs/operators'

import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Feature, FeatureCustomField } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { ModalBody } from '@services/modal.service'
import { ProjectService } from '@services/project.service'
import { RightSidebarService } from '@services/right-sidebar.service'

@Component({
  selector: 'shared-view-feature-details',
  templateUrl: './view-feature-details.component.html',
  styleUrls: ['./view-feature-details.component.css'],
})
export class ViewFeatureDetailsComponent implements AfterContentInit, ModalBody, OnChanges, OnDestroy {
  @Input() detailsType: 'panel' | 'modal' = 'modal'
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal

  private _subscriptions: Subscription[] = []

  public cancelText = 'Cancel'
  public title = 'Modal Title'
  public modalSize: 'medium' | 'large' | 'fullscreen'

  public attachments: FeatureProperty[] = []
  public customFields: FeatureCustomField[] = []
  public hasDescription: boolean = false
  public retrievingCustomFields: boolean = false
  public sanitizedMessage: SafeHtml

  get hasAttachments() { return this.attachments?.length > 0 }
  get hasCustomFields() { return this.customFields?.length > 0 }
  get onlyAttachments() { return this.hasAttachments && !(this.hasDescription || this.hasCustomFields) }
  get onlyDescription() { return this.hasDescription && !(this.hasAttachments || this.hasCustomFields) }
  get onlyCustomFields() { return this.hasCustomFields && !(this.hasAttachments || this.hasDescription) }

  constructor(
    private _domSanitizer: DomSanitizer,
    private _featureService: FeatureService,
    private _fileReferenceService: FileReferenceService,
    private _projectService: ProjectService,
    private _rightSidebarService: RightSidebarService
  ) { }

  ngAfterContentInit(): void {
    this._subscriptions.push(
      this._featureService.selectedFeature$.pipe(
        startWith(this._featureService.selectedFeature),
        filter(feature => feature != undefined),
        distinctUntilKeyChanged('id'),
        filter(feature => feature.interactions.some(i => i.actions.some(a => a.key == 'showDetails'))),
        map(feature => new Feature(feature.sceneID, feature.name, feature.type, feature)),
        switchMap(feature => {
          if (feature.customFields == null) {
            this.retrievingCustomFields = true

            return this._featureService.getFeaturesDetails(feature).pipe(
              map(details => {
                this.retrievingCustomFields = false

                feature.description = details.description
                feature.customFields = details.customFields

                return feature
              })
            )
          } else {
            return of(feature)
          }
        }),
      ).subscribe(feature => {
        let description = feature.description

        /** Set values of description, properties, and attachments */
        this.setDescription(description)
        this.customFields = feature?.customFields

        this.attachments = feature?.attachments
          .filter(attachment => {
            if (attachment.value == null || attachment.value == '') { //
              return false
            } else if (attachment.fileReferenceID != null) { // Regular Attachments
              return true
            } else { // Remote Attachments
              try {
                const parsedValue = JSON.parse(attachment.value)
                const url = parsedValue?.url

                return url != null && url != ''
              } catch (e) {
                return false
              }
            }
          })
          .sort((a, b) => {
            const getValueToSortBy = (prop: FeatureProperty) => {
              if (prop.fileReferenceID) {
                return prop.value?.toString().toLowerCase() || ''
              } else {
                try {
                  const { displayName } = JSON.parse(prop.value)

                  return displayName.toString().toLowerCase()
                } catch (e) {
                  return ''
                }
              }
            }
            let aValue: string = getValueToSortBy(a)
            let bValue: string = getValueToSortBy(b)

            if (aValue > bValue) return 1
            else if (aValue < bValue) return -1
            else return 0
          })


        /** Show some remote attachments in the description */
        forkJoin(
          this.attachments
            .filter(attachment => attachment.fileReferenceID == null || attachment.type == 'asset')
            .map(attachment => {
              if (attachment.type == 'asset') {
                return this._fileReferenceService.getUrlForFileReference(attachment.fileReference, this._projectService.currentProject.id).pipe(
                  switchMap(url => {
                    return this._fileReferenceService.createBlob(url).pipe(
                      map(blob => `<p></p><img src="${blob}"></img>`)
                    )
                  })
                )
              } else {
                const { displayName, url, headers, showInDescription } = JSON.parse(attachment.value)

                if (showInDescription) {
                  const formattedHeaders = {}

                  headers.forEach(header => formattedHeaders[header['key']] = header['value'])

                  return this._fileReferenceService.createBlob(url, formattedHeaders).pipe(
                    map(blob => `<p></p><h2>${displayName}</h2><img src="${blob}"></img>`)
                  )
                }
              }

              return null
            })
            .filter(blobs => blobs !== null)
        ).subscribe(blobs => {
          blobs.forEach((blob) => description += blob)

          this.setDescription(description)
        })
      })
    )
  }

  ngOnChanges() {
    this._subscriptions.push(
      this.events?.subscribe(event => {
        if (event == 'close') {
          this.close()
        } else if (event == 'submit') {
          this.submit()
        }
      })
    )
  }

  private setDescription(text: string) {
    if (text == null || text == '') {
      this.hasDescription = false
    } else {
      this.hasDescription = true

      setTimeout(() => {
        this.sanitizedMessage = this._domSanitizer.bypassSecurityTrustHtml(text)

        if (this.detailsType == 'modal') {
          this._rightSidebarService.prepareHTML("modal-details")
        } else if (this.detailsType == 'panel') {
          this._rightSidebarService.prepareHTML("right-sidepanel")
        }
      })
    }
  }

  close() {
    this.modal?.hide()
  }

  submit() {
    this.modal?.hide()
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe())
  }
}