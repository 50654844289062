<div class="card shadow d-flex flex-column p-3 justify-content-center gap-1">
    <span class="fs-6 text-muted">{{title}}</span>
    <div class="d-flex justify-content-between">
        <span class="text-primary fs-5">{{subtitle}}</span>
    </div>

    <hr class="bg-dark w-100">

    <div class="d-flex flex-column gap-2">
        <span class="fs-6 text-secondary">Amount Paid</span>
        <span class="fs-3 fw-bold text-secondary">
            {{ invoice.amount_due / 100 | currency: invoice.currency.toUpperCase() }}
        </span>
        <!-- Customer Information -->
        <span><strong>Customer:</strong> {{ invoice.customer_name }}</span>
        <span><strong>Email:</strong> {{ invoice.customer_email }}</span>
        <span *ngIf="invoice.customer_address"><strong>Address:</strong>
            {{ invoice.customer_address.line1 }}, {{ invoice.customer_address.city }},
            {{ invoice.customer_address.state }} {{ invoice.customer_address.postal_code }},
            {{ invoice.customer_address.country }}
        </span>

        <!-- Invoice Details -->
        <span><strong>Description:</strong> {{ invoice.description }}</span>
        <span><strong>Invoice Number:</strong> {{ invoice.number }}</span>
        <span><strong>Date:</strong> {{ invoice.created * 1000 | date: 'medium' }}</span>
        <span>
            <strong>Status:</strong>
            {{ invoice.status | titlecase }}
        </span>

        <!-- Invoice Links -->
        <span>
            <a href="{{ invoice.hosted_invoice_url }}" target="_blank"> View Invoice Online </a>
        </span>
    </div>
</div>