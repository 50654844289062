import { AssetField, AssetFieldResult } from "./AssetField"
import { AssetFieldValue, AssetFieldValueResult } from "./AssetFieldValue"

export interface AssetResult {
    id: number
    asset_type_id: number
    date_created: string
    date_updated: string
    fields: AssetFieldResult[]
    field_values: AssetFieldValueResult[]
}

export interface AssetOptions {
    id?: number
    assetTypeID?: number
    dateCreated?: string
    dateUpdated?: string
    fields?: AssetField[]
    fieldValues?: AssetFieldValue[]
}

export class Asset {
    id: number
    assetTypeID: number
    dateCreated: string
    dateUpdated: string
    fields?: AssetField[]
    fieldValues: AssetFieldValue[]

    constructor(assetTypeID: number, options?: AssetOptions) {
        this.id = options?.id ?? null
        this.assetTypeID = assetTypeID
        this.dateCreated = options?.dateCreated ?? null
        this.dateUpdated = options?.dateUpdated ?? null
        this.fields = options?.fields ?? []
        this.fieldValues = options?.fieldValues ?? []
    }
}