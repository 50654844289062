import { Tooltip } from 'bootstrap'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private _tooltips: Tooltip[] = []
  private _options: Partial<Tooltip.Options> = {
    trigger: "hover",
    container: "body",
    animation: true,
    delay: { show: 500, hide: 0 },
  }

  constructor() { }

  intializeTooltips() {
    this.hideAllTooltips()

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

    tooltipTriggerList.forEach((tooltip: string | Element) =>
      this._tooltips.push(new Tooltip(tooltip, this._options)))
  }

  hideAllTooltips() {
    for (let tooltip of this._tooltips) tooltip.hide()
  }
}
