import { Modal } from 'bootstrap'
import { Subscription } from 'rxjs'
import { Observable } from 'rxjs/internal/Observable'
import { filter, map, startWith } from 'rxjs/operators'

import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { Project } from '@classes/Project'
import { AuthenticationService } from '@services/authentication.service'
import { DashboardService } from '@services/dashboard.service'
import { ModalService } from '@services/modal.service'
import { ProjectService, usersProjects$ } from '@services/project.service'
import { SubscriptionService } from '@services/subscription.service'
import { TooltipService } from '@services/tooltip.service'

@Component({
  selector: 'shared-modal-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],

})
export class ProjectDetailsComponent implements OnDestroy, AfterViewInit {
  public cancelText = 'Close'
  public modal: Modal
  public title = 'Project Details'

  public onCancel: CallableFunction
  public link: string

  private _subscriptions: Subscription[] = []
  public currentUserPermission: string = ''
  public selectedProject$: Observable<Project> = this.dashboardService.selectedProjectForDetails$

  get atPublicProjectsLimit$() {
    return usersProjects$.pipe(
      startWith(this.projectService.usersProjects),
      map(ps => ps.filter(p => p.publicStatus).length >= this._subscriptionService.publicProjectLimit))
  }
  get hasFinancialAccess() { return this._authenticationService.hasFinancialAccess }
  get hasOrganization() { return this._authenticationService.hasOrganization }
  get inFreeTier() { return this._subscriptionService.inFreeTier }

  constructor(
    private _authenticationService: AuthenticationService,
    private _subscriptionService: SubscriptionService,
    private _tooltipService: TooltipService,
    public dashboardService: DashboardService,
    public modalService: ModalService,
    public projectService: ProjectService,
  ) {
    this._subscriptions.push(
      this.dashboardService.selectedProjectForDetails$.pipe(
        filter(project => project !== undefined && project.permissions.length > 0)
      ).subscribe(project => {
        this.currentUserPermission = this.dashboardService.getUserPermissionForProject(project)
      }),
    )
  }

  _submit() {

  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }
    if (this.dashboardService.selectedProjectForDetails.title.length == 0) return
    this.modal?.hide()
  }

  updateProject(updatedProject: Project) {
    if (updatedProject.title.length == 0) return
    this.projectService.updateProject(updatedProject).subscribe()
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  ngOnDestroy() {
    this.modal.dispose()
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}