import { saveAs } from 'file-saver'
import { BehaviorSubject } from 'rxjs'
import { map, skip, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { Platform } from '@angular/cdk/platform'
import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DropdownOptionChange } from '@assets/edit-asset-field/edit-asset-field.component'
import { Asset, AssetResult } from '@classes/Asset'
import { AssetField, AssetFieldResult } from '@classes/AssetField'
import { AssetFieldValue, AssetFieldValueResult } from '@classes/AssetFieldValue'
import { AssetType, AssetTypeResult } from '@classes/AssetType'
import { FileReference } from '@classes/FileReference'

import { EndpointOptions, RequestService } from './request.service'
import { fileReferenceFromResult } from './scene.service'

export type AssetTypePaginationOptions = {
  append?: boolean
  size: number,
  offset: number,
  sortBy: string,
  sortByFieldID: number
  sortOrder: 'ASC' | 'DESC',
  filterColumn: string
  filterValue: string
}

export type AssetPaginationOptions = {
  size: number,
  offset: number,
  sortBy: string,
  sortByFieldID: number
  sortOrder: 'ASC' | 'DESC',
  filterFieldIDs: number[]
  filterValues: string[]
}

export function assetTypeFromResult(result: AssetTypeResult) {
  return new AssetType(result.name, {
    id: result.id,
    description: result.description,
    assetCount: result.asset_count,
    fields: result?.fields ? result.fields.map(f => assetFieldFromResult(f)) : [],
    rows: result?.rows ? result.rows.map(r => assetRowFromResult(r)) : []
  })
}

export function assetFieldFromResult(result: AssetFieldResult) {
  return new AssetField(result.name, result.description, result.type, {
    id: +result.id,
    assetTypeID: +result.asset_type_id,
    pageID: +result.page_id ? +result.page_id : null,
    required: result.required,
    defaultValue: result.default_value,
    archived: result.archived,
    dropdownOptions: result.dropdown_options,
    nextFieldID: +result.next_field_id ? +result.next_field_id : null
  })
}

export function assetFromResult(result: AssetResult) {
  return new Asset(result.asset_type_id, {
    id: result.id,
    dateCreated: result.date_created,
    dateUpdated: result.date_updated,
    fields: result.fields.map(f => assetFieldFromResult(f)),
    fieldValues: result.field_values.map(fv => assetFieldValueFromResult(fv))
  })
}

export function assetFieldValueFromResult(result: AssetFieldValueResult) {
  return new AssetFieldValue(result.asset_field_id, result.value, {
    id: result.id,
    assetID: result.asset_id,
    updatedAt: result.updated_at,
    fileReferences: result.file_references ? result?.file_references.map(fr => fileReferenceFromResult(fr)) : []
  })
}

export type AssetRowResult = {
  asset_id: number
  values: Array<string | number>
  field_ids: Array<number>
}

export type AssetRow = {
  assetID: number
  values: Array<string | number>
  fieldIDs: number[]
}

export function assetRowFromResult(result: AssetRowResult) {
  return {
    assetID: result.asset_id,
    values: result.values,
    fieldIDs: result.field_ids
  } as AssetRow
}

export type AssetHistoryEntryResult = {
  id: number,
  asset_id: number,
  timestamp: string,
  description: string
}

export type AssetHistoryEntry = {
  id: number,
  assetID: number,
  timestamp: string,
  description: string
}

export function assetHistoryFromResult(result: AssetHistoryEntryResult) {
  return {
    id: result.id,
    assetID: result.asset_id,
    timestamp: convertTimestampToReadableDate(result.timestamp),
    description: result.description
  } as AssetHistoryEntry
}

function convertTimestampToReadableDate(timestamp: string) {
  const dateObject = new Date(timestamp)

  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true } as Intl.DateTimeFormatOptions
  const timeString = dateObject.toLocaleTimeString('en-US', timeOptions)

  const month = String(dateObject.getMonth() + 1).padStart(2, '0')
  const day = String(dateObject.getDate()).padStart(2, '0')
  const year = String(dateObject.getFullYear()).slice(-2)

  const timeZone = new Intl.DateTimeFormat('en', { timeZoneName: 'short' }).format(dateObject).split(' ')[1]

  return `${timeString} ${month}/${day}/${year} (${timeZone})`
}

function addPaginationOptionsToURL(url: string, paginationOptions: Partial<AssetPaginationOptions>) {
  let httpParams = new HttpParams()

  Object.keys(paginationOptions).forEach(key => {
    if (paginationOptions[key]) {
      httpParams = httpParams.set(key, paginationOptions[key].toString())
    }
  })

  return `${url}?${httpParams.toString()}`
}

const assetTypesSource = new BehaviorSubject<AssetType[]>(undefined)
export const assetTypes$ = assetTypesSource.asObservable()

const assetTypesCountSource = new BehaviorSubject<number>(0)
export const assetTypesCount$ = assetTypesCountSource.asObservable()

const changesTriggerSource = new BehaviorSubject<any>(undefined)
export const changesListener$ = changesTriggerSource.pipe(skip(1))

const selectedAssetTypeSource = new BehaviorSubject<AssetType>(undefined)
export const selectedAssetType$ = selectedAssetTypeSource.asObservable()

const selectedAssetSource = new BehaviorSubject<Asset>(undefined)
export const selectedAsset$ = selectedAssetSource.asObservable()

const selectedAssetFieldSource = new BehaviorSubject<AssetField>(undefined)
export const selectedAssetField$ = selectedAssetFieldSource.asObservable()

const assetTableSource = new BehaviorSubject<AssetRow[]>(undefined)
export const assetTableRows$ = assetTableSource.asObservable()

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  /** <Field ID, Filter Term> */
  public filterMap = new Map<number, string>()
  public loading = false
  /** <Asset Type ID, <Field ID, Filter Term>> */
  public savedFilters = new Map<number, Map<number, string>>()
  public selectedAssets: Set<number> = new Set<number>()
  public sortOrder: 'ASC' | 'DESC' = 'ASC'

  get assetTypes() { return assetTypesSource.getValue() }
  set assetTypes(assetTypes: AssetType[]) { assetTypesSource.next(assetTypes) }
  get assetTypesCount() { return assetTypesCountSource.getValue() }
  set assetTypesCount(count: number) { assetTypesCountSource.next(count) }
  get selectedAssetType() { return selectedAssetTypeSource.getValue() }
  set selectedAssetType(assetType: AssetType) { selectedAssetTypeSource.next(assetType) }
  get selectedAsset() { return selectedAssetSource.getValue() }
  set selectedAsset(asset: Asset) { selectedAssetSource.next(asset) }
  get selectedAssetField() { return selectedAssetFieldSource.getValue() }
  set selectedAssetField(assetField: AssetField) { selectedAssetFieldSource.next(assetField) }
  get assetTableRows() { return assetTableSource.getValue() }
  set assetTableRows(assetTableRows: AssetRow[]) { assetTableSource.next(assetTableRows) }

  get fields() { return this.selectedAssetType?.fields ?? [] }
  get pages(): { pageID: number, fields: AssetField[] }[] {
    const pages: { [key: number]: AssetField[] } = {}

    // Group fields by pageID
    this.fields.forEach(field => {
      if (!pages[field.pageID]) {
        pages[field.pageID] = []
      }
      pages[field.pageID].push(field)
    })

    // Sort fields within each page by following the nextFieldID
    for (const pageID in pages) {
      const fields = pages[pageID]
      const fieldMap = new Map<number, AssetField>()
      fields.forEach(field => fieldMap.set(field.id, field))

      const orderedFields: AssetField[] = []
      const seenFields = new Set<number>()
      let currentField = fields.find(field => !fields.some(f => f.nextFieldID === field.id))

      while (currentField) {
        orderedFields.push(currentField)
        seenFields.add(currentField.id)
        currentField = fieldMap.get(currentField.nextFieldID)
        if (currentField && seenFields.has(currentField.id)) {
          // Break if there's a loop
          break
        }
      }

      // Add any remaining fields that were not in the initial order
      fields.forEach(field => {
        if (!seenFields.has(field.id)) {
          orderedFields.push(field)
        }
      })

      pages[pageID] = orderedFields
    }

    // Convert to the desired format
    return Object.keys(pages).map(pageID => ({
      pageID: Number(pageID),
      fields: pages[pageID]
    }))
  }

  public assetTypeGetOptions: AssetTypePaginationOptions = {
    filterColumn: 'name',
    filterValue: '',
    offset: 0,
    size: 100,
    sortBy: 'name',
    sortByFieldID: null,
    sortOrder: "ASC",
  }
  public editing: 'assetType' | 'assetField' | 'assetEntry' = undefined
  get isMobile() { return this._platform.ANDROID || this._platform.IOS }

  constructor(private _requestService: RequestService, private _platform: Platform) { }

  triggerChangesListener() {
    changesTriggerSource.next(undefined)
  }

  /** Asset Entries */

  createAsset(assetType: AssetType, assetFieldValues: AssetFieldValue[]) {
    const url = `${environment.api}/assets/${assetType.id}`
    const options: EndpointOptions = {
      progressBar: { title: `Creating Asset`, autohide: false },
      successToast: { title: `Added ${assetType.name} Asset` },
      error: { operation: 'Create Asset', toast: true }
    }
    const formData: FormData = new FormData()
    const files = []

    assetFieldValues.forEach((afv, i) => {
      const fileArr = afv.fileReferences.map(fr => fr.file)

      if (fileArr) {
        files.push(...fileArr)
      }

      fileArr.forEach(file => formData.append(`afv_${i}`, file, file.name))
    })

    formData.append('assetFieldValues', JSON.stringify(assetFieldValues))
    return this._requestService.create<AssetRow>(url, formData, options)
  }

  getAsset(assetID: number) {
    const url = `${environment.api}/assets/${assetID}`
    const options: EndpointOptions = { error: { operation: 'Get Asset', toast: true, throwError: true } }

    return this._requestService.get<AssetResult>(url, options).pipe(
      map(result => assetFromResult(result))
    )
  }

  getAssetIDs(assetTypeID: number) {
    const url = `${environment.api}/assets/ids/${assetTypeID}`
    const options: EndpointOptions = { error: { operation: 'Get Asset IDs', toast: true, throwError: true } }

    return this._requestService.get<number[]>(url, options)
  }

  getAssetHistory(assetID: number) {
    const url = `${environment.api}/assets/${assetID}/history`
    const options: EndpointOptions = { error: { operation: 'Get Asset History', toast: true, throwError: true, } }

    return this._requestService.get<AssetHistoryEntryResult[]>(url, options).pipe(
      map(results => results.map(r => assetHistoryFromResult(r)))
    )
  }

  deleteAsset(assetID: number) {
    const url = `${environment.api}/asset/${assetID}`
    const options: EndpointOptions = {
      error: { toast: true, operation: 'Delete Asset' },
      successToast: { title: `Asset Deleted` },
    }

    return this._requestService.delete<AssetField>(url, options)
  }

  deleteAssets(assetIDs: number[]) {
    const url = `${environment.api}/assets/delete`
    const options: EndpointOptions = {
      error: { toast: true, operation: 'Delete Assets' },
      successToast: { title: `Assets Deleted` },
    }

    return this._requestService.create<AssetField>(url, assetIDs, options)
  }

  duplicateAsset(asset: Asset) {
    const url = `${environment.api}/asset/copy/${asset.id}`
    const options: EndpointOptions = {
      error: { operation: 'Duplicate Asset', toast: true, },
      successToast: { title: `Duplicated Asset` },
    }

    return this._requestService.create(url, asset, options)
  }

  duplicateAssets(assetIDs: number[]) {
    const url = `${environment.api}/assets/copy`
    const options: EndpointOptions = {
      error: { operation: 'Duplicate Assets', toast: true, },
      successToast: { title: `Duplicated Assets` },
    }

    return this._requestService.create(url, assetIDs, options)
  }

  setSelectedAssetByID(assetID: number) {
    this.editing = 'assetEntry'
    const options: EndpointOptions = { error: { operation: 'Set Selected Asset By ID', throwError: true } }
    const url = `${environment.api}/assets/${assetID}`

    return this._requestService.get<AssetResult>(url, options).pipe(
      map(assetResult => assetFromResult(assetResult)),
      tap(asset => this.selectedAsset = asset)
    )
  }

  updateAsset(asset: Asset) {
    const url = `${environment.api}/asset/${asset.id}`
    const options: EndpointOptions = {
      error: { operation: 'Update Asset', toast: true, throwError: true, },
      successToast: { title: `Updated Asset` },
    }

    return this._requestService.update(url, asset, options)
  }

  updateAssets(assetIDs: number[], changes: { fieldID: number, value: any }[]) {
    const url = `${environment.api}/assets`
    const options: EndpointOptions = {
      error: { operation: 'Update Assets', toast: true, throwError: true, },
      successToast: { title: `Updated Assets` },
    }

    return this._requestService.update(url, { assetIDs, changes }, options)
  }

  /** Asset Types */

  getAssetTypes(paginationOptions?: Partial<AssetTypePaginationOptions>) {
    const options: EndpointOptions = { error: { operation: 'Get Asset Types', toast: true } }
    let url = `${environment.api}/assets/types`

    if (paginationOptions) {
      url = addPaginationOptionsToURL(url, paginationOptions)
    }

    return this._requestService.get<{ assetTypes: AssetTypeResult[], assetTypesCount: number }>(url, options).pipe(
      map(({ assetTypes: assetTypeResults, assetTypesCount }) => {
        const assetTypes = assetTypeResults.map(type => assetTypeFromResult(type))

        if (paginationOptions?.append) {
          this.assetTypes = this.assetTypes.concat(assetTypes)
        } else {
          this.assetTypes = assetTypes
        }

        this.assetTypesCount = assetTypesCount

        return { assetTypes, assetTypesCount }
      })
    )
  }

  getAssetTypeData(assetTypeID: number, paginationOptions?: Partial<AssetPaginationOptions>) {
    const options: EndpointOptions = { error: { toast: true, operation: "Get Asset Type Data", throwError: true } }
    let url = `${environment.api}/assets/type/data/${assetTypeID}`

    if (paginationOptions) {
      url = addPaginationOptionsToURL(url, paginationOptions)
    }

    return this._requestService.get<AssetTypeResult>(url, options).pipe(
      map(result => assetTypeFromResult(result))
    )
  }

  getAssetTypeByID(assetTypeID: number) {
    const options: EndpointOptions = { error: { toast: true, operation: "Get Asset Type", throwError: true } }
    let url = `${environment.api}/assets/type/${assetTypeID}`

    return this._requestService.get<AssetTypeResult>(url, options).pipe(
      map(result => assetTypeFromResult(result))
    )
  }

  createAssetType(assetType: AssetType) {
    const url = `${environment.api}/assets/type`
    const options: EndpointOptions = {
      error: { operation: 'Create Asset Type', toast: true },
      successToast: { title: 'Create Asset Type', message: 'Asset Type Created' },
    }

    return this._requestService.create(url, assetType, options)
  }

  deleteAssetType(assetType: AssetType) {
    const url = `${environment.api}/assets/type/${assetType.id}`
    const options: EndpointOptions = {
      error: { operation: 'Delete Asset Type', toast: true },
      successToast: { title: 'Delete Asset Type', message: 'Asset Type Deleted' },
    }

    return this._requestService.delete(url, options)
  }

  duplicateAssetType(assetType: AssetType) {
    const url = `${environment.api}/assets/copy/type/${assetType.id}`
    const options: EndpointOptions = {
      error: { operation: 'Duplicate Asset Type', toast: true },
      successToast: { title: 'Duplicated Asset Type', message: 'Asset Type Duplicated' },
    }

    return this._requestService.create(url, assetType, options)
  }

  updateAssetType(assetType: AssetType) {
    const url = `${environment.api}/assets/type/${assetType.id}`
    const options: EndpointOptions = {
      error: { operation: 'Update Asset Type', toast: true },
      successToast: { title: 'Update Asset Type', message: 'Asset Type Updated' },
    }

    return this._requestService.update(url, assetType, options)
  }

  /** Asset Fields */

  createAssetField(field: AssetField) {
    const url = `${environment.api}/assets/field/${field.assetTypeID}`
    const options: EndpointOptions = {
      successToast: { title: `Added Asset Field` },
      error: { operation: 'Create Asset Field', toast: true }
    }

    return this._requestService.create<AssetField>(url, field, options).pipe(
      tap(field => {
        const fields = this.selectedAssetType.fields

        fields.push(field)

        this.selectedAssetType.fields = fields
      })
    )
  }

  deleteAssetField(field: AssetField) {
    const url = `${environment.api}/assets/field/${field.id}`
    const options: EndpointOptions = {
      successToast: { title: `Deleted Asset Field` },
      error: { operation: 'Delete Asset Field', toast: true }
    }

    return this._requestService.delete<{ id: number, message: string }>(url, options).pipe(
      tap(response => {
        const i = this.selectedAssetType.fields.findIndex(f => f.id == field.id)
        this.selectedAssetType.fields.splice(i, 1)
      })
    )
  }

  getAssetFieldsByAssetType(assetTypeID: number) {
    const url = `${environment.api}/assets/fields/${assetTypeID}`
    const options: EndpointOptions = { error: { operation: 'Get Asset Fields by Asset Type', toast: true, throwError: true } }

    return this._requestService.get<AssetFieldResult[]>(url, options).pipe(
      map(assetFieldResults => assetFieldResults.map(assetFieldResult => assetFieldFromResult(assetFieldResult)))
    )
  }

  getAssetFieldValue(assetID: number, fieldID: number) {
    const url = `${environment.api}/assets/assetFieldValue/${assetID}/${fieldID}`
    const options: EndpointOptions = { error: { operation: 'Get Asset Field Value', toast: true, throwError: true } }

    return this._requestService.get<AssetFieldValueResult>(url, options).pipe(
      map(afv => assetFieldValueFromResult(afv))
    )
  }

  removeFileFromAssetFieldValue(fieldValueID: number, fileReference: FileReference) {
    const url = `${environment.api}/files/${fileReference.id}/assetFieldValue/${fieldValueID}`
    const options: EndpointOptions = {
      successToast: { title: `Deleted File from Asset` },
      error: { operation: 'Delete File From Asset', toast: true, throwError: true }
    }

    return this._requestService.delete(url, options)
  }

  updateAssetField(field: AssetField) {
    const url = `${environment.api}/assets/field/${field.id}`
    const options: EndpointOptions = {
      successToast: { title: `Updated Asset Field` },
      error: { operation: 'Update Asset Field Type', toast: true }
    }

    return this._requestService.update<{ message: string }>(url, field, options).pipe(
      tap(() => {
        const fieldIndex = this.selectedAssetType.fields.findIndex(f => f.id == field.id)
        this.selectedAssetType.fields[fieldIndex] = field
      })
    )
  }

  updateAssetFieldPositions(field: AssetField, newNextID: number, newPageID: number) {
    const url = `${environment.api}/assets/field/positions/${field.assetTypeID}`
    const options: EndpointOptions = {
      successToast: { title: `Updated Asset Fields` },
      error: { operation: 'Update Asset Fields', toast: true }
    }

    const body = { field, newNextID, newPageID }

    return this._requestService.update<string>(url, body, options)
  }

  updateAssetFieldType(field: AssetField, useDefault: boolean) {
    const url = `${environment.api}/assets/field/type/${field.id}`
    const options: EndpointOptions = {
      successToast: { title: `Updated Asset Field Type` },
      error: { operation: 'Update Asset Field Type', toast: true }
    }

    return this._requestService.update<{ id: number, message: string }>(url, { field, useDefault }, options).pipe(
      tap(response => {
        const i = this.selectedAssetType.fields.findIndex(f => f.id == field.id)
        this.selectedAssetType.fields[i] = field
      })
    )
  }

  updateAssetFieldOptions(field: AssetField, optionChanges: DropdownOptionChange[]) {
    const url = `${environment.api}/assets/field/options/${field.id}`
    const options: EndpointOptions = {
      successToast: { title: `Updated Asset Field Dropdown Options` },
      error: { operation: 'Update Asset Field Dropdown Options', toast: true }
    }

    return this._requestService.update<{ id: number, message: string }>(url, { field, optionChanges }, options).pipe(
      tap(response => {
        const i = this.selectedAssetType.fields.findIndex(f => f.id == field.id)
        this.selectedAssetType.fields[i] = field
      })
    )
  }

  exportAssetsAsCSV(assetType: AssetType, body?: { assetIDs?: number[], filterFieldIDs?: number[], filterValues?: string[] }) {
    const url = `${environment.api}/assets/export/${assetType.id}`
    const options: EndpointOptions = {
      error: { operation: 'Export Asset Type as CSV', toast: true },
      httpOptions: { responseType: 'blob' },
      successToast: { title: `Assets Exported to CSV` },
    }

    return this._requestService.create<Blob>(url, body, options).pipe(
      tap(result => saveAs(result, `${assetType.name}_asset_entries`))
    )
  }

  importAssetsFromCSV(file: any, assetType: AssetType) {
    const url = `${environment.api}/assets/import/${assetType.id}`
    const options: EndpointOptions = {
      error: { operation: 'Import Assets from CSV', toast: true },
      successToast: { title: `Assets Imported from CSV` },
    }
    const formData = new FormData()

    formData.append('file', file)

    return this._requestService.create<{ response: string }>(url, formData, options)
  }
}