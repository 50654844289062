import { AfterContentChecked, ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { ContextMenuService } from '@services/context-menu.service'
import { ModalService } from '@services/modal.service'

import { ContextMenuDirective } from './directives/context-menu.directive'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterContentChecked {
  @ViewChild(ContextMenuDirective, { static: true }) contextMenu!: ContextMenuDirective;

  get currentModule() { return this.authenticationService.currentModule }
  get user$() { return this.authenticationService.user$ }
  get isSafari() {
    const agent = navigator.userAgent
    return !agent.includes('Chromium') && !agent.includes('Chrome') && agent.includes('Safari')
  }

  constructor(
    public authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef,
    public contextMenuService: ContextMenuService,
    public modalService: ModalService,
    public viewContainerRef: ViewContainerRef,
  ) {
    this.authenticationService.createAccessToken()
      .subscribe(token => localStorage.setItem('accessToken', token))

    /**
     * Disabling image bitmapping improves performance 
     * & reduces crashing experienced on iOS devices
     */
    if (this.isSafari) window.createImageBitmap = undefined

    // Give the ModalService reference to the AppComponent
    this.modalService.initialize(this.viewContainerRef)
  }

  ngAfterViewInit(): void {
    this.contextMenuService.container = this.contextMenu.viewContainerRef
  }

  logout() {
    this.authenticationService.logout()
  }

  closeContextMenu() {
    this.contextMenuService.closeContext()
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
}