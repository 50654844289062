import { Component } from '@angular/core';
import { EnvironmentManagerService } from '@services/environment-manager.service';
import { SceneService } from '@services/scene.service';

@Component({
  selector: 'shared-karta-logo',
  templateUrl: './karta-logo.component.html',
  styleUrls: ['./karta-logo.component.css']
})
export class KartaLogoComponent{

  constructor(
    public envManager: EnvironmentManagerService,
    public sceneService: SceneService
  ) { }
}
