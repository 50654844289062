import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[opacityHandler]'
})
export class OpacityHandlerDirective {
  /** Makes an element more transparent if value */
  @Input() public set opacityHandler(isFilteredIn: boolean) {
    if (isFilteredIn) {
      this.opacity = '1'
    } else {
      this.opacity = '0.5'
    }
  }

  get element(): HTMLElement { return this.elementRef.nativeElement }
  set opacity(value: string) { this.element.style.opacity = value }

  constructor(public elementRef: ElementRef) { }
}