<ng-container *ngIf="item">
    <td>
        {{name}}
        <span class="text-muted" style="font-size: small;" *ngIf="getCost(quantity)">
            {{getCost(quantity) / 100 | currency}}/{{interval}}
        </span>
    </td>

    <td class="align-middle text-center">
        <div class="d-flex gap-1 align-items-center justify-content-center" [title]="message">
            <button [disabled]="!canDecrement" class="btn btn-sm btn-outline-danger quantity-adjuster-btn"
                (click)="decrement()">
                <i class="fa-solid fa-minus"></i>
            </button>

            <span class="p-2 border rounded">{{quantity | number}}</span>

            <button class="btn btn-sm btn-outline-primary quantity-adjuster-btn" (click)="increment()">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </td>

    <td [ngClass]="{'text-danger': quantityDifference < 0, 'text-success':  quantityDifference > 0}"
        class="align-middle text-center">
        {{ quantityDifference > 0 ? '+' : '' }}{{ quantityDifference | number}}
    </td>
</ng-container>