import { AfterViewInit, Component } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { SubscriptionService } from '@services/subscription.service'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'billing-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements AfterViewInit {
  get billingPeriodEnd() { return this._subscriptionService.periodEnd }
  get hasFinancialAccess() { return this._authService.hasFinancialAccess }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get inProTier() { return this._subscriptionService.inProTier }
  get trialEnded() { return this._subscriptionService.trialEnded && this._subscriptionService.inFreeTier }

  constructor(
    private _authService: AuthenticationService,
    private _rightSidebarService: RightSidebarService,
    private _subscriptionService: SubscriptionService,
    private _toastService: ToastService,
  ) {
    this._subscriptionService.getSubscriptionUsage().subscribe()
    this._subscriptionService.getSubscriptionDetails().subscribe()
  }

  ngAfterViewInit(): void {
    this._subscriptionService.getSubscriptionDetails().subscribe(details => {
      if (details.status == 'past_due') {
        this.showOverdueToast()
      }
    })
  }

  showOverdueToast() {
    this._toastService.toast({
      actionButton: {
        title: "Update Payment Information",
        callback: () => this._subscriptionService.openSubscriptionBillingPortal().subscribe()
      },
      autohide: false,
      color: 'red',
      message: "Payment for your subscription is overdue. Please update your payment information.",
      title: "Action Required",
    })
  }

  openEditSubscriptionPanel() {
    this._rightSidebarService.offCanvasReference.offcanvas.show()
  }
}