<div class="p-2">
    <input class='form-control form-control-sm' type='search' [(ngModel)]="search" placeholder="Search icons">

    <div *ngIf="icons.length > 0" class="d-flex flex-row flex-wrap justify-content-center"
        style="overflow-y: scroll; min-width: 190px; max-height: 200px">
        <a *ngFor="let icon of icons | searchSortByKeys: ['iconName']: search" (click)="selectIcon(icon)"
            [title]="icon.iconName" class="p-1"
            [ngClass]="{'text-primary': icon.class == selected, 'text-dark': icon.class != selected}">
            <i class="text-center" [style.width]="'20px'" [class]="icon.class"></i>
        </a>
    </div>
</div>