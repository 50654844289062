import { Component } from '@angular/core';
import { RouteService } from '@services/route.service';
import { SceneService } from '@services/scene.service';

@Component({
  selector: 'shared-share-btn',
  templateUrl: './share-btn.component.html',
  styleUrls: ['./share-btn.component.css']
})
export class ShareBtnComponent {
  public btnEnabled = (key: string): boolean => this.sceneService.selectedScene?.properties?.find(p => p.key == key)?.value as boolean

  constructor(
    public sceneService: SceneService,
    public routeService: RouteService
  ) { }

  public getCurrentShareLink() {
    this.routeService.getCurrentShareLink()
  }
}