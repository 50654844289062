import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'shared-floating-label',
  templateUrl: './floating-label.component.html',
  styleUrls: ['./floating-label.component.css']
})
export class FloatingLabelComponent {
  @Input() label: string = ''
  @Input() ngModelIn: string = ''
  @Input() placeholder: string = ''
  @Input() type: string = 'text'
  @Output() ngModelInChange = new EventEmitter<string>()
  @Output() onChange = new EventEmitter<string>()

  public id = Math.random().toString(36).slice(-10)

  constructor() { }
}