import { Subscription } from 'rxjs'
import { startWith } from 'rxjs/operators'

import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy } from '@angular/core'
import { Connection, ConnectionProperty, URLObject, UrlParameter } from '@classes/Connection'
import { ConnectionResponse } from '@classes/ConnectionResponse'
import { FileReference } from '@classes/FileReference'
import { EditConnectionDetailsComponent } from '@modal/edit-connection-details/edit-connection-details.component'
import { ConnectionService, selectedConnection$ } from '@services/connection.service'
import { FeatureService, features$ } from '@services/feature.service'
import { ModalService } from '@services/modal.service'
import { SceneService } from '@services/scene.service'
import { TooltipService } from '@services/tooltip.service'

type UpdateOptions = {
  toast: boolean
  toastMessage: string
  undoable: boolean
}

@Component({
  selector: 'shared-edit-connection',
  templateUrl: './edit-connection.component.html',
  styleUrls: ['./edit-connection.component.css']
})
export class EditConnectionComponent implements OnChanges, AfterViewInit, OnDestroy {
  public connection: Connection
  private _subscriptions: Subscription[] = []
  public features$ = features$.pipe(startWith(this.featureService.features))
  public latitude: string
  public longitude: string
  public parsedURL: URLObject
  public selectedFile: File
  public showConnectionDataPicker = false

  public root: string = ''
  public selectedConnection$ = selectedConnection$
  public tooltips = {
    root: `Specifies where the data lives inside the API response. Access 
      nested data using "/"; ex: path/attribute`,
    identifier: `Selects a data object from the data accessed by the root 
      path; ex: Identifier - 'org_id', Value - '97'`,
    name: `Specifies where the name lives inside of data accessed by the 
      root path. Access nested data using "/"; ex: attribute/nestedAttribute`,
    description: `Specifies where the description lives inside of data 
      accessed by the root path. Access nested data using "/" ; ex: attribute/nestedAttribute`,
    coordinate: `Specifies where the coordinates lives inside of data 
      accessed by the root path. Access nested data using ; ex: attribute/nestedAttribute`
  }

  get createFeatures() { return this.connection.createFeatures }
  get filename(): string { return this.fileReference?.filename }
  get fileReference(): FileReference { return this.connection?.properties?.find(p => p.fileReference)?.fileReference }
  get fileReferenceID(): number { return this.fileReference?.id }
  get isMap(): boolean { return this._sceneService.selectedScene.type == 'Map' }
  get latLongProperty(): ConnectionProperty { return this.connection.properties.find(prop => prop.key == 'coordinateKey') }
  get iconScaleProperty(): ConnectionProperty { return this.connection.properties.find(prop => prop.key == 'size') }
  get needsFilePicker() { return this.markerImage || this.createFeatures }
  get overrideDescription() { return this.connection.overrideDescription }
  get overrideName() { return this.connection.overrideName }
  get selectedScene() { return this._sceneService.selectedScene }
  get markerImage(): ConnectionProperty { return this.connection.properties.find(p => p.key == 'file') }
  get urlStatus$() { return this._connectionService.urlStatus$ }

  public isHeadersCollapsed = true
  public isParamsCollapsed = true

  constructor(
    private _connectionService: ConnectionService,
    private _modalService: ModalService,
    private _sceneService: SceneService,
    private _tooltipService: TooltipService,
    public featureService: FeatureService,
    private cd: ChangeDetectorRef
  ) {
    selectedConnection$.subscribe(connection => this.connection = connection)
  }

  ngOnInit() {
    this._prepConnection()
    this.validateURL()
  }

  ngOnChanges() {
    this._prepConnection()
    this.validateURL()
  }

  ngAfterViewInit() {
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  private _prepConnection() {
    this.parsedURL = JSON.parse(this.connection.url)
    let latLngProp = this.connection.properties.find(prop => prop.key == 'coordinateKey')
    if (latLngProp) {
      let coordinates = JSON.parse(latLngProp.value)
      this.longitude = coordinates.longitude
      this.latitude = coordinates.latitude
    }

  }

  public addHeader() {
    this.parsedURL.headers.push({
      key: null,
      value: null
    })
    this.updateURL(this.connection, true)
  }

  public deleteHeader(index: number) {
    this.parsedURL.headers.splice(index, 1)
    this.updateURL(this.connection, true)
  }

  public extractParameters() {
    if (this.parsedURL.parameters == null) {
      this.parsedURL.parameters = []
    }

    const { baseURL, params } = this._connectionService.extractParams(this.parsedURL.url)

    params.forEach(([key, value]) => this.addParameter(key, value))

    this.parsedURL.url = baseURL
    this.updateURL(this.connection, true)
  }

  public addParameter(key = '', value = '') {
    if (this.parsedURL.parameters == null) {
      this.parsedURL.parameters = []
    }

    this.parsedURL.parameters.push({
      key, value, dynamic: false, type: 'time'
    })
  }

  public deleteParameter(index: number) {
    this.parsedURL.parameters.splice(index, 1)
    this.updateURL(this.connection, true)
  }

  public updateConnection(connection: Connection, options: Partial<UpdateOptions> = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    // TODO: Support Connections in the Command Service
    // if (options?.undoable) this._commandService.update.connection(connection).subscribe() 
    this._connectionService.updateConnection(connection, { toast: options?.toast, toastMessage: options?.toastMessage }).subscribe()
  }

  public updateProperty(property: ConnectionProperty, options: Partial<UpdateOptions> = {}) {
    options.undoable = options?.undoable ?? true
    options.toast = options?.toast ?? true

    this._connectionService.updateConnectionProperty(property, options).subscribe()
  }

  public updateURL(connection: Connection, validateURL: boolean, options: Partial<UpdateOptions> = {}) {
    if (validateURL) {
      this.validateURL()
    } else {
      this.updateConnectionResponse()
    }

    let stringifiedURL = JSON.stringify(this.parsedURL)
    connection.url = stringifiedURL
    this._connectionService.showJSONPicker = false
    this._connectionService.parsedURL = this.parsedURL
    this.updateConnection(connection, options)
  }

  public urlChanged(connection: Connection, validateURL: boolean, options: Partial<UpdateOptions> = {}) {
    this.parsedURL.parameters = []
    // Don't take the parameters if there aren't any
    if (this.parsedURL.url.indexOf('?') != -1) this.extractParameters()
    this.updateURL(connection, validateURL, options)
  }

  public updateOverrides(connection: Connection) {
    if (this.connection.overrideName == false && !this.connection.createFeatures) this.connection.nameKey = ''
    if (this.connection.overrideDescription == false && !this.connection.createFeatures) this.connection.descriptionKey = ''

    this.updateConnection(connection)
  }

  public updateCoordinateProperty(property: ConnectionProperty) {
    property.value = JSON.stringify({ longitude: this.longitude, latitude: this.latitude })
    this.updateProperty(property)
  }

  public onFileChange(file: File) {
    this.selectedFile = file

    if (this.markerImage) {
      this._connectionService.updateMarkerImage(this.markerImage, this.selectedFile)
    } else {
      this._connectionService.createMarkerImage(this.connection.id, file)
    }
  }

  public toggleConnectionDataPicker(attributeName: string, object: Connection | ConnectionProperty | URLObject) {
    if (this.showConnectionDataPicker) {
      this.closeConnectionDataPicker()
      return
    }

    if (this._connectionService.connectionResponse == null) return

    this.showConnectionDataPicker = true

    /** For Connection Override Attributes */
    if (object instanceof Connection) {
      this._connectionService.setConnectionDataPickerCallback((key, value, path) => {
        object[attributeName] = path
        this.updateOverrides(this.connection)
        this.showConnectionDataPicker = false

      })
      /** For Properties -- handle independent long/lat*/
    } else if (object instanceof ConnectionProperty) {
      this._connectionService.setConnectionDataPickerCallback((key, value, path) => {
        if (attributeName == 'longitude') this.longitude = path
        if (attributeName == 'latitude') this.latitude = path

        this.updateCoordinateProperty(object)
        this.showConnectionDataPicker = false

      })
      /** For Connection URL Attributes */
    } else this._connectionService.setConnectionDataPickerCallback((key, value, path) => {
      if (attributeName == 'externalID') object[attributeName] = value
      else object[attributeName] = path
      this.updateURL(this.connection, false)
      this.showConnectionDataPicker = false

    })
  }

  public validateURL() {
    this._connectionService.urlStatus = 'loading'

    const url = this.parsedURL.url
    const connectionResponseOptions = {
      root: this.parsedURL.dataPath,
      identifier: this.createFeatures ? undefined : this.parsedURL.idAttribute,
      identifierValue: this.createFeatures ? undefined : this.parsedURL.externalID
    }

    if (url != '') {
      this._connectionService.tryConnection(this.connection).subscribe(
        (data) => {
          this._connectionService.connectionResponse = new ConnectionResponse(data, connectionResponseOptions)
          this._connectionService.urlStatus = 'good'
          this.cd.detectChanges()
        },
        (error) => {
          this._connectionService.urlStatus = 'bad'
          this.cd.detectChanges()
        }
      )
    }
  }

  public updateConnectionResponse() {
    const options = {
      root: this.parsedURL.dataPath,
      identifier: this.createFeatures ? undefined : this.parsedURL.idAttribute,
      identifierValue: this.createFeatures ? undefined : this.parsedURL.externalID
    }

    this._connectionService.connectionResponse = new ConnectionResponse(this._connectionService.connectionResponse.data, options)
  }

  openDetails() {
    if (this._connectionService.urlStatus != 'good') return
    this._modalService.showAsModal(EditConnectionDetailsComponent)
  }

  updateDynamic(parameter: UrlParameter) {
    if (parameter.dynamic) {
      if (parameter.type == 'time') {
        if (!["today", "yesterday", "last-week", "last-month", "last-year"].includes(parameter.value)) {
          parameter.value = 'today'
        }
      }
    } else {
      parameter.value = ''
    }

    this.updateURL(this.connection, true)
  }

  closeConnectionDataPicker() {
    this.showConnectionDataPicker = false
    this._connectionService.clearConnectionDataPickerCallback()
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}