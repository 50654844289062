import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SubscriptionService } from '@services/subscription.service'
import { UserService } from '@services/user.service'

const bodys = {
  '402': "Payment for subscription is overdue. Please update payment information or contact the organization's administrator.",
  '403': "Sorry, you don't have access to this module. Please contact your organization administrator to request access.",
  '404': "We are unable to locate the specified project. Please verify the URL or reach out to our support team for further assistance."
};

const titles = {
  '402': "Action Required",
  '403': "Access Denied",
  '404': "Project Not Found"
}

@Component({
  selector: 'shared-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {
  get body() {
    if (this.errorCode == '402' && this._subscriptionService.inFreeTier) return "Your free trial has expired. Consider upgrading to pro tier."
    return bodys[this.errorCode]
  }
  get errorCode() { return this.params.code }
  get params() { return this.route.snapshot.params }
  get title() { return titles[this.errorCode] }
  get user() { return this.userService.currentUser }

  constructor(
    public userService: UserService,
    public route: ActivatedRoute,
    private router: Router,
    private _subscriptionService: SubscriptionService
  ) { }

  navigateToDashboard() {
    this.router.navigate(['projects'])
  }
}