import { forkJoin } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { CommandService } from '@services/command.service'
import { FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { SpatialAnnotationService } from '@services/spatial-annotation.service'
import { SubscriptionService } from '@services/subscription.service'
import { UpdateOptions } from '@shared/edit-feature/edit-feature.component'

@Component({
  selector: 'shared-edit-marker',
  templateUrl: './edit-marker.component.html',
  styleUrls: ['./edit-marker.component.css']
})
export class EditMarkerComponent {
  public selectedFeature: Feature
  public selectedFeature$ = this._featureService.selectedFeature$.pipe(
    filter(feature => feature != null),
    filter(feature => feature.type == 'marker'),
    map(feature => new Feature(feature.sceneID, feature.name, feature.type, feature))
  )

  get file() { return this._annotationService.file }
  get icon() { return this.selectedFeature.icon }
  get image() { return this.selectedFeature.image }

  get displayType() { return this.selectedFeature.properties.find(p => p.key == "displayType").value }
  public maxSize: number = 1.25

  get atDocumentLimit() { return this._subscriptionService.atDocumentLimit }

  constructor(
    private _annotationService: SpatialAnnotationService,
    private _commandService: CommandService,
    private _featureService: FeatureService,
    private _fileReferenceService: FileReferenceService,
    private _spacialAnnotationService: SpatialAnnotationService,
    private _subscriptionService: SubscriptionService
  ) {
    this.selectedFeature$.subscribe(feature => {
      this.selectedFeature = feature
      if (this.displayType == "icon") this.maxSize = 0.15
      else if (this.displayType == "label") this.maxSize = 0.30
      else this.maxSize = 1.25
    })
  }

  updateFeature(feature: Feature, options: UpdateOptions = {}) {
    options.undoable = options.undoable ?? true
    options.toast = options.toast ?? true

    if (options.undoable) {
      this._commandService.update.feature(feature).subscribe()
    } else {
      this._featureService.updateFeature(feature, { toast: options.toast }).subscribe()
    }
  }

  updateProperty(property: FeatureProperty, options: UpdateOptions = {}) {
    options.undoable = options.undoable ?? true
    options.toast = options.toast ?? true

    this._spacialAnnotationService.defaultIcon = {
      backgroundColor: this.selectedFeature.backgroundColor.value,
      backgroundShape: this.selectedFeature.backgroundShape.value,
      color: this.selectedFeature.color.value,
      icon: this.selectedFeature.icon.value,
    }

    this._spacialAnnotationService.defaultIcon[property.key] = property.value

    if (options.undoable) {
      this._commandService.update.featureProperty(property).subscribe()
    } else {
      this._featureService.updateFeatureProperty(property, { toast: options.toast }).subscribe()
    }
  }

  updateColor(property: FeatureProperty, color: string) {
    property.value = color

    this._spacialAnnotationService.defaultIcon = {
      backgroundColor: this.selectedFeature.backgroundColor.value,
      backgroundShape: this.selectedFeature.backgroundShape.value,
      color: this.selectedFeature.color.value,
      icon: this.selectedFeature.icon.value,
    }
    this._spacialAnnotationService.defaultIcon[property.key] = property.value

    this._commandService.update.featureProperty(property).subscribe()
  }

  updateSize(property: FeatureProperty) {
    property.value = property.value.toString()
    this._commandService.update.featureProperty(property).subscribe()
  }

  updateDisplayType(property: FeatureProperty) {
    const type = property.value
    let sizeProperty = this.selectedFeature.properties.find(p => p.key == "size")

    if (type == "icon") {
      sizeProperty.value = "0.05"
      this.maxSize = 0.15
    } else if (type == "label") {
      sizeProperty.value = "0.12"
      this.maxSize = 0.30
    } else {
      sizeProperty.value = "0.07"
      this.maxSize = 1.25
    }

    forkJoin([
      this._commandService.update.featureProperty(sizeProperty),
      this._commandService.update.featureProperty(property)
    ]).subscribe()
  }

  replaceImage(imageFile: File) {
    if (!imageFile) return

    let image = new Image
    image.src = window.URL.createObjectURL(imageFile)
    image.onload = async () => {
      if (image.width > 1024 || image.height > 1024) imageFile = await this._fileReferenceService.resizeImage(image, imageFile)

      const fileReference = this.image.fileReference
      fileReference.file = imageFile

      this._fileReferenceService.updateFileReference(fileReference)
        .subscribe(fileReference => {
          this.image.fileReference = fileReference
          this._featureService.updateFeaturesLocally(this.selectedFeature)
        })
    }
  }

  addImage(imageFile: File) {
    let image = new Image
    image.src = window.URL.createObjectURL(imageFile)
    image.onload = async () => {
      if (image.width > 1024 || image.height > 1024) imageFile = await this._fileReferenceService.resizeImage(image, imageFile)

      this._fileReferenceService.createFileReferences([imageFile]).pipe(
        switchMap(results => {
          const fileReference = results[0]
          const featureProperty = new FeatureProperty('image', 'file', null, { featureID: this.selectedFeature.id, fileReference: fileReference, fileReferenceID: fileReference.id })
          return this._featureService.createFeatureProperty(featureProperty).pipe(
            map(property => {
              this.selectedFeature.properties.push(property)
            })
          )
        }),
        switchMap(_ => this._subscriptionService.getSubscriptionUsage())
      ).subscribe()
    }
  }

  removeImage() {
    this._featureService.deleteFeatureProperty(this.selectedFeature.image)
      .pipe(switchMap(() => this._subscriptionService.getSubscriptionUsage()))
      .subscribe()
  }

  updateIcon(icon: string, options: UpdateOptions = {}) {
    this.icon.value = icon

    this.updateProperty(this.icon, options)
  }
}