<div class="mb-3">
    <div class="input-group">
        <ng-container *ngFor="let axis of form.controls; index as i">
            <span [hidden]='!axes[i]' class="input-group-text">
                <b>{{axes[i]}}</b>
            </span>
            <input *ngIf="(!focusedField || focusedField == axes[i])" [hidden]='!axes[i]' type='number'
                class="form-control" [formControl]="axis" (focus)="focusField(axes[i])" (focusout)="unfocusField()"
                (change)="onEdit()">
        </ng-container>
    </div>
</div>