<form [formGroup]="form" class="d-grid gap-2">
    <!-- Name Input -->
    <div>
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" formControlName="name" class="form-control"
            [class.is-invalid]="name.invalid && name.touched">
        <div class="invalid-feedback" *ngIf="name.invalid">Name is required.</div>
    </div>

    <!-- Map Position -->
    <div>
        <label class="form-label">Position</label>
        <shared-mini-map class="form-control" [bearing]="defaultBearing" [center]="defaultCenter" [pitch]="defaultPitch"
            [style]="style.value" [zoom]="defaultZoom" (mapMove)="setPosition($event)">
        </shared-mini-map>
    </div>

    <!-- Style Select -->
    <div>
        <label class="form-label">Style</label>
        <select class="form-select" formControlName="style">
            <option *ngFor="let style of mapStyles" [value]="style.style">
                {{ style.display }}
            </option>
        </select>
    </div>

    <!-- Terrain Switch -->
    <div>
        <label class="form-label">Terrain</label>

        <div class="form-check form-switch">
            <label class="form-check-label" for="terrain">
                3D
            </label>
            <input class="form-check-input" type="checkbox" formControlName="terrain" id="terrain">
        </div>
    </div>
</form>