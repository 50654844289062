import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { Model } from '@classes/Model'
import { AuthenticationService } from '@services/authentication.service'
import { selectedModel$ } from '@services/model.service'
import { UserService } from '@services/user.service'

@Component({
  selector: 'shared-model-grid',
  templateUrl: './model-grid.component.html',
  styleUrls: ['./model-grid.component.css']
})
export class ModelGridComponent implements OnChanges {
  @Input() models: Model[]
  @Output() onClick: EventEmitter<any> = new EventEmitter()
  public hoveredID: number
  public selectedID: number

  get inBuilder() { return this._authenticationService.currentModule == 'builder' }

  constructor(
    private _authenticationService: AuthenticationService,
    private _userService: UserService
  ) {
    selectedModel$.subscribe(model => this.selectedID = model?.id)
  }

  ngOnChanges() {
    if (this.selectedID) {
      this.models.sort((a, b) => {
        if (a.id == this.selectedID) {
          return -1
        } else if (b.id == this.selectedID) {
          return 1
        } else {
          return 0
        }
      })
    }
  }

  isOwner(model: Model) {
    return model.permissions.some(p => p.userID == this._userService.currentUser.id && p.permission == 'Owner')
  }

  setHovered(model: Model) {
    this.hoveredID = model?.id
  }

  selectModel(model: Model, event: PointerEvent) {
    this.selectedID = model.id
    this.onClick.emit({ event: event, model: model })
  }
}