<div class="d-flex flex-column d-grid gap-3">
    <ng-container *ngFor="let file of files; index as i">
        <div class="bg-white border rounded d-flex align-items-center">
            <label class="text-center btn rounded-1 btn-primary" for="file-{{i}}">
                <i class="fas fa-file-search"></i>
                <input [hidden]="true" id="file-{{i}}" type="file" [accept]="accepted"
                    (change)="replaceFile($event.target.files[0], i)">
            </label>
            <span class="text-nowrap text-truncate ps-3 me-auto">
                {{ file.name }}
            </span>
            <a class="btn text-secondary" (click)="removeFile(i)">
                <i class="fas fa-trash"></i>
            </a>
        </div>
    </ng-container>

    <label class="btn btn-success btn-sm align-self-start">
        {{ btnTitle }}
        <input [hidden]="true" type="file" multiple [accept]="accepted" (change)="addFiles($event.target.files)">
    </label>
</div>