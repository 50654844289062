import { Collapse } from 'bootstrap'

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core'

type State = 'hide' | 'show' | 'toggle'
type CollapsibleOptions = {
  button?: HTMLElement
  parent?: HTMLElement
  emitter?: EventEmitter<State>
  shown?: boolean
}

@Directive({
  selector: '[makeCollapsible]'
})
export class CollapsibleDirective implements OnDestroy {
  @Output() isCollapsed = new EventEmitter<boolean>()
  @Input() public set makeCollapsible(options: CollapsibleOptions) {
    this.collapseComponent = new Collapse(this.element, { toggle: options.shown ?? false, parent: options?.parent ?? this.element.parentElement })
    this.options = options

    this.options.button?.addEventListener('click', () => this.toggle())
    this.options.emitter?.subscribe((state) => this.toggle(state))
  }

  public collapseComponent: Collapse
  public options: CollapsibleOptions

  get element(): HTMLElement { return this.elementRef.nativeElement }

  constructor(public elementRef: ElementRef) { }

  toggle(state: State = 'toggle') {
    if (state == 'hide') {
      this.isCollapsed.emit(true)

      this.collapseComponent.hide()
    } else if (state == 'show') {
      this.isCollapsed.emit(false)

      this.collapseComponent.show()
    } else {
      if (this.element.classList.contains('show')) {
        this.isCollapsed.emit(true)
      } else {
        this.isCollapsed.emit(false)
      }

      this.collapseComponent.toggle()
    }
  }

  ngOnDestroy(): void {
    this.collapseComponent.dispose()

    this.options.button?.removeEventListener('click', () => this.toggle())
    this.options.emitter?.unsubscribe()
  }
}