<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <div *ngIf="selectedProject$ | async as project" class='container-fluid d-flex p-4 w-100 modal-body'>

                <!-- NO EDITING PERMISSIONS -->
                <div *ngIf="currentUserPermission != 'Owner' && (currentUserPermission != '' || project.publicStatus || project.sharedWithOrganization)"
                    class="container">
                    <div class="my-auto d-grid gap-4">
                        <div class="d-flex">
                            <img class="me-3" style="height: 100px; width: 100px;" src="{{ project.thumbnail }}">
                            <div class="mb-2">
                                <h1 class="display-1 mb-2">{{ project.title }}</h1>
                                <div class="text-muted mb-1">Created: {{ project.createdOn }}</div>
                                <div class="text-muted">Last edited: {{ project.lastEdited }}</div>
                            </div>
                        </div>
                        <div *ngIf="project.description?.length > 0">
                            <h3 class="display-3 mb-2">Description</h3>
                            <p class="text-muted ms-2">{{ project.description }}</p>
                        </div>

                        <shared-edit-project-permissions></shared-edit-project-permissions>
                    </div>
                </div>

                <!-- OWNER -->
                <div *ngIf="currentUserPermission == 'Owner'" class="container">
                    <div class="my-auto d-grid gap-3">
                        <div class="d-grid gap-2">
                            <div>
                                <label class="form-label">Project title</label>
                                <input class="form-control" (focusout)="updateProject(project)"
                                    [(ngModel)]="project.title" placeholder="Project Title">
                                <small *ngIf="project.title.length == 0" class="form-text text-danger">
                                    Title is required
                                </small>
                            </div>

                            <div>
                                <label class="form-label">Project description</label>
                                <textarea class="form-control" (focusout)="updateProject(project)"
                                    [(ngModel)]="project.description" placeholder="Project description"></textarea>
                            </div>

                            <div class="d-grid gap-2">
                                <div *ngIf="project.publicStatus || !(atPublicProjectsLimit$ | async); else atPublicLimit"
                                    class="form-check form-switch">
                                    <input [(ngModel)]="project.publicStatus" class="form-check-input" type="checkbox"
                                        (change)="updateProject(project)" style="cursor: pointer" id="public-switch">
                                    <label class="form-check-label" style="padding-left: 10px" for="public-switch">
                                        Public Project
                                    </label>
                                </div>
                                <ng-template #atPublicLimit>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" [disabled]="true">
                                        <label class="form-check-label" style="padding-left: 10px"
                                            [title]="'At public project limit'">
                                            <i class="fas fa-lock"></i> Public Project
                                        </label>

                                        <i *ngIf="hasFinancialAccess" class="fal fa-info-circle ms-2" type="button"
                                            routerLink="/billing" title="Upgrade Your Plan"></i>
                                    </div>
                                </ng-template>

                                <div *ngIf="hasOrganization" class="form-check form-switch">
                                    <input [(ngModel)]="project.sharedWithOrganization" class="form-check-input"
                                        type="checkbox" (change)="updateProject(project)" style="cursor: pointer;"
                                        [disabled]="inFreeTier">
                                    <label class="form-check-label" style="padding-left: 10px;">
                                        <i *ngIf="inFreeTier" class="fas fa-lock"></i>
                                        Share with Organization
                                    </label>
                                </div>

                                <div *ngIf="hasOrganization" class="form-check form-switch">
                                    <input [(ngModel)]="project.showEditors" class="form-check-input" type="checkbox"
                                        (change)="updateProject(project)" style="cursor: pointer;"
                                        [disabled]="inFreeTier">
                                    <label class="form-check-label" style="padding-left: 10px">
                                        <i *ngIf="inFreeTier" class="fas fa-lock"></i>
                                        Show editors on project card
                                    </label>
                                </div>
                            </div>
                        </div>

                        <shared-edit-project-permissions *ngIf="hasOrganization"></shared-edit-project-permissions>
                    </div>

                    <div class="mt-3 d-flex justify-content-between">
                        <label class="text-muted">Created: {{ project.createdOn | date }}</label>
                        <label class="text-muted">Last edited: {{ project.lastEdited | date }}</label>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <!-- <button type="button" class="btn" [ngClass]="{
                    'btn-primary': submitColor == 'blue' || submitColor == null,
                    'btn-success': submitColor == 'green',
                    'btn-danger': submitColor == 'red',
                    'btn-warning': submitColor == 'yellow'
                }" (click)="_submit()">{{submitText}}</button> -->
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>