import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'shared-multiple-file-picker',
  templateUrl: './multiple-file-picker.component.html',
  styleUrls: ['./multiple-file-picker.component.css']
})
export class MultipleFilePickerComponent {
  @Input() btnTitle: string = '+ Add Files'
  @Input() acceptedFileTypes: { audio?: boolean, images?: boolean, videos?: boolean, models?: boolean } = {}
  @Output() fileRemoved = new EventEmitter<File>()
  @Output() fileReplaced = new EventEmitter<[File, File]>()
  @Output() filesAdded = new EventEmitter<File[]>()
  @Output() filesChanged = new EventEmitter<File[]>()

  get accepted() {
    const { audio, images, models, videos } = this.acceptedFileTypes
    const fileTypes = []
    let acceptedString = ''

    if (audio) fileTypes.push('aduio/*')
    if (images || models) fileTypes.push('image/*')
    if (models) fileTypes.push('.obj', '.mtl', '.gltf', '.bin', '.glb')
    if (videos) fileTypes.push('video/*')

    fileTypes.forEach((fileType, i) => {
      if (i == 0) acceptedString = fileType
      else acceptedString = acceptedString + ', ' + fileType
    })

    return acceptedString
  }

  public files: File[] = []

  constructor() { }

  public replaceFile(file: File, index: number) {
    const replaced = this.files[index]

    this.files[index] = file

    this.fileReplaced.emit([replaced, file])
    this.filesChanged.emit(this.files)
  }

  public removeFile(index: number) {
    const [file] = this.files.splice(index, 1)

    this.fileRemoved.emit(file)
    this.filesChanged.emit(this.files)
  }

  public addFiles(fileList: FileList) {
    const files = Array.from(fileList)

    this.files = this.files.concat(files)

    this.filesAdded.emit(files)
    this.filesChanged.emit(this.files)
  }
}