import { Action } from './Action'

export type InteractionOptions = {
    id?: number,
    featureID?: number

}

export type InteractionType =
    'change'
    | 'click'
    | 'deselect'
    | 'hover'
    | 'keypress'
    | 'long-click'
    | 'move'
    | 'panel-click'
    | 'resize'
    | 'rotate'

export class Interaction {
    public id: number
    public featureID: number
    public type: InteractionType
    public actions: Action[]

    constructor(type: InteractionType, actions: Action[], options?: InteractionOptions) {
        this.id = options?.id ? +options.id : undefined
        this.featureID = options?.featureID ? +options.featureID : undefined
        this.type = type
        this.actions = actions ? actions.map(a => new Action(a.key, a.value, a)) : []
    }

    public equals(interaction: Interaction) {
        if (interaction === undefined) return false
        if (this.id != interaction.id &&
            this.featureID != interaction.featureID &&
            this.type != interaction.type) return false

        if (this.actions.length != interaction.actions.length) return false

        this.actions.forEach(action => {
            if (!action.equals(interaction.actions.find(i => i.id == action.id))) return false
        })

        return true
    }
}