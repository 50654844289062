import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Scene } from '@classes/Scene'
import { SceneProperty } from '@classes/SceneProperty'
import { SceneService } from '@services/scene.service'
import { CreateSceneBody } from '@shared/create-scene/create-scene.component'

@Component({
  selector: 'shared-create-standard-scene',
  templateUrl: './create-standard-scene.component.html',
  styleUrls: ['./create-standard-scene.component.css']
})
export class CreateStandardSceneComponent implements CreateSceneBody {
  public form = new FormGroup({
    name: new FormControl<string>('New Standard Scene', [Validators.required]),
  })

  get name() { return this.form.get('name') as FormControl<string> }

  constructor(public _sceneService: SceneService) { }

  createScene$(projectID: number) {
    const scene = this.getScene(projectID)

    return this._sceneService.createStandardScene$(scene)
  }

  getScene(projectID?: number) {
    return new Scene(projectID, this.name.value, 'Standard')
  }
}