<ng-container *ngIf="onlyAttachments || onlyDescription || onlyCustomFields; else accordian">
    <ng-container *ngIf="onlyDescription">
        <div [innerHTML]="sanitizedMessage" class="p-3 ck-content" style="overflow-wrap: break-word;"></div>
    </ng-container>

    <ng-container *ngIf="onlyCustomFields">
        <div class="p-3">
            <shared-custom-field-viewer [customFields]="customFields"></shared-custom-field-viewer>
        </div>
    </ng-container>

    <ng-container *ngIf="onlyAttachments">
        <div class="p-3">
            <shared-view-attachment-list [attachments]="attachments"></shared-view-attachment-list>
        </div>
    </ng-container>
</ng-container>

<ng-template #accordian>
    <div *ngIf="retrievingCustomFields" class="d-flex justify-content-center p-4">
        <div class="spinner-grow text-primary" role="status" *ngFor="let i of [1,2,3,4,5]">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div [hidden]="retrievingCustomFields" #accordianParent class="accordion">
        <!-- Feature's Description -->
        <div class="accordion-item">
            <div class="accordion-header" #descriptionButton>
                <div class="accordion-button collapsed" type="button"
                    [ngClass]="{'empty text-muted': !hasDescription }">
                    Description
                </div>
            </div>

            <div class="accordion-collapse collapse"
                [makeCollapsible]="{button: descriptionButton, parent: accordianParent, shown: hasDescription}">
                <div class="accordion-body pt-0">
                    <div *ngIf="hasDescription" [innerHTML]="sanitizedMessage" class="ck-content" style="overflow-wrap: break-word;"></div>
                    <strong *ngIf="!hasDescription">No Description</strong>
                </div>
            </div>
        </div>

        <!-- Feature's Custom Fields -->
        <div class="accordion-item">
            <div #propertiesButton class="accordion-header">
                <div class="accordion-button collapsed" type="button"
                    [ngClass]="{'empty text-muted': !hasCustomFields }">
                    Properties
                </div>
            </div>

            <div class="accordion-collapse collapse"
                [makeCollapsible]="{button: propertiesButton, parent: accordianParent, shown: !hasDescription}">
                <div class="accordion-body pt-0">
                    <shared-custom-field-viewer *ngIf="hasCustomFields" [customFields]="customFields"></shared-custom-field-viewer>
                    <strong *ngIf="!hasCustomFields">No Properties</strong>
                </div>
            </div>
        </div>

        <!-- Feature's Attachments -->
        <div class="accordion-item">
            <div #attachmentsButton class="accordion-header">
                <div class="accordion-button collapsed" type="button"
                    [ngClass]="{'empty text-muted': !hasAttachments }">
                    Attachments
                </div>
            </div>

            <div class="accordion-collapse collapse"
                [makeCollapsible]="{button: attachmentsButton, parent: accordianParent}">
                <div class="accordion-body pt-0">
                    <shared-view-attachment-list *ngIf="hasAttachments" [attachments]="attachments">
                    </shared-view-attachment-list>
                    <strong *ngIf="!hasAttachments">No Attachments</strong>
                </div>
            </div>
        </div>
    </div>
</ng-template>