import { Modal } from 'bootstrap'

import { Component } from '@angular/core'
import { ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-modal-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.css'],
})
export class ShareLinkComponent {
  public cancelText = 'Cancel'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Copy Link'
  public title = 'Share URL'

  public onCancel: CallableFunction
  public link: string

  constructor() { }

  _submit() {
    this._copyLink().then(_ => this.modal?.hide())
  }

  private async _copyLink(): Promise<boolean> {
    await navigator.clipboard.writeText(this.link)
    return true
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}
