<!-- AND/OR Operation && AddNewGroup btn -->
<div class="d-flex mt-1 align-items-center">
    <a *ngIf='ruleObjects.length > 1' class='btn btn-sm btn-light border' (click)="toggleOperation()">
        {{ruleGroup.operation | uppercase}}
    </a>
    <div class="d-flex justify-content-end w-100 me-2 align-items-center">
        <button *ngIf='ruleObjects.length >= 1 && maxDepthReached == false' [disabled]="maxChildrenReached"
            (click)="addNewGroup()" class="btn btn-sm btn-light">
            + Add Group
        </button>
        <button *ngIf='parentIsNotRoot' type="button" class="btn btn-close ms-2" (click)="deleteRuleGroup()"></button>
    </div>
</div>

<div *ngFor="let ruleObject of ruleObjects; index as i" class="ms-3" style="position:relative">
    <!-- Top Half left Line for Operation Relationship -->
    <div *ngIf='ruleObjects.length > 1' class="top-line"></div>
    <!-- Bottom Half left Line for Operation Relationship -->
    <div *ngIf='i != ruleObjects.length - 1' class="bottom-line"></div>

    <div class="d-flex align-items-center">
        <!-- Horizontal Line for Operation Relationship -->
        <div *ngIf='ruleObjects.length > 1' class="horizontal-line"></div>

        <!-- RULE -->
        <div *ngIf="isRule(ruleObject)" class="d-flex w-100 my-1 align-items-center"
            [ngClass]="{'hovered': hoveredRule == i}" (mouseenter)="hoverRule(i)" (mouseleave)="unhoverRule()">
            <shared-edit-rule class='flex-grow-1' [rule]="ruleObject" [searchableProperties]="searchableProperties"
                (edit)="updateRule($event, i)">
            </shared-edit-rule>

            <div *ngIf='hoveredRule == i' class="btn-group">
                <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Action
                </button>
                <ul class="dropdown-menu dropdown-menu-end my-n1">
                    <li>
                        <button [disabled]="maxChildrenReached" (click)='addNewRule(i)' class="dropdown-item"
                            type="button">
                            Add New Rule
                        </button>
                    </li>
                    <li>
                        <button (click)='moveIntoGroup(i, "above")' *ngIf="prevIsGroup(i)" class="dropdown-item"
                            type="button">
                            Move Into Above Rule Group
                        </button>
                    </li>
                    <li>
                        <button (click)='moveIntoGroup(i, "below")' *ngIf="nextIsGroup(i)" class="dropdown-item"
                            type="button">
                            Move Into Below Rule Group
                        </button>
                    </li>
                    <li>
                        <button (click)='deleteRuleObject($event, i)' class="dropdown-item" type="button">
                            Delete Rule
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <!-- RULE GROUPS; RECURSIVE -->
        <div *ngIf='isGroup(ruleObject)' class="card ps-1 my-1 d-flex w-100"
            style="background-color: rgba(0, 0, 0, 0.2)">
            <shared-rule-group class="flex-grow-1" [depth]="depth + 1" [ruleGroup]="ruleObject"
                (edit)="updateRuleGroup($event, i)" [searchableProperties]="searchableProperties"
                (remove)="deleteRuleObject($event, i)">
            </shared-rule-group>
        </div>

    </div>
</div>