import { BehaviorSubject, of } from 'rxjs'
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Connection, IConnection } from '@classes/Connection'
import { Feature, isThreeFeature } from '@classes/Feature'
import { Scene } from '@classes/Scene'
import { sceneLoadMap, scenesLoaded$ } from '@classes/SceneManager'
import { EndpointOptions, RequestService } from '@services/request.service'

import { ConnectionService } from './connection.service'
import { EnvironmentManagerService } from './environment-manager.service'
import {
  actionFromResult, ActionResult, featureFromResult, FeatureResult, FeatureService, interactionFromResult, InteractionResult, modelFromResult,
  ModelResult
} from './feature.service'
import { LoadingService } from './loading.service'
import { ModelService } from './model.service'
import { RightSidebarService } from './right-sidebar.service'
import { sceneFromResult, SceneResult, SceneService } from './scene.service'
import { ToastService } from './toast.service'
import { VirtualTourService } from './virtual-tour.service'

export interface ClipboardContents {
  type: ClipboardContentType
  id?: number
  coordinates?: [number, number][]
}

interface CopiedSceneResult {
  scene: SceneResult,
  features: FeatureResult[]
}

export type ClipboardContentType = "" | "scene" | "feature" | "interaction" | "action" | "connection" | "coordinates"
@Injectable({
  providedIn: 'root'
})
export class CopyService {
  private _copyEndpoint = environment.api + '/copy'
  private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
  public copiedContentType: ClipboardContentType = ""

  get controls() { return this._envManager.controls }
  get map() { return this._envManager.map }

  public clipboardContents$ = new BehaviorSubject<ClipboardContents>(undefined)
  constructor(
    private _modelService: ModelService,
    private _connectionService: ConnectionService,
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _http: HttpClient,
    private _loadingService: LoadingService,
    private _requestService: RequestService,
    private _rightSidebar: RightSidebarService,
    private _sceneService: SceneService,
    private _toastService: ToastService,
    private _virtualTourService: VirtualTourService
  ) { }

  public checkClipboardContentType() {
    this.getClipboardText().then(text => {
      try {
        const contents = JSON.parse(text) as ClipboardContents
        if (contents.type) this.copiedContentType = contents.type
      } catch (error) { }
    })
  }

  getClipboardText() {
    return navigator.clipboard.readText()
  }

  copyContentsToClipboard(contents: ClipboardContents) {
    this.clipboardContents$.next(contents)
    return navigator.clipboard.writeText(JSON.stringify(contents))
  }


  clearClipboard() {
    navigator.clipboard.writeText('')
    this.copiedContentType = ''
    this.clipboardContents$.next(undefined)
  }

  async getCoordinates() {
    return this.getClipboardText().then((text: string) => {
      try {
        const contents = JSON.parse(text) as ClipboardContents

        if (contents?.type == 'coordinates') return contents.coordinates
      } catch (error) { }

      return []
    })
  }

  public copySceneToClipboard(scene: Scene) {
    this.copiedContentType = "scene"
    const contents = { type: "scene", id: scene.id } as ClipboardContents
    this.clipboardContents$.next(contents)

    navigator.clipboard.writeText(JSON.stringify(contents)).then(() =>
      this._toastService.toast({ title: "Scene Copied", color: "green" }))
  }

  public duplicateScene(sceneID: number, destinationProjectID: number = this._sceneService.selectedScene.projectID) {
    const url = `${environment.api}/scene/copy/${sceneID}`
    const body = { destinationProjectID }
    const options: EndpointOptions = {
      successToast: { title: "Scene Duplicated" },
      error: { operation: "Duplicate Scene", toast: true }
    }

    return this._requestService.create<CopiedSceneResult>(url, body, options).pipe(
      map(({ scene: sceneResult, features: featureResults }) => {
        const scene = sceneFromResult(sceneResult)
        const scenes = this._sceneService.scenes
        const prevSceneIndex = this._sceneService.scenes.length - 1

        scenes[prevSceneIndex].nextID = scene.id

        this._sceneService.scenes = [scene].concat(scenes)

        const features = featureResults.map(result => featureFromResult(result))
        this._featureService.scenesFeatures.set(scene.id, features)
        this._featureService.features = features
      })
    )
  }

  public copyFeatureToClipboard(feature: Feature) {
    this.copiedContentType = "feature"
    const contents = { type: "feature", id: feature.id } as ClipboardContents
    this.clipboardContents$.next(contents)
    navigator.clipboard.writeText(JSON.stringify(contents)).then(() =>
      this._toastService.toast({ title: "Feature Copied", color: "green" }))
  }

  public duplicateFeature(featureID: number, destinationSceneID: number) {
    const destinationScene = this._sceneService.scenes.find(s => s.id == destinationSceneID)
    let position = []

    // TODO: Figure out the position based on destination and source scene
    if (destinationScene.type == 'Standard' || (destinationScene.type == 'Virtual Tour' && !this._virtualTourService.viewpointMode)) {
      position = this.controls.target.toArray()
    } else if (destinationScene.type == "Map") {
      const center = this.map.getCenter()

      try {
        var altitude = this.map.queryTerrainElevation(center)
      } catch {
        var altitude = 0
      }

      position = [center.lng, center.lat, altitude]
    } else if (destinationScene.type == "360 Image" || (destinationScene.type == 'Virtual Tour' && this._virtualTourService.viewpointMode)) {
      const cameraPosition = this._envManager.modelSpace.camera.position.clone()
      const target = this._envManager.modelSpace.orbitControls.target.clone()
      let distance = 2
      if (destinationScene.type == "360 Image") distance = 20
      const direction = cameraPosition.clone().sub(target).normalize().multiplyScalar(distance)
      const newTarget = target.sub(direction)
      position = [newTarget.x, newTarget.y, newTarget.z]
    }

    return this._featureService.duplicateFeature(featureID, destinationSceneID, position).pipe(
      switchMap(({ parent, children }) => {
        if ([parent, ...children].some(feature => isThreeFeature(feature))) {
          return scenesLoaded$.pipe(
            startWith(sceneLoadMap.get(destinationSceneID)),
            filter(load => load.loaded),
            map(() => { return { parent, children } }),
            take(1)
          )
        } else {
          return of({ parent, children })
        }
      }),
      tap(({ parent, children }) => {
        const group = this._envManager.sceneManager.getFeatureGroup(parent.id)

        if (this._envManager.transformControls.attached) {
          this._envManager.transformControls.detach()
        }

        if (group) {
          this._envManager.transformControls.attach(group)
        } else if (children.length > 0) {
          const childrenModels = this._envManager.sceneManager.getDescendents(parent)

          childrenModels.forEach(model =>
            model && this._envManager.transformControls.attach(model)
          )
        }

        this._featureService.selectedFeature = parent
        this._rightSidebar.setTab("Feature")
        this._rightSidebar.openPanel()

        if (this._envManager.transformControls.attached) {
          this._envManager.postProcessor.highlight('click', this._envManager.transformControls.object as THREE.Group)
        }
      })
    )
  }

  public copyConnectionToClipboard(connection: Connection) {
    this.copiedContentType = "connection"
    const contents = { type: "connection", id: connection.id }
    navigator.clipboard.writeText(JSON.stringify(contents)).then(() =>
      this._toastService.toast({ title: "Connection Copied", color: "green" }))
  }

  public duplicateConnection(connectionID: number, destinationSceneID: number) {
    const url = `${this._copyEndpoint}/connection/${connectionID}/dest/${destinationSceneID}`
    const options: EndpointOptions = {
      successToast: { title: "Connection Duplicated" },
      error: { operation: "Duplicate Connection", toast: true }
    }

    return this._requestService.create<IConnection>(url, {}, options).pipe(
      map(result => {
        const connection = new Connection(destinationSceneID, result)

        this._connectionService.editConnectionLocally(connection, 'create')
        this._connectionService.setSelectedConnectionByID(connection.id)
        this._rightSidebar.openPanel()

        return connection
      })
    )
  }

  public duplicateModel(modelID: number) {
    const url = `${environment.api}/copy/model/${modelID}`
    const options: EndpointOptions = {
      successToast: { title: "Model Duplicated" },
      error: { operation: "Duplicate Model", toast: true }
    }

    return this._requestService.get<ModelResult>(url, options).pipe(
      map((result) => {
        const model = modelFromResult(result)
        this._modelService.editModelLocally(model, 'create')
        return model
      })
    )
  }

  public duplicateInteraction(interactionID: number, destinationFeatureID: number) {
    const url = `${this._copyEndpoint}/interaction/${interactionID}/dest/${destinationFeatureID}`

    return this._http.get<InteractionResult>(url, this._httpOptions).pipe(
      map(i => interactionFromResult(i)),
      tap((interaction) => {
        const feature = this._featureService.getFeature(interaction.featureID)

        feature.interactions = ([interaction].concat(feature.interactions))

        this._featureService.updateFeaturesLocally(feature)
        this._toastService.toast({ title: "Interaction Duplicated", color: "green" })
      })
    )
  }

  public duplicateAction(actionID: number, destinationInteractionID: number) {
    const url = `${this._copyEndpoint}/action/${actionID}/dest/${destinationInteractionID}`
    return this._http.get<ActionResult>(url, this._httpOptions).pipe(
      map(a => actionFromResult(a)),
      tap((action) => {
        const feature = this._featureService.features.find(f => f.interactions.some(i => i.id == action.interactionID))
        const interaction = feature.interactions.find(i => action.interactionID == i.id)

        interaction.actions = ([action].concat(interaction.actions))

        this._featureService.updateFeaturesLocally(feature)
        this._toastService.toast({ title: "Action Duplicated", color: "green" })
      })
    )
  }

  public paste() {
    navigator.clipboard.readText()
      .then(clipboardText => {
        try {
          const contents = JSON.parse(clipboardText) as ClipboardContents
          const sceneID = this._sceneService.selectedSceneID

          if (contents.type == "scene") this.duplicateScene(contents.id, this._sceneService.selectedScene.projectID).subscribe()
          else if (contents.type == "connection") this.duplicateConnection(contents.id, sceneID).subscribe()
          else if (contents.type == "feature") {
            this._loadingService.await(
              this.duplicateFeature(contents.id, this._sceneService.selectedScene.id)
            )
          } else if (clipboardText != "") this._toastService.toast({ title: "Unable to paste clipboard contents", color: "red" })
        } catch (err) { } // User pasted something not meant for us.
      })
  }
}