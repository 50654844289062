<div *ngIf="retrievingCustomFields; else hasCustomFields" class="d-flex flex-column pt-4 pb-2 gap-3 align-items-center">
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status"></div>

    <span>Loading custom fields...</span>
</div>

<ng-template #hasCustomFields>
    <div *ngIf="customFields.length == 0">
        <h1 class="text-secondary pt-3" style="text-align: center;">
            <small><small>No custom fields in selected feature</small></small>
        </h1>
        <a class="btn btn-success btn-sm mt-1 w-100" (click)="createCustomField()">
            + Custom Field
        </a>
    </div>

    <table *ngIf="customFields.length > 0" class="table table-striped table-hover border border-top-0">
        <thead>
            <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
                <th scope="col">Type</th>
                <th scope="col"></th>
            </tr>
        </thead>

        <tbody class="scrollable-body">
            <tr *ngFor="let customField of customFields">
                <td style="vertical-align: middle" [title]="customField.key">
                    <input [(ngModel)]="customField.key" class="form-control form-control-sm" type="text"
                        (focus)="oldKey = customField.key" (change)="updateCustomField(customField, 'key', oldKey)"
                        maxlength="50">
                </td>

                <td style="vertical-align: middle" [title]="customField.value">
                    <input class="form-control form-control-sm" [(ngModel)]="customField.value"
                        (focus)="oldValue = customField.value"
                        (change)="updateCustomField(customField, 'value', oldValue)" [type]="getType(customField.value)"
                        [step]="getType(customField.value) == 'number' ? '0.01' : ''" maxlength="255">
                </td>

                <td style="vertical-align: middle; text-align: center">
                    {{getType(customField)}}
                </td>

                <td style="vertical-align: middle">
                    <div class="d-flex">
                        <a class="btn btn-sm" (click)="deleteCustomField(customField)">
                            <i class="fa fa-trash text-danger"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>

        <tfoot>
            <a class="btn btn-success btn-sm mt-1 w-100" (click)="createCustomField()">
                + Custom Field
            </a>
        </tfoot>
    </table>
</ng-template>