import { Offcanvas } from 'bootstrap'

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { UiService } from '@services/ui.service'

@Component({
  selector: 'shared-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css']
})
export class RightPanelComponent implements AfterViewInit {
  @ViewChild('rightPanel') rightPanel: ElementRef

  get bodyComponent() { return this._uiService.rightPanel.component }
  get destroyOnClose() { return this._uiService.rightPanel.destroyOnClose }
  get isMobile() { return this._environmentManager.isMobile }
  get isOpen() { return this._uiService.rightPanel.isOpen }
  get isResponsive() { return this._uiService.rightPanel.isResponsive }
  get shouldShowComponent() { return !this.destroyOnClose || this.isOpen }
  get title() { return this._uiService.rightPanel.title }
  get width() { return this._uiService.rightPanel.width }
  get zIndex() { return this._uiService.rightPanel.zIndex }

  constructor(private _uiService: UiService, private _environmentManager: EnvironmentManagerService) { }

  ngAfterViewInit(): void {
    this._uiService.rightPanel.panel = new Offcanvas(this.rightPanel.nativeElement)
  }

  closePanel() {
    this._uiService.closeRightPanel()
  }
}