import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ConnectionProperty } from '@classes/Connection'
import { ConnectionService } from '@services/connection.service'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'shared-edit-connection-attachment',
  templateUrl: './edit-connection-attachment.component.html',
  styleUrls: ['./edit-connection-attachment.component.css']
})
export class EditConnectionAttachmentComponent implements OnInit {
  @Input() attachment: ConnectionProperty
  @Output() showConnectionDataPicker: EventEmitter<CallableFunction> = new EventEmitter()
  @Output() delete: EventEmitter<boolean> = new EventEmitter()

  public displayName: string
  public headers: Array<{ key: string, value: string }>
  public url: string
  public showInDescription: boolean

  public urlIsValid$ = this.connectionService.urlStatus$.pipe(
    tap(_ => this.cd.detectChanges())
  )

  constructor(private connectionService: ConnectionService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    const { displayName, headers, url, showInDescription } = JSON.parse(this.attachment.value)

    this.displayName = displayName ?? ''
    this.headers = headers ?? []
    this.url = url ?? ''
    this.showInDescription = showInDescription ?? false
  }

  updateAttachment() {
    this.attachment.value = JSON.stringify({ displayName: this.displayName, headers: this.headers, url: this.url, showInDescription: this.showInDescription })
    this.connectionService.updateConnectionProperty(this.attachment, { toast: true, toastMessage: 'Attachment Updated' }).subscribe()
  }

  openConnectionDataPicker() {
    const updateCustomFieldCallback = (key: string, value: any, path: string) => {
      this.url = path
      this.updateAttachment()
    }

    this.showConnectionDataPicker.emit(updateCustomFieldCallback)
  }
  
  deleteAttachment() {
    this.delete.emit(true)
  }
}