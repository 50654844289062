import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'shared-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent {
  @Input() disabled: boolean = false
  @Input() ngModelIn: boolean
  @Input() title: string
  @Output() ngModelInChange = new EventEmitter<boolean>()
  @Output() onChange = new EventEmitter<boolean>()

  public id = Math.random().toString(36).slice(-10)

  constructor() { }
}