<div class="d-grid gap-2">
    <ng-container *ngIf="geojsonFiles && geojsonFiles.length != 0">
        <label class="form-label">GeoJSON Files</label>

        <div class="card bg-light mb-2 p-3 d-grid gap-3">
            <ng-container *ngFor="let file of geojsonFiles; index as i">
                <div class="bg-white rounded d-flex align-items-center">
                    <label class="text-center btn rounded-1 btn-primary" for="replace-file-{{i}}">
                        <i class="fas fa-file-search"></i>
                        <input [hidden]="true" id="replace-file-{{i}}" type="file" accept=".geojson, .shp"
                            (change)="replaceFile($event.target.files[0], i)">
                    </label>

                    <input [disabled]="true" [ngModel]="file.name" type="text" class="form-control">

                    <a class="btn text-secondary border" (click)="removeFile(i)">
                        <i class="fas fa-trash"></i>
                    </a>
                </div>
            </ng-container>
        </div>

        <div class="form-group">
            <label for="geojsonPropertyName">GeoJSON Property Name for Label:</label>
            <input [(ngModel)]="nameKey" type="text" class="form-control" id="geojsonPropertyName"
                placeholder='e.g., "name"'>
            <small class="form-text text-muted">
                Enter the property name from the GeoJSON that should be used as the label.
            </small>
        </div>
    </ng-container>

    <div class="d-flex align-items-center">
        <div class="me-3 d-flex" style="width:100px">
            <label tabindex="0" class="form-control btn btn-success btn-sm">+ Add Files
                <input type="file" class="invisible" id="featureInputGroupImage" style="width: 1px"
                    accept=".geojson, .shp" [required]="true" (change)="onFileChange($event.target.files)" multiple>
            </label>
        </div>
    </div>
    <small *ngIf="!isValid && touched" class="form-text text-danger">
        Must provide GeoJSON file
    </small>
</div>