export type ConnectionResponseOptions = {
    root: string,
    identifier: string
    identifierValue: string
}

export class ConnectionResponse {
    public data: Object
    public root: string = ''
    public identifier: string = ''
    public identifierValue: string = ''

    constructor(data: Object, options?: ConnectionResponseOptions) {
        this.data = data
        this.root = options?.root ? options.root : ''
        this.identifier = options?.identifier ? options.identifier : ''
        this.identifierValue = options?.identifierValue ? options.identifierValue : ''
    }

    get jsonToShow(): Object {
        let jsonToShow
        if (this.dataFromRoot && this.identifier != '' && this.identifierValue != '') {
            if (this.dataFromRoot instanceof Array) {
                jsonToShow = this.dataFromRoot.find(j => j[this.identifier] == this.identifierValue)
            } else if (this.dataFromRoot instanceof Object) {
                for (const property in this.dataFromRoot) {
                    let data = this.dataFromRoot[property]
                    if (data[this.identifier] == this.identifierValue) {
                        jsonToShow = data
                        break
                    }
                }
            }
        }

        return jsonToShow ?? this.data
    }

    get dataFromRoot(): Array<any> | Object {
        return this.getValueFromPath(this.root, this.data)
    }

    private getValueFromPath(path: string, data: any): any {
        if (path == '' || path == null) return data
        let pathArray = path?.split('/') ?? []
        let value = { ...data }
        try {
            for (let attr of pathArray) {
                value = value[attr]
            }
        } catch {
            return null
        }
        if (value === undefined) return null
        return value
    }
}