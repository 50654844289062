<div class="btn-group btn-group-sm" role="group" aria-label="Button Group for Transform Control's different Modes"
    style="background: rgb(255, 255, 255, 0.2)">
    <input *ngIf="translation" type="radio" class="btn-check" [name]="id" #translate [id]="'translate' + id"
        autocomplete="off" (click)="setMode('translate')" checked>
    <label *ngIf="translation" class="btn btn-outline-light text-dark" [for]="'translate' + id" title="Translate">
        <i class="fas fa-arrows-alt"></i>
    </label>

    <input *ngIf="rotation" type="radio" class="btn-check" [name]="id" #rotate [id]="'rotate' + id" autocomplete="off"
        (click)="setMode('rotate')">
    <label *ngIf="rotation" class="btn btn-outline-light text-dark" [for]="'rotate' + id" title="Rotate">
        <i class="fas fa-sync-alt"></i>
    </label>

    <input *ngIf="scale" type="radio" class="btn-check" [name]="id" #scale [id]="'scale' + id" autocomplete="off"
        (click)="setMode('scale')">
    <label *ngIf="scale" class="btn btn-outline-light text-dark" [for]="'scale' + id" title="Scale">
        <i class="fas fa-expand-alt"></i>
    </label>
</div>