import { User } from './User'

export type ProjectPermissionOptions = {
    lastEdited?: Date
    lastAccessed?: Date
}

export type PermissionType = 'Owner' | 'Editor' | 'Viewer' | 'Supervisor'

export class ProjectPermission {
    public userID: string
    public projectID: number
    public permission: PermissionType
    public user: User
    public lastEdited: Date
    public lastAccessed: Date

    constructor(userID: string, projectID: number, permission: PermissionType, user?: User, options?: ProjectPermissionOptions) {
        this.userID = userID
        this.projectID = +projectID
        this.permission = permission
        this.user = user
        this.lastEdited = options ? options.lastEdited : null
        this.lastAccessed = options ? options.lastAccessed : null
    }
}