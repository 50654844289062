import { Modal } from 'bootstrap'

import { Component } from '@angular/core'
import { ModalService } from '@services/modal.service'

@Component({
  selector: 'shared-modal-create-interaction',
  templateUrl: './create-interaction.component.html',
  styleUrls: ['./create-interaction.component.css'],
})
export class CreateInteractionComponent {
  public modal: Modal
  public title = 'Modal Title'
  public onCancel: CallableFunction

  constructor(
    public modalService: ModalService,
  ) { }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}
