<div class="rounded-circle square text-center" [ngClass]="{'scheme-bg-secondary': scene.id == (selectedScene$ | async).id }">
    <i style="margin-top:10px" [ngClass]="{
            'fas': scene.type != 'Standard' && scene.type != '360 Image', 
            'fa-light fa-360-degrees': scene.type == '360 Image', 
            'fa-globe-americas': scene.type == 'Map', 
            'fad fa-shapes': scene.type == 'Standard', 
            'fa-street-view': scene.type == 'Virtual Tour', 
            'text-muted': scene.id != (selectedScene$ | async).id
        }">
    </i>
</div>