<ul class="list-group" style='cursor: pointer; user-select: none;'>
    <li *ngIf="clickInfo?.data?.type != 'view' && sceneNotProcessing" (click)="copy()"
        class="list-group-item list-group-item-action">
        Copy {{clickInfo?.data?.name}}
    </li>
    <li *ngIf="clickInfo?.data?.type != 'view' && sceneNotProcessing" (click)="duplicate()"
        class="list-group-item list-group-item-action">
        Duplicate {{clickInfo?.data?.name}}
    </li>
    <li *ngIf="clickInfo?.data?.type == 'group'" (click)="ungroup()" class="list-group-item list-group-item-action">
        Ungroup {{clickInfo?.data?.name}}
    </li>
    <li *ngIf="clickInfo.type == 'feature' && clickInfo?.data.parentID" (click)="removeFromGroup(clickInfo.data)"
        class="list-group-item list-group-item-action">
        Remove {{clickInfo?.data?.name}} from Group
    </li>
    <li (click)="delete()" class="list-group-item list-group-item-action list-group-item-danger">
        Delete {{clickInfo?.data?.name}}
    </li>
</ul>