<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <div *ngIf="selectedModel$ | async as model" class="container p-4 modal-body">
                <div *ngIf="!userOwnsModel(model)" class="mb-3">
                    <div class="mb-2 ">
                        <span class="fw-bold form-label">Name: </span>
                        <span>{{model.name}}</span>
                    </div>
                    <div>
                        <span class="fw-bold form-label">Description: </span>
                        <span>{{model.description}}</span>
                    </div>
                </div>
                <div *ngIf='userOwnsModel(model)' class="mb-3">
                    <!-- Name -->
                    <div class="mb-2">
                        <div class="d-flex">
                            <label class="fw-bold form-label me-auto">Name</label>
                            <div class="form-label text-muted">Last Changed: {{model.lastChanged | date: 'short'}}
                            </div>
                        </div>
                        <input class='form-control' type="text" [(ngModel)]="model.name" (change)="updateModel(model)">
                    </div>

                    <!-- Description -->
                    <div>
                        <label class="fw-bold form-label">Description</label>
                        <input class='form-control' type="text" [(ngModel)]="model.description"
                            (change)="updateModel(model)">
                    </div>
                </div>
                <!-- Last Modified -->
                <!-- Editors -->
                <div *ngIf="hasOrganization">
                    <label class="fw-bold form-label">Permissions</label>
                    <shared-edit-model-permissions></shared-edit-model-permissions>
                </div>
            </div>

            <div class="modal-footer">
                <!-- <button type="button" class="btn" [ngClass]="{
                    'btn-primary': submitColor == 'blue' || submitColor == null,
                    'btn-success': submitColor == 'green',
                    'btn-danger': submitColor == 'red',
                    'btn-warning': submitColor == 'yellow'
                    }" (click)="_submit()">{{submitText}}</button> -->
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>