import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'create-interaction-vector3-picker',
  templateUrl: './vector3-picker.component.html',
  styleUrls: ['./vector3-picker.component.css']
})
export class Vector3PickerComponent implements AfterViewInit {
  @Input() formParent: UntypedFormGroup
  @Input() defaultNum: number
  get x(): UntypedFormControl { return <UntypedFormControl>this.formParent.get('x') }
  get y(): UntypedFormControl { return <UntypedFormControl>this.formParent.get('y') }
  get z(): UntypedFormControl { return <UntypedFormControl>this.formParent.get('z') }

  ngAfterViewInit(): void {
    if (this.defaultNum && this.x.pristine && this.y.pristine && this.z.pristine) {
      this.x.setValue(this.defaultNum)
      this.y.setValue(this.defaultNum)
      this.z.setValue(this.defaultNum)
    }
  }
}
