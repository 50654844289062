import { Component } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-latlng-helper',
  templateUrl: './latlng-helper.component.html',
  styleUrls: ['./latlng-helper.component.css']
})
export class LatlngHelperComponent {
  get mapSpace() { return this.envManager.mapSpace }
  get latlngString() {
    let lng = this.sceneService.selectedScene?.longitude?.value as number
    let lat = this.sceneService.selectedScene?.latitude?.value as number

    if (this.mapSpace?.mouseLngLat) {
      lng = this.mapSpace?.mouseLngLat?.lng
      lat = this.mapSpace?.mouseLngLat?.lat
    }

    return `Lng: ${lng?.toPrecision(11)}° Lat: ${lat?.toPrecision(11)}°`
  }

  constructor(public envManager: EnvironmentManagerService, public sceneService: SceneService) { }
}