<!-- Navigation Bar -->
<billing-nav-bar [showTabs]="false"></billing-nav-bar>

<div class="main" [ngSwitch]="currentPhase">
    <div class="body d-flex flex-column align-items-center gap-4">
        <ng-container *ngSwitchCase="'collectingInformation'">
            <div class="ms-auto">
                <a class="btn btn-close" aria-label="Close" (click)="returnToDashboard()"></a>
            </div>

            <billing-select-tier #selectTier></billing-select-tier>

            <billing-create-organization #createOrg class="px-1"></billing-create-organization>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="validateOrganizationInfo()">
                    Proceed to Payment
                </button>
            </div>
        </ng-container>

        <h1 *ngSwitchCase="'submitting'" class="fw-bold text-secondary fs-1">
            Submitting Payment Information...
        </h1>

        <ng-container *ngSwitchCase="'success'">
            <billing-view-invoice [invoice]="invoice" [title]="'Payment Confirmation'"
                [subtitle]="'Pro Tier Upgrade'"></billing-view-invoice>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="loginToOrganization()">
                    Go to Dashboard
                </button>
            </div>
        </ng-container>
    </div>

    <!-- Toasts -->
    <div class="toast-container d-flex flex-column m-3">
        <shared-toast-container></shared-toast-container>
    </div>
</div>

<!-- Right panel -->
<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="right-panel"
    aria-labelledby="right-panel-label">
    <div class="offcanvas-header">
        <h1 class="offcanvas-title" id="right-panel-label">Confirm Details</h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
        </button>
    </div>

    <div class="offcanvas-body d-flex flex-column">
        <billing-view-products [products]="proProductPackage" [priceMultiplier]="priceMultiplier">
        </billing-view-products>

        <!-- Checkout button -->
        <div class="d-flex justify-content-center ">
            <button [disabled]="!isValid" (click)="completeProTierCheckout()" class="btn btn-success" type="button"
                data-bs-dismiss="offcanvas" aria-label="Close">
                Finish Upgrade
            </button>
        </div>
    </div>
</div>