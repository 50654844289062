<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="{
        'modal-lg' : currentTab != 'assets',  
        'modal-xl' : currentTab == 'assets',  
    }" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>
            <div class="modal-body p-4">

                <ul [ngClass]="{'nav nav-tabs bg-white' : true, 'border-bottom-0' : currentTab == 'descriptionEditor'}">
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;"
                            (click)="currentTab = 'descriptionEditor'"
                            [ngClass]="{'active': currentTab == 'descriptionEditor'}">Description Editor</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;"
                            (click)="currentTab = 'customFieldEditor'"
                            [ngClass]="{'active': currentTab == 'customFieldEditor'}">Custom Field Editor</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;" (click)="currentTab = 'attachments'"
                            [ngClass]="{'active': currentTab == 'attachments'}">Attachments</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;" (click)="currentTab = 'assets'"
                            [ngClass]="{'active': currentTab == 'assets'}">Asset</a>
                    </li>
                    <shared-custom-fields-undo-redo-btns (undo)="undo()" (redo)="redo()" [undoStackSize]="undoStackSize" [redoStackSize]="redoStackSize" class="ms-auto" [hidden]="currentTab != 'customFieldEditor'">
                    </shared-custom-fields-undo-redo-btns>
                </ul>

                <ng-container *ngIf="config && selectedFeature">
                    <ckeditor [hidden]="currentTab != 'descriptionEditor'" #ckeditor [editor]="editor" [config]="config"
                        [(ngModel)]="selectedFeature.description" (focusout)="updateDetails($event)">
                    </ckeditor>
                </ng-container>

                <shared-edit-feature-custom-fields #editFeatureCustomFieldsComponent [hidden]="currentTab != 'customFieldEditor'">
                </shared-edit-feature-custom-fields>

                <shared-edit-attachment-list [hidden]="currentTab != 'attachments'" class="my-2 p-1">
                </shared-edit-attachment-list>

                <shared-asset-picker [hidden]="currentTab != 'assets'" class='w-100' style="overflow-x: hidden"></shared-asset-picker>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>