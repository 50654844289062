import { EMPTY } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { Component, QueryList, ViewChildren } from '@angular/core'
import { EditAssetsFieldValueComponent } from '@assets/edit-assets-field-value/edit-assets-field-value.component'
import { TypeToConfirmComponent } from '@modal/type-to-confirm/type-to-confirm.component'
import { AssetService } from '@services/asset.service'
import { ModalService } from '@services/modal.service'
import { UiService } from '@services/ui.service'

@Component({
  selector: 'assets-edit-assets',
  templateUrl: './edit-assets.component.html',
  styleUrls: ['./edit-assets.component.css']
})
export class EditAssetsComponent {
  @ViewChildren('fields') fieldComponents: QueryList<EditAssetsFieldValueComponent>

  public submittingChanges = false

  get assetIDs() { return Array.from(this.selectedAssets.values()) }
  get fields() { return this.selectedAssetType?.fields }
  get selectedAssets() { return this._assetService.selectedAssets }
  get selectedAssetType() { return this._assetService.selectedAssetType }

  constructor(private _assetService: AssetService, private _modalService: ModalService, private _uiService: UiService) { }

  clearChanges() {
    this.fieldComponents.forEach(fieldComponent => fieldComponent.reset())
  }

  deleteAssets() {
    if (!this.submittingChanges) {
      this._modalService.showAsModal<TypeToConfirmComponent>(TypeToConfirmComponent)
        .then((componentRef) => {
          componentRef.instance.title = `Delete ${this.selectedAssets.size} Assets`
          componentRef.instance.message = `Are you sure you want to delete these Assets? <strong>THIS ACTION CANNOT BE UNDONE.</strong>`
          componentRef.instance.submitText = 'Delete'
          componentRef.instance.submitColor = 'red'
          componentRef.instance.confirmWord = 'DELETE ASSETS'
          componentRef.instance.onSubmit = () => {
            this.submittingChanges = true

            this._assetService.deleteAssets(this.assetIDs)
              .pipe(catchError(this.handleError()))
              .subscribe(() => {
                this.submittingChanges = false
                this._assetService.triggerChangesListener()
                this.selectedAssets.clear()
                this._uiService.closeRightPanel()
              })
          }
        })
    }
  }

  duplicateAssets() {
    if (!this.submittingChanges) {
      this.submittingChanges = true

      this._assetService.duplicateAssets(this.assetIDs)
        .pipe(catchError(this.handleError()))
        .subscribe(() => {
          this.submittingChanges = false
          this._assetService.triggerChangesListener()
        })
    }
  }

  exportAssets() {
    if (!this.submittingChanges) {
      this.submittingChanges = true

      this._assetService.exportAssetsAsCSV(this.selectedAssetType, { assetIDs: this.assetIDs })
        .pipe(catchError(this.handleError()))
        .subscribe(() => this.submittingChanges = false)
    }
  }

  updateAssets() {
    const changes = this.fieldComponents
      .filter(fieldComponent => fieldComponent.isChanged && fieldComponent.isValid)
      .map(fieldComponent => fieldComponent.changes)

    if (!this.submittingChanges && changes.length > 0) {
      this.submittingChanges = true

      this._assetService.updateAssets(this.assetIDs, changes)
        .pipe(catchError(this.handleError()))
        .subscribe(() => {
          this.submittingChanges = false
          this._assetService.triggerChangesListener()
        })
    }
  }

  handleError() {
    return () => {
      this.submittingChanges = false

      return EMPTY
    }
  }
}