import { Component, Input } from '@angular/core'
import { FeatureProperty } from '@classes/FeatureProperty'
import { FileReferenceService } from '@services/file-reference.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'

@Component({
  selector: 'shared-view-attachment-list',
  templateUrl: './view-attachment-list.component.html',
  styleUrls: ['./view-attachment-list.component.css']
})
export class ViewAttachmentListComponent {
  @Input() attachments: FeatureProperty[]

  constructor(
    private _fileReferenceService: FileReferenceService,
    private _modelService: ModelService,
    private _projectService: ProjectService,
  ) { }

  getIcon(attachmentProperty: FeatureProperty) {
    return this._modelService.iconClasses(attachmentProperty?.fileReference?.filename)
  }

  // TODO: Figure out how to differentiate attachments; some will need currentProjectID and some need an Asset ID
  openAttachment(attachmentProperty: FeatureProperty) {
    const fileReference = attachmentProperty.fileReference

    this._fileReferenceService.openFile(fileReference, this._projectService.currentProject.id).subscribe()
  }
}