<div class="input-group">
    <ng-container [formGroup]="formParent">
        <div class="input-group-prepend">
            <span class="input-group-text">
                <b>x</b>
            </span>
        </div>
        <input class="form-control" [formControlName]="'x'" type="number" aria-describedby="basic-addon2">
        <div class="input-group-prepend">
            <span class="input-group-text">
                <b>y</b>
            </span>
        </div>
        <input class="form-control" [formControlName]="'y'" type="number" aria-describedby="basic-addon2">
        <div class="input-group-prepend">
            <span class="input-group-text">
                <b>z</b>
            </span>
        </div>
        <input class="form-control" [formControlName]="'z'" type="number" aria-describedby="basic-addon2">
    </ng-container>
</div>