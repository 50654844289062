import { gps } from 'exifr'

import { Component } from '@angular/core'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'

type MarkerInformation = {
  embeddedImage?: File,
  gpsPosition?: [number, number, number],
  hash?: string,
  marker: Feature
}

@Component({
  selector: 'app-create-marker',
  templateUrl: './create-marker.component.html',
  styleUrls: ['./create-marker.component.css']
})
/** 
 * 
 * 
 * 
 * TODO: Needs work to actually be completed 
 * 
 * 
 * 
 * */
export class CreateMarkerComponent {
  public clipboardCoordinates: [number, number][] = []
  public createOne = true // False implies we mean to Create Many
  public iconGPSPosition: [number, number, number]
  public iconTouched = false
  public markerIcon: File
  public markerInformation: MarkerInformation[] = [{
    marker: new Feature(this.selectedSceneID, 'New Marker', 'marker')
  }]

  get center(): [number, number, number] {
    const { lng, lat } = this._envManager.map.getCenter()

    return [lng, lat, 0]
  }
  get selectedSceneID() { return this._sceneService.selectedSceneID }

  get embeddedFile() { return this.markerInformation[0].embeddedImage }
  get embeddedFiles() { return this.markerInformation.map(info => info.embeddedImage) }
  get gpsPosition() { return this.markerInformation[0].gpsPosition }
  get marker() { return this.markerInformation[0].marker }
  get markers() { return this.markerInformation.map(info => info.marker) }

  constructor(
    private _copyService: CopyService,
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _sceneService: SceneService
  ) {
    this.marker.position = this.center
    this.marker.properties.push(
      new FeatureProperty("integer", "iconScale", "0.06")
    )

    this._copyService.getCoordinates()
      .then(coordinates => this.clipboardCoordinates = coordinates)
  }

  addMarker(embeddedFile?: File, gpsPosition?: [number, number, number]) {
    const marker = new Feature(
      this.selectedSceneID,
      embeddedFile?.name ??
      'New Marker',
      'marker',
      { position: this.center }
    )

    marker.properties.push(
      new FeatureProperty("integer", "iconScale", "0.06")
    )

    this.markerInformation.push({
      embeddedImage: embeddedFile,
      gpsPosition: gpsPosition,
      marker: marker
    })
  }

  removeMarker(index: number) {
    this.markerInformation.splice(index, 1)
  }

  createMarkers$() {
    // return 
    // this._featureService.createMarkers(this.markerIcon, this.selectedScene.id, this.markerInformation)

    //   .subscribe(features => {
    //     console.log(features)
    //   })
  }

  resetPosition(marker: Feature) {
    marker.position = this.center
  }

  setPosition(marker: Feature, position: [number, number, number]) {
    marker.position = position
  }

  getGPSPosition(file: File): Promise<[number, number, number] | undefined> {
    return gps(file).then(({ longitude, latitude }) => {
      if (latitude && longitude) {
        return [longitude, latitude, 0]
      } else {
        return undefined
      }
    }).catch(() => undefined)
  }

  pasteCoordinates(marker?: Feature) {
    if (marker) {
      const [longitude, latitude] = this.clipboardCoordinates[0]
      const position = [longitude, latitude, 0] as [number, number, number]

      marker.position = position
    } else {
      this.clipboardCoordinates.forEach(([longitude, latitude], i) => {
        const position = [longitude, latitude, 0] as [number, number, number]

        if (this.markers[i]) {
          this.markers[i].position = position
        }
      })
    }
  }

  uploadIcon(file: File) {
    if (file) {
      this.markerIcon = file
      this.iconTouched = true

      if (this.marker.name == '') {
        this.marker.name = file.name
      }

      this.getGPSPosition(file)
        .then(position => this.iconGPSPosition = position)
    }
  }

  embedImage(index: number, file: File) {
    if (file) {
      this.markerInformation[index].embeddedImage = file

      this.getGPSPosition(file)
        .then(position => this.markerInformation[index].gpsPosition = position)
    }
  }

  uploadEmbeddedImages(fileList: FileList) {
    if (fileList) {
      const files = Array.from(fileList)

      Promise.all(
        files.map(file => this.getGPSPosition(file)
          .then(position => this.addMarker(file, position))
        )
      )
    }
  }

  replaceEmbeddedImage(index: number, file: File) {
    this.embedImage(index, file)
  }

  removeEmbeddedImage(index: number) {
    this.markerInformation[index].embeddedImage = undefined
    this.markerInformation[index].gpsPosition = undefined
  }
}