<ul *ngIf='attachments.length > 0' class="list-group">
    <ng-container *ngFor="let attachment of attachments; let i = index">
        <ng-container *ngIf="attachment.fileReferenceID; else remote">
            <li [attr.data-index]="i"
                class="list-group-item list-group-item-action d-flex align-items-center pe-2 d-grid gap-2"
                [title]="attachment.fileReference.filename" (click)="openAttachment(attachment)" style="cursor: pointer;">
                <i [ngClass]="getIcon(attachment)" style="width: 20px;"></i>
                <span class="text-nowrap text-truncate">{{ attachment.fileReference.filename }}</span>
            </li>
        </ng-container>
        <ng-template #remote>
            <li [attr.data-index]="i"
                class="list-group-item list-group-item-action d-flex align-items-center pe-2 d-grid gap-2" style="cursor: pointer;">
                <shared-view-remote-attachment [attachment]="attachment" class="w-100">
                </shared-view-remote-attachment>
            </li>
        </ng-template>
    </ng-container>
</ul>