import { Component } from '@angular/core';
import { AssetHistoryEntry } from '@services/asset.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-show-asset-history',
  templateUrl: './show-asset-history.component.html',
  styleUrls: ['./show-asset-history.component.css']
})
export class ShowAssetHistoryComponent {
  public cancelText = 'Close'
  public modal: Modal
  public title = 'Modal Title'

  public history: AssetHistoryEntry[]

  public onCancel: CallableFunction

  constructor() { }


  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}
