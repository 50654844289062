<div *ngIf="retrievingCustomFields; else hasCustomFields" class="d-flex flex-column gap-3 align-items-center">
  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status">
  </div>

  <span>Loading custom fields...</span>

  <button class="btn btn-light btn-sm" (click)="cancel()">Cancel</button>
</div>

<ng-template #hasCustomFields>
  <div *ngIf="!retrievingCustomFields && ruleObjects.length > 0; else noFilters" class="d-grid gap-2"
    style="user-select: none">
    <!-- Results -->
    <div class="d-flex justify-content-between align-items-center">
      <label>Active Filters</label>

      <span class="badge rounded-pill bg-primary">
        {{ resultsCount }} matches
      </span>
    </div>

    <!-- Filter Rules -->
    <shared-rule-group [ruleGroup]="activeRuleGroup" [searchableProperties]='searchableProperties'
      (edit)="updateRuleGroup($event)">
    </shared-rule-group>

    <!-- Hide Features Switch -->
    <div class="form-switch d-flex d-grid gap-2 mb-4">
      <input class="form-check-input" id="hide-feature-switch" type="checkbox" role="switch"
        [(ngModel)]="hiddenIfInapplicable" style="cursor: pointer;">
      <label class="form-check-label" for="hide-feature-switch">
        Hide feature if it does not have property
      </label>
    </div>
  </div>

  <!-- Add Rule Button -->
  <ng-template #noFilters>
    <div class="d-flex justify-content-center">
      <a (click)="addRule()" class="btn btn-sm btn-primary w-25">
        + Add Rule
      </a>
    </div>
  </ng-template>
</ng-template>