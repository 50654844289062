import { Observable } from "rxjs"
import { FeatureCustomField } from "./Feature"
import { FeatureService } from "@services/feature.service"
import { EditFeatureCustomFieldsComponent } from "@shared/edit-feature-custom-fields/edit-feature-custom-fields.component"
import { tap } from "rxjs/operators"

export const ID_MAP = new Map<number, number>()

export interface CustomFieldCommand {
    execute(): Observable<any>
    undo(): Observable<any>
}

export class CreateCustomFieldCommand implements CustomFieldCommand {
    private created: FeatureCustomField

    constructor(private featureService: FeatureService, private customFieldEditor: EditFeatureCustomFieldsComponent, private customField: FeatureCustomField) {
        this.created = customField
    }

    execute(): Observable<any> {
        return this.featureService.createCustomField(this.created).pipe(
            tap((cf: FeatureCustomField) => {
                if (this.customField.id) ID_MAP.set(this.customField.id, cf.id)
                this.created.id = cf.id
                this.customFieldEditor.customFields.push(this.created)
            })
        )
    }

    undo(): Observable<any> {
        if (ID_MAP.get(this.customField.id)) this.created.id = ID_MAP.get(this.customField.id)
        this.customFieldEditor.customFields.splice(this.customFieldEditor.customFields.indexOf(this.customField), 1)
        return this.featureService.deleteCustomField(this.created)
    }
}

export class UpdateCustomFieldCommand implements CustomFieldCommand {
    private original: FeatureCustomField
    private modified: FeatureCustomField

    constructor(private featureService: FeatureService, private customFieldEditor: EditFeatureCustomFieldsComponent, private originalCustomField: FeatureCustomField, private modifiedCustomField: FeatureCustomField) {
        this.original = { ...originalCustomField }
        this.modified = { ...modifiedCustomField }
    }

    execute(): Observable<any> {
        if (ID_MAP.has(this.modifiedCustomField.id)) {
            this.modified.id = ID_MAP.get(this.modifiedCustomField.id)
            const index = this.customFieldEditor.customFields.findIndex(cf => cf.id == this.modified.id)
            this.customFieldEditor.customFields[index] = this.modified
        } else {
            const index = this.customFieldEditor.customFields.findIndex(cf => cf.id == this.modifiedCustomField.id)
            this.modified.id = this.modifiedCustomField.id
            this.customFieldEditor.customFields[index] = this.modified
        }

        Object.assign(this.originalCustomField, this.modifiedCustomField)
        Object.assign(this.modifiedCustomField, this.modified)
        return this.featureService.updateCustomField(this.modified)
    }

    undo(): Observable<any> {
        if (ID_MAP.get(this.originalCustomField.id)) {
            this.original.id = ID_MAP.get(this.originalCustomField.id)
            let newIndex = this.customFieldEditor.customFields.findIndex(cf => cf.id == this.original.id)
            this.customFieldEditor.customFields[newIndex] = this.original
        } else {
            let oldIndex = this.customFieldEditor.customFields.findIndex(cf => cf.id == this.originalCustomField.id)
            this.original.id = this.originalCustomField.id
            this.customFieldEditor.customFields[oldIndex] = this.original
        }

        Object.assign(this.originalCustomField, this.original)
        Object.assign(this.modifiedCustomField, this.originalCustomField)
        return this.featureService.updateCustomField(this.original)
    }
}

export class DeleteCustomFieldCommand implements CustomFieldCommand {
    private deleted: FeatureCustomField

    constructor(private featureService: FeatureService, private customFieldEditor: EditFeatureCustomFieldsComponent, private deletedCustomField: FeatureCustomField) {
        this.deleted = deletedCustomField
    }
    
    execute(): Observable<any> {
        if (ID_MAP.get(this.deletedCustomField.id)) {
            this.deleted.id = ID_MAP.get(this.deletedCustomField.id)
        }
        const index = this.customFieldEditor.customFields.indexOf(this.deletedCustomField)
        this.customFieldEditor.customFields.splice(index, 1)
        return this.featureService.deleteCustomField(this.deleted)
    }

    undo(): Observable<any> {
        return this.featureService.createCustomField(this.deleted).pipe(
            tap((response: FeatureCustomField) => {
                if (this.deletedCustomField.id) ID_MAP.set(this.deletedCustomField.id, response.id)
                this.deleted.id = response.id
                this.customFieldEditor.customFields.push(this.deleted)
            })
        )
    }
}