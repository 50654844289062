import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-beta-pill',
  templateUrl: './beta-pill.component.html',
  styleUrls: ['./beta-pill.component.css']
})
export class BetaPillComponent implements OnInit {
  /** Number that determines the size of the BetaPill
   * @default 10
   */
  @Input() size: number = 10
  get fontSize() { return (this.size * 0.1) + 'em'}
  constructor() { }

  ngOnInit(): void {
  }

}
