import { LngLatLike } from 'mapbox-gl'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import * as THREE from 'three'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Action } from '@classes/Action'
import { Feature } from '@classes/Feature'
import { Interaction, InteractionType } from '@classes/Interaction'
import { Scene } from '@classes/Scene'
import { mapEaseTo } from '@classes/Transitions'
import { LoadingService } from '@services/loading.service'
import { ViewFeatureDetailsComponent } from '@shared/view-feature-details/view-feature-details.component'

import { EnvironmentManagerService } from './environment-manager.service'
import { FeatureService, interactionFromResult, InteractionResult } from './feature.service'
import { ModalService } from './modal.service'
import { RightSidebarService } from './right-sidebar.service'
import { SceneService } from './scene.service'
import { ToastService } from './toast.service'
import { VirtualTourService } from './virtual-tour.service'

const movingToSource = new BehaviorSubject<boolean>(undefined)

const HTTPOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

export type MoveToPayload = {
  cameraPosition: { x: number, y: number, z: number },
  controlsPosition: { x: number, y: number, z: number },
  bearing: number,
  longitude: number,
  latitude: number,
  pitch: number,
  zoom: number,
  inViewpointMode: boolean,
  currentViewPointFeatureID: number
}

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  private _actions = {
    move: (feature: Feature, position: [number, number, number]) => {
      feature.position = position
      this._featureService.updateFeaturesLocally(feature)
    },
    rotate: (feature: Feature, rotation: [number, number, number]) => {
      feature.rotation = rotation
      this._featureService.updateFeaturesLocally(feature)
    },
    scale: (feature: Feature, scale: [number, number, number]) => {
      feature.scale = scale
      this._featureService.updateFeaturesLocally(feature)
    },
    focus: (feature: Feature) => {
      if (this._virtualTourService.viewpointMode) {
        const position = new THREE.Vector3().fromArray(feature.position)

        this._envManager.lookAt(this.camera, this.controls, position)
      } else {
        setTimeout(() => {
          this._envManager.focusOnFeatures(feature)
        })
      }
    },
    moveTo: async (payload: MoveToPayload) => {
      const { bearing, cameraPosition, controlsPosition, longitude, latitude, pitch, zoom, inViewpointMode, currentViewPointFeatureID } = payload
      const scene = this._sceneService.selectedScene

      if (scene.type == 'Map') {
        const center = [longitude, latitude] as LngLatLike

        setTimeout(() => {
          mapEaseTo(this.map, { bearing, center, pitch, zoom })
        })
      } else if (scene.type == '360 Image') {
        const { x, y, z } = cameraPosition
        const position = new THREE.Vector3(x, y, z).clone().negate()

        this._envManager.lookAt(this.camera, this.controls, position)
      } else {
        // Handle standard scenes?
        const positionOverride = new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z)
        const controlsOverride = new THREE.Vector3(controlsPosition.x, controlsPosition.y, controlsPosition.z)

        let currentViewPoint = this.sceneManager.getFeatureGroup(+currentViewPointFeatureID)
        const inSameViewpoint = currentViewPoint?.userData?.featureID == this._virtualTourService.currentViewpoint?.userData?.featureID
        if (inViewpointMode && !inSameViewpoint) {
          this._virtualTourService.enterViewpoint(currentViewPoint, {
            levelOutCamera: !inViewpointMode,
            positionOverride,
            controlsOverride
          })
        } else if (inViewpointMode && inSameViewpoint) {
          this._virtualTourService.easeCameraIntoPosition(null, {
            easeTime: 2000,
            controlsOverride,
            positionOverride
          })
        } else if (!inViewpointMode && this._virtualTourService.viewpointMode) {
          this._virtualTourService.exitViewpoint().then(_ => Promise.all([
            this._envManager.easeTo(this.camera.position, cameraPosition as THREE.Vector3, { easeType: TWEEN.Easing.Cubic.InOut, easeTime: 2000 }),
            this._envManager.focus(this.camera, this.controls, controlsPosition as THREE.Vector3, { easeTime: 1500 })
          ]))
        } else {
          Promise.all([
            this._envManager.easeTo(this.camera.position, cameraPosition as THREE.Vector3, { easeType: TWEEN.Easing.Cubic.InOut, easeTime: 2000 }),
            this._envManager.focus(this.camera, this.controls, controlsPosition as THREE.Vector3, { easeTime: 1500 })
          ])
        }
      }
    },
    hide: (feature: Feature) => this._envManager.toggleVisibility(feature, { visible: false, updateLocally: true }),
    show: (feature: Feature) => this._envManager.toggleVisibility(feature, { visible: true, updateLocally: true }),
    toggleHidden: (feature: Feature) => this._envManager.toggleVisibility(feature, { updateLocally: true }),
    openScene: (nextScene: Scene) => {
      const currentScene = this._sceneService.selectedScene
      const currentIs360 = currentScene.type == '360 Image'
      const nextIs360 = nextScene.type == '360 Image'

      this._envManager.pushToSceneStack()

      if (nextIs360 && currentIs360) this._envManager.saveCameraAngle()

      this._loadingService.setLoaded(false)

      // IMPORTANT NOTE: A small delay is applied to ensure the loading spinner is shown for previously loaded scenes
      setTimeout(() => this._sceneService.selectedScene = nextScene, 25)
    },
    openTab: (url: string) => window.open(url),
    showDetails: (feature: Feature, type: 'modal' | 'panel', size: "medium" | "large" | "fullscreen") => {
      if (type == 'modal') {
        //TODO: Make scrolling work without getting rid of the backdrop. Otherwise, it looks bad!
        this._modalService.insertIntoModal(ViewFeatureDetailsComponent, {
          title: feature.name,
          size: size,
          closeText: 'Close',
          destroyOnClose: true
        })

      } else if (type == 'panel') {
        this._rightSidebarService.openPanel(feature.name)
      }
    },
    disableInteractions: (feature: Feature) => {
      feature.interactable = false
      this._featureService.updateFeaturesLocally(feature)
    },
    enableInteractions: (feature: Feature) => {
      feature.interactable = true
      this._featureService.updateFeaturesLocally(feature)
    },
    toggleInteractions: (feature: Feature) => {
      feature.interactable = !feature.interactable
      this._featureService.updateFeaturesLocally(feature)
    },
    loadIn: (feature: Feature) => this._loadingService.await(this.sceneManager.loadIn(feature)),
    loadOut: (feature: Feature) => this.sceneManager.loadOut(feature),
    toggleLoad: (feature: Feature) => feature.unloaded ? this._actions.loadIn(feature) : this._actions.loadOut(feature),
  }

  get camera() { return this.modelSpace?.camera }
  get controls() { return this.modelSpace?.orbitControls }
  get map() { return this._envManager.map }
  get modelSpace() { return this._envManager.modelSpace }
  get sceneManager() { return this._envManager.sceneManager }

  constructor(
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _http: HttpClient,
    private _loadingService: LoadingService,
    private _modalService: ModalService,
    private _rightSidebarService: RightSidebarService,
    private _sceneService: SceneService,
    private _toastService: ToastService,
    private _virtualTourService: VirtualTourService,
  ) { }

  /** Performs all of the `Feature's` `Actions` given the interaction `type` */
  initiateActions(feature: Feature, type: InteractionType) {
    const initiator = this._featureService.getFeature(feature.id)
    if (initiator.interactable == false) {
      return
    }

    initiator.interactions
      .filter(interaction => interaction.type == type)
      .forEach(({ actions }) => {
        actions
          .sort((a, b) => a.id - b.id) // Sorting so that they execute in order of their creation
          .forEach(({ key, value }) => {
            const payload = value
            const id = payload?.id
            const recipient = this._featureService.getFeature(id)

            switch (key) {
              case 'move': case 'rotate': case 'scale':
                const vector = payload?.array as [number, number, number]

                if (recipient == null) {
                  console.error('Action has no target')
                } else if (recipient.type == 'group') {
                  const children = this._featureService.getDescendents(recipient)
                    .filter(f => f.type != 'group')

                  children.forEach(child =>
                    this._actions[key](child, vector)
                  )
                } else {
                  this._actions[key](recipient, vector)
                }
                break
              case 'hide': case 'show': case 'toggleHidden':
              case 'disableInteractions': case 'enableInteractions': case 'toggleInteractions':
              case 'loadIn': case 'loadOut': case 'toggleLoad':
                if (recipient == null) {
                  console.error('Action has no target')
                } else if (recipient.type == 'group') {
                  const children = this._featureService.getDescendents(recipient)
                    .filter(f => f.type != 'group')

                  children.forEach(child => this._actions[key](child))
                } else {
                  this._actions[key](recipient)
                }
                break
              case 'focus':
                if (recipient == null) {
                  console.error('Action has no target')
                } else this._actions.focus(recipient)
                break
              case 'moveTo':
                this._actions.moveTo(payload)
                break
              case 'openScene':
                const scene = this._sceneService.scenes.find(scene => id == scene.id)

                this._actions.openScene(scene)
                break
              case 'openTab':
                const url = payload?.string as string

                this._actions.openTab(url)
                break
              case 'showDetails':
                const displayType = payload?.string as "modal" | "panel"
                const size = payload?.size as "medium" | "large" | "fullscreen"

                this._actions.showDetails(initiator, displayType, size)
                break
            }
          })
      })
  }

  /*
   * When you send an interaction with actions, the actions also get created in the backend,
   * so there is no need to call createAction for each action.
   */
  createInteraction(interaction: Interaction): Observable<Interaction> {
    const addInteractionLocally = (int: Interaction) => {
      const features = this._featureService.features
      const feature = features.find(f => f.id == int.featureID)
      feature.interactions ? feature.interactions.push(int) : feature.interactions = [int]
      this._featureService.updateFeaturesLocally(feature)
    }
    const url = `${environment.api}/interaction/${interaction.featureID}`

    return this._http.post<InteractionResult>(url, interaction, HTTPOptions).pipe(
      map(intResult => {
        const interaction = interactionFromResult(intResult)
        addInteractionLocally(interaction)
        this._toastService.toast({ title: "Interaction Created", color: "green" })

        return interaction
      })
    )
  }

  deleteInteraction(int: Interaction): Observable<Interaction> {
    const removeInteractionLocally = (intDeleted: Interaction) => {
      const features = this._featureService.features
      const feature = features.find(f => f.id == intDeleted.featureID)
      const intIndex: number = feature.interactions.findIndex(int => int.id == intDeleted.id)

      intIndex != -1 ? feature.interactions.splice(intIndex, 1) : console.error("Removed interaction that didnt exist in feature.")

      this._featureService.updateFeaturesLocally(feature)
    }

    return this._http.delete(`${environment.api}/interaction/${int.id}`).pipe(
      catchError(this.handleError("Failed to delete interaction")),
      map(() => {
        removeInteractionLocally(int)
        this._toastService.toast({ title: "Interaction Deleted", color: "green" })
        return int
      })
    )
  }

  updateInteraction(int: Interaction) {
    const updateInteractionLocally = (intUpdated: Interaction, feats: Feature[]) => {
      let feature = feats.find(f => f.id == intUpdated.featureID)
      const intIndex: number = feature.interactions.findIndex(int => int.id == intUpdated.id)

      if (intIndex != -1 && feature.interactions)
        feature.interactions[intIndex] = int
      else {
        feature.interactions = [int]
        console.error("Updated interaction but it didnt exist in feature.")
      }

      this._featureService.updateFeaturesLocally(feature)
    }

    return this._http.put(`${environment.api}/interaction/${int.id}`, int, HTTPOptions).pipe(
      catchError(this.handleError("Failed to update interaction.")),
      switchMap(() => {
        let feats = this._featureService.features
        updateInteractionLocally(int, feats)
        return of(int)
      }),
      tap(() => {
        this._toastService.toast({ title: "Interaction Updated", color: "green" })
      })
    )
  }

  createAction(action: Action, interaction: Interaction): Observable<Action> {
    const addActionLocally = () => {
      const features = this._featureService.features
      const feature = features.find(f => f.id == interaction.featureID)
      const intIndex: number = feature.interactions.findIndex(i => i.id == interaction.id)

      interaction.actions.push(action)
      feature.interactions[intIndex] = interaction // Update Interaction in Feature

      this._featureService.updateFeaturesLocally(feature)
    }
    const url = `${environment.api}/action/${interaction.id}`

    return this._http.post<{ id: number }>(url, action, HTTPOptions).pipe(
      catchError(this.handleError<{ id: number }>("Failed to create action.")),
      map(results => {
        action.id = results.id
        addActionLocally()
        this._toastService.toast({ title: "Action Created", color: "green" })
        return action
      })
    )
  }

  deleteAction(action: Action): Observable<Action> {
    const deleteActionLocally = (action: Action) => {
      const features = this._featureService.features
      const feature = features.find(feature => feature.interactions.some(i => i.id == action.interactionID))
      const interaction = feature.interactions.find(i => i.id == action.interactionID)

      const actIndex = interaction.actions.findIndex(a => a.id == action.id)
      const intIndex: number = feature.interactions.findIndex(i => i.id == interaction.id)

      interaction.actions.splice(actIndex, 1) // Delete Action in Interaction
      feature.interactions[intIndex] = interaction // Update Interaction in Feature

      this._featureService.updateFeaturesLocally(feature)
    }

    return this._http.delete(`${environment.api}/action/${action.id}`).pipe(
      catchError(this.handleError("Failed to delete action.")),
      map(() => {
        deleteActionLocally(action)
        this._toastService.toast({ title: "Action Deleted", color: "green" })
        return action
      })
    )
  }

  updateAction(action: Action, int: Interaction): Observable<Action> {
    const updateActionLocally = (act: Action) => {
      const features = this._featureService.features
      const feature = features.find(f => f.id == int.featureID)
      const actIndex = int.actions.findIndex(a => a.id == act.id)
      const intIndex: number = feature.interactions.findIndex(i => i.id == int.id)

      int.actions[actIndex] = act // Update Action in Interaction

      // Update Interaction in Feature
      if (feature.interactions) feature.interactions[intIndex] = int
      else {
        feature.interactions = [int]
        console.error("Updated interaction but it didnt exist in feature.")
      }

      this._featureService.updateFeaturesLocally(feature)
    }
    const url = `${environment.api}/action/${action.id}`

    return this._http.put(url, action, HTTPOptions).pipe(
      catchError(this.handleError("Failed to update action.")),
      map(() => {
        updateActionLocally(action)
        this._toastService.toast({ title: "Action Updated", color: "green" })
        return action
      })
    )
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {

      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the interaction error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}