import { Subscription } from 'rxjs'
import * as THREE from 'three'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'

import { PostProcessor } from '@classes/PostProcessor'
import { Raycaster } from '@classes/Raycaster'
import { SceneType } from '@classes/Scene'
import { SceneManager } from '@classes/SceneManager'

import { MapControls } from './OrbitControls'

export interface Space {
    camera: THREE.PerspectiveCamera
    canvas: HTMLCanvasElement
    postProcessor: PostProcessor
    raycaster: Raycaster
    renderer: THREE.WebGLRenderer
    renderer2D: CSS2DRenderer
    rendering: boolean
    dispose: () => any
    render: () => any
}

export type ModelSpaceConfiguration = {
    /** TODO: Make background color something handled by the SceneManager */
    background?: THREE.Color
    cameraFOV?: number
    cameraPosition?: THREE.Vector3
    controlsLocked?: boolean
    targetPosition?: THREE.Vector3
}

export const DEFAULT_BACKGROUND = 0x404040
export const DEFAULT_FAR = 100000
export const DEFAULT_FOV = 90
export const DEFAULT_NEAR = 0.001

export class ModelSpace implements Space {
    private _main: HTMLElement
    private _subscriptions: Subscription[] = []
    public camera: THREE.PerspectiveCamera
    public orbitControls: MapControls
    public postProcessor: PostProcessor
    public raycaster: Raycaster
    public renderer: THREE.WebGLRenderer
    public renderer2D: CSS2DRenderer
    public rendering: boolean = true

    get canvas(): HTMLCanvasElement { return this.renderer.domElement }

    /** Sets the cameras field of view. Restricts FOV from going over a certain maximum or under a minimum. */
    set fov(value: number) {
        const camera = this.camera
        const fovMAX = 120
        const fovMIN = 10

        camera.fov = Math.max(Math.min(value, fovMAX), fovMIN)
        camera.projectionMatrix = new THREE.Matrix4()
            .makePerspective(camera.fov, window.innerWidth / window.innerHeight, camera.near, camera.far)
    }

    constructor(
        protected _container: HTMLElement,
        public sceneManager: SceneManager,
        config: ModelSpaceConfiguration = {},
    ) {
        this._main = _container.parentElement
        this.camera = new THREE.PerspectiveCamera(DEFAULT_FOV, this._main.clientWidth / this._main.clientHeight, DEFAULT_NEAR, DEFAULT_FAR)

        this.initCanvas(_container)
        this._initRaycaster()

        this.canvas.id = '360 Image'

        if (config?.background) {
            this.sceneManager.renderedScene.background = config.background
        }

        if (config?.controlsLocked) {
            this.setControls('360 Image')
        } else {
            this.setControls('Standard')
        }

        this.camera.position.copy(config.cameraPosition ?? new THREE.Vector3(10, 10, 10))
        this.orbitControls.target.copy(config.targetPosition ?? new THREE.Vector3(0, 0, 0))
        this.fov = config?.cameraFOV ?? DEFAULT_FOV

        if (this.camera.position.equals(this.orbitControls.target)) {
            this.camera.position.add(new THREE.Vector3(0, 0.01, 0))
        }

        const animateCallBack = {
            callAnimate: () => {
                if (this.rendering) {
                    requestAnimationFrame(animateCallBack.callAnimate)
                    this.render()
                }
            }
        }

        animateCallBack.callAnimate()
    }

    protected initCanvas(container: HTMLElement) {
        let canvas = document.createElement('canvas')
        container.appendChild(canvas)

        this.renderer = new THREE.WebGLRenderer({ canvas: canvas, logarithmicDepthBuffer: true })
        this.renderer.setSize(this._main.clientWidth, this._main.clientHeight)
        this.renderer.autoClear = true
        this.renderer.setPixelRatio(window.devicePixelRatio)

        this.renderer2D = new CSS2DRenderer()
        this.renderer2D.setSize(this._main.clientWidth, this._main.clientHeight)
        this.renderer2D.domElement.style.position = 'absolute'
        this.renderer2D.domElement.style.top = '0px'
        this.renderer2D.domElement.style.pointerEvents = 'none'
        this.renderer2D.domElement.id = 'css2DElements'
        container.appendChild(this.renderer2D.domElement)

        this.orbitControls = new MapControls(this.camera, this.canvas)
        this.orbitControls.enableDamping = true
        this.orbitControls.dampingFactor = 0.11
    }

    private _initRaycaster() {
        this.raycaster = new Raycaster(this.sceneManager, this.camera, this.canvas)
        this.postProcessor = new PostProcessor(this.sceneManager.renderedScene, this.camera, this.canvas)
    }

    /**
     * Updates the `OrbitControls`, `Camera` projection matrix, & `TWEEN` animations.
     * Resizes the `Camera` aspect ratio, the `Scene` `Renderer`, & the `CSS2DRenderer`.
     * Renders the current `Scene`, `PostProcesser` effects, & `CSSObjects`.
     */
    render() {
        if (this.orbitControls) { // While updating, camera.lookAt() will not work
            this.orbitControls.update()
        }

        this.camera.aspect = this._main.clientWidth / this._main.clientHeight

        this.camera.updateProjectionMatrix()
        this.renderer.setSize(this._main.clientWidth, this._main.clientHeight)
        this.renderer.render(this.sceneManager.renderedScene, this.camera)
        this.renderer2D.setSize(this._main.clientWidth, this._main.clientHeight)
        this.renderer2D.render(this.sceneManager.renderedScene, this.camera)
        this.postProcessor.render()

        TWEEN.update()
    }

    dispose() {
        this._subscriptions.forEach(sub => sub.unsubscribe())

        this.renderer.forceContextLoss()
        this.renderer.context = null
        this.renderer.domElement.remove()
        this.renderer.domElement = null
        this.renderer.renderLists.dispose()
        this.renderer.dispose()

        this.raycaster.dispose()
        this.postProcessor.dispose()

        this.renderer = null
        this.rendering = false
    }

    getGroupScreenPosition(group: THREE.Group): { x: number, y: number } {
        let boundingBox = new THREE.Box3()
        let groupCenter = new THREE.Vector3()

        boundingBox.setFromObject(group)
        boundingBox.getCenter(groupCenter)

        const widthHalf = 0.5 * this.canvas.clientWidth
        const heightHalf = 0.5 * this.canvas.clientHeight

        groupCenter.project(this.camera)

        groupCenter.x = (groupCenter.x * widthHalf) + widthHalf
        groupCenter.y = - (groupCenter.y * heightHalf) + heightHalf

        return groupCenter
    }

    setControls(type: SceneType) {
        if (type == 'Standard' || type == 'Virtual Tour') {
            this.orbitControls.mouseButtons.LEFT = THREE.MOUSE.PAN
            this.orbitControls.touches = { ONE: THREE.TOUCH.PAN, TWO: THREE.TOUCH.DOLLY_ROTATE }
            this.orbitControls.enablePan = true
            this.orbitControls.enableZoom = true
            this.orbitControls.maxDistance = Infinity
            this.orbitControls.rotateSpeed = 1
        } else if (type == '360 Image') {
            this.orbitControls.touches.ONE = THREE.TOUCH.ROTATE
            this.orbitControls.mouseButtons.LEFT = THREE.MOUSE.ROTATE
            this.orbitControls.enablePan = false
            this.orbitControls.enableZoom = false
            this.orbitControls.maxDistance = 5000
            this.orbitControls.rotateSpeed = -0.25 // Invert to create "grab" effect
        }

        this.camera.fov = DEFAULT_FOV
    }
}