<div class='d-flex flex-column gap-2' style="user-select: none;">
    <div class="d-flex justify-content-between">
        <div class='input-group input-group-sm'>
            <span class='input-group-text'>Name</span>
            <input class="form-control" type="text" [(ngModel)]="displayName" (change)='updateAttachment()'>
            <div class="d-flex justify-content-end ms-2">
                <a class="btn btn-sm btn-danger" (click)="deleteAttachment()">
                    <i class="fa fa-trash"></i>
                </a>
            </div>
        </div>
    </div>

    <div class='input-group input-group-sm d-flex'>
        <span class='input-group-text'>Path to URL</span>
        <input class="form-control" type="text" [(ngModel)]="url" (change)='updateAttachment()'>

        <a class="input-group-text open-picker" (click)="openConnectionDataPicker()">
            <i [ngClass]="{
                    'fa-regular fa-binary' : true, 
                    'active' : urlIsValid$ | async, 
                    'inactive' : !(urlIsValid$ | async)
                }">
            </i>
        </a>
    </div>
    <div class="form-check form-switch me-auto">
        <input class="form-check-input" type="checkbox" [(ngModel)]="showInDescription" (change)='updateAttachment()'
            style="cursor: pointer;">
        Show Image in Description
    </div>
</div>