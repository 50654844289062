import { environment } from 'src/environments/environment'

import { Injectable } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'
import { RightPanelComponent } from '@billing/right-panel/right-panel.component'
import { FeatureProperty } from '@classes/FeatureProperty'
import { ProjectService } from '@services/project.service'

import { ConnectionService } from './connection.service'

export type BuilderTabs = "Scene" | "Feature" | "Details" | "Files" | "Permissions" | "Connection"

@Injectable({
  providedIn: 'root'
})
export class RightSidebarService {
  public attachments: FeatureProperty[] = []
  public offCanvasReference: RightPanelComponent
  public open: boolean = false
  public panelTitle: string
  public right: string = "-350px"
  public sanitizedDisplay: SafeHtml
  public tab: BuilderTabs | string = "Scene"

  constructor(
    public connectionService: ConnectionService,
    public projectService: ProjectService,
  ) { }

  openPanel(panelTitle?: string) {
    if (panelTitle) this.tab = panelTitle
    this.right = "0px"
    this.open = true
  }

  closePanel(afterClose?: CallableFunction) {
    this.right = "-350px"
    this.open = false
    if (this.tab == 'Connection') this.connectionService.clearConnectionDataPickerCallback()
    if (afterClose) {
      setTimeout(() => {
        afterClose()
      }, 500) // Matches CSS transition time for closing panel
    }
  }

  togglePanel() {
    if (this.open) this.closePanel()
    else this.openPanel()
  }

  setTab(tab: BuilderTabs) {
    this.tab = tab
  }

  prepareHTML(containerClass: string) {
    setTimeout(() => {
      const [container]: Element[] = document.getElementsByClassName(containerClass) as any
      if (container === undefined) return

      // Ensure embedded media is displayed correctly
      container.querySelectorAll('oembed[url]').forEach(element => {
        if (element.childNodes.length == 0) { // @ts-ignore
          iframely.load(element, element.attributes.url.value)
        }

        element.setAttribute('style', 'width:100%')
      })

      container.querySelectorAll('div[data-oembed-url]').forEach(element => {
        // Discard the static media preview from the database (empty the <div data-oembed-url="...">).
        while (element.firstChild) {
          element.removeChild(element.firstChild)
        }

        // @ts-ignore
        iframely.load(element, element.dataset.oembedUrl) // Generate the media preview using Iframely. 
      })

      // Make all links open a new tab
      container.querySelectorAll('a').forEach(link => link.setAttribute('target', '_blank'))

      // Generate new tokens for images hosted by us
      container.querySelectorAll('img').forEach(img => {
        img.style.cursor = 'pointer'

        const url = new URL(img.src)

        if (environment.api.includes(url.origin)) {
          for (let [key, value] of url.searchParams.entries()) {
            if (key == 'token') {
              const [fileID, projectID, hash] = url.pathname?.split('/')?.slice(-3)

              url.href = `${environment.api}/file/token/${fileID}/${projectID}/${hash}`
              img.src = url.href

              break
            }
          }
        }

        // Adds a click listener to the img tag that opens the image in a new tab
        img.addEventListener('click', () => {
          const url = img.src
          const filename = url.substring(url.lastIndexOf('/') + 1)

          // Create a new window/tab
          const newWindow = window.open('', '_blank')

          // Write content to the new window/tab
          newWindow.document.write(`
            <html>
              <head>
                <title>${filename}</title>
              </head>
              <body>
                <img src="${url}" style='max-width:500px'>
              </body>
            </html>`
          )

          // Close the document to allow interaction
          newWindow.document.close()
        })
      })

    })
  }
}