import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { UntypedFormArray } from '@angular/forms'

@Component({
  selector: 'shared-edit-vector',
  templateUrl: './edit-vector.component.html',
  styleUrls: ['./edit-vector.component.css']
})
export class EditVectorComponent implements OnChanges {
  /** FormArray assumed to only have 3 controls */
  @Input() axes: [string, string, string] = ['x', 'y', 'z']
  @Input() disabled: boolean
  @Input() form: UntypedFormArray
  @Output() edit: EventEmitter<any> = new EventEmitter()

  public focusedField: string

  constructor() { }

  ngOnChanges() {
    if (this.form && this.disabled) this.form.disable()
    else this.form.enable()
  }

  focusField(axis: string) {
    this.focusedField = axis
  }

  unfocusField() {
    this.focusedField = undefined
  }

  onEdit() {
    if (this.form.valid) this.edit.emit({ vector: this.form.value })
  }
}
