<div class="offcanvas-body d-flex flex-column gap-3">
    <div>
        <h3>{{title}}</h3>

        <ul class="list-group">
            <li *ngFor="let price of products" class="list-group-item d-flex justify-content-between">
                <div class="d-flex flex-column">
                    <strong>{{price.product.name}}</strong>
                    <span>x{{price.quantity}}</span>
                </div>
                <span>${{(price.unit_amount * price.quantity ?? 0) / 100 * priceMultiplier}}</span>
            </li>
        </ul>
    </div>

    <div>
        <h3>Total Cost</h3>
        <div class="bg-light p-3 rounded">
            <h4>${{totalAmount}}</h4>
        </div>
    </div>
</div>