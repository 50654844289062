<a *ngIf="coordinates?.length >= minCoordinates" class="w-100 btn btn-outline-primary mb-1"
    (click)="pasteCoordinates()">
    <i class="fas fa-paste fa-fw"></i>
    Paste Coordinates
</a>
<div class='card-body mb-2 mt-2' *ngFor="let coordinateGroup of coordinateFormArray.controls; let i = index"
    [formGroup]="coordinateGroup">
    <div class='d-flex pb-2'>
        <div class='fw-bold me-auto'>Point {{i + 1}}</div>
        <div class="d-flex justify-content-end d-grid gap-1">
            <a class="btn p-0" (click)="copyPosition(coordinateGroup)">
                <i class="fa-regular fa-copy fa-fw"></i>
            </a>

            <a class="btn text-success p-0" (click)="pasteCoordinate(coordinateGroup)">
                <i class="fas fa-paste fa-fw"></i>
            </a>

            <a *ngIf="numCoordinates > minCoordinates" class="btn text-danger p-0"
                (click)='removeCoordinatesFromForm(i)'>
                <i class="fas fa-trash fa-fw"></i>
            </a>
        </div>
    </div>
    <div class="ng-container">
        <div class="d-flex">
            <div class="flex-grow-1 pe-2">
                <input type="number" id="map-lon" class="form-control" formControlName="longitude"
                    placeholder="Longitude" min="-180" max="180" [required]="true" (change)="onEdit()">
                <small class="form-text text-danger"
                    *ngIf="coordinateGroup.get('longitude').status == 'INVALID' && coordinateGroup.get('longitude').touched">
                    Longitude must be between -180 and 180
                </small>
            </div>

            <div class="flex-grow-1 pe-2 me-auto">
                <input type="number" id="map-lat" class="form-control" formControlName="latitude" placeholder="Latitude"
                    min="-90" max="90" [required]="true" (change)="onEdit()">
                <small class="form-text text-danger"
                    *ngIf="coordinateGroup.get('latitude').status == 'INVALID' && coordinateGroup.get('latitude').touched">
                    Latitude must be between -90 and 90
                </small>
            </div>
        </div>
    </div>
</div>
<a class='btn btn-success' (click)='addCoordinatesToForm()'><i class='far fa-plus'></i></a>