<form [formGroup]="form" class="d-grid form-group bg-light border rounded gap-2 p-2">
    <div class="d-flex">
        <label class="fw-bold form-label">Add User Permissions</label>
        <button type="button" class="btn btn-close ms-auto" aria-label="Close" (click)="cancel()"></button>
    </div>

    <div class="d-flex align-items-center gap-2">
        <ng-select class="w-100" formControlName="userID">
            <ng-option *ngFor="let user of availableUsers" [value]="user.user_id">
                {{user.name}} - {{user.email}}
            </ng-option>
        </ng-select>

        <select class="form-select" formControlName="permission">
            <option *ngIf="!forModels">Viewer</option>
            <option selected>Editor</option>
            <option>Owner</option>
        </select>

        <div class="btn btn-success align-self-center text-nowrap" (click)="submit()">
            <i class="fas fa-plus fa-fw"></i> Add
        </div>
    </div>
</form>