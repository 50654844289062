<nav id='top-navbar' class="navbar navbar-toggleable-sm navbar-expand-sm navbar-dark sticky-top scheme-bg-dark me-0">
    <div class="container-fluid">
        <!-- Logo -->
        <div class="navbar-brand">
            <shared-logo [link]="'/projects'"></shared-logo>
        </div>

        <!-- Profile Picture -->
        <shared-profile-picture></shared-profile-picture>
    </div>
</nav>

<body>
    <div class="position-absolute top-50 start-50 translate-middle text-center">
        <img style="height: 128px; width: auto" [src]="'assets/logo.png'">

        <p class="text-center fs-1">{{title}}</p>
        <p class="text-center fs-3">{{body}}</p>

        <button class="btn btn-outline-secondary p-1" (click)="navigateToDashboard()">
            Return to Dashboard
        </button>
    </div>
</body>