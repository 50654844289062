import { filter, map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { FeatureTypes } from '@classes/Feature'
import { select$ } from '@classes/Raycaster'
import { TagOptions } from '@utils/TagFeatures'

export type Activity = "drawing" | "marking" | "measuring" | "tagging"
export type MarkerTypes = "icon" | "image" | "label"
export type TagTypes = Extract<FeatureTypes, "icon" | "label">

@Injectable({
  providedIn: 'root'
})
export class SpatialAnnotationService {
  public activity: Activity
  public defaultIcon: TagOptions = {}
  public file: File

  public mark$ = select$.pipe(filter(() => this.activity == 'marking' && this.enabled), map(intersection => ({ intersection, type: this.markerType })))
  public markerType: MarkerTypes = "label"

  public tag$ = select$.pipe(filter(() => this.activity == 'tagging' && this.enabled), map(intersection => ({ intersection, type: this.tagType })))
  public tagType: TagTypes = "label"

  get enabled() { return this.activity != null }
  get isMarking() { return this.activity == 'marking' }
  get isTagging() { return this.activity == 'tagging' }

  constructor() { }

  enable(canvas: HTMLCanvasElement, activity: Activity) {
    this.activity = activity

    canvas.style.cursor = 'crosshair'
  }

  disable(canvas: HTMLCanvasElement) {
    this.activity = null

    canvas.style.cursor = 'auto'
  }
}