import { FeatureCollection } from 'geojson'
import * as Mapbox from 'mapbox-gl'
import { fromEvent, merge, timer } from 'rxjs'
import { map, switchMap, takeUntil, throttleTime } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

@Component({
  selector: 'shared-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.css']
})
export class MiniMapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() bearing: number = 0
  @Input() bounds: Mapbox.LngLatBounds
  @Input() center: Mapbox.LngLat = new Mapbox.LngLat(-94.22473927523083, 37.68426163908313)
  @Input() markerLocations: { location: Mapbox.LngLat, properties?: any }[] = []
  @Input() pitch: number = 0
  @Input() style: string = 'mapbox://styles/mapbox/satellite-streets-v11'
  @Input() zoom: number = 4
  @Input() width: string = '100%'
  @Input() height: string = '300px'
  @Input() isMapViewer: boolean = false
  @Output() mapMove = new EventEmitter<Mapbox.CameraOptions>()
  @Output() mapClick = new EventEmitter<Mapbox.CameraOptions>()
  @Output() mapLongClick = new EventEmitter<{ location: Mapbox.LngLat }>()
  @Output() markerClick = new EventEmitter<{ location: Mapbox.LngLat, properties?: any }>()
  public id = Math.random().toString(36).slice(-10)
  public map: Mapbox.Map

  get click$() { return fromEvent<Mapbox.MapMouseEvent>(this.map, 'click') }
  get drag$() { return fromEvent<{ type: "drag"; target: Mapbox.Map; } & { originalEvent?: MouseEvent | TouchEvent; }>(this.map, 'drag') }
  get load$() { return fromEvent<{ type: "load", target: Mapbox.Map; }>(this.map, 'load') }
  get mousedown$() { return fromEvent<Mapbox.MapMouseEvent>(this.map, 'mousedown') }
  get mousemove$() { return fromEvent<Mapbox.MapMouseEvent>(this.map, 'mousemove') }
  get mouseup$() { return fromEvent<Mapbox.MapMouseEvent>(this.map, 'mouseup') }
  get render$() { return fromEvent<{ type: "render"; target: Mapbox.Map; }>(this.map, 'render') }
  get style$() { return fromEvent<{ type: "style.load"; target: Mapbox.Map; }>(this.map, 'style.load') }
  get touchend$() { return fromEvent<Mapbox.MapTouchEvent>(this.map, 'touchend') }
  get touchmove$() { return fromEvent<Mapbox.MapTouchEvent>(this.map, 'touchmove') }
  get touchstart$() { return fromEvent<Mapbox.MapTouchEvent>(this.map, 'touchstart') }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes.bearing) {
        this.map.setBearing(this.bearing)
      }

      if (changes.bounds && !this.boundsAreEqual(changes.bounds.currentValue, changes.bounds.previousValue)) {
        this.map.fitBounds(this.bounds, { padding: 100 })
      }

      if (changes.center) {
        if (typeof changes.center.currentValue.lng != 'number' || typeof changes.center.currentValue.lat != 'number') {
          this.center = new Mapbox.LngLat(-94.22473927523083, 37.68426163908313)
        }

        this.map.setCenter(this.normalizeCoordinates(this.center))
      }

      if (changes.pitch) {
        this.map.setPitch(this.pitch)
      }

      if (changes.style) {
        this.map.setStyle(this.style)
      }

      if (changes.zoom) {
        this.map.setZoom(this.zoom)
      }

      if (changes.markerLocations) {
        if (this.map.getSource('markers')) {
          (this.map.getSource('markers') as Mapbox.GeoJSONSource).setData(this.getMarkersFeatureCollection())
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (typeof this.center.lng != 'number' || typeof this.center.lat != 'number') {
      this.center = new Mapbox.LngLat(-94.22473927523083, 37.68426163908313)
    }

    this._setUpMap()
  }

  private _setUpMap() {
    this.map = new Mapbox.Map({
      accessToken: environment.mapboxAccessToken,
      antialias: true,
      attributionControl: false,
      bearing: this.bearing,
      center: this.normalizeCoordinates(this.center),
      container: this.id,
      logoPosition: 'bottom-left',
      pitch: this.pitch,
      style: this.style,
      trackResize: true,
      zoom: this.zoom,
    })
      .addControl(new MapboxGeocoder({
        accessToken: environment.mapboxAccessToken,
        mapboxgl: Mapbox,
        marker: false,
        collapsed: true
      }))
      .addControl(new Mapbox.NavigationControl())
      .addControl(new Mapbox.AttributionControl({ compact: true }))
      .addControl(
        new Mapbox.GeolocateControl({
          positionOptions: { enableHighAccuracy: true, maximumAge: 0, timeout: 100 },
          showAccuracyCircle: true,
          showUserHeading: true,
          showUserLocation: true,
          trackUserLocation: true
        })
      )

    this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11')
    this.map.getCanvas().style.width = "100%"

    this.load$.subscribe(() => this.addMarkersLayer())

    this.render$.pipe(throttleTime(100)).subscribe(() => {
      this.map.resize()
      this.map.triggerRepaint()
    })

    this.style$.subscribe(() => {
      this.map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      })

      this.map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0, 5, 0.3, 8, 1],
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0, 0],
          'sky-atmosphere-sun-intensity': 5
        }
      })

      if (this.bounds) {
        this.map.fitBounds(this.bounds, { padding: 100, animate: false })
      }
    })

    this.mousemove$.pipe(throttleTime(100)).subscribe(event => {
      const { lng, lat } = event.lngLat

      if (lng && lat) {
        this.mapMove.emit({ bearing: this.map.getBearing(), center: this.normalizeCoordinates(event.lngLat), pitch: this.map.getPitch(), zoom: this.map.getZoom() })
      }
    })

    this.click$.subscribe(event => {
      const { lng, lat } = event.lngLat

      if (lng && lat) {
        this.mapClick.emit({ bearing: this.map.getBearing(), center: this.normalizeCoordinates(event.lngLat), pitch: this.map.getPitch(), zoom: this.map.getZoom() })
      }
    })

    this.mousedown$.pipe( // Long click event
      switchMap((downEvent) =>
        timer(1000).pipe(
          takeUntil(merge(this.drag$, this.mouseup$)),
          map(() => downEvent)
        )
      )
    ).subscribe((downEvent) => {
      this.mapLongClick.emit({ location: this.normalizeCoordinates(downEvent.lngLat) })
    })

    this.touchstart$.pipe( // Long touch event
      switchMap((startEvent) =>
        timer(1000).pipe(
          takeUntil(merge(this.touchmove$, this.touchend$)),
          map(() => startEvent)
        )
      )
    ).subscribe(event => {
      this.mapLongClick.emit({ location: this.normalizeCoordinates(event.lngLat) })
    })
  }

  addMarkersLayer() {
    if (!this.map.getSource('markers')) {
      this.map.addSource('markers', {
        type: 'geojson',
        data: this.getMarkersFeatureCollection(),
      })

      this.map.addLayer({
        id: 'markers',
        type: 'circle',
        source: 'markers',
        paint: {
          'circle-radius': 8,
          'circle-color': '#007cbf',
          'circle-blur': 0.15,
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff',
          'circle-stroke-opacity': 1.0,
          'circle-translate': [0, 0],
          'circle-translate-anchor': 'viewport',
          'circle-pitch-alignment': 'map',
          'circle-pitch-scale': 'map'
        }
      })

      this.map.on('click', 'markers', (e) => {
        const coordinates = e.features[0].geometry['coordinates'].slice()
        const properties = e.features[0].properties

        this.markerClick.emit({ location: new Mapbox.LngLat(coordinates[0], coordinates[1]), properties })
      })
    }
  }

  boundsAreEqual(bounds1: [[number, number], [number, number]] | null, bounds2: [[number, number], [number, number]] | null): boolean {
    if (bounds1 === bounds2) {
      return true
    } else if (bounds1 === null || bounds2 === null) {
      return false
    } else {
      const [sw1, ne1] = bounds1
      const [sw2, ne2] = bounds2

      return sw1[0] === sw2[0] && sw1[1] === sw2[1] && ne1[0] === ne2[0] && ne1[1] === ne2[1]
    }
  }

  getMarkersFeatureCollection() {
    return {
      type: 'FeatureCollection',
      features: this.markerLocations.map(marker => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [marker.location.lng, marker.location.lat],
        },
        properties: marker.properties,
      })),
    } as FeatureCollection
  }

  normalizeCoordinates(coordinates: Mapbox.LngLat): Mapbox.LngLat {
    if (!coordinates || typeof coordinates.lng != 'number' || typeof coordinates.lat != 'number') {
      // Return a default value if coordinates are null or incomplete
      return { lng: 0, lat: 0 } as Mapbox.LngLat
    }

    // Force longitude to be within the -180 to 180 range
    const correctedLng = ((+coordinates.lng + 180) % 360 + 360) % 360 - 180

    // Force latitude to be within the -90 to 90 range
    let correctedLat = +coordinates.lat

    if (coordinates.lat > 90) {
      correctedLat = 90
    } else if (coordinates.lat < -90) {
      correctedLat = -90
    }

    return { lng: correctedLng, lat: correctedLat } as Mapbox.LngLat
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove()
    }
  }
}