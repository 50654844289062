import { FeatureTypes } from './Feature'
import { FeaturePropertyTypes } from './FeatureProperty'
import { FileReference } from './FileReference'

export type ConnectionTypes = FeatureTypes | "customFields"
export type ConnectionPropertyTypes = FeaturePropertyTypes

export interface IConnection {
    id?: number
    sceneID?: number
    featureID?: number
    descriptionKey?: string
    groupFeatures?: boolean
    name?: string
    nameKey?: string
    overrideDescription?: boolean
    overrideName?: boolean
    showDetails?: boolean
    showInViewerPanel?: boolean
    type?: ConnectionTypes
    url?: string
    properties?: IConnectionProperty[]
}

export interface IConnectionProperty {
    id?: number
    connectionID?: number
    fileReference?: FileReference
    fileReferenceID?: number
    key?: string
    type?: ConnectionPropertyTypes
    value?: string
}

export type URLObject = {
    url: string
    headers: UrlHeader[]
    parameters: UrlParameter[]
    dataPath: string
    idAttribute: string
    externalID: string
}

export type UrlHeader = {
    key: string
    value: string
}

export type UrlParameter = {
    dynamic: boolean
    key: string
    type: string
    value: string
}

export class Connection implements IConnection {
    public id: number
    public sceneID: number
    public featureID: number
    public overrideName: boolean
    public overrideDescription: boolean
    public descriptionKey: string
    public name: string
    public nameKey: string
    public showDetails: boolean
    public showInViewerPanel: boolean
    public groupFeatures: boolean
    public type: ConnectionTypes
    public url: string
    public properties: ConnectionProperty[]
    public createFeatures: boolean

    get filters() { return this.properties?.filter(p => p.key == "filter") }
    get customFields() { return this.properties?.filter(p => p.type == "customField") }
    get attachments() { return this.properties?.filter(p => p.key == "attachment") }

    constructor(sceneID?: number, options?: IConnection) {
        this.id = options?.id ? +options.id : undefined
        this.sceneID = sceneID ? +sceneID : undefined
        this.featureID = options?.featureID ? +options.featureID : null
        this.overrideName = options?.overrideName !== undefined ? options.overrideName : (options?.featureID && options?.nameKey ? true : false)
        this.overrideDescription = options?.overrideDescription !== undefined ? options.overrideDescription : (options?.featureID && options?.descriptionKey ? true : false)
        this.descriptionKey = options?.descriptionKey
        this.name = options?.name
        this.nameKey = options?.nameKey
        this.showDetails = options?.showDetails ? true : false
        this.showInViewerPanel = options?.showInViewerPanel ? true : false
        this.groupFeatures = options?.groupFeatures ?? true
        this.type = options?.type
        this.url = options?.url ? options.url : '{}'
        this.createFeatures = this.type != 'customFields'
        this.properties = options?.properties && options?.properties.length > 0 ? options.properties
            .map(icp => new ConnectionProperty(icp.type, icp.key, icp.value, icp)) : []
    }
}

export class ConnectionProperty implements IConnectionProperty {
    public id: number
    public connectionID: number
    public fileReference: FileReference
    public fileReferenceID: number
    public key: string
    public type: ConnectionPropertyTypes
    public value: string

    constructor(type: ConnectionPropertyTypes, key: string, value: string, options?: IConnectionProperty) {
        this.id = options?.id ? +options.id : undefined
        this.connectionID = options?.connectionID ? +options.connectionID : undefined
        this.fileReference = options?.fileReference
        this.fileReferenceID = options?.fileReference?.id ?? options?.fileReferenceID
        this.type = type
        this.key = key
        this.value = value
    }
}