<div class="d-flex flex-column mb-1">
    <!-- KEY -->
    <div class="mb-2 d-flex justify-content-between">
        <div class="input-group input-group-sm">
            <span class="input-group-text" data-bs-toggle="tooltip"
            title="Specify name to identify data by; becomes searchable term in Viewer">Field Name</span>
            <input [(ngModel)]="customField.key" class='form-control form-control-sm' type="text"
            (change)="updateCustomField()">
            <div class="d-flex justify-content-end ms-2">
                <a class="btn btn-sm btn-danger" (click)="deleteCustomField()">
                    <i class="fa fa-trash"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="input-group input-group-sm">
        <!-- VALUE TYPE -->
        <span class="input-group-text" data-bs-toggle="tooltip" title='Specify type of data'>Type</span>
        <select class='form-control form-control-sm form-select' [(ngModel)]="valueType" (change)="updateCustomField()">
            <option value="text" [selected]="valueType =='text'">Text</option>
            <option value="number" [selected]="valueType =='number'">Number</option>
            <option value="path" [selected]="valueType =='path'">Path</option>
        </select>
        <!-- VALUE -->
        <span class="input-group-text" data-bs-toggle="tooltip" [title]="valueTooltip">Value</span>
        <input class='form-control form-control-sm' [type]='valueType' [(ngModel)]="value"
            [step]='valueType == "number" ? "0.01" : ""' (change)="updateCustomField()">
            <a *ngIf='valueType =="path"' class="input-group-text open-picker"
            (click)="openConnectionDataPicker()">
                <i [ngClass]="{
                    'fa-regular fa-binary': true, 
                    'active' : urlIsValid$ | async, 
                    'inactive' : !(urlIsValid$ | async)}">
                </i>
            </a>
    </div>
</div>