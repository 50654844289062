import { Component } from '@angular/core'
import { CreateGeoJsonOptions, FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-create-features-from-geojson',
  templateUrl: './create-features-from-geojson.component.html',
  styleUrls: ['./create-features-from-geojson.component.css']
})
export class CreateFeaturesFromGeojsonComponent {
  public geojsonFiles: File[] = []
  public nameKey: string = ''
  public touched: boolean = false

  get isValid() { return this.geojsonFiles.length > 0 }
  get selectedSceneID() { return this.sceneService.selectedSceneID }

  constructor(public featureService: FeatureService, public sceneService: SceneService) { }

  createFeaturesFromGeoJson$(options: CreateGeoJsonOptions = {}) {
    options.nameKey = this.nameKey

    return this.featureService.createFeaturesFromGeoJson(this.geojsonFiles, this.selectedSceneID, options)
  }

  removeFile(index: number) {
    this.geojsonFiles.splice(index, 1)
    this.touched = true
  }

  onFileChange(files: FileList) {
    this.geojsonFiles.push(...Array.from(files))
    this.touched = true
  }

  replaceFile(file: File, index: number) {
    this.geojsonFiles[index] = file
    this.touched = true
  }

  public showRequiredFields() {
    this.touched = true
  }
}