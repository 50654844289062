<div class="d-flex">
    <!-- UNDO -->
    <div>
        <a class="btn btn-sm text-light" (click)="undo()" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Undo">
            <ng-container *ngIf="canUndo; else cantUndo">
                <i class="fa-solid fa-share fa-flip-horizontal"></i>
            </ng-container>
            <ng-template #cantUndo>
                <i class="fa-thin fa-share fa-flip-horizontal"></i>
            </ng-template>
        </a>
    </div>
    
    <!-- REDO -->
    <div>
        <a class="btn btn-sm text-light" (click)="redo()" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Redo">
            <ng-container *ngIf="canRedo; else cantRedo">
                <i class="fa-solid fa-share"></i>
            </ng-container>
            <ng-template #cantRedo>
                <i class="fa-thin fa-share"></i>
            </ng-template>
        </a>
    </div>
</div>