import { FileReferenceResult } from '@services/scene.service'

import { FileReference } from './FileReference'

export interface AssetFieldValueResult {
    id?: number
    asset_id?: number
    asset_field_id?: number
    value?: string
    updated_at?: string
    file_references: FileReferenceResult[]
}

export interface AssetFieldValueOptions {
    id?: number
    assetID?: number
    assetFieldID?: number
    value?: string | string[] | number | number[]
    updatedAt?: string
    fileReferences?: FileReference[]
}

export class AssetFieldValue {
    id?: number
    assetID: number
    assetFieldID: number
    value: string | string[] | number | number[]
    updatedAt?: string
    fileReferences?: FileReference[]

    constructor(assetFieldID: number, value: string | string[] | number | number[], options?: Partial<AssetFieldValueOptions>) {
        this.id = options?.id ?? null
        this.assetID = options?.assetID ?? null
        this.assetFieldID = assetFieldID
        this.value = value
        this.updatedAt = options?.updatedAt ?? null
        this.fileReferences = options?.fileReferences ?? null
    }
}
