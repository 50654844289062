<shared-loading-screen [showTips]='currentModule == "builder"'
    *ngIf="currentModule == 'builder' || currentModule == 'viewer'">
</shared-loading-screen>

<!-- Sign Out Modal -->
<div class="modal fade in" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div *ngIf="user$ | async as user" class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title m-0" id="staticBackdropLabel">{{ user.name }}</h1>
                <button class="btn btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row align-items-center profile-header">
                    <div class="col-md-2 mb-3">
                        <img [src]="user.picture" alt="User's profile picture"
                            class="rounded-circle img-fluid profile-picture" />
                    </div>
                    <div class="col-md text-center text-md-left">
                        <p class="lead text-muted">{{ user.email }}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="logout()" class="btn btn-danger">Sign Out</button>
            </div>
        </div>
    </div>
</div>

<div (pointerdown)="closeContextMenu()" style="position: absolute; height: 100dvh; width:100dvw; overflow: hidden;">
    <router-outlet></router-outlet>
</div>

<ng-template contextMenu></ng-template>