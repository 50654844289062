<div [ngClass]="{'d-flex flex-column justify-content-center align-items-center': (customFields$ | async).length == 0}">
    <h1 *ngIf='(customFields$ | async).length == 0' class="text-secondary pt-2" style="text-align: center;">
        <small><small>No custom fields in selected connection</small></small>
    </h1>
    <div style="max-height:400px; overflow-y: auto;">
        <div *ngFor='let customField of customFields; index as i;' class="mt-1 p-1 border bg-light">
            <shared-edit-connection-custom-field [customField]="customField"
                (showConnectionDataPicker)='toggleConnectionDataPicker($event, i)'
                (delete)="deleteCustomField(customField, i)">
            </shared-edit-connection-custom-field>
            <shared-connection-data-picker *ngIf="showConnectionDataPicker && customFieldIndexForPicker == i"
                (close)='closeConnectionDataPicker()' class="card p-1 bg-light"
                style="max-height: 200px; overflow-y: scroll;">
            </shared-connection-data-picker>
        </div>
    </div>
    <a class="btn btn-primary btn-sm mt-1" [ngClass]="{'w-100': (customFields$ | async).length > 0}"
        (click)="addCustomField()">+ Custom Field</a>
</div>