import { Modal } from 'bootstrap'
import Editor from 'ckeditor5-custom-build/ckeditor'
import { combineLatest, Subscription } from 'rxjs'
import { distinctUntilKeyChanged, filter, startWith } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { Component, OnDestroy, ViewChild } from '@angular/core'
import { EditorConfig } from '@ckeditor/ckeditor5-core'
import { FeatureProperty } from '@classes/FeatureProperty'
import { AuthenticationService } from '@services/authentication.service'
import { FeatureService } from '@services/feature.service'
import { ToastColor } from '@services/toast.service'
import { EditFeatureCustomFieldsComponent } from '@shared/edit-feature-custom-fields/edit-feature-custom-fields.component'
import { CreateCustomFieldCommand, CustomFieldCommand, DeleteCustomFieldCommand, UpdateCustomFieldCommand } from '@classes/CustomFieldCommand'
import { FeatureCustomField } from '@classes/Feature'

@Component({
  selector: 'shared-modal-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.css'],
})
export class EditDetailsComponent implements OnDestroy {
  @ViewChild('editFeatureCustomFieldsComponent') editFeatureCustomFieldsComponent: EditFeatureCustomFieldsComponent

  public cancelText = 'Close'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Save'
  public title = 'Edit Details'

  public onCancel: CallableFunction

  private _subscriptions: Subscription[] = []
  public attachments: FeatureProperty[] = []
  public config: EditorConfig
  public currentTab: string = 'descriptionEditor'
  public editor = Editor as any
  public onSubmit: CallableFunction

  private undoStack: CustomFieldCommand[] = []
  private redoStack: CustomFieldCommand[] = []

  public undoing: boolean = false
  public redoing: boolean = false

  get undoStackSize() { return this.undoStack.length }
  get redoStackSize() { return this.redoStack.length }

  get selectedFeature() {
    return this.featureService.selectedFeature
  }

  get selectedFeature$() {
    return this.featureService.selectedFeature$
      .pipe(startWith(this.selectedFeature))
  }

  constructor(
    public authenticationService: AuthenticationService,
    public featureService: FeatureService,
  ) {
    this._subscriptions.push(
      combineLatest([
        this.authenticationService.getToken(),
        this.selectedFeature$.pipe(
          filter(feature => feature != null),
          distinctUntilKeyChanged('id')
        )
      ]).subscribe(([token, feature]) => {
        this.config = {
          simpleUpload: {
            uploadUrl: `${environment.api}/feature/embed/${feature.id}`,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        }
      }),
    )
  }

  _submit(): boolean {
    this.onSubmit()
    return true
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }

  updateDetails(event: any) {
    const feature = this.selectedFeature
    const editorData = event.target.ckeditorInstance.getData()

    /** TODO: Fix issue where some files aren't deleted if pasted & removed without saving */
    const deleteUnusedEmbeddedImages = () => {
      const getFileReferenceIDs = (body: string) =>
        body.match(/(\/api\/v1\/file\/\d*)/g)?.map(url => parseInt(url.match(/\d+/g).pop()))
        ?? []
      const currFileReferenceIDs = getFileReferenceIDs(editorData)
      const prevFileReferenceIDs = getFileReferenceIDs(feature.description)

      prevFileReferenceIDs.filter(fileReferenceID => !currFileReferenceIDs.includes(fileReferenceID))
        .map(fileReferenceID => feature.embeddedImages.find(image => image.fileReferenceID == fileReferenceID))
        .filter(embeddedImage => embeddedImage != null)
        .forEach(embeddedImage => this.featureService.deleteFeatureProperty(embeddedImage).subscribe())
    }

    deleteUnusedEmbeddedImages()

    feature.description = editorData

    this.featureService.updateFeature(feature).subscribe()
  }

  undo() {
    if (this.undoStack.length == 0) return
    if (this.undoing) return
    this.undoing = true

    const command = this.undoStack.pop()
    this.redoStack.push(command)
    command?.undo().subscribe(_ => this.undoing = false)
  }

  redo() {
    if (this.redoStack.length == 0) return
    if (this.redoing) return
    this.redoing = true

    const command = this.redoStack.pop()
    this.undoStack.push(command)
    command?.execute().subscribe(_ => this.redoing = false)
  }

  createCustomField(customField: FeatureCustomField) {
    const command = new CreateCustomFieldCommand(this.featureService, this.editFeatureCustomFieldsComponent, customField)
    this.executeCommand(command)
  }

  updateCustomField(original: FeatureCustomField, modified: FeatureCustomField) {
    const command = new UpdateCustomFieldCommand(this.featureService, this.editFeatureCustomFieldsComponent, original, modified)
    this.executeCommand(command)
  }

  deleteCustomField(customField: FeatureCustomField) {
    const command = new DeleteCustomFieldCommand(this.featureService, this.editFeatureCustomFieldsComponent, customField)
    this.executeCommand(command)
  }

  executeCommand(command: CustomFieldCommand) {
    this.undoStack.push(command)
    this.redoStack = []
    command.execute().subscribe()
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}