<div *ngIf="!loaded || sceneFailed || sceneProcessing" class="backdrop" [ngClass]="{'darken': darken }">
    <div class="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center d-grid gap-3">
        <img class="user-select-none rotate w-25" src="assets/logo.png" alt="Loading">
        <label *ngIf="loadingMessage" class="text-light fs-4">{{loadingMessage}}</label>

        <div class="progress w-100" *ngIf="progress > 1 && progress < 100">
            <div class="progress-bar progress-bar-striped progress-bar-animated d-flex align-items-center"
                role="progressbar" [style.width]="progress + '%'" aria-valuenow="{{progress}}" aria-valuemin="0"
                aria-valuemax="100" style="background-color: #082c3f;">
                <span class="text-white fw-bold">
                    {{progress | number:'1.0-0'}}%
                </span>
            </div>
        </div>
    </div>
</div>