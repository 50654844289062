import { Modal } from 'bootstrap'

import { Component } from '@angular/core'
import { Model } from '@classes/Model'
import { AuthenticationService } from '@services/authentication.service'
import { ModelService, selectedModel$ } from '@services/model.service'
import { ToastColor } from '@services/toast.service'
import { UserService } from '@services/user.service'

@Component({
  selector: 'shared-modal-model-details',
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.css'],
})
export class ModelDetailsComponent {
  public cancelText = 'Close'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Save'
  public title = 'Model Details'

  public onSubmit: CallableFunction
  public onCancel: CallableFunction

  public selectedModel$ = selectedModel$

  get currentUser() { return this._userService.currentUser }
  get hasOrganization() { return this._authenticationService.hasOrganization }

  constructor(
    private _authenticationService: AuthenticationService,
    private _modelService: ModelService,
    private _userService: UserService
  ) { }

  userOwnsModel(model: Model) {
    return model.permissions
      .some(({ permission, userID }) => userID == this.currentUser.id && permission == 'Owner')
  }

  updateModel(model: Model) {
    this._modelService.updateModel(model, { updateLocally: true, toast: true }).subscribe()
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }
    this.modal?.hide()
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }
}