import { Component, Input } from '@angular/core'

@Component({
  selector: 'billing-view-item, [view-item]',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.css']
})
export class ViewItemComponent {
  @Input() item: { usage: number, limit: number }
  @Input() name: string

  get limit() { return this.item?.limit }
  get usage() { return this.item?.usage }
  get usagePercent() { return (this.usage / this.limit) || 0 }

  constructor() { }
}