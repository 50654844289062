import { tap } from 'rxjs/operators'

import { Component } from '@angular/core'
import { DeleteComponent } from '@modal/delete/delete.component'
import { ConnectionService } from '@services/connection.service'
import { CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { LoadingService } from '@services/loading.service'
import { ModalService } from '@services/modal.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { SceneService } from '@services/scene.service'
import { UndoService } from '@services/undo.service'

import { TypeToConfirmComponent } from '@modal/type-to-confirm/type-to-confirm.component'

@Component({
  selector: 'shared-right-sidebar-btns',
  templateUrl: './right-sidebar-btns.component.html',
  styleUrls: ['./right-sidebar-btns.component.css']
})
export class RightSidebarBtnsComponent {
  get canUseActions() {
    if (this.featureService.selectedFeature && this.featureService.selectedFeature.virtualTourBackdrop) return false
    return true
  }

  get canCopy() {
    if (this.featureService.selectedFeature && this.featureService.selectedFeature.type == 'view') return false
    return true
  }

  get canDelete() {
    return true
  }

  constructor(
    private _loadingService: LoadingService,
    public connectionService: ConnectionService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
    public featureService: FeatureService,
    public modalService: ModalService,
    public rightSidebarService: RightSidebarService,
    public sceneService: SceneService,
    public undoService: UndoService,
  ) { }

  duplicate() {
    const sceneID = this.sceneService.selectedSceneID

    if (this.rightSidebarService.tab == 'Scene') {
      this.copyService.duplicateScene(sceneID).subscribe()
    } else if (this.rightSidebarService.tab == 'Feature') {
      const feature = this.featureService.selectedFeature

      this._loadingService.await(
        this.copyService.duplicateFeature(feature.id, sceneID)
      )
    } else if (this.rightSidebarService.tab == 'Connection') {
      const connection = this.connectionService.selectedConnection

      this.copyService.duplicateConnection(connection.id, sceneID).subscribe()
    }
  }

  copy() {
    if (this.rightSidebarService.tab == 'Scene') {
      this.copyService.copySceneToClipboard(this.sceneService.selectedScene)
    } else if (this.rightSidebarService.tab == 'Feature') {
      this.copyService.copyFeatureToClipboard(this.featureService.selectedFeature)
    } else if (this.rightSidebarService.tab == 'Connection') {
      this.copyService.copyConnectionToClipboard(this.connectionService.selectedConnection)
    }
  }

  delete() {
    const tab = this.rightSidebarService.tab.toString() as 'Connection' | 'Feature' | 'Scene'

    if (tab == 'Scene') this.deleteScene()
    else if (tab == 'Feature') this.deleteFeature()
    else if (tab == 'Connection') this.deleteConnection()
  }

  deleteScene() {
    const selectedSceneName = this.sceneService.selectedScene.name
    const onSubmit = () => this.sceneService.deleteScene(this.sceneService.selectedScene).subscribe()

    this.modalService.showAsModal<TypeToConfirmComponent>(TypeToConfirmComponent)
      .then((componentRef) => {
        componentRef.instance.title = `Delete ${selectedSceneName}`
        componentRef.instance.message = `"${selectedSceneName}" and all of its data will be deleted. <strong>THIS ACTION CANNOT BE UNDONE.</strong>`
        componentRef.instance.submitText = 'Delete'
        componentRef.instance.submitColor = 'red'
        componentRef.instance.confirmWord = 'DELETE SCENE'
        componentRef.instance.onSubmit = onSubmit.bind(this)
      })
  }


  deleteFeature() {
    const selectedFeature = this.featureService.selectedFeature
    const onSubmit = () => {
      this.envManager.sceneManager.removeFeatureFromScene(selectedFeature)
      this.featureService.deleteFeature(selectedFeature).pipe(
        // TODO: Don't clear when undoing deleteFeature is allowed
        tap(() => this.undoService.clear.bind(this.undoService)())
      ).subscribe(() => this.rightSidebarService.setTab("Scene"))
    }

    this.openModal(selectedFeature.name, onSubmit)
  }

  deleteConnection() {
    const selectedConnectionName = this.connectionService.selectedConnection.name
    const onSubmit = () => {
      this.connectionService.deleteConnection(this.connectionService.selectedConnection).subscribe(() => {
        this.rightSidebarService.closePanel()
      })
    }

    this.openModal(selectedConnectionName, onSubmit)
  }

  openModal(name: string, onSubmit: CallableFunction) {

    this.modalService.showAsModal<DeleteComponent>(DeleteComponent)
      .then((componentRef) => {
        componentRef.instance.title = `Delete ${name}`
        componentRef.instance.message = `"${name}" and all of its data will be deleted`
        componentRef.instance.submitText = 'Delete'
        componentRef.instance.submitColor = 'red'
        componentRef.instance.onSubmit = onSubmit.bind(this)
      })
  }
}
