import { Component, Input, OnChanges } from '@angular/core';
import { Action } from '@classes/Action';
import { Interaction } from '@classes/Interaction';


@Component({
  selector: 'shared-view-interaction',
  templateUrl: './view-interaction.component.html',
  styleUrls: ['./view-interaction.component.css']
})
export class ViewInteractionComponent implements OnChanges {
  @Input() interaction: Interaction

  public actions: Action[] = []

  constructor() { }

  ngOnChanges(): void { this.actions = this.interaction.actions }

  }