<!-- JSON Picker Dialog -->
<shared-connection-data-picker *ngIf="showConnectionDataPicker" (close)='closeConnectionDataPicker()'
    class="card p-1 bg-light"
    style="margin-left:-350px; margin-top:50px; width:350px; max-height:75%; position: absolute; overflow-y: auto;">
</shared-connection-data-picker>

<div *ngIf="selectedConnection$ | async as connection" class='form-group container-fluid pt-2'
    style="user-select: none;">
    <div class='d-grid gap-3'>
        <!-- Connection Name -->
        <div class='input-group input-group-sm d-flex'>
            <label class='input-group-text'>Name</label>
            <input class='form-control' type="text" [(ngModel)]="connection.name"
                (change)="updateConnection(connection)">
        </div>

        <!-- Connection URL -->
        <div>
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text'>URL</span>
                <input class="form-control" [ngClass]="{
                'is-valid': (urlStatus$ | async) == 'good', 
                'is-invalid': (urlStatus$ | async) == 'bad'
            }" type="text" [(ngModel)]="parsedURL.url" (change)='urlChanged(connection, true)'>

                <!-- Test URL Button -->
                <span class='input-group-text' (click)="validateURL()">
                    <i *ngIf='(urlStatus$ | async) != "loading"' class="fa fa-refresh" [ngClass]="{
                            'refresh-button text-success': (urlStatus$ | async) == 'good' || (urlStatus$ | async) == 'bad', 
                            'refresh-button-active text-secondary rotate': (urlStatus$ | async) == 'loading'}">
                    </i>
                    <i *ngIf='(urlStatus$ | async) == "loading"'
                        class='fa-solid fa-spinner-third refresh-button-active rotate'></i>
                </span>

                <!-- Connection Status -->
                <span *ngIf='(urlStatus$ | async) == "good"' class="valid-feedback">
                    Successful Connection
                </span>
                <span *ngIf='(urlStatus$ | async) == "bad"' class="invalid-feedback">
                    Failed Connection. Please check URL and/or Headers
                </span>
            </div>
            <p *ngIf='(urlStatus$ | async) == "loading"' class="text-secondary">
                Fetching...
            </p>
        </div>

        <!-- Connection Headers & Parameters -->
        <div class="p-2 bg-white border d-grid gap-3">
            <div class="d-grid gap-2">
                <!-- Header title and collapse button  -->
                <div class="d-flex flex-row">
                    <span>Headers</span>

                    <a (click)="isHeadersCollapsed = !isHeadersCollapsed" class="ms-auto btn p-0 border-0"
                        type="button">
                        <i *ngIf="!isHeadersCollapsed" class="fas fa-sm dark-gray fa-chevron-down"></i>
                        <i *ngIf="isHeadersCollapsed" class="fas fa-sm dark-gray fa-chevron-right"></i>
                    </a>
                </div>

                <ng-container *ngIf="!isHeadersCollapsed">
                    <!-- Add Header Button -->
                    <a class="btn btn-success btn-sm" (click)="addHeader()">
                        + Header
                    </a>

                    <!-- Headers -->
                    <div class="d-grid gap-2">
                        <div *ngFor="let header of parsedURL.headers; let i = index"
                            class='input-group input-group-sm d-flex'>
                            <!-- Key -->
                            <span class='input-group-text'>Key</span>
                            <input class='form-control' type="text" [(ngModel)]="header.key"
                                (change)='updateURL(connection, true)'>

                            <!-- Value -->
                            <span class='input-group-text'>Value</span>
                            <input class='form-control' type="text" [(ngModel)]="header.value"
                                (change)='updateURL(connection, true)'>

                            <!-- Remove Header -->
                            <div class="ms-1">
                                <a class="btn btn-sm btn-danger" (click)="deleteHeader(i)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="d-grid gap-2">
                <!-- Parameter title and collapse button  -->
                <div class="d-flex flex-row">
                    <span>Parameters</span>

                    <a (click)="isParamsCollapsed = !isParamsCollapsed" class="ms-auto btn p-0 border-0" type="button">
                        <i *ngIf="!isParamsCollapsed" class="fas fa-sm dark-gray fa-chevron-down"></i>
                        <i *ngIf="isParamsCollapsed" class="fas fa-sm dark-gray fa-chevron-right"></i>
                    </a>
                </div>

                <ng-container *ngIf="!isParamsCollapsed">
                    <!-- Add Parameter Button -->
                    <a class="btn btn-success btn-sm" (click)="addParameter()">
                        + Parameter
                    </a>

                    <!-- Parameters -->
                    <div class="d-grid gap-2">
                        <div *ngFor="let param of parsedURL.parameters; let i = index"
                            class="d-flex flex-column d-grid gap-1">
                            <!-- ROW 1 -->
                            <div class="input-group input-group-sm">
                                <!-- Key -->
                                <span class='input-group-text'>Key</span>
                                <input class='form-control' type="text" [(ngModel)]="param.key"
                                    (change)='updateURL(connection, true)'>

                                <!-- Value -->
                                <span class='input-group-text'>Value</span>
                                <input *ngIf="!param.dynamic" class='form-control' type="text" [(ngModel)]="param.value"
                                    (change)='updateURL(connection, true)'>

                                <!-- Dynamic Value -->
                                <select *ngIf="param.dynamic && param.type == 'time'"
                                    (change)="updateURL(connection, true)" class="form-select"
                                    [(ngModel)]="param.value">
                                    <option value="today" [selected]="param.value == 'today'">Today</option>
                                    <option value="yesterday" [selected]="param.value == 'yesterday'">Yesterday</option>
                                    <option value="last-week" [selected]="param.value == 'last-week'">Last Week</option>
                                    <option value="last-month" [selected]="param.value == 'last-month'">Last Month
                                    </option>
                                    <option value="last-year" [selected]="param.value == 'last-year'">Last Year</option>
                                </select>

                                <!-- Remove Parameter -->
                                <div class="align-self-start ms-auto" type="button">
                                    <a class="btn btn-sm btn-danger ms-1" (click)="deleteParameter(i)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <!-- ROW 2 -->
                            <div class="form-check form-switch">
                                <!-- Make Dynamic Switch -->
                                <input (change)="updateDynamic(param)" class='form-check-input' type="checkbox"
                                    [(ngModel)]="param.dynamic" style="cursor: pointer;">
                                <label class='form-check-label ms-1'>
                                    Make {{param.key}} Dynamic
                                </label>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Connection Type (Disabled) -->
        <div class="input-group input-group-sm d-flex">
            <span class='input-group-text'>Type</span>
            <input class='form-control form-select-sm' *ngIf="createFeatures" value="Create new Features" disabled>
            <input class='form-control form-select-sm' *ngIf="!createFeatures" value="Add data to existing Feature"
                disabled>
        </div>

        <!-- Root Path -->
        <div class='input-group input-group-sm d-flex'>
            <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.root">
                Root Path
            </span>

            <input class='form-control' type="text" [(ngModel)]="parsedURL.dataPath"
                (change)='updateURL(connection, false)'>
            <a class="input-group-text open-picker" (click)="toggleConnectionDataPicker('dataPath', parsedURL)">
                <i class="fa-regular fa-binary" [ngClass]="{
                    'active' : (urlStatus$ | async) == 'good', 
                    'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                </i>
            </a>
        </div>

        <ng-container *ngIf="createFeatures; else modifyingFeature">
            <!-- Path to Name -->
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.name">
                    Path for Name
                </span>
                <input class='form-control' type="text" [(ngModel)]="connection.nameKey"
                    (change)='updateOverrides(connection)'>
                <a class="input-group-text open-picker" (click)="toggleConnectionDataPicker('nameKey', connection)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>

            <!-- Path to Description -->
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.description">
                    Path for Description
                </span>
                <input class='form-control' type="text" [(ngModel)]="connection.descriptionKey"
                    (change)='updateOverrides(connection)'>
                <a class="input-group-text open-picker"
                    (click)="toggleConnectionDataPicker('descriptionKey', connection)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>

            <!-- Feature Type -->
            <div class='input-group input-group-sm d-flex'>
                <label class="input-group-text">Feature Type</label>
                <select class="form-select" [(ngModel)]="connection.type" (change)='updateConnection(connection)'>
                    <!-- <option value="3D" selected>3D model</option> -->
                    <!-- <option value='text'>Text</option> -->
                    <!-- <option value='image'>Image</option> -->
                    <option *ngIf='selectedScene.type == "Map"' value='marker'>Marker</option>
                    <!-- <option *ngIf='selectedScene.type == "Map"' value='line'>Line</option> -->
                </select>
            </div>
        </ng-container>

        <ng-template #modifyingFeature>
            <!-- Identifier -->
            <div class="input-group input-group-sm d-flex">
                <!-- Key -->
                <span class="input-group-text" data-bs-toggle="tooltip" [title]="tooltips.identifier">
                    Identifier
                </span>
                <input type="text" class="form-control" placeholder="id, pid, fid, org_id, etc."
                    [(ngModel)]="parsedURL.idAttribute" (change)='updateURL(connection, false)'>
                <a class="input-group-text open-picker" (click)="toggleConnectionDataPicker('idAttribute', parsedURL)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>

                <!-- Value -->
                <span class="input-group-text">Value</span>
                <input type="text" class="form-control" [(ngModel)]="parsedURL.externalID"
                    (change)='updateURL(connection, false)'>
                <a class="input-group-text open-picker" (click)="toggleConnectionDataPicker('externalID', parsedURL)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                            'active' : (urlStatus$ | async) == 'good', 
                            'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>

            <!-- Referenced Feature -->
            <div class="input-group input-group-sm d-flex">
                <span class='input-group-text'>Feature</span>
                <select class='form-select' [(ngModel)]="connection.featureID" (change)='updateConnection(connection)'>
                    <option *ngFor="let feature of (features$ | async)" [value]="feature.id"
                        [selected]="connection.featureID == +feature.id" [title]="feature.name">
                        {{feature.name}}
                    </option>
                </select>
            </div>

            <!-- Override Name -->
            <div class="form-check form-switch">
                <input class='form-check-input' type="checkbox" [(ngModel)]="connection.overrideName"
                    (change)='updateOverrides(connection)' style="cursor: pointer;">
                <label class='form-check-label'>Override Feature Name</label>
            </div>

            <div *ngIf='overrideName' class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.name">
                    Path for Name
                </span>
                <input class='form-control' type="text" [(ngModel)]="connection.nameKey"
                    (change)='updateOverrides(connection)'>
                <a class="input-group-text open-picker" (click)="toggleConnectionDataPicker('nameKey', connection)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>

            <!-- Override Description -->
            <div class="form-check form-switch">
                <input class='form-check-input' type="checkbox" [(ngModel)]="connection.overrideDescription"
                    (change)='updateOverrides(connection)' style="cursor: pointer;">
                <label class='form-check-label'>Override Feature Description</label>
            </div>

            <div *ngIf='overrideDescription' class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.description">
                    Path for Description
                </span>
                <input class='form-control' type="text" [(ngModel)]="connection.descriptionKey"
                    (change)='updateOverrides(connection)'>
                <a class="input-group-text open-picker"
                    (click)="toggleConnectionDataPicker('descriptionKey', connection)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                            'active' : (urlStatus$ | async) == 'good', 
                            'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>
        </ng-template>

        <!-- Coordinates -->
        <ng-container *ngIf='latLongProperty'>
            <!-- Longitude -->
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.coordinate">
                    Path for Longitude
                </span>
                <input class='form-control' type="text" [(ngModel)]="longitude"
                    (change)='updateCoordinateProperty(latLongProperty)'>
                <a class="input-group-text open-picker"
                    (click)="toggleConnectionDataPicker('longitude', latLongProperty, true)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>
            <!-- Latitude -->
            <div class='input-group input-group-sm d-flex'>
                <span class='input-group-text' data-bs-toggle="tooltip" [title]="tooltips.coordinate">
                    Path for Latitude
                </span>
                <input class='form-control' type="text" [(ngModel)]="latitude"
                    (change)='updateCoordinateProperty(latLongProperty)'>
                <a class="input-group-text open-picker"
                    (click)="toggleConnectionDataPicker('latitude', latLongProperty, true)">
                    <i class="fa-regular fa-binary" [ngClass]="{
                        'active' : (urlStatus$ | async) == 'good', 
                        'inactive' : (urlStatus$ | async) == 'bad' || (urlStatus$ | async) == 'loading'}">
                    </i>
                </a>
            </div>
        </ng-container>

        <!-- Image File -->
        <div *ngIf='needsFilePicker' class='d-grid gap-1'>
            <span *ngIf='markerImage' class="form-label text-truncate" [title]="markerImage.fileReference.filename">
                Selected File Name: {{markerImage.fileReference.filename}}
            </span>
            <div class='input-group input-group-sm'>
                <input type="file" class="form-control" id="connectionImage" accept="image/*"
                    (change)="onFileChange($event.target.files[0])" [required]="needsFilePicker && !markerImage">
            </div>
        </div>

        <!-- Icon Scale -->
        <ng-container *ngIf="iconScaleProperty">
            <li class="list-group-item d-flex align-items-center justify-content-between d-grid gap-2">
                <label>Icon<br>Size</label>
                <input class="form-range" type="range" min="0.01" max="1.25" step="0.01"
                    (change)="updateProperty(iconScaleProperty)" [(ngModel)]="iconScaleProperty.value">
                <span class="badge rounded-pill bg-primary bg-gradient">
                    {{(iconScaleProperty.value / 1.25) | percent }}
                </span>
            </li>
        </ng-container>

        <button class="btn btn-primary btn-sm w-100" (click)="openDetails()"
            [disabled]="(urlStatus$ | async) != 'good'">
            <i class="far fa-edit"></i>
            Edit Connection Details
        </button>

        <div *ngIf='createFeatures' class="d-grid gap-3">
            <!-- Show Details -->
            <div class="form-check form-switch">
                <label class='form-label'>Show Details on Click</label>
                <input class="form-check-input" type="checkbox" id="showDetails" [(ngModel)]="connection.showDetails"
                    (change)='updateConnection(connection)' style="cursor: pointer;">
            </div>

            <!-- Show in Group -->
            <div class="form-check form-switch">
                <label class='form-label'>Show Group in Navigation Panel</label>
                <input class="form-check-input" type="checkbox" [(ngModel)]="connection.groupFeatures"
                    (change)='updateConnection(connection)' style="cursor: pointer;">
            </div>

            <!-- Show in Left Panel -->
            <div class="form-check form-switch">
                <label class='form-label'>Show Features in Navigation Panel</label>
                <input class="form-check-input" type="checkbox" id="showInViewerPanel"
                    [(ngModel)]="connection.showInViewerPanel" (change)='updateConnection(connection)'
                    style="cursor: pointer;">
            </div>
        </div>
    </div>
</div>