<div *ngIf="resources && seats" class="d-flex flex-column gap-2 ms-4">
    <div class="d-flex gap-2 align-items-center">
        <label class="fs-4 fw-bold">Resources</label>
    </div>
    <ul class="list-group list-group-flush">
        <li view-item [item]="documents" [name]="'Documents'" class="list-group-item"></li>
        <li view-item [item]="images" [name]="'360 Images'" class="list-group-item"></li>
        <li view-item [item]="models" [name]="'Models'" class="list-group-item"></li>
    </ul>

    <div class="d-flex gap-2 align-items-center">
        <label class="fs-4 fw-bold">Seats</label>
    </div>
    <ul class="list-group list-group-flush">
        <li view-item [item]="admin" [name]="'Admins'" class="list-group-item"></li>
        <li view-item [item]="editor" [name]="'Editors'" class="list-group-item"></li>
        <li view-item [item]="readOnly" [name]="'Read only'" class="list-group-item"></li>
    </ul>
</div>