import Stripe from 'stripe'

import { Component, Input } from '@angular/core'

@Component({
  selector: 'billing-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent {
  @Input() products: Stripe.Price[] = []
  @Input() title: string = 'Your Products'
  @Input() priceMultiplier: number = 1

  get totalAmount() {
    return this.products.reduce((total, price) =>
      total + (price.unit_amount * (price['quantity'] ?? 0) * this.priceMultiplier / 100), 0
    )
  }
}