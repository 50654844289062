import * as levenshtein from 'damerau-levenshtein'

import { Pipe, PipeTransform } from '@angular/core'
import { FilterableData } from '@utils/Objects'

const FILTER_WEIGHT = 1000
const SUBSTRING_WEIGHT = 100

export type SearchOptions = {
  getChildren?: (parent: FilterableData) => FilterableData[]
  isFilteredOut?: (item: FilterableData) => boolean
}

@Pipe({
  name: 'searchSortByName',
})
export class SearchSortByNamePipe implements PipeTransform {

  /** The items with the lowest weight are sorted to the front of the list. */
  transform(items: FilterableData[], searchValue: string, options?: SearchOptions): FilterableData[] {
    if (searchValue == null || items == null || items.length == 0) {
      return items
    }

    return items.slice().sort((itemA, itemB) =>
      this.getTotalWeight(itemA, searchValue, options) - this.getTotalWeight(itemB, searchValue, options)
    )
  }

  /** @return A value that can be used to sort an item in a list */
  getTotalWeight(item: FilterableData, term: string, options?: SearchOptions) {
    if (item) {
      if (term == '') { // If there is no search term, no need to search the name
        const totalWeight = this.getFilterWeight(item, options)
        const childWeight = this.getChildrenWeight(item, term, options)

        /** Substitute your weight with the child's weight if the child matches more than you */
        return childWeight < totalWeight ? childWeight : totalWeight
      } else { // Else if there is a search term, find weight of everything
        const stepsWeight = this.getSteps(item.name, term)
        const substringWeight = this.getSubstringWeight(item.name.toLowerCase(), term.toLowerCase())
        const filterWeight = this.getFilterWeight(item, options)
        const totalWeight = stepsWeight + substringWeight + filterWeight

        const childWeight = this.getChildrenWeight(item, term, options)

        /** Substitute your weight with the child's weight if the child matches more than you */
        return childWeight < totalWeight ? childWeight : totalWeight
      }
    }
  }

  /** @return Number of character modifications to transform the term into the target */
  getSteps(target: string, term: string) {
    return levenshtein(term, target)?.steps
  }

  getSubstringWeight(target: string, term: string) {
    let weight = 0

    if (target.includes(term)) {
      weight -= SUBSTRING_WEIGHT
    }

    if (target.startsWith(term)) {
      weight -= SUBSTRING_WEIGHT
    }

    return weight
  }

  getFilterWeight(item: FilterableData, options: SearchOptions) {
    if (options?.isFilteredOut && options.isFilteredOut(item)) {
      return 0
    } else {
      return -FILTER_WEIGHT
    }
  }

  getChildrenWeight(item: FilterableData, term: string, options: SearchOptions) {
    const children = options?.getChildren(item)

    if (children?.length > 0) {
      const matchiestChild = this.transform(children, term, options)[0]

      return this.getTotalWeight(matchiestChild, term, options)
    } else {
      return null
    }
  }
}