import { Component, EventEmitter, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModelPermission } from '@classes/ModelPermission'
import { PermissionType, ProjectPermission } from '@classes/ProjectPermission'
import { User } from '@classes/User'
import { AuthenticationService } from '@services/authentication.service'
import { DashboardService } from '@services/dashboard.service'
import { ModelService } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'shared-create-permission',
  templateUrl: './create-permission.component.html',
  styleUrls: ['./create-permission.component.css'],
  providers: []
})
export class CreatePermissionComponent {
  @Output() onCreate: EventEmitter<any> = new EventEmitter()

  public availableUsers = []
  public form = new FormGroup({
    permission: new FormControl<PermissionType>("Editor", [Validators.required]),
    userID: new FormControl<string>("", [Validators.required]),
  })

  get currentModule() { return this._authService.currentModule }
  get forModels() { return this.currentModule == "models" || this.currentModule == 'model-editor' }
  get isDashboard() { return this.currentModule == "projects" }
  get members() { return this._authService.organizationMembers }
  get model() { return this._modelService.selectedModel }
  get permissions() { return this.forModels ? this.model.permissions : this.project.permissions }
  get project() { return this.isDashboard ? this._dashboardService.selectedProjectForDetails : this._projectService.currentProject }

  constructor(
    private _authService: AuthenticationService,
    private _dashboardService: DashboardService,
    private _modelService: ModelService,
    private _projectService: ProjectService,
    private _toastService: ToastService,
  ) {
    this._authService.getOrganizationMembers().subscribe(members => {
      this.availableUsers = members.filter(member =>
        !this.permissions.some(({ userID }) => userID == member.user_id)
      )
    })
  }

  submit(): boolean {
    if (this.form.valid) {
      const { permission, userID } = this.form.value
      const user = this.members.find(member => member.user_id == userID) as Partial<User>

      if (this.forModels) {
        const modelID = this.model.id

        this._modelService.createModelPermissions({ modelID, permission, user, userID } as ModelPermission).subscribe()
      } else {
        const projectID = this.project.id
        const projectPermission = { permission, projectID, user, userID } as ProjectPermission

        this._projectService.createProjectPermissions([projectPermission], this.project).subscribe()
      }

      this.onCreate.emit({ create: true })

      return true
    } else {
      this._toastService.toast({ title: "Error", message: "Please select a user from the dropdown", color: "red" })

      return false
    }
  }

  cancel() {
    this.onCreate.emit({ create: false })
  }
}