<ul class="list-group" style="width: 200px; min-height: 40px; cursor: pointer">
    <li class="list-group-item d-flex d-grid gap-2 align-items-center fw-bold" (click)="copyPosition()">
        {{displayCoordinate(currentPosition)}}
        <i class="fa fa-copy ms-auto"></i>
    </li>

    <ng-container *ngIf="coordinates.length > 0">
        <li class="list-group-item d-flex d-grid gap-2 align-items-start text-muted">
            <div class="d-flex flex-column">
                <div *ngFor="let coordinate of coordinates">
                    {{displayCoordinate(coordinate)}}
                </div>
            </div>
            <a class="ms-auto text-danger" (click)="clear()">
                <i class="fa fa-trash"></i>
            </a>
        </li>
    </ng-container>
</ul>