<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>
            <div class="modal-body p-4">

                <ul [ngClass]="{'nav nav-tabs bg-white' : true}">
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;" (click)="currentTab = 'customFields'"
                            [ngClass]="{'active': currentTab == 'customFields'}">Custom Field Editor</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;" (click)="currentTab = 'filters'"
                            [ngClass]="{'active': currentTab == 'filters'}">Filters</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: black; cursor: pointer;" (click)="currentTab = 'attachments'"
                            [ngClass]="{'active': currentTab == 'attachments'}">Attachments</a>
                    </li>
                </ul>


                <!-- Custom Fields -->
                <shared-connection-custom-fields-editor [hidden]="currentTab != 'customFields'" class="my-2 p-1">
                </shared-connection-custom-fields-editor>

                <!-- Filters -->
                <shared-filter-connections [hidden]="currentTab != 'filters'" class="my-2 p-1">
                </shared-filter-connections>

                <!-- Attachments -->
                <shared-connection-attachment-editor [hidden]="currentTab != 'attachments'" class="my-2 p-1">
                </shared-connection-attachment-editor>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>