import { Component } from '@angular/core'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'billing-view-subscription-items',
  templateUrl: './view-subscription-items.component.html',
  styleUrls: ['./view-subscription-items.component.css']
})
export class ViewSubscriptionItemsComponent {
  get admin() { return this.seats?.admin }
  get editor() { return this.seats?.editor }
  get documents() { return this.resources?.documents }
  get images() { return this.resources?.['360_images'] }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get models() { return this.resources?.models }
  get readOnly() { return this.seats?.read_only }
  get resources() { return this._subscriptionService.resources }
  get seats() { return this._subscriptionService.seats }

  constructor(private _subscriptionService: SubscriptionService) { }


}