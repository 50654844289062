import * as THREE from "three"
import * as postprocessing from "three/examples/jsm/postprocessing/OutlinePass"

/** KARTA CODE START */

export interface OutlinePassParameters {
	selectedObjects?: THREE.Object3D[];
	visibleEdgeColor?: THREE.Color;
	hiddenEdgeColor?: THREE.Color;
	edgeGlow?: number;
	usePatternTexture?: boolean;
	edgeThickness?: number;
	edgeStrength?: number;
	downSampleRatio?: number;
	pulsePeriod?: number;
	resolution?: THREE.Vector2;
	patternTexture?: THREE.Texture;
}

export class OutlinePass extends postprocessing.OutlinePass {
	private _visibilityCache: Map<any, any>;

	// Modified from original
	constructor(resolution: THREE.Vector2, scene: THREE.Scene, camera: THREE.Camera, options?: OutlinePassParameters) {
		super(resolution, scene, camera)
		
		this.setValues(options)
		this._visibilityCache = new Map();
	}

	setValues(values) {
		if (values === undefined) return

		for (const key in values) {
			const newValue = values[key];
			const currentValue = this[key];

			if (newValue === undefined) continue
			if (currentValue === undefined) continue

			this[key] = newValue;
		}
	}

	changeVisibilityOfNonSelectedObjects(bVisible) {

		// Modified from original
		const cache = this._visibilityCache;

		/** KARTA CODE END */

		const selectedMeshes = [];

		function gatherSelectedMeshesCallBack(object) {

			if (object.isMesh) selectedMeshes.push(object);

		}

		for (let i = 0; i < this.selectedObjects.length; i++) {

			const selectedObject = this.selectedObjects[i];
			selectedObject.traverse(gatherSelectedMeshesCallBack);

		}

		function VisibilityChangeCallBack(object) {

			/** KARTA CODE START */

			// Modified from original
			if (object.isMesh || object.isSprite || object.userData.isTransformControls) {

				/** KARTA CODE END */

				// only meshes and sprites are supported by OutlinePass

				let bFound = false;

				for (let i = 0; i < selectedMeshes.length; i++) {

					const selectedObjectId = selectedMeshes[i].id;

					if (selectedObjectId === object.id) {

						bFound = true;
						break;

					}

				}

				if (bFound === false) {

					const visibility = object.visible;

					if (bVisible === false || cache.get(object) === true) {

						object.visible = bVisible;

					}

					cache.set(object, visibility);

				}

			} else if (object.isPoints || object.isLine) {

				// the visibilty of points and lines is always set to false in order to
				// not affect the outline computation

				if (bVisible === true) {

					object.visible = cache.get(object); // restore

				} else {

					cache.set(object, object.visible);
					object.visible = bVisible;

				}

			}

		}

		this.renderScene.traverse(VisibilityChangeCallBack);

	}
}