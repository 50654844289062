import { Component, ViewContainerRef } from '@angular/core'
import { ToastService } from '@services/toast.service'

@Component({
  selector: 'shared-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.css']
})
export class ToastContainerComponent {
  constructor(
    private _toastService: ToastService,
    private _viewContainerRef: ViewContainerRef
  ) {
    this._toastService.toastContainer = this._viewContainerRef
  }
}