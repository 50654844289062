import { Component, Input } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'

@Component({
  selector: 'shared-left-menu-button',
  templateUrl: './left-menu-button.component.html',
  styleUrls: ['./left-menu-button.component.css']
})
export class LeftMenuButtonComponent {
  @Input() buttonText: string
  @Input() iconClasses: string
  @Input() show: "models" | "features" | "interface" | "permissions" | "scenes" | "settings" | "connections"
  @Input() top: boolean = true

  get isSelected() { return this.envManager.leftPanelTab == this.show }
  get inBeta() { return this.show == 'connections' }

  constructor(public envManager: EnvironmentManagerService) { }

  clicked() {
    if (this.isSelected && this.envManager.showLeftPanel) {
      this.envManager.toggleLeftPanel(false)
    } else {
      this.envManager.toggleLeftPanel(true, this.show)
    }
  }
}