<div class="main bg-light">
    <!-- Navigation Bar -->
    <billing-nav-bar></billing-nav-bar>

    <div class="container p-3 d-flex flex-column gap-3">
        <div class="d-flex justify-content-between">
            <ng-container *ngIf="inFreeTier">
                <div class="card p-3 bg-light bg-gradient shadow-sm justify-content-center">
                    <span *ngIf="!trialEnded">Trial ends on {{billingPeriodEnd | date }}</span>
                    <span *ngIf="trialEnded" class="text-danger fw-bold">Your Free Trial Has Ended</span>
                </div>

                <shared-upgrade-tier-banner class="ms-auto"></shared-upgrade-tier-banner>
            </ng-container>

            <ng-container *ngIf="inProTier">
                <div *ngIf="billingPeriodEnd" class="card p-3 bg-light bg-gradient shadow-sm"
                    style="max-width: fit-content;">
                    <span> Next Invoice on {{billingPeriodEnd | date}} </span>
                </div>

                <div *ngIf='hasFinancialAccess' class="ms-auto d-flex justify-content-end gap-2">
                    <div>
                        <button class="btn btn-outline-secondary btn-sm d-flex gap-2 align-items-center p-2"
                            (click)="openEditSubscriptionPanel()">
                            <span>Edit subscription</span>
                            <i class="fa fa-pen-to-square"></i>
                        </button>
                    </div>
                    <billing-edit-payment-info></billing-edit-payment-info>
                </div>
            </ng-container>
        </div>

        <div class="row">
            <billing-view-subscription-items class="col-5"></billing-view-subscription-items>

            <div [hidden]="inFreeTier" class="col-7">
                <billing-edit-add-ons></billing-edit-add-ons>
            </div>
        </div>
    </div>

    <!-- Toasts -->
    <div class="toast-container d-flex flex-column m-3">
        <shared-toast-container></shared-toast-container>
    </div>
</div>

<billing-right-panel></billing-right-panel>