import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {
  transform(array: any[], key: string): any[] {
    array?.forEach(item => item[key] = item[key]?.trim())
    return array
  }
}