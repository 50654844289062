<form [formGroup]="form">
    <div class="d-grid gap-2">
        <!-- Project Title Input -->
        <div>
            <label for="title" class="form-label">Title</label>

            <input type="text" id="title" formControlName="title" class="form-control" [maxlength]="50"
                [class.is-invalid]="title.invalid && title.touched">

            <div class="invalid-feedback" *ngIf="title.invalid">Title is required.</div>
        </div>

        <!-- Project Description Input -->
        <div>
            <label for="description" class="form-label">Description</label>

            <textarea id="description" rows="2" formControlName="description" class="form-control"></textarea>
        </div>

        <!-- Starting Scene -->
        <div>
            <label class="form-label">Starting Scene</label>

            <div class="card p-2 bg-light">
                <shared-modal-create-scene></shared-modal-create-scene>
            </div>
        </div>
    </div>
</form>