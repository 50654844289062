import * as Mapbox from 'mapbox-gl'
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs'
import { skip } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import * as THREE from 'three'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'

import { MapManager } from '@classes/MapManager'
import { DEFAULT_FAR, DEFAULT_FOV, DEFAULT_NEAR, Space } from '@classes/ModelSpace'
import { PostProcessor } from '@classes/PostProcessor'
import { Raycaster } from '@classes/Raycaster'
import { SceneManager } from '@classes/SceneManager'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

export type MapSpaceConfiguration = {
    bearing?: number
    center?: [number, number]
    pitch?: number
    zoom?: number
}

export const styles = [
    { display: 'Basic Dark', style: 'mapbox://styles/mapbox/dark-v10' },
    { display: 'Basic Light', style: 'mapbox://styles/mapbox/light-v10' },
    { display: 'Guidance Day', style: 'mapbox://styles/mapbox/navigation-guidance-day-v4' },
    { display: 'Guidance Night', style: 'mapbox://styles/mapbox/navigation-guidance-night-v4' },
    { display: 'Navigation Day', style: 'mapbox://styles/mapbox/navigation-preview-day-v4' },
    { display: 'Navigation Night', style: 'mapbox://styles/mapbox/navigation-preview-night-v4' },
    { display: 'Outdoors', style: 'mapbox://styles/mapbox/outdoors-v11' },
    { display: 'Satellite with Streets', style: 'mapbox://styles/mapbox/satellite-streets-v11' },
    { display: 'Satellite', style: 'mapbox://styles/mapbox/satellite-v9' },
    { display: 'Standard', style: 'mapbox://styles/mapbox/standard' },
    { display: 'Streets', style: 'mapbox://styles/mapbox/streets-v11' },
]

const mapSubject: BehaviorSubject<Mapbox.Map> = new BehaviorSubject<Mapbox.Map>(undefined)
export const map$: Observable<Mapbox.Map> = mapSubject.pipe(skip(1))

export class MapSpace implements Space {
    private _main: HTMLElement
    private _subscriptions: Subscription[] = []
    public camera: THREE.PerspectiveCamera
    public map: Mapbox.Map
    public mouseLngLat: Mapbox.LngLat
    public postProcessor: PostProcessor
    public raycaster: Raycaster
    public renderer: THREE.WebGLRenderer
    public renderer2D: CSS2DRenderer
    public rendering: boolean = false
    public sceneMercatorCoordinates: Mapbox.MercatorCoordinate

    get canvas(): HTMLCanvasElement { return this.map.getCanvas() }

    constructor(
        private _container: HTMLElement,
        public sceneManager: SceneManager,
        public mapManager: MapManager,
        public config: MapSpaceConfiguration = {},
    ) {
        this._main = _container.parentElement
        this.camera = new THREE.PerspectiveCamera(DEFAULT_FOV, this._main.clientWidth / this._main.clientHeight, DEFAULT_NEAR, DEFAULT_FAR)

        this.map = new Mapbox.Map({
            accessToken: environment.mapboxAccessToken,
            antialias: true,
            attributionControl: false,
            bearing: config.bearing,
            center: config.center,
            container: this._container,
            logoPosition: 'bottom-right',
            pitch: config.pitch,
            trackResize: true,
            zoom: config.zoom,
        })
            .addControl(new Mapbox.AttributionControl({ compact: true }))
            .addControl(new Mapbox.NavigationControl())
            .addControl(new MapboxGeocoder({
                accessToken: environment.mapboxAccessToken,
                mapboxgl: Mapbox,
                marker: false,
                collapsed: true
            }))

        this.sceneMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(config.center, 0)

        mapSubject.next(this.map)
    }

    private _initRaycaster(map: Mapbox.Map = null) {
        const [lng, lat] = this.config.center
        const config = { map, center: { lng, lat } }

        this.raycaster = new Raycaster(this.sceneManager, this.camera, this.canvas, config)
        this.postProcessor = new PostProcessor(this.sceneManager.renderedScene, this.camera, this.canvas)
    }

    onAdd(map: Mapbox.Map, gl: WebGLRenderingContext) {
        this.map = map

        // use the Mapbox GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            canvas: map.getCanvas(),
            context: gl,
            logarithmicDepthBuffer: true
        })

        this.renderer.autoClear = false
        // Initializing the labelRenderer with the normal renderer
        this.renderer2D = new CSS2DRenderer()
        this.renderer2D.setSize(map.getCanvas().clientWidth, map.getCanvas().clientHeight)
        this.renderer2D.domElement.style.position = 'absolute'
        this.renderer2D.domElement.style.top = '0px'
        this.renderer2D.domElement.style.pointerEvents = 'none'
        this.renderer2D.domElement.id = 'css2DElements'
        map.getContainer().appendChild(this.renderer2D.domElement)

        this._initRaycaster(map)

        this._subscriptions.push(
            fromEvent<Mapbox.MapMouseEvent>(this.map, 'mousemove')
                .subscribe((event) => this.mouseLngLat = event.lngLat)
        )
    }

    render(): (gl: WebGLRenderingContext, matrix: number[]) => any {
        return (gl: WebGLRenderingContext, matrix: number[]) => {
            var sceneRotate = [Math.PI / 2, 0, 0]

            // Transformation parameters to position, rotate and scale the THREE scene onto the map
            var sceneTransform = {
                translateX: this.sceneMercatorCoordinates.x,
                translateY: this.sceneMercatorCoordinates.y,
                translateZ: this.sceneMercatorCoordinates.z,
                rotateX: sceneRotate[0],
                rotateY: sceneRotate[1],
                rotateZ: sceneRotate[2],
                scale: this.sceneMercatorCoordinates.meterInMercatorCoordinateUnits()
            }

            var rotationX = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(1, 0, 0),
                sceneTransform.rotateX
            )

            var rotationY = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 1, 0),
                sceneTransform.rotateY
            )

            var rotationZ = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 0, 1),
                sceneTransform.rotateZ
            )

            var m = new THREE.Matrix4().fromArray(matrix)
            var l = new THREE.Matrix4().makeTranslation(
                sceneTransform.translateX,
                sceneTransform.translateY,
                sceneTransform.translateZ
            ).scale(
                new THREE.Vector3(
                    sceneTransform.scale,
                    -sceneTransform.scale,
                    sceneTransform.scale
                )
            ).multiply(rotationX).multiply(rotationY).multiply(rotationZ)

            this.camera.projectionMatrix = m.multiply(l)
            this.renderer.render(this.sceneManager.renderedScene, this.camera)
            this.renderer.resetState() //.state.reset()
            this.renderer2D.setSize(this.map.getCanvas().clientWidth, this.map.getCanvas().clientHeight)
            this.renderer2D.render(this.sceneManager.renderedScene, this.camera)

            if (this.postProcessor) {
                this.postProcessor.render()
            }

            this.map.triggerRepaint()
        }
    }

    toggleControls(allow: boolean) {
        if (allow) {
            this.map.dragPan.enable()
            this.map.dragRotate.enable()
            this.map.scrollZoom.enable()
        } else {
            this.map.dragPan.disable()
            this.map.dragRotate.disable()
            this.map.scrollZoom.disable()
        }
    }

    getGroupScreenPosition(group: THREE.Group): { x: number, y: number } {
        let boundingBox = new THREE.Box3()
        let boxCenter = new THREE.Vector3()

        boundingBox.setFromObject(group)
        boundingBox.getCenter(boxCenter)

        return this.getPointScreenPosition(boxCenter)
    }

    /** 
     * Finds the pixel position based on a THREE.Vector3Given a THREE.Vector3 representing a models position in a scene, 
     * this finds the coordinates and then the pixel position of those coordinates. 
     * If the coordinates are not represented on the screen, it returns Number.MAX_VALUE 
     * @returns `Point` representing 2D screen pixel position OR `Number.MAX_VALUE`
     */
    getPointScreenPosition(position: THREE.Vector3) {
        const latLng = this.getLngLat(position)

        return this.map.project(latLng)
    }

    /** 
     * Finds the Long, Lat position on the map based on a THREE.Vector3 
     * representing a position in the THREE.Scene 
     * @returns the Mapbox LngLat position. 
     */
    getLngLat(position: THREE.Vector3) {
        const mercatorMeter = this.sceneMercatorCoordinates.meterInMercatorCoordinateUnits()

        // This may look wrong...don't touch it.
        let pointMercatorCoordinates = new Mapbox.MercatorCoordinate(
            mercatorMeter * position.x + this.sceneMercatorCoordinates.x,
            mercatorMeter * position.z + this.sceneMercatorCoordinates.y,
            mercatorMeter * position.y + this.sceneMercatorCoordinates.z
        )

        return pointMercatorCoordinates.toLngLat()
    }

    dispose() {
        this.map.remove()
        this._subscriptions.forEach(sub => sub.unsubscribe())

        this.renderer.forceContextLoss()
        this.renderer.context = null
        this.renderer.domElement.remove()
        this.renderer.domElement = null
        this.renderer.renderLists.dispose()
        this.renderer.dispose()

        this.raycaster.dispose()
        this.postProcessor.dispose()

        this.renderer = null
        this.rendering = false
    }
}