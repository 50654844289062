import { EMPTY, forkJoin, Observable, of } from 'rxjs'
import { concatMap, switchMap, tap } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Feature } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { FileReference } from '@classes/FileReference'
import { Model } from '@classes/Model'
import { Project } from '@classes/Project'
import { SceneProperty } from '@classes/SceneProperty'

import { CreateFeatureOptions, CreateGroupOptions, FeatureService } from './feature.service'
import { FileReferenceService } from './file-reference.service'
import { ModelService } from './model.service'
import { ProjectService } from './project.service'
import { SceneService } from './scene.service'
import { UndoService } from './undo.service'

export interface Command {
  executed: boolean
  type: string
  execute(): Observable<any>
  undo(): Observable<any>
  id?: number
}

type CommandTypes = 'Feature' | 'FeatureAndProperty' | 'FeatureProperties' | 'FeatureProperty' | 'FileReference' | 'Model' | 'Project' | 'SceneProperty'

export class UpdateCommand<Type> implements Command {
  public executed = false

  constructor(
    public type: CommandTypes,
    private update: (id?: number) => Observable<Type>,
    private revert: (id?: number) => Observable<Type>,
    public id?: number,
  ) { }

  execute(): Observable<Type> {
    if (this.executed) return EMPTY
    return this.update(this.id).pipe(tap(() => this.executed = true))
  }

  undo(): Observable<Type> {
    if (!this.executed) return EMPTY
    return this.revert(this.id).pipe(tap(() => this.executed = false))
  }
}

export class CreateCommand<Type> implements Command {
  private _id: number
  public executed = false
  public previousID: number

  get id() { return this._id }
  set id(id: number) {
    this.previousID = this.id
    this._id = id
  }

  constructor(
    public type: CommandTypes,
    private create: () => Observable<Type>,
    private destroy: () => Observable<Type>,
  ) { }

  execute(): Observable<Type> {
    if (this.executed) return EMPTY

    return this.create().pipe(
      tap(creation => {
        this.id = creation['id']

        this.executed = true
      })
    )
  }

  undo(): Observable<Type> {
    if (!this.executed) return EMPTY

    return this.destroy().pipe(
      tap(() => this.executed = false)
    )
  }
}

class DeleteCommand<Type> implements Command {
  public executed = false
  public type = 'Delete'
  public id: number

  /**
   * @returns undefined. TODO: figure out how to do undo of delete.
   */
  constructor(
    private newThing: Type,
    private create: (newThing: Type) => Observable<Type>,
    private destroy: (newThing: Type) => Observable<Type>) {
    if (!newThing || !create || !destroy) return undefined
  }

  execute(): Observable<Type> {
    if (!this.executed) return this.destroy(this.newThing).pipe(tap(() => this.executed = true))
  }

  undo(): Observable<Type> {
    if (this.executed) return this.create(this.newThing).pipe(tap(result => {
      this.newThing = result
      this.executed = false
    }))
  }
}

@Injectable({
  providedIn: 'root'
})
export class CommandService {
  public readonly update = {
    project: (project: Project) => {
      const previous = this.projectService.currentProject
      const update = () => this.projectService.updateProject(project)
      const revert = () => this.projectService.updateProject(previous)
      const command = new UpdateCommand<Project>('Project', update, revert)

      return this._execute<Feature>(command)
    },
    sceneProperty: (property: SceneProperty) => {
      const scene = this.sceneService.scenes.find(scene => scene.id == property.sceneID)
      const previous = scene.properties.find(p => p.key == property.key)
      const update = (id: number) => {
        property.id = id
        return this.sceneService.updateSceneProperty(property)
      }
      const revert = (id: number) => {
        previous.id = id
        return this.sceneService.updateSceneProperty(previous)
      }
      const command = new UpdateCommand<SceneProperty>('SceneProperty', update, revert, property.id)

      return this._execute<SceneProperty>(command)
    },
    feature: (feature: Feature) => {
      const previous = this.featureService.getFeature(feature.id)
      const update = (id: number) => {
        feature.id = id
        return this.featureService.updateFeature(feature)
      }
      const revert = (id: number) => {
        previous.id = id
        return this.featureService.updateFeature(previous)
      }
      const command = new UpdateCommand<Feature>('Feature', update, revert, feature.id)

      return this._execute<Feature>(command)
    },
    featureProperty: (property: FeatureProperty) => {
      const feature = this.featureService.getFeature(property.featureID)
      const previous = feature.properties.find(p => p.key == property.key)

      const update = () => this.featureService.updateFeatureProperty(property)
      const revert = () => this.featureService.updateFeatureProperty(previous)

      const command = new UpdateCommand<FeatureProperty>('FeatureProperty', update, revert, feature.id)

      return this._execute<Feature>(command)
    },
    featureProperties: (properties: FeatureProperty[]) => {
      const featureID = properties[0].featureID
      const updateCommands: Observable<FeatureProperty>[] = []
      const revertCommands: Observable<FeatureProperty>[] = []

      properties.forEach(property => {
        const feature = this.featureService.getFeature(featureID)
        const previous = feature.properties.find(p => p.key == property.key)

        updateCommands.push(this.featureService.updateFeatureProperty(property))
        revertCommands.unshift(this.featureService.updateFeatureProperty(previous))
      })

      const update = () => forkJoin(updateCommands)
      const revert = () => forkJoin(revertCommands)

      const command = new UpdateCommand<FeatureProperty[]>('FeatureProperties', update, revert, featureID)

      return this._execute<FeatureProperty[]>(command)
    },
    featureAndProperty: (feature: Feature, property: FeatureProperty) => {
      const previousFeature = this.featureService.getFeature(feature.id)
      const previousProperty = previousFeature.properties.find(p => p.key == property.key)

      const updateFeature = () => this.featureService.updateFeature(feature)
      const updateProperty = () => this.featureService.updateFeatureProperty(property)

      const revertFeature = () => this.featureService.updateFeature(previousFeature)
      const revertProperty = () => this.featureService.updateFeatureProperty(previousProperty)

      const update = () => forkJoin([updateFeature(), updateProperty()])
      const revert = () => forkJoin([revertFeature(), revertProperty()])

      const command = new UpdateCommand<any>('FeatureAndProperty', update, revert, feature.id)

      return this._execute<Feature>(command)
    },
    model: (model: Model) => {
      const previous = this.modelService.previousModel ?? this.modelService.models.find(a => a.id == model.id)
      const update = (id: number) => {
        model.id = id
        return this.modelService.updateModel(model, { updateLocally: true, toast: true })
      }
      const revert = (id: number) => {
        previous.id = id
        return this.modelService.updateModel(previous, { updateLocally: true })
      }
      const command = new UpdateCommand<Model>('Model', update, revert, model.id)

      return this._execute<Model>(command)
    },
    fileReference: (reference: FileReference) => {
      const model = this.modelService.editableModels.find(model => model.id == reference.modelID)
      const previous = model.files.find(fr => fr.id == reference.id)
      const update = (id: number) => {
        reference.id = id
        return this.fileReferenceService.updateFileReference(reference)
      }
      const revert = (id: number) => {
        previous.id = id
        return this.fileReferenceService.updateFileReference(previous)
      }
      const command = new UpdateCommand<FileReference>('FileReference', update, revert, reference.id)

      return this._execute<Model>(command)
    }
  }

  public readonly create = {
    feature: (feature: Feature, options?: Partial<CreateFeatureOptions>) => {
      const create = () => this.featureService.createFeature(feature, options).pipe(tap(f => feature = f))
      const destroy = () => this.featureService.deleteFeature(feature)
      const command = new CreateCommand<Feature>('Feature', create, destroy)

      return this._execute<Feature>(command)
    },
    group: (options: CreateGroupOptions = {}) => {
      let group
      const create = () => this.featureService.createFeatureGroup(options)
        .pipe(tap(f => group = f))
      const destroy = () =>
        forkJoin(options.children?.slice().map(f => this.featureService.updateFeature(f)))
          .pipe(switchMap(() => this.featureService.deleteFeature(group)))

      const command = new CreateCommand<Feature>('Feature', create, destroy)

      return this._execute<Feature>(command)
    },
    model: (model: Model, ...files: File[]) => {
      const create = () => this.modelService.createModel(model, ...files).pipe(tap(f => model = f))
      const destroy = () => this.modelService.deleteModel(model)
      const command = new CreateCommand<Model>('Model', create, destroy)

      return this._execute<Model>(command)
    },
  }

  public readonly delete = {
    // action: () => { },
    // model: () => { },
    // feature: () => { },
    // featureProperty: () => { },
    // interaction: () => { },
    // permission: () => { },
    // project: () => { },
    // scene: () => { },
    // sceneProperty: () => { },
    // user: () => { },
  }

  constructor(
    private modelService: ModelService,
    private featureService: FeatureService,
    private fileReferenceService: FileReferenceService,
    private projectService: ProjectService,
    private sceneService: SceneService,
    private undoService: UndoService,
  ) { }

  /**
   * Calls execute on a command and stacks for undoRedo
   * @param command
   * @returns The Observable result of the command's execute function.
   */
  private _execute<Type>(command: Command): Observable<Type> {
    return command.execute().pipe(
      concatMap((value, index) => index === 0
        ? of(value).pipe(
          tap(() => this.undoService.stack(command))
        )
        : of(value)
      ))
  }
}