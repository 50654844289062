import { Modal } from 'bootstrap'

import { Component } from '@angular/core'
import { ToastColor } from '@services/toast.service'

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.css']
})
export class ConfirmActionComponent {
  public cancelText = 'Cancel'
  public message = 'Modal Message'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Submit'
  public title = 'Modal Title'

  public onCancel: CallableFunction
  public onSubmit: CallableFunction

  constructor() { }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }

    this.modal?.hide()
  }
}