<div id='kartaAttrib' class='mb-1' style="z-index:var(--overlay-buttons-index)">
    <svg height='18px' id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1326.9 236.49">
        <defs>
            <style>.cls-1,.cls-5,.cls-6{opacity:0.9;}.cls-2,.cls-5,.cls-6{fill:#fff;}.cls-3,.cls-4{fill:none;stroke-width:6px;}.cls-3,.cls-4,.cls-5,.cls-6{stroke:#000;stroke-miterlimit:10;}.cls-4{stroke-linecap:round;}.cls-5{font-size:150.48px;stroke-width:8px;font-family:Arial-BoldMT, Arial;font-weight:700;}.cls-6{stroke-width:3px;}</style>
        </defs>
        <a target="_blank" xlink:href='https://kartorium.com'>
        <g class="cls-1">
                <path class="cls-2" d="M276.79,126.39v95.23a20,20,0,0,1-10,17.33L234.6,257.53l-26.94-46.65a49.72,49.72,0,1,0-17.49,10.25L217,267.67l-32.74,18.9a20,20,0,0,1-20,0L81.82,239a20,20,0,0,1-10-17.33V126.39a20,20,0,0,1,10-17.33l82.47-47.62a20,20,0,0,1,20,0l82.48,47.62A20,20,0,0,1,276.79,126.39Z" transform="translate(-68.81 -55.75)"/>
                <path class="cls-3" d="M266.78,239a20,20,0,0,0,10-17.33V126.39a20,20,0,0,0-10-17.33L184.3,61.44a20,20,0,0,0-20,0L81.82,109.06a20,20,0,0,0-10,17.33v95.23a20,20,0,0,0,10,17.33l82.47,47.62a20,20,0,0,0,20,0" transform="translate(-68.81 -55.75)"/>
                <line class="cls-4" x1="148.23" y1="211.92" x2="121.36" y2="165.38"/>
                <line class="cls-4" x1="138.85" y1="155.13" x2="165.79" y2="201.78"/>
                <line class="cls-4" x1="148.23" y1="211.92" x2="115.49" y2="230.82"/>
                <line class="cls-4" x1="197.97" y1="183.2" x2="165.79" y2="201.78"/>
                <path class="cls-4" d="M207.66,210.88a49.72,49.72,0,1,0-17.49,10.25" transform="translate(-68.81 -55.75)"/>
            </g>
            <text class="cls-5" transform="translate(240.42 168.98) scale(0.97 1)">Kartorium</text>
            <text x="-68.81" y="-55.75"/>
            <circle class="cls-6" cx="105.49" cy="118.25" r="35.2"/>
        </a>
    </svg>
</div>