import { EMPTY, forkJoin } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { Stripe } from 'stripe'

import { Component, QueryList, ViewChildren } from '@angular/core'
import { EditSubscriptionItemComponent } from '@billing/edit-subscription-item/edit-subscription-item.component'
import { RightSidebarService } from '@services/right-sidebar.service'
import { SubscriptionService } from '@services/subscription.service'

const errorMessages = {
  401: 'Unauthorized to make changes.',
  402: 'There was an issue with your payment method. Please update your information or contact support.',
  408: 'Payment confirmation window expired. Please try another update.',
  500: 'Something bad happened on our end. Please contact support for further guidance.'
}

const ITEM_ORDER = [
  'Admin Seat',
  'Editor Seat',
  'Read-Only Seat',
  'Model',
  '360° Image',
  'Document'
]

@Component({
  selector: 'billing-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.css']
})
export class EditSubscriptionComponent {
  @ViewChildren('editItems') editItems: QueryList<EditSubscriptionItemComponent> = new QueryList<any>()
  private _errorCode: number = undefined
  public invoiceAmount: number = undefined
  public invoice: Stripe.Invoice = undefined
  public items: Stripe.SubscriptionItem[] = []
  public state: 'editing' | 'processing' | 'previewing' | 'confirming' | 'summary' | 'error' = 'editing'

  get changes() {
    return this.editItems?.filter(item => item.changed)
      ?.map(editItem => ({ name: editItem.name, quantity: editItem.quantity - editItem.item.quantity }))
  }
  get changesPresent() { return this.editItems?.some(editItem => editItem.changed) }
  get costDifference() { return this.updatedSubscriptionCost - this.currentSubscriptionCost }
  get currentSubscriptionCost() { return this.editItems?.map(editItem => editItem.getCost(editItem.item.quantity) / 100)?.reduce((a, b) => a + b, 0) }
  get failedMessage() { return errorMessages[this._errorCode] }
  get interval() { return this._subscriptionService.interval }
  get updatedSubscriptionCost() { return this.editItems?.map(editItem => editItem.getCost(editItem.quantity) / 100)?.reduce((a, b) => a + b, 0) }

  constructor(private _subscriptionService: SubscriptionService, private _rightSidebarService: RightSidebarService) {
    if (!this._subscriptionService.inFreeTier) {
      this.getSubscriptionItems()
        .subscribe(items => this.items = items)
    }
  }

  getSubscriptionItems() {
    return this._subscriptionService.getSubscriptionItems().pipe(
      map(items => items
        .filter(item => {
          const metadata = item?.price?.product?.['metadata']

          return metadata?.type != 'addon' && metadata?.type != 'tier'
        })
        .sort((a, b) => ITEM_ORDER.indexOf((a.price.product as Stripe.Product).name) - ITEM_ORDER.indexOf((b.price.product as Stripe.Product).name))
      )
    )
  }

  processChanges() {
    this.state = 'processing'

    const updatedItems = this.editItems.filter(editItem => editItem.changed).map(editItem => ({ ...editItem.item, quantity: editItem.quantity }))

    this._subscriptionService.previewInvoice(updatedItems).pipe(
      catchError(error => this._handleError(error))
    ).subscribe(({ amount_due }) => {
      this.state = 'previewing'
      this.invoiceAmount = amount_due
    })
  }

  confirmChanges() {
    this.state = 'confirming'

    const updatedItems = this.editItems.filter(editItem => editItem.changed).map(editItem => ({ ...editItem.item, quantity: editItem.quantity }))

    this._subscriptionService.updateSubscriptionItems(updatedItems).pipe(
      catchError(error => this._handleError(error)),
      tap(response => {
        this.state = 'summary'
        this.invoiceAmount = undefined
        this.invoice = response.invoice
      }),
      switchMap(() => forkJoin([
        this.getSubscriptionItems().pipe(tap(items => this.items = items)),
        this._subscriptionService.getSubscriptionUsage()]
      )),
    ).subscribe()
  }

  cancelChanges() {
    this.state = 'editing'
    this.invoiceAmount = undefined
  }

  clear() {
    this.editItems.forEach(editItem => editItem.clear())
  }

  close() {
    this._rightSidebarService.offCanvasReference.offcanvas.hide()
    this.state = 'editing'
    this.invoice = undefined
  }

  private _handleError(error: any) {
    const statusCode = error.status
    this.state = 'error'
    this._errorCode = statusCode

    return EMPTY
  }
}