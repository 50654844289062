import { Modal } from 'bootstrap'
import * as Mapbox from 'mapbox-gl'

import { Component } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { AssetField } from '@classes/AssetField'
import { AssetType } from '@classes/AssetType'
import { AssetService } from '@services/asset.service'
import { ToastColor, ToastService } from '@services/toast.service'

function minLengthArray(min: number): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (c instanceof FormArray) {
      return c.controls.length >= min ? null : { 'minLengthArray': { valid: false } }
    } else {
      return null
    }
  }
}

@Component({
  selector: 'assets-create-asset-type',
  templateUrl: './create-asset-type.component.html',
  styleUrls: ['./create-asset-type.component.css']
})
export class CreateAssetTypeComponent {
  public cancelText = 'Cancel'
  public form: FormGroup
  public isSubmitting = false
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Create'
  public title = 'Create Asset Type'

  get description() { return this.form.get('description') }
  get formFields() { return this.form.get('fields') as FormArray }
  get name() { return this.form.get('name') }

  constructor(private _assetService: AssetService, private _toastService: ToastService) {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      description: new FormControl(''),
      fields: new FormArray([], minLengthArray(1))
    })

    this.addField()
  }

  addField() {
    const newField = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      description: new FormControl(''),
      type: new FormControl('text'),
      required: new FormControl(false),
      defaultValue: new FormControl<string | string[]>(''),
      dropdownOptions: new FormArray([new FormControl(''), new FormControl('')], minLengthArray(2)),
      latitude: new FormControl('', [Validators.min(-90), Validators.max(90)]),
      longitude: new FormControl('', [Validators.min(-180), Validators.max(180)]),
    })

    newField.get('type').valueChanges.subscribe(type => {
      if (type === 'chooseMultiple') newField.get('defaultValue').setValue([])
      else if (type === 'chooseOne') newField.get('defaultValue').setValue('')
    })

    this.formFields.push(newField)
  }

  removeField(index: number) {
    this.formFields.removeAt(index);
  }

  addDropdownOption(fieldIndex: number) {
    const dropdownOptions = this._getFieldDropdownOptionsFormArray(fieldIndex)
    dropdownOptions.push(new FormControl(''))
  }

  removeDropdownOption(fieldIndex: number, dropdownIndex: number) {
    const dropdownOptions = this._getFieldDropdownOptionsFormArray(fieldIndex)
    dropdownOptions.removeAt(dropdownIndex)
  }

  _getFieldDropdownOptionsFormArray(fieldIndex: number) {
    const field = this.formFields.controls[fieldIndex] as FormGroup
    return field.get('dropdownOptions') as FormArray
  }

  _getDropdownOption(fieldIndex: number, dropdownIndex: number) {
    const dropdownOptions = this._getFieldDropdownOptionsFormArray(fieldIndex)
    return dropdownOptions.controls[dropdownIndex] as FormControl
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()

      this._toastService.toast({ title: "Error", message: "Invalid submission. Please double-check your asset details.", color: "red" })
    } else if (!this.isSubmitting) {
      this.isSubmitting = true

      const name = this.form.get('name').value
      const description = this.form.get('description').value
      const assetType = new AssetType(name, { description: description })

      const fields = this.formFields.controls.map((control, i) => {
        const name = control.get('name').value
        const description = control.get('description').value
        const type = control.get('type').value
        const required = control.get('required').value
        const defaultValue = this._getDefaultValue(control as FormGroup)
        const dropdownOptions = this._getFieldDropdownOptionsFormArray(i).controls.map(option => option.value)

        return new AssetField(name, description, type, { defaultValue, dropdownOptions, required })
      })

      assetType.fields.push(...fields)

      this._assetService.createAssetType(assetType).subscribe(() => {
        this.close()
        this._assetService.triggerChangesListener()
      }, (error) => this.isSubmitting = false)
    }
  }

  close() {
    this.modal?.hide()
  }

  private _getDefaultValue(control: FormGroup): string | string[] | number {
    if (control.get('type').value == 'location') {
      return [control.get('longitude').value, control.get('latitude').value]
    } else {
      return control.get('defaultValue').value
    }
  }

  toggleDefaultValue(event: Event, i: number) {
    const field = this.formFields.controls[i]
    const isChecked = (event.target as HTMLInputElement).checked

    if (isChecked) field.get('defaultValue').enable()
    else field.get('defaultValue').disable()
  }

  handleMapClick(camera: Mapbox.CameraOptions, field: FormGroup) {
    const { lng, lat } = camera.center as Mapbox.LngLat

    field.get('longitude').setValue(lng)
    field.get('latitude').setValue(lat)
  }
}