import { Modal } from 'bootstrap'

import { Component, OnInit } from '@angular/core'
import { ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-modals-edit-connection-details',
  templateUrl: './edit-connection-details.component.html',
  styleUrls: ['./edit-connection-details.component.css']
})
export class EditConnectionDetailsComponent implements OnInit {
  public cancelText = 'Close'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Save'
  public title = 'Edit Details'

  public onSubmit: CallableFunction
  public onCancel: CallableFunction

  public currentTab: "filters" | "customFields" | "attachments" = 'customFields'
  constructor() { }

  ngOnInit(): void {
  }

  _submit(): boolean {
    this.onSubmit()
    return true
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }

}
