import { Modal } from 'bootstrap'

import { Component, ElementRef, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Model } from '@classes/Model'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { ModelService } from '@services/model.service'
import { ToastColor, ToastService } from '@services/toast.service'

@Component({
  selector: 'shared-modal-create-model',
  templateUrl: './create-model.component.html',
  styleUrls: ['./create-model.component.css'],
})
export class CreateModelComponent {
  public cancelText = 'Cancel'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Create'
  public title = 'Create Model'

  public onSubmit: CallableFunction
  public onCancel: CallableFunction

  public form: UntypedFormGroup
  @ViewChild('fileInput') fileInput: ElementRef
  public selectedFiles: File[] = []

  constructor(
    private elementRef: ElementRef,
    private _formBuilder: UntypedFormBuilder,
    public modelService: ModelService,
    public envManager: EnvironmentManagerService,
    private _toastService: ToastService
  ) {

    this.form = this._formBuilder.group({
      name: ["New Model", [Validators.required]],
      description: [""],
      files: [""]
    })
  }

  submit(): boolean {
    if (this.form.invalid || this.selectedFiles.length == 0) {
      this._toastService.toast({ title: "Error", message: "Invalid input in the model creation", color: "red" })
      return false
    }

    let model = new Model(
      this.form.value.name,
      [],
      { description: this.form.value.description }
    )

    this.modelService.createModel(model, ...this.selectedFiles).subscribe()

    return true
  }

  onFileChange(files: FileList) {
    this.selectedFiles.push(...Array.from(files))
  }

  removeFile(idx: number) {
    this.selectedFiles.splice(idx, 1)
    this.fileInput.nativeElement.value = ''
  }

  _submit() {
    if (this.onSubmit) {
      this.onSubmit()
    }
    const submitSuccessful = this.submit()
    if (submitSuccessful) this.modal?.hide()
    this.ngOnDestroy()
  }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
    this.ngOnDestroy()
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove()
  }
}