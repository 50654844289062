import * as Mapbox from 'mapbox-gl'

import { Injectable } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { mapEaseTo } from '@classes/Transitions'
import { ShareLinkComponent } from '@modal/share-link/share-link.component'

import { EnvironmentManagerService } from './environment-manager.service'
import { FeatureService } from './feature.service'
import { FilterService } from './filter.service'
import { ModalService } from './modal.service'
import { SceneService } from './scene.service'

export type ScenePosition = {
  camera: number[]
  target?: number[]
}

export type MapScenePosition = {
  longitude: number
  latitude: number
  bearing: number
  pitch: number
  zoom: number
}

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  public queryParams: ParamMap

  constructor(
    private _envManager: EnvironmentManagerService,
    private _featureService: FeatureService,
    private _filterService: FilterService,
    private _modalService: ModalService,
    private _sceneService: SceneService,
    private _route: ActivatedRoute,
  ) {
    this._route.queryParamMap.subscribe(map => this.queryParams = map)
  }

  public handleURLParams() {
    if (!this.queryParams) return

    let easeOptions = {}

    this.setCameraPosition()
    this.setTargetPosition()

    this.setEaseOption('zoom', 'number', easeOptions)
    this.setEaseOption('center', 'Mapbox.LngLatLike', easeOptions)
    this.setEaseOption('pitch', 'number', easeOptions)
    this.setEaseOption('bearing', 'number', easeOptions)

    if (this._sceneService.selectedScene.type == 'Map') mapEaseTo(this._envManager.mapSpace.map, easeOptions)

    this.setFocusOnFeatures()
  }

  private setCameraPosition() {
    this.handleParam('cameraPosition', (value) => {
      this._envManager.camera.position.fromArray(value)
    })
  }

  private setTargetPosition() {
    this.handleParam('targetPosition', (value) => {
      this._envManager.controls.target.fromArray(value)
    })
  }

  private setEaseOption(option: string, type: string, easeOptions: any) {
    if (this.queryParams.has(option)) {
      try {
        let value: any = JSON.parse(this.queryParams.get(option))

        if (type === 'number') value = value as number
        else if (type === 'Mapbox.LngLatLike') value = value as Mapbox.LngLatLike

        easeOptions[option] = value
      } catch (error) { }
    }
  }

  private setFocusOnFeatures() {
    if (this._envManager.initialLoad && this.queryParams.has('feature')) {
      try {
        const feature = this._featureService.getFeature(JSON.parse(this.queryParams.get('feature')) as number)
        if (feature) this._envManager.focusOnFeatures(feature)
      } catch (error) { }
    }
  }

  private handleParam(param: string, action: (value: any) => void) {
    if (this.queryParams.has(param)) {
      try {
        const value = JSON.parse(this.queryParams.get(param))
        action(value)
      } catch (error) { }
    }
  }

  public getCurrentShareLink() {
    const paramMap = this.buildParamMap()
    const fullUrl = this.generateUrlWithParams(paramMap)

    this._modalService.showAsModal(ShareLinkComponent).then(componentRef => {
      componentRef.instance.link = fullUrl
    })
  }

  private buildParamMap(): Map<string, string> {
    let paramMap = new Map<string, string>()
    paramMap.set('scene', this._sceneService.selectedSceneID.toString())

    if (this._featureService.selectedFeatureID) {
      paramMap.set('feature', this._featureService.selectedFeatureID.toString())
    }

    const cameraSnapshot = this._envManager.getScenePosition()
    if (this._sceneService.selectedScene.type == 'Map') {
      this.addMapSceneParams(paramMap, cameraSnapshot)
    } else {
      this.addSceneParams(paramMap, cameraSnapshot)
    }

    if (this._filterService.filtering) {
      paramMap.set('filter', JSON.stringify(this._filterService.rootFilterForURL))
    }

    return paramMap
  }

  private addMapSceneParams(paramMap: Map<string, string>, cameraSnapshot: any) {
    const { longitude, latitude, bearing, pitch, zoom } = cameraSnapshot as MapScenePosition
    paramMap.set('center', JSON.stringify([longitude, latitude]))
    paramMap.set('bearing', JSON.stringify(bearing))
    paramMap.set('pitch', JSON.stringify(pitch))
    paramMap.set('zoom', JSON.stringify(zoom))
  }

  private addSceneParams(paramMap: Map<string, string>, cameraSnapshot: any) {
    const { camera, target } = cameraSnapshot as ScenePosition
    paramMap.set('cameraPosition', JSON.stringify(camera))
    paramMap.set('targetPosition', JSON.stringify(target))
  }

  private generateUrlWithParams(paramMap: Map<string, string>): string {
    const baseUrl = window.location.href.split('?')[0]
    const urlParams = Array.from(paramMap.entries())
      .map(([key, val]) => `${key}=${encodeURI(val)}`)
      .join('&')
    return `${baseUrl}?${urlParams}`
  }
}
