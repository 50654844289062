import { Component, Input } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'

@Component({
  selector: 'billing-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  @Input() showTabs: boolean = true

  get isMobile() { return this._environmentManager.isMobile }

  constructor(private _environmentManager: EnvironmentManagerService) { }
}