<div class="input-group input-group-sm d-flex flex-column d-grid gap-2" style="user-select: none;">
    <!-- Filename -->
    <div class="input-group-sm">
        <span>Filename</span>
        <input class="form-control" type="text" [(ngModel)]="displayName" (change)="updateAttachment()">
    </div>

    <!-- URL -->
    <div class="input-group-sm">
        <span>URL</span>
        <input class="form-control" type="text" [(ngModel)]="url" (change)="updateAttachment()">
    </div>

    <!-- Headers -->
    <div class="d-grid gap-1">
        <span *ngIf="headers.length > 0">Headers</span>
        <div *ngFor="let header of headers; let i = index" class="input-group input-group-sm d-flex">
            <!-- Key -->
            <span class="input-group-text">Key</span>
            <input class="form-control" type="text" [(ngModel)]="header.key" (change)="updateAttachment()">

            <!-- Value -->
            <span class="input-group-text">Value</span>
            <input class="form-control" type="text" [(ngModel)]="header.value" (change)="updateAttachment()">

            <!-- Remove -->
            <a class="btn btn-sm btn-outline-danger ms-1" (click)="deleteHeader(i)">
                <i class="fa fa-trash"></i>
            </a>
        </div>
    </div>

    <div class="d-flex align-items-center h-100">
        <a class="btn btn-success btn-sm" (click)="addHeader()">
            + Header
        </a>

        <!-- Connection Status -->
        <div class="me-1 ms-auto">
            <span *ngIf="urlStatus == 'good'" class="text-success">
                Successful Connection
            </span>
            <span *ngIf="urlStatus == 'bad'" class="text-danger">
                Failed Connection. Please check URL and/or Headers
            </span>
        </div>

        <!-- Test URL Button -->
        <div class="btn btn-light btn-sm border me-1" (click)="validateURL()">
            <i *ngIf="urlStatus != 'loading'" class="fa fa-refresh" [ngClass]="{
                    'refresh-button': urlStatus == 'good' || urlStatus == 'bad', 
                    'text-success': urlStatus == 'good', 
                    'text-danger': urlStatus == 'bad', 
                    'refresh-button-active text-secondary rotate': urlStatus == 'loading' }">
            </i>
            <i *ngIf="urlStatus == 'loading'" class="fa-solid fa-spinner-third refresh-button-active rotate"></i>
        </div>

        <!-- Download -->
        <a class="btn btn-sm btn-secondary me-1" title="Download" [ngClass]="{'disabled': !attachmentFile}"
            (click)="downloadAttachment()">
            <i class="fas fa-download"></i>
        </a>

        <!-- Remove -->
        <a class="btn btn-sm btn-danger" title="Delete" (click)="removeAttachment()">
            <i class="fa fa-trash"></i>
        </a>
    </div>
    <div class="form-check form-switch ms-auto mt-auto">
        <input class="form-check-input" type="checkbox" [(ngModel)]="showInDescription" (change)='updateAttachment()' style="cursor: pointer;">
        Show Image in Description
    </div>
</div>