import * as Mapbox from 'mapbox-gl'
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs'
import { distinctUntilKeyChanged, filter, map, pairwise, skip, startWith, switchMap, tap, toArray } from 'rxjs/operators'
import * as THREE from 'three'
//@ts-ignore
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh'
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader'

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@services/authentication.service'
import { features$, FeatureService } from '@services/feature.service'
import { FileReferenceService } from '@services/file-reference.service'
import { FilterService } from '@services/filter.service'
import { ModelService, selectedModel$ } from '@services/model.service'
import { ProjectService } from '@services/project.service'
import { SceneService, selectedScene$ } from '@services/scene.service'
import { ToastService } from '@services/toast.service'
import { itemNotInList } from '@utils/Objects'
import { createTagGeometry, createTagMaterial, createTagTexture } from '@utils/TagFeatures'
import {
    allCharsBelowBaseline, applyToMaterials, applyToMeshes, disposeDescendants, ModelChanges, modifyModel, noCharsBelowBaseline, rotateToFaceCamera
} from '@utils/ThreeJS'

import { CustomTransformControls } from './CustomTransformControls'
import { cutOut } from './CutOutProcessor'
import { Feature, filesEqualInFeature, hasFiles, isThreeFeature } from './Feature'
import { FileReference } from './FileReference'
import { FileReferenceLoader } from './FileReferenceLoader'
import { filesEqual, Model, physicallyEqual } from './Model'
import * as Karta from './Scene'

// @ts-ignore
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
// @ts-ignore
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

const scenesLoadedSubject = new BehaviorSubject<SceneLoad>(undefined)
const modelsLoadedSubject = new BehaviorSubject<ModelLoad>(undefined)

export const scenesLoaded$: Observable<SceneLoad> = scenesLoadedSubject.pipe(skip(1))
export const modelScenesLoaded$: Observable<ModelLoad> = modelsLoadedSubject.pipe(skip(1))
export const sceneLoadMap = new Map<number, SceneLoad>()
export const modelLoadMap = new Map<number, ModelLoad>()

const DEFAULT_FONT = 'assets/Anton_Regular.json'

interface SphereOptions {
    correctSeam: boolean
    radius: number
    widthSegments: number
    heightSegments: number
    opacity: number
    phiStart: number
    phiLength: number
    thetaStart: number
    thetaLength: number
}

export class SceneLoad {
    private _backgroundLoaded: boolean = true
    private _initialLoad: boolean = true
    private _loaded: boolean = false
    private _loadingFeatures: Feature[] = []

    /** Karta.Scene with the scene's data. */
    public sceneData: Karta.Scene
    /** THREE.Scene with our models. */
    public scene: THREE.Scene = new THREE.Scene()

    /** True if this is the first load. */
    get initialLoad() { return this._initialLoad }
    /** True if all features are loaded and background is loaded. */
    get loaded() { return this._loaded }
    /** The Features left to load. */
    get loading() { return this._loadingFeatures }
    set loading(features: Feature[]) { this._loadingFeatures = features }

    get backgroundLoaded() { return this._backgroundLoaded }

    set backgroundLoaded(loaded: boolean) {
        let change = false

        if (loaded != this.backgroundLoaded) change = true

        this._backgroundLoaded = loaded
        this._checkLoaded(change)
    }

    constructor(sceneData: Karta.Scene) {
        if (sceneLoadMap.has(sceneData?.id)) return sceneLoadMap.get(sceneData.id)
        if (sceneData?.type == '360 Image' || sceneData?.type == 'Map') this._backgroundLoaded = false

        this.sceneData = sceneData
        this.scene.name = sceneData.name
        this._initScene()
        sceneLoadMap.set(sceneData.id, this)

        scenesLoadedSubject.next(this)
    }

    /** Handle issue where we hang waiting on scenes that have no features */
    noFeatures() {
        if (this.loading.length == 0) this._checkLoaded(false)
    }

    /** @param featureID The id of the feature that finished loading */
    featureFinishedLoading(featureID: number) {
        const featureIndex = this._loadingFeatures.findIndex(f => f.id == featureID)

        if (featureIndex == -1)
            console.warn(`Feature ${featureID} loaded, but was possibly already loaded.`)

        this._loadingFeatures.splice(featureIndex, 1)
        this._checkLoaded()
    }

    addFeaturesThatAreLoading(...features: Feature[]) {
        this._loadingFeatures.push(
            ...features.filter(feature => itemNotInList(feature, this._loadingFeatures))
        )

        if (features.length > 0) {
            this._checkLoaded()
        }
    }

    private _checkLoaded(changed: boolean = true) {
        const newLoadState = this._loadingFeatures.length == 0 && this._backgroundLoaded
        // If we are going from loaded to unloaded, we are no longer in the initial load.
        if (this._loaded && !newLoadState) this._initialLoad = false

        if (changed || this._loaded != newLoadState) {
            this._loaded = newLoadState
            scenesLoadedSubject.next(this)
        } else this._loaded = newLoadState
    }

    private _initScene() {
        const light = new THREE.HemisphereLight(0xffffff, 0x444444)
        light.position.set(0, 20, 0)

        this.scene.add(light, new THREE.AmbientLight(0xcccccc, 0.4))
    }
}

// TODO: make all the instance variables readonly
export class ModelLoad {
    private _group: THREE.Group = undefined
    /** The model's THREE.Group. When changed, the ModelLoad emits. Also, sets loaded */
    get group(): THREE.Group { return this._group }
    set group(group: THREE.Group) {
        if (this._group) this._group.removeFromParent()
        this._group = group
        this.loaded = group !== undefined // We are loaded if the group exists

        modelsLoadedSubject.next(this) /** Emits since the state of loaded could have changed */
    }
    /** A boolean, true if the model was loaded */
    public loaded: boolean = false

    /** THREE.Scene with our model */
    public scene: THREE.Scene = new THREE.Scene()
    constructor(
        /** Model object which has our model's data */
        public model: Model
    ) {
        if (modelLoadMap.has(model.id)) return modelLoadMap.get(model.id)
        this._initScene()
        modelLoadMap.set(model.id, this)
        modelsLoadedSubject.next(this)
    }

    private _initScene() {
        const axesHelper = new THREE.AxesHelper(200000)
        const grid = new THREE.GridHelper(20000, 300, 0x000000, 0x000000)
        const gridMaterial = grid.material as THREE.Material
        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)

        axesHelper.name = 'modelEditorAxesHelper'
        grid.name = "modelEditorGridHelper"
        gridMaterial.opacity = 0.2
        gridMaterial.transparent = true
        hemiLight.position.set(0, 20, 0)
        this.scene.background = new THREE.Color(0xD2D2D2)

        this.scene.add(axesHelper, grid, hemiLight, new THREE.AmbientLight(0xcccccc, 0.4))
    }
}

export type CreateTextureOptions = {
    correctSeam?: boolean,
    mapping?: THREE.Mapping,
    offset?: THREE.Vector2,
    repeat?: THREE.Vector2,
    wrapS?: THREE.Wrapping,
}

@Injectable({
    providedIn: 'root'
})
export class SceneManager {
    private _subscriptions: Subscription[] = []

    /** These are defined by the Environment Manager, initialized in the ModelSpace */
    public camera: THREE.Camera
    public canvas: HTMLCanvasElement
    private _sceneOriginMap = new Map<number, { lng: number, lat: number }>()
    public fontMap = new Map<string, Font>()
    public transformControls: CustomTransformControls

    get renderedScene() {
        if (this.modelService.modelIsSelected) return this.modelScene
        else return this.scene
    }

    get scene() { return new SceneLoad(this.sceneService.selectedScene).scene }

    get modelScene() {
        const model = this.modelService.selectedModel

        if (model) return new ModelLoad(model).scene
        else return undefined
    }

    get featureGroups(): THREE.Group[] {
        const groups: THREE.Group[] = []
        this.scene.traverse(c => { if (c.userData.featureID) groups.push(c as THREE.Group) })
        return groups
    }

    get allFeatureGroups(): THREE.Group[] {
        const groups: THREE.Group[] = []
        sceneLoadMap.forEach(load =>
            load.scene.traverse(c => { if (c.userData.featureID) groups.push(c as THREE.Group) })
        )
        return groups
    }

    get modelGroup(): THREE.Group {
        let modelGroup: THREE.Group

        this.modelScene.traverse((group: THREE.Group) => { if (group.userData.modelID) modelGroup = group })

        return modelGroup
    }

    /** Features that the SceneManager turns into THREE models */
    private get _threeFeatures$() {
        return features$
            .pipe(map(features => features.filter(feature => isThreeFeature(feature))))
    }

    get isViewer() {
        return this.authenticationService.currentModule == 'viewer'
    }

    get isSafari() {
        const agent = navigator.userAgent
        return !agent.includes('Chromium') && !agent.includes('Chrome') && agent.includes('Safari')
    }

    get isZUp() {
        return THREE.Object3D.DefaultUp.equals(new THREE.Vector3(0, 0, 1))
    }

    constructor(
        private _fileReferenceService: FileReferenceService,
        private _router: Router,
        private _toastService: ToastService,
        public modelService: ModelService,
        public authenticationService: AuthenticationService,
        public featureService: FeatureService,
        public fileLoader: FileReferenceLoader,
        public filterService: FilterService,
        public projectService: ProjectService,
        public sceneService: SceneService,
    ) {
        /** Subscriptions for updating/creating/deleting features, models, and background images based on the selected scene/model */
        this._subscriptions.push(

            selectedScene$.subscribe(scene => {
                if (scene.type == 'Virtual Tour')
                    THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1)
                else
                    THREE.Object3D.DefaultUp = new THREE.Vector3(0, 1, 0)
            }),

            // Handle Scene with 0 Features to load
            selectedScene$
                .pipe(
                    switchMap(() => this._threeFeatures$),
                    map(fs => fs.filter(f => f.type != '3D' || !f.unloaded)),
                    filter(fs => fs.length == 0))
                .subscribe(fs => {
                    const sceneLoad = new SceneLoad(this.sceneService.selectedScene)
                    console.warn('No three features to load in this scene')
                    sceneLoad.noFeatures()
                }),

            // Handle Previous Scenes that did not finish loading
            selectedScene$
                .pipe(distinctUntilKeyChanged('id'), pairwise())
                .subscribe(([previous, current]) => new SceneLoad(previous).loading = []),

            // Creates all uncreated Features
            this._threeFeatures$.pipe(
                map(fs => fs.filter(f => this.getFeatureGroup(f.id) === undefined && !f.unloaded)),
                filter(fs => fs.length > 0),
                switchMap(features => {
                    const sceneID = features[0].sceneID
                    const sceneData = this.sceneService.scenes.find(s => s.id == sceneID)
                    const sceneLoad = new SceneLoad(sceneData)

                    sceneLoad.addFeaturesThatAreLoading(...features)
                    const createFeatures$ = features.map(feature =>
                        this._createFeature(feature).pipe(
                            map(model => {
                                this.addModelToScene(model, feature)
                                sceneLoad.featureFinishedLoading(feature.id)

                                return [model, feature] as [THREE.Group, Feature]
                            })
                        )
                    )
                    return forkJoin(createFeatures$)
                }),
            ).subscribe(() => {
                /* Apply CutOuts and assign the feature data to the resulting mesh */
                if (this.isViewer) {
                    this.featureService.features
                        .filter(feature => feature.type == 'cutOut')
                        .forEach(cutOut => this.applyCutOut(cutOut))
                }
            }),

            // Update Feature
            selectedScene$.pipe(
                distinctUntilKeyChanged('id'),
                switchMap(() => this._threeFeatures$.pipe(
                    startWith([] as Feature[]),
                    pairwise(),
                    map(([previousFeatures, currentFeatures]) => {
                        return currentFeatures.filter(curr => {
                            if (!previousFeatures.some(prev => prev.id == curr.id)) return false

                            const prev = previousFeatures.find(prev => prev.id == curr.id)

                            return !curr.equals(prev)
                        })
                    })
                ))
            ).subscribe(features => {
                features.forEach(feature => {
                    if (feature.unloaded) return
                    const group = this.getFeatureGroup(feature.id)
                    // prevents cutOut mesh from updating its orientation when visibility checkbox changed
                    if (this.isViewer && feature.type == 'cutOut') return

                    if (feature.type == 'label' || feature.type == 'icon') {
                        const mesh = group.children.find(child => child instanceof THREE.Mesh) as THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
                        mesh.geometry.dispose()
                        createTagTexture(feature).then(texture => {
                            const textureHeight = 1
                            const textureWidth = texture.image.width / texture.image.height

                            const backgroundShape = feature.backgroundShape.value
                            mesh.material = createTagMaterial(texture, { opacity: feature.opacity, showOnTop: feature.showOnTop.value })

                            mesh.geometry = createTagGeometry(textureWidth, textureHeight, backgroundShape)
                        })
                    }
                    const renderSide: THREE.Side = JSON.parse(feature?.renderSide?.value ?? null)

                    applyToMaterials(group, material => {
                        if (['3D', 'cutOut', 'image', 'label', 'icon'].includes(feature.type)) {
                            material.depthTest = !feature.showOnTop.value
                            material.depthWrite = !feature.showOnTop.value
                        }

                        if (feature.type == '3D') material.side = renderSide ?? THREE.FrontSide
                        material.opacity = feature?.opacity ?? 1

                        if (feature.type != 'cutOut') {
                            material.transparent = true
                        } else {
                            material.transparent = false
                        }
                    })

                    this.updateOrientation(group, feature)
                })
            }),

            // Recreate Features when their files change
            selectedScene$.pipe(
                distinctUntilKeyChanged('id'),
                switchMap(() => this._threeFeatures$.pipe(
                    map(features => features.filter(feature => hasFiles(feature))),
                    pairwise(),
                    map(([previousFeatures, currentFeatures]) => {
                        return currentFeatures.filter(curr => {
                            const prev = previousFeatures.find(prev => prev.id == curr.id)
                            const featureNotDeleted = previousFeatures.some(prev => prev.id == curr.id)
                            const filesChanged = !filesEqualInFeature(curr, prev)

                            return featureNotDeleted && filesChanged
                        })
                    }),
                    filter(features => features.length > 0),
                    switchMap((features) => {
                        const createFeatures$ = features.map(feature => {
                            const model = this.getFeatureGroup(feature.id)
                            const sceneID = feature.sceneID
                            const sceneData = this.sceneService.scenes.find(s => s.id == sceneID)
                            const sceneLoad = new SceneLoad(sceneData)
                            const wasAttached = this.transformControls.attached

                            sceneLoad.addFeaturesThatAreLoading(feature)
                            model.removeFromParent()
                            this.disposeGroup(model)

                            return this._createFeature(feature).pipe(
                                tap(group => {
                                    this.addModelToScene(group, feature)

                                    if (wasAttached) {
                                        this.transformControls.detach()
                                        this.transformControls.attach(group)
                                    }

                                    sceneLoad.featureFinishedLoading(feature.id)
                                })
                            )
                        })

                        return forkJoin(createFeatures$)
                    })
                ))
            ).subscribe(),

            // Initial create for model scene
            selectedModel$.pipe(
                filter(model => model !== undefined),
                filter(model => this.getModelGroup(model.id) === undefined),
                switchMap(model => {
                    new ModelLoad(model) // Emit that we are loading a new model
                    return this._createModel(model)
                })
            ).subscribe(group => {
                const modelID = group.userData.modelID
                const modelLoad = modelLoadMap.get(modelID)
                const modelScene = modelLoad.scene

                modelScene.add(group).dispatchEvent({ type: 'add', group: group })
                modelLoad.group = group
            }),

            // Recreate model when files change
            selectedModel$.pipe(
                pairwise(),
                filter(([prev, curr]) => prev && curr && prev.id == curr.id && !filesEqual(prev, curr)),
                switchMap(([prev, curr]) => {
                    if (this.transformControls?.attached) {
                        this.transformControls.detach()
                    }

                    const modelLoad = new ModelLoad(curr)

                    this.disposeGroup(modelLoad.group)
                    modelLoad.group = undefined

                    return this._createModel(curr)
                })
            ).subscribe(group => {
                const modelID = group.userData.modelID
                const modelLoad = modelLoadMap.get(modelID)
                const modelScene = modelLoad.scene

                modelLoad.group = group
                modelScene.add(group).dispatchEvent({ type: 'add', group: group })

                this.transformControls.attach(group)
            }),

            // Update Model
            selectedModel$.pipe(
                pairwise(),
                filter(([prev, curr]) => prev?.id == curr?.id && curr !== undefined),
                filter(([prev, curr]) => !physicallyEqual(prev, curr))
            ).subscribe(([prev, curr]) => {
                const modelID = curr.id
                const modelGroup = this.getModelGroup(modelID)

                const updateGroup = (group) => {
                    let attached = this.transformControls.attached
                    if (attached) this.transformControls.detach()

                    this._applyModelsTransformations(curr, group)

                    if (attached) this.transformControls.attach(group)

                    applyToMeshes(group, mesh => { // Handles updating color, opacity and the overrideMaterial 
                        const overrideMaterial: THREE.MeshPhongMaterial = mesh.userData.overrideMaterial
                        const originalMaterial: THREE.Material | THREE.Material[] = mesh.userData.originalMaterial

                        overrideMaterial.color = new THREE.Color(curr.color)
                        overrideMaterial.opacity = curr.opacity

                        if (curr.overrideColor) mesh.material = overrideMaterial
                        else mesh.material = originalMaterial
                    })
                }

                updateGroup(modelGroup)
            }),

            selectedScene$.pipe(
                filter(s => s.type == '360 Image'),
                switchMap(sceneData => {
                    const backgroundImageProperty = sceneData.properties.find(p => p.type == "image")
                    const fileReference = backgroundImageProperty.fileReference
                    const sceneLoad = new SceneLoad(sceneData)
                    const options = { wrapS: THREE.RepeatWrapping } as CreateTextureOptions

                    if (sceneLoad.backgroundLoaded) return EMPTY

                    return this.createTexture(fileReference, options).pipe(
                        tap(texture => {
                            sceneLoad.backgroundLoaded = true
                            texture.mapping = THREE.EquirectangularReflectionMapping
                            this.scene.background = texture
                        })
                    )
                })
            ).subscribe()
        )

        const MAX_SCENES = this.isSafari ? 1 : 3 /** TODO: Figure out why reloading a scene crashes safari */
        let loadedScenes: Karta.Scene[] = []

        this._subscriptions.push(
            /** 
             * This is in an effort to resolve memory issues when switching between Scenes.
             * When switching scenes: 
             *  - We may unload previously loaded `THREE.Scenes`.
             *  - We may reload the webpage.
             */
            selectedScene$.pipe(
                distinctUntilKeyChanged('id'),
                startWith(undefined as Karta.Scene),
                pairwise()
            ).subscribe(([previous, current]) => {
                /** Amount of memory used by the application */
                const heapUsed = window.performance['memory'].usedJSHeapSize as number
                /** Amount of memory the device can handle */
                const heapLimit = window.performance['memory'].jsHeapSizeLimit as number

                if (heapUsed > heapLimit * 0.9) { // We want to catch the crash before we go over our limit
                    if (loadedScenes.length > 0) {
                        const nextSceneID = this.sceneService.selectedSceneID
                        const url = this._router.url
                        const index = (url.indexOf('?') != -1 ? url.indexOf('?') : undefined)
                        const baseURL = url.substring(0, index)
                        const navigationURL = baseURL + `?scene=${nextSceneID}`

                        window.location.replace(navigationURL)
                    } else {
                        this._toastService.toast({ title: 'Error', message: "This scene's memory usage exceeds your available memory", color: 'red' })
                    }
                }

                if (loadedScenes.some(scene => scene.id == current.id)) {
                    // TODO: Reorder list
                    loadedScenes = loadedScenes
                        .filter(scene => scene.id != current.id)
                        .concat(current)
                } else {
                    loadedScenes.push(current)

                    if (loadedScenes.length > MAX_SCENES) {
                        const firstScene = loadedScenes[0]
                        const sceneLoad = sceneLoadMap.get(firstScene.id)

                        this.disposeGroup(sceneLoad.scene)
                        sceneLoadMap.delete(firstScene.id)
                        loadedScenes.splice(0, 1)
                    }
                }
            }),

            selectedModel$.pipe( /** When leaving the model editor, handle unloading its `THREE.Scene` */
                pairwise(),
                filter(([prev, curr]) => prev != null),
                filter(([prev, curr]) => prev?.id != curr?.id)
            ).subscribe(([previous, current]) => {
                const modelLoad = modelLoadMap.get(previous.id)
                this.disposeGroup(modelLoad.scene)
                modelLoadMap.delete(previous.id)
            }),
        )
    }

    /**
     * Given a feature, creates a THREE.Group associated with the feature type.
     * Adds a name and userData to the group.
     */
    private _createFeature(feature: Feature) {
        let observable: Observable<THREE.Group> = EMPTY

        if (feature.type == '3D') observable = this._create3DFeature(feature)
        else if (feature.type == 'image') observable = this._createImageFeature(feature)
        else if (feature.type == 'icon' || feature.type == 'label') observable = this._createTagFeature(feature)
        else if (feature.type == 'view') observable = of(this._createViewPoint(feature))
        else if (feature.type == 'cutOut') observable = this._createCutOutBox()
        else console.error('No three.js object associated with feature type:', feature.type)

        return observable.pipe(tap((group: THREE.Group) => {
            group.name = feature.name
            group.userData.featureID = feature.id

            if (feature.model) group.userData.modelID = feature.model.id
            if (feature.isBackdrop) {
                group.userData.cannotBeTransformed = true
            } else if (this.isZUp && feature.type != '3D' && feature.type != 'label' && feature.type != 'icon') {
                const radianMultiplier = Math.PI / 180

                applyToMeshes(group, mesh => {
                    mesh.userData.originalRotation = mesh.rotation.clone()
                    mesh.rotation.set(90 * radianMultiplier, 90 * radianMultiplier, 0)
                })
            }
        }))
    }

    /** Performs configuration on a Feature's model before adding to the correct Scene */
    private addModelToScene(model: THREE.Group, feature: Feature) {
        const sceneID = feature.sceneID
        const sceneData = this.sceneService.scenes.find(s => s.id == sceneID)
        const sceneLoad = new SceneLoad(sceneData)
        const scene = sceneLoad.scene

        scene.add(model)
        this.updateOrientation(model, feature)
        scene.dispatchEvent({ type: 'add', group: model })

        feature.unloaded = false

        // Handle Filters when building the scene for the 1st time
        const isFilteredIn = this.filterService.isFilteredIn(feature)
        model.visible = feature.visible && isFilteredIn

        /** Helps determine render sorting; otherwise would be occluded by the backdrop */
        if (sceneData.type == 'Virtual Tour' && !model.userData?.isVirtualTourBackdrop) {
            model.renderOrder = 1
        }
    }

    /**
     * Given a model, creates a THREE.Group associated with the model files.
     * Adds a name and userData to the group.
     */
    private _createModel(model: Model) {
        const modelSetup = (group: THREE.Group) => {
            group.userData.modelID = model.id
            group.name = model.name
        }

        return this.loadFiles(model.files, model.id).pipe(
            tap(parent => {
                applyToMeshes(parent, mesh => { // @ts-ignore
                    mesh.geometry.computeBoundsTree()

                    this._setupMaterial(mesh, model)
                })

                this._applyModelsTransformations(model, parent)

                modelSetup(parent)
            })
        )
    }

    /**
     * Loads a feature's model's files, sets the overrided material, builds the model tree
     * @returns Parent group containing all the children of the loaded file
     */
    private _create3DFeature(feature: Feature) {
        return this.loadFiles(feature.model.files, this.projectService.currentProject.id).pipe(
            map(modelGroup => {
                const isVirtualTourBackdrop = feature.properties.find(prop => prop.key == 'virtualTourBackdrop')
                const featureGroup = new THREE.Group()

                applyToMeshes(modelGroup, mesh => { // @ts-ignore
                    mesh.geometry.computeBoundsTree()

                    const renderSide: THREE.Side = JSON.parse(feature.renderSide.value)
                    this._setupMaterial(mesh, feature.model, {
                        side: renderSide,
                        opacity: feature.opacity,
                        depthTest: !feature.showOnTop.value,
                        depthWrite: !feature.showOnTop.value
                    })

                    if (isVirtualTourBackdrop) {
                        mesh.renderOrder = 0 /** Helps determine render sorting for other features that would be occluded by the backdrop */
                        mesh.userData.isVirtualTourBackdrop = true
                        mesh.geometry.computeVertexNormals()
                    }
                })

                /** We don't do any model transformations on the Virtual Tour Backdrop since ruin the alignment of viewpoints */
                if (!isVirtualTourBackdrop) {
                    this._applyModelsTransformations(feature.model, modelGroup)
                }

                featureGroup.add(modelGroup)

                return featureGroup
            })
        )
    }

    /**
     * Loads the image associated with a features properties.
     * Handles trackCamera and scaleWithCamera
     * @returns image inside a group
     */
    private _createImageFeature(feature: Feature) {
        if (feature.type != 'image') return EMPTY

        const fileReference = feature.image.fileReference
        const options = { wrapS: THREE.RepeatWrapping } as CreateTextureOptions

        return this.createTexture(fileReference, options).pipe(map(texture => {
            const imgGeo: THREE.PlaneGeometry = new THREE.PlaneGeometry(texture.image.width, texture.image.height)
            const imgMat = new THREE.MeshBasicMaterial({
                map: texture,
                opacity: feature.opacity,
                side: THREE.DoubleSide,
                transparent: true,
                alphaTest: 0.1,
                depthTest: !feature?.showOnTop?.value,
                depthWrite: !feature?.showOnTop?.value
            })
            const mesh = new THREE.Mesh(imgGeo, imgMat)
            const group = new THREE.Group()

            mesh.onBeforeRender = this.getPlaneMeshOnBeforeRender(feature.id)

            group.add(mesh)

            return group
        }))
    }

    public getPlaneMeshOnBeforeRender(featureID: number) {
        return () => {
            const feature = this.featureService.scenesFeatures.get(this.sceneService.selectedSceneID)
                .find(feature => feature.id == featureID)

            if (feature && this.camera) {
                if (feature.trackCamera?.value) { // Updates the Feature to face the camera during render
                    this.faceCamera(feature)
                }

                if (feature.scaleWithCamera?.value) { // Updates the Feature to scale in ratio to its position to the camera
                    this.scaleWithCamera(feature)
                }
            }
        }
    }

    public faceCamera(feature: Feature, lockAxis?: { x?: boolean, y?: boolean, z?: boolean }) {
        const isMap = this.sceneService.selectedScene.type == "Map"
        const applyToChildren = this.isZUp && feature.type == "image"
        const model = this.getFeatureGroup(feature.id)

        return rotateToFaceCamera(model, this.camera, { applyToChildren, isMap, lockAxis })
    }

    public scaleWithCamera(feature: Feature) {
        let cameraPosition = this.camera.position.clone()
        const isMapScene = this.sceneService.selectedScene.type == "Map"
        if (isMapScene) {
            var camInverseProjection = new THREE.Matrix4().copy(this.camera.projectionMatrix)
            camInverseProjection = camInverseProjection.invert()
            cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
        }
        const group = this.getFeatureGroup(feature.id)
        // Scale the mesh by a ratio of new camera height to height of mesh
        const meshWorldPosition = group.getWorldPosition(new THREE.Vector3())
        const z = new THREE.Vector3().subVectors(meshWorldPosition, cameraPosition).length()
        const heightInPixels = 50
        const meshSize = new THREE.Box3().setFromObject(group).getSize(new THREE.Vector3())
        const frustumHeight = Math.tan(THREE.MathUtils.degToRad((this.camera as THREE.PerspectiveCamera).fov) / 2) * 2 * z
        // const frustumHeight = 1
        const meshNewHeight = (heightInPixels / this.canvas.clientHeight) * frustumHeight

        if (Math.abs(1 - meshNewHeight / meshSize.y) > 0.0001) {
            if ((meshNewHeight / meshSize.y) <= 1.1) group.scale.multiplyScalar(meshNewHeight / meshSize.y)
        }

        if (isMapScene) {
            group.scale.multiplyScalar(0.5)
        }
    }

    /**
     * Loads the HTML associated with a features properties, converts to an .png that's used by a tag feature
     * 
     * @returns tag label or icon inside a group
     */
    private _createTagFeature(feature: Feature) {
        if (feature.type != 'icon' && feature.type != 'label') {
            return EMPTY
        }

        return this._createTagMesh(feature).pipe(
            map(mesh => new THREE.Group().add(mesh))
        )
    }

    private _createTagMesh(feature: Feature) {
        return new Observable<THREE.Mesh>((observer) => {
            createTagTexture(feature).then((texture) => {
                const showOnTop = feature.showOnTop?.value ?? false
                let tagMaterial = createTagMaterial(texture, { opacity: feature.opacity, showOnTop })

                if (feature.type == 'label' && allCharsBelowBaseline(feature.text.value)) {
                    tagMaterial.map.offset.y = -0.1
                }
                else if (feature.type == 'label' && noCharsBelowBaseline(feature.text.value)) {
                    tagMaterial.map.offset.y = 0.05
                }

                const textureHeight = 1
                const textureWidth = texture.image.width / texture.image.height

                const backgroundShape = feature.backgroundShape.value

                let geometry = createTagGeometry(textureWidth, textureHeight, backgroundShape)
                const mesh = new THREE.Mesh(geometry, tagMaterial)

                mesh.onBeforeRender = this.getPlaneMeshOnBeforeRender(feature.id)
                if (showOnTop) mesh.renderOrder = 999999
                observer.next(mesh)
                observer.complete()
            })
        })
    }

    /** Creates a ViewPoint which is an image sphere specially used for Virtual Tour mode */
    private _createViewPoint(feature: Feature): THREE.Group {
        if (feature.type != 'view') return

        const group = new THREE.Group()
        const viewPointPlate = new THREE.Mesh(
            new THREE.CylinderBufferGeometry(0.1, 0.16, 0.0002, 50, 1, true),
            new THREE.MeshPhongMaterial({
                color: 0xEEEEEE,
                opacity: feature.opacity,
                side: THREE.DoubleSide,
                transparent: true,
            })
        )

        /** Rotate plate so that it is the same orientation as the mesh */
        viewPointPlate.rotateX(Math.PI / 2)

        group.add(viewPointPlate)

        group.userData.isViewpoint = true

        return group
    }

    /** Creates the Box that is used in the Builder as a CutOut reference */
    private _createCutOutBox(): Observable<THREE.Group> {
        const cubeGeometry = new THREE.BoxGeometry(1, 1, 1)
        const cubeMaterialParameters = { color: 0xd9d9d9, opacity: 0.5, side: THREE.FrontSide, transparent: true }
        const cubeMaterial = new THREE.MeshPhysicalMaterial(cubeMaterialParameters)
        const cube = new THREE.Mesh(cubeGeometry, cubeMaterial)
        const lineEdgesGeometry = new THREE.EdgesGeometry(cubeGeometry)
        const lineEdgesParameters = { color: 0x000000, opacity: 0.5, depthTest: false, transparent: true }
        const lineEdgesMaterial = new THREE.LineBasicMaterial(lineEdgesParameters)
        const lineEdges = new THREE.LineSegments(lineEdgesGeometry, lineEdgesMaterial)
        const model = new THREE.Group()

        cube.add(lineEdges)
        model.add(cube)

        return of(model)
    }

    /**
    * Creates a THREE.Texture given a file reference ID to an image file
    * @param correctSeam overlaps the texture a miniscule amount to correct for bad 360 image captures
    */
    public createTexture(fileReference: FileReference, options: CreateTextureOptions = {}, interruption$: Observable<any> = undefined): Observable<THREE.Texture> {
        return this._fileReferenceService.blobFromFileReference(fileReference, interruption$).pipe(
            switchMap(blobURL =>
                new Observable<THREE.Texture>(observer => {
                    new THREE.TextureLoader().load(blobURL, (texture) => {
                        let { correctSeam, mapping, offset, repeat, wrapS } = options
                        mapping = mapping ?? THREE.EquirectangularReflectionMapping

                        if (mapping) texture.mapping = mapping
                        if (wrapS) texture.wrapS = wrapS
                        if (correctSeam) {
                            texture.repeat.x = -0.9993
                            texture.offset.x = 0.9995
                        } else {
                            if (repeat) texture.repeat.copy(repeat)
                            if (offset) texture.offset.copy(offset)
                        }

                        texture.name = fileReference.filename

                        observer.next(texture)
                        observer.complete()
                    }, () => { }, (err) => { observer.error(err) })
                })
            )
        )
    }

    public getFont(fontURL = DEFAULT_FONT): Observable<Font> {
        if (this.fontMap.has(fontURL)) return of(this.fontMap.get(fontURL))
        else return new Observable<Font>((obs) =>
            new FontLoader().load(fontURL, (font: Font) => {
                this.fontMap.set(fontURL, font)
                obs.next(font)
            })
        )
    }

    public getFeatureGroup(featureID: number): THREE.Group {
        return this.featureGroups.find(c => c.userData.featureID == featureID) as THREE.Group
    }

    public getModelGroup(modelID: number): THREE.Group {
        if (modelLoadMap.has(modelID)) return modelLoadMap.get(modelID).group as THREE.Group
        return undefined
    }

    /**
     * Finds all the children of a Feature.
     * @param parent Feature with type = 'group'.
     * @returns THREE.group[] with all the children.
     */
    public getDescendents(parent: Feature): THREE.Group[] {
        if (parent.type != 'group') return
        let descendents: THREE.Group[] = []

        this.featureService.features.forEach(child => {
            if (child.parentID == parent.id) {
                if (child.type == 'group') descendents.push(...this.getDescendents(child))
                else descendents.push(this.getFeatureGroup(child.id))
            }
        })
        return descendents
    }

    /**
     * Takes FileReferences and starts loading the files as THREE objects.
     * @param files FileReferences from a model (or feature's model)
     * @returns a parent group which includes all the children
     */
    public loadFiles(files: FileReference[], modelOrProjectID: number) {
        return forkJoin([
            this.fileLoader.objLoader$(files, modelOrProjectID).pipe(toArray()),
            this.fileLoader.mtlObjLoader$(files, modelOrProjectID).pipe(toArray()),
            this.fileLoader.gltfLoader$(files, modelOrProjectID).pipe(toArray()),
        ]).pipe(
            map(([objs, mtls, gltfs]) => {
                let parent = new THREE.Group()
                objs.forEach(obj => parent.add(obj))
                mtls.forEach(mtl => parent.add(mtl))
                gltfs.forEach(gltf => parent.add(gltf))
                return parent
            })
        )
    }

    /**
     * Applies the models pos, rot, and scale transformations to the group.
     * Auto centers the model before model transformations applied.
     **/
    private _applyModelsTransformations(model: Model, modelGroup: THREE.Group) {
        const radianMultiplier = Math.PI / 180
        const modelParent = modelGroup.parent

        if (modelParent) modelParent.remove(modelGroup)

        // TODO: auto scale the models so that they are resonable size (like < 1000m?)
        // Center the group of meshes on (0,0,0)
        const box = new THREE.Box3().setFromObject(modelGroup)
        const size = box.getSize(new THREE.Vector3())
        const center = box.getCenter(new THREE.Vector3()).negate()
        const temp = new THREE.Group()

        modelGroup.position.set(center.x + modelGroup.position.x, center.y + modelGroup.position.y + size.y / 2, center.z + modelGroup.position.z)
        while (modelGroup.children.length > 0) temp.attach(modelGroup.children.pop())
        modelGroup.position.set(0, 0, 0)
        while (temp.children.length > 0) modelGroup.attach(temp.children.pop())

        // Apply the model's position, rotation, and scale to the group
        modelGroup.rotation.set(model.rotation[0] * radianMultiplier, model.rotation[1] * radianMultiplier, model.rotation[2] * radianMultiplier)
        modelGroup.position.set(model.position[0], model.position[1], model.position[2])
        modelGroup.scale.set(model.scale[0], model.scale[1], model.scale[2])

        if (this.isZUp) {
            modelGroup.rotation.set(90 * radianMultiplier, 90 * radianMultiplier, 0)
        }

        if (modelParent) modelParent.attach(modelGroup)
    }

    /** Updates a group's transformations and visibility */
    public updateOrientation(group: THREE.Group, feature: Feature) {
        const featureID = group.userData.featureID

        if (!featureID) return // Skips if not a feature

        const sceneLoad = sceneLoadMap.get(feature.sceneID)
        const scene = sceneLoad.scene
        const sceneData = sceneLoad.sceneData
        const radianMultiplier = Math.PI / 180

        // Not sure if these match the function description
        group.visible = feature.visible

        let controlsChildren = []
        if (this.transformControls?.attached) {
            controlsChildren = this.transformControls.attachedChildren?.slice()
            this.transformControls.detach()
        }

        scene.attach(group)

        if (sceneData.type == 'Map') {
            let sceneOrigin = this.getOrigin(sceneData)
            let sceneAltitude: number = 0
            let sceneMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(
                sceneOrigin,
                sceneAltitude
            )

            group.scale.set(feature.scale[0], feature.scale[1], feature.scale[2])

            let featureMercatorCoordinates = Mapbox.MercatorCoordinate.fromLngLat(
                { lng: feature.position[0], lat: feature.position[1] }, // Longitude, Latitude
                feature.position[2] // Altitude
            )

            const mercatorMeter = sceneMercatorCoordinates.meterInMercatorCoordinateUnits()
            group.position.set(
                (featureMercatorCoordinates.x - sceneMercatorCoordinates.x) / mercatorMeter,
                (featureMercatorCoordinates.z - sceneMercatorCoordinates.z) / mercatorMeter,
                (featureMercatorCoordinates.y - sceneMercatorCoordinates.y) / mercatorMeter
            )

            group.rotation.set(feature.rotation[0] * radianMultiplier, feature.rotation[1] * radianMultiplier, feature.rotation[2] * radianMultiplier)
            group.visible = feature.visible
        } else {
            group.position.fromArray(feature.position)
            group.rotation.set(feature.rotation[0] * radianMultiplier, feature.rotation[1] * radianMultiplier, feature.rotation[2] * radianMultiplier)
            group.scale.fromArray(feature.scale)
            group.visible = feature.visible
        }

        if (controlsChildren?.length > 0) {
            controlsChildren.forEach(child => this.transformControls.attach(child))
        }
    }

    getOrigin(sceneData: Karta.Scene) {
        if (!this._sceneOriginMap.has(sceneData.id)) {
            const longitude = +sceneData?.longitude?.value
            const latitude = +sceneData?.latitude?.value
            this._sceneOriginMap.set(sceneData.id, { lng: longitude, lat: latitude })
        }
        return this._sceneOriginMap.get(sceneData.id)
    }

    /**
     * Saves the material created by the file references.
     * Saves the override material created by the user when they choose a color for the model.
     */

    private _setupMaterial(mesh: THREE.Mesh, model: Model, parameters?: THREE.MeshStandardMaterialParameters) {
        const materials = [].concat(mesh.material) as THREE.MeshStandardMaterial[] // In case material is an array of materials

        materials.forEach(material => {
            material.side = parameters?.side ?? THREE.FrontSide
            material.opacity = parameters?.opacity ?? 1
            material.transparent = true
            material.depthTest = parameters?.depthTest ?? true
            material.depthWrite = parameters?.depthWrite ?? true
            material.needsUpdate = true
            if (material.isMeshStandardMaterial) material.metalness = parameters?.metalness ?? 0
        })

        mesh.receiveShadow = true
        mesh.userData.originalMaterial = mesh.material
        mesh.userData.overrideMaterial = new THREE.MeshPhongMaterial({
            color: parameters?.color ?? model.color,
            shininess: 100,
            side: parameters?.side ?? THREE.DoubleSide,
            opacity: parameters?.opacity ?? model.opacity,
            transparent: parameters?.transparent ?? true,
            depthTest: parameters?.depthTest ?? true,
            depthWrite: parameters?.depthWrite ?? true
        })

        if (model.overrideColor) mesh.material = mesh.userData.overrideMaterial
    }

    /** Removes an object and its children from the scene and memory.
     *  @param obj The three object to remove from memory and the scene. */
    public disposeGroup(obj: THREE.Group | THREE.Object3D) {
        disposeDescendants(obj)
    }

    public loadIn(feature: Feature) {
        const group = this.getFeatureGroup(feature.id)

        if (group) {
            return EMPTY
        } else {
            return this._createFeature(feature).pipe(
                tap(model => {
                    this.addModelToScene(model, feature)

                    if (this.isViewer && feature.type == 'cutOut') {
                        this.applyCutOut(feature)
                    }

                    feature.unloaded = false
                    this.featureService.updateLoadLocally(feature)
                })
            )
        }
    }

    public loadOut(feature: Feature) {
        const group = this.getFeatureGroup(feature.id)

        if (group) {
            this.transformControls.detach()
            this.disposeGroup(group)
            group.removeFromParent()
        }

        if (feature.unloaded == false) {
            feature.unloaded = true
            this.featureService.updateLoadLocally(feature)
        }
    }

    public applyCutOut(feature: Feature) {
        const cutOutBox = this.getFeatureGroup(feature.id)
        const boxClone = cutOutBox.clone()

        if (feature.type != 'cutOut' || cutOutBox == null) return

        cutOutBox.children.forEach(child => this.disposeGroup(child))

        const targets = this.featureService.features
            .filter(feature => isThreeFeature(feature) && feature.type != 'cutOut')
            .map(feature => this.getFeatureGroup(feature.id))
            .filter(model => model != null)
        const mesh = cutOut(boxClone, ...targets)

        cutOutBox.rotation.set(0, 0, 0)
        cutOutBox.attach(mesh)
    }

    public modifyModel(feature: Feature, changes: ModelChanges = {}) {
        const model = this.getFeatureGroup(feature.id)
        const { position, rotation, scale } = changes

        if (model == null) {
            return
        }

        if (changes.font != null || changes.text != null) {
            changes.font = changes.font ?? this.fontMap.get(DEFAULT_FONT)
            changes.text = changes.text ?? feature.text?.value
        }

        modifyModel(model, changes)

        if (position != null || rotation != null || scale != null) {
            this.updateOrientation(model, feature)
        }
    }

    recreateModel(feature: Feature) {
        const model = this.getFeatureGroup(feature.id)

        if (model != null) {
            this.disposeGroup(model)
        }

        return this._createFeature(feature).pipe(
            tap(model => this.addModelToScene(model, feature))
        )
    }

    removeFeatureFromScene(feature: Feature) {
        const group = this.getFeatureGroup(feature.id)

        if (!group) return

        this.transformControls.detach()
        this.disposeGroup(group)
    }
}