import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core'
import { Feature, isMapFeature, isThreeFeature } from '@classes/Feature'
import { MapManager } from '@classes/MapManager'
import { scenesLoaded$ } from '@classes/SceneManager'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { filtersChanged$, FilterService } from '@services/filter.service'
import { LoadingService } from '@services/loading.service'
import { VirtualTourService } from '@services/virtual-tour.service'

@Component({
  selector: 'shared-feature-visibility-icon',
  templateUrl: './feature-visibility-icon.component.html',
  styleUrls: ['./feature-visibility-icon.component.css'],
})
export class FeatureVisibilityIconComponent implements OnChanges, OnDestroy {
  @Input() feature: Feature
  @Input() updateFeature: boolean = false

  private _subscriptions: Subscription[] = []
  public model: THREE.Group

  get isFilteredOut() {
    if (this.feature.type == 'group') {
      const descendents = this.featureService.getDescendents(this.feature)

      return descendents.some(child => this.filterService.isFilteredIn(child)) == false
    } else {
      return this.filterService.isFilteredIn(this.feature) == false
    }
  }
  get loaded() { return this.loadingService.loaded }
  get sceneManager() { return this.envManager.sceneManager }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _loadingService: LoadingService,
    public envManager: EnvironmentManagerService,
    public mapManager: MapManager,
    public featureService: FeatureService,
    public filterService: FilterService,
    public loadingService: LoadingService,
    public virtualTourService: VirtualTourService,
  ) {
    this._subscriptions.push(
      scenesLoaded$
        .pipe(filter((scene) => scene.loaded))
        .subscribe(() => {
          this.model = this.sceneManager.getFeatureGroup(this.feature.id)
          this.refreshView()
        }),
      filtersChanged$.subscribe(() => this.refreshView())
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.model = this.sceneManager.getFeatureGroup(this.feature.id)
  }

  toggleVisibility(feature: Feature, checkbox: HTMLInputElement) {
    const isChecked = checkbox.checked
    const inVirtualTour = this.virtualTourService.viewpointMode
    const isViewPoint = feature.type == 'view'
    const isLoading = !this.loadingService.loaded
    const updatePermanently = this.updateFeature
    const updateLocally = !this.updateFeature

    if (feature.type == 'group') {
      this.loadingService.await(
        this.envManager.toggleVisibility(feature, { visible: isChecked, updatePermanently, updateLocally })
      )
    } else {
      if ((isViewPoint && inVirtualTour) || isLoading) {
        checkbox.checked = !isChecked
      } else {
        this.loadingService.await(
          this.envManager.toggleVisibility(feature, { visible: isChecked, updatePermanently, updateLocally })
        )
      }
    }

    this.updateParent(this.feature, isChecked)
  }

  loadThenMakeVisible(feature: Feature) {
    feature.visible = true
    this._loadingService.await(this.sceneManager.loadIn(feature))
    this.envManager.toggleVisibility(feature, { visible: true, updatePermanently: false, updateLocally: true })

    this.updateParent(this.feature, true)
  }

  updateParent(child: Feature, visible: boolean) {
    const parentID = child.parentID
    const parent = this.featureService.getFeature(parentID)

    if (!parent) return

    if (visible) {
      parent.visible = visible
    } else {
      const descendents = this.featureService.getDescendents(parent)
      const descendentIsVisible = descendents.some((feature) => {
        if (isThreeFeature(feature)) {
          const model = this.sceneManager.getFeatureGroup(feature.id)

          return model?.visible
        } else if (isMapFeature(feature)) {
          return this.mapManager.isVisible(feature.id.toString())
        }
      })

      parent.visible = descendentIsVisible
    }

    this.featureService.updateVisibilityLocally(parent)

    this.updateParent(parent, visible)
  }

  isMapFeature(feature: Feature) {
    return isMapFeature(feature)
  }

  isThreeFeature(feature: Feature) {
    return isThreeFeature(feature)
  }

  refreshView() {
    this._cdr.markForCheck()
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe)
  }
}