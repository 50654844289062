import { Modal } from 'bootstrap'
import * as Mapbox from 'mapbox-gl'
import { Subscription } from 'rxjs'
import { filter, switchMap, tap } from 'rxjs/operators'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AssetField } from '@classes/AssetField'
import { AssetFieldValue } from '@classes/AssetFieldValue'
import { FileReference } from '@classes/FileReference'
import { AssetService, selectedAssetType$ } from '@services/asset.service'
import { ToastService } from '@services/toast.service'

function nonEmptyValueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (value === null || (Array.isArray(value) && value.length === 1 && value[0] === '') || (typeof value === 'string' && value.trim() === '')) {
      return { nonEmptyString: true }
    } else {
      return null
    }
  }
}

/**
 * Validates if the control's value is numeric, allowing null or empty values.
 *
 * @param control - FormControl with the value to validate.
 * @returns An object with `{ numeric: true }` if invalid, otherwise null.
 */
function isNumericValidator(control: FormControl): { [key: string]: boolean } | null {
  const value = control.value
  // Allow null or empty values
  if (value === null || value === '') return null
  // Check if the value matches a numeric pattern (integer or float, with optional leading negative sign)
  const valid = /^-?\d+(\.\d+)?$/.test(value)
  return valid ? null : { numeric: true }
}

let previousLocation = []
let previousZoom = 15

@Component({
  selector: 'assets-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.css']
})
export class AddAssetComponent implements OnDestroy, OnInit {
  private _subscriptions: Subscription[] = []
  public assetPages: FormArray<FormArray<FormGroup>>
  public currentPageIndex = 0
  public modal: Modal
  public previousLocation = previousLocation
  public previousZoom = previousZoom
  public saving: boolean = false

  get assetTableRows() { return this._assetService.assetTableRows }
  set assetTableRows(rows) { this._assetService.assetTableRows = rows }
  get selectedAssetType() { return this._assetService.selectedAssetType }
  get showSummaryPage() { return this.currentPageIndex == this.assetPages?.controls?.length }
  get title() { return `Add ${this.selectedAssetType?.name ?? 'Asset Type'}` }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _assetService: AssetService,
    private _router: Router,
    private _toastService: ToastService
  ) {
    this._subscriptions.push(
      selectedAssetType$.pipe(
        filter(asset => asset != null),
        tap(() => this.assetPages = this._buildAssetPageForms()),
        switchMap(() =>
          this.assetPages.valueChanges.pipe(
            tap(() => this._cacheFormData())
          )
        )
      ).subscribe()
    )
  }

  ngOnInit(): void {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: { addAsset: true },
      queryParamsHandling: 'merge'
    })
  }

  getValueValidators(field: AssetField) {
    if (field.type == 'number') {
      if (field.required) {
        return Validators.compose([Validators.required, nonEmptyValueValidator(), isNumericValidator])
      } else {
        return Validators.compose([isNumericValidator])
      }
    } else if (field.required && field.type !== 'images' && field.type !== 'files') {
      return Validators.compose([Validators.required, nonEmptyValueValidator()])
    } else {
      return null
    }
  }

  private _buildAssetPageForms() {
    const form = new FormArray(
      this._assetService.pages.map(page => // Group forms by page
        new FormArray(
          page.fields.map(field => {
            // Create a form group for the current field
            const form = new FormGroup({
              fieldID: new FormControl(field.id),
              name: new FormControl<string>(field.name),
              description: new FormControl<string>(field.description),
              type: new FormControl<string>(field.type),
              dropdownOptions: new FormControl<string[]>(field.dropdownOptions),
              required: new FormControl<boolean>(field.required),
              value: new FormControl<any>(field.defaultValue, this.getValueValidators(field)),
              longitude: new FormControl<number>(field.defaultValue?.[0] ?? '', {
                validators: field.required && field.type == 'location' ? Validators.required : undefined,
                updateOn: 'blur'
              }),
              latitude: new FormControl<number>(field.defaultValue?.[1] ?? '', {
                validators: field.required && field.type == 'location' ? Validators.required : undefined,
                updateOn: 'blur'
              }),
              files: new FormControl<File[]>([], field.required && (field.type == 'images' || field.type == 'files') ? Validators.required : undefined)
            })

            return form
          })
        )
      )
    )

    const cachedFormData = localStorage.getItem('addAssetForm')

    try {
      if (cachedFormData) {
        const data = JSON.parse(cachedFormData)

        if (data.id == this.selectedAssetType.id) {
          form.setValue(data.form, { emitEvent: true })
        } else {
          localStorage.removeItem('addAssetForm')
        }
      }
    } catch {
      localStorage.removeItem('addAssetForm')
    }

    return form
  }

  private _cacheFormData() {
    const rawForm = this.assetPages.getRawValue()

    rawForm.forEach(page => {
      page.forEach(field => {
        if (field.type == 'images' || field.type == 'files') {
          field.files = []
        }
      })
    })

    try {
      localStorage.setItem('addAssetForm', JSON.stringify({ id: this.selectedAssetType.id, form: rawForm }))
    } catch { }
  }

  addFiles(event, formGroup: FormGroup) {
    const newFiles = Array.from(event.target.files)
    const existingFiles = formGroup.get('files').value
    const allFiles = existingFiles.concat(newFiles)

    formGroup.get('files').setValue(allFiles)
  }

  clearFile(formGroup: FormGroup, fileIndex: number) {
    const files = formGroup.get('files').value.slice()

    files.splice(fileIndex, 1)

    formGroup.get('files').setValue(files)
    formGroup.get('value').setValue('')
  }

  updateTrueFalse(bool: "True" | "False", formControl: FormControl) {
    if (formControl.value == bool && !formControl.hasValidator(Validators.required)) {
      formControl.setValue(null)
    } else {
      formControl.setValue(bool)
    }
  }

  async submit() {
    if (this.assetPages.invalid) {
      this._toastService.toast({ title: "Error", message: "Invalid input in the asset creation", color: "red" })
    } else {
      const assetFieldValues = this.assetPages.controls.map(page => {
        return page.controls.map((control: FormGroup) => {
          const assetFieldID = control.get('fieldID').value
          const fieldType = control.get('type').value
          let value: any
          let fileReferences: FileReference[] = []

          switch (fieldType) {
            case 'location':
              value = [control.get('longitude').value, control.get('latitude').value]
              break

            case 'images':
              const images = control.get('files').value as File[]
              images.forEach(image => {
                fileReferences.push(new FileReference(undefined, undefined, image, { filename: image.name }))
              })
              value = `${images.length} Files`
              break

            case 'files':
              const files = control.get('files').value as File[]
              files.forEach(file => {
                fileReferences.push(new FileReference(undefined, undefined, file, { filename: file.name }))
              })
              value = `${files.length} Files`
              break

            default:
              value = control.get('value').value
              break
          }

          return new AssetFieldValue(assetFieldID, value, { fileReferences: fileReferences })
        })
      })
        .reduce((acc, curr) => acc.concat(curr), [])

      this.saving = true

      const createdAsset = await this._assetService.createAsset(this.selectedAssetType, assetFieldValues).toPromise()

      this.assetTableRows.unshift(createdAsset)
      this._assetService.triggerChangesListener()
      this._assetService.selectedAssetType.assetCount = this._assetService.selectedAssetType.assetCount + 1

      this.saving = false

      localStorage.removeItem('addAssetForm')

      this.modal.hide()
    }
  }

  cancel() {
    this.modal.hide()
  }

  goToPreviousStep() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--
    }
  }

  public goToNextStep() {
    if (this.currentPageIndex < this.assetPages.controls.length) {
      const currentFormGroup = this.assetPages.at(this.currentPageIndex)

      if (currentFormGroup.valid) {
        this.currentPageIndex++
      } else {
        currentFormGroup.markAllAsTouched()

        this._toastService.toast({ title: "Validation Error", message: "Please correct the errors before proceeding.", color: "red" })
      }
    }
  }

  getProgress(): string {
    return ((this.currentPageIndex) / this.assetPages?.controls?.length * 100) + '%'
  }

  handleMapClick(camera: Mapbox.CameraOptions, formGroup: FormGroup) {
    const { lng, lat } = camera.center as Mapbox.LngLat

    formGroup.get('longitude').setValue(lng)
    formGroup.get('latitude').setValue(lat)

    previousLocation[0] = lng
    previousLocation[1] = lat
    previousZoom = camera.zoom
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
    this._router.navigate([], { queryParams: { addAsset: null }, queryParamsHandling: 'merge' })
  }
}