<div class="d-flex flex-column h-100">
    <div class="position-relative z-3">
        <span class="position-absolute top-0 start-50 translate-middle-x badge rounded-pill text-bg-primary">
            {{selectedAssets.size}}
        </span>
    </div>

    <div class="container p-3 d-flex flex-column gap-2" style="overflow-y: auto">
        <assets-edit-assets-field-value #fields *ngFor="let field of fields" [field]="field">
        </assets-edit-assets-field-value>
    </div>

    <div class="d-flex w-100 justify-content-between align-items-center border-top mt-auto">
        <div class="btn-group bg-light m-2" role="group">
            <button class="btn btn-primary btn-sm text-nowrap" (click)="updateAssets()">
                <div *ngIf="!submittingChanges">
                    <i class="me-2 fa-solid fa-floppy-disk-pen"></i>
                    <span>Save Changes</span>
                </div>
                <div *ngIf="submittingChanges">
                    <div class="me-2 spinner-border spinner-border-sm" role="status" aria-hidden="true">
                    </div>
                    Saving Changes...
                </div>
            </button>

            <button class="btn btn-outline-secondary btn-sm text-nowrap" (click)="clearChanges()">
                <i class="fa-solid fa-ban"></i>
            </button>
        </div>

        <div class="vr my-2"></div>

        <div class="btn-group btn-group-sm bg-light m-2 w-100" role="group">
            <button type="button" class="btn btn-outline-secondary" (click)="exportAssets()">
                <i class="me-2 fa-solid fa-download"></i>
                <span>Export</span>
            </button>

            <button type="button" class="btn btn-outline-success" (click)="duplicateAssets()">
                <i class="me-2 fa-solid fa-plus"></i>
                <span>Duplicate</span>
            </button>

            <button type="button" class="btn btn-outline-danger" (click)="deleteAssets()">
                <i class="me-2 fa-solid fa-trash-can"></i>
                <span>Delete</span>
            </button>
        </div>
    </div>
</div>