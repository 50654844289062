import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BillingRoutingModule } from '@billing/billing-routing.module'
import { SharedModule } from '@shared/shared.module'

import { CreateOrganizationComponent } from './create-organization/create-organization.component'
import { EditAddOnsComponent } from './edit-add-ons/edit-add-ons.component'
import { EditPaymentInfoComponent } from './edit-payment-info/edit-payment-info.component'
import { EditSubscriptionItemComponent } from './edit-subscription-item/edit-subscription-item.component'
import { EditSubscriptionComponent } from './edit-subscription/edit-subscription.component'
import { MainComponent } from './main/main.component'
import { NavBarComponent } from './nav-bar/nav-bar.component'
import { RightPanelComponent } from './right-panel/right-panel.component'
import { SelectTierComponent } from './select-tier/select-tier.component'
import { UpgradeTierComponent } from './upgrade-tier/upgrade-tier.component'
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component'
import { ViewItemComponent } from './view-item/view-item.component'
import { ViewProductsComponent } from './view-products/view-products.component'
import { ViewSubscriptionItemsComponent } from './view-subscription-items/view-subscription-items.component'

@NgModule({
  declarations: [
    CreateOrganizationComponent,
    EditAddOnsComponent,
    EditPaymentInfoComponent,
    EditSubscriptionComponent,
    EditSubscriptionItemComponent,
    MainComponent,
    NavBarComponent,
    RightPanelComponent,
    SelectTierComponent,
    UpgradeTierComponent,
    ViewInvoiceComponent,
    ViewItemComponent,
    ViewProductsComponent,
    ViewSubscriptionItemsComponent,
  ],
  imports: [
    BillingRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    CreateOrganizationComponent,
    SelectTierComponent,
    ViewInvoiceComponent,
    ViewProductsComponent,
  ]
})
export class BillingModule { }