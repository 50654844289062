import { of, Subscription } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

import { Component, Inject, OnDestroy } from '@angular/core'
import { FeatureCustomField } from '@classes/Feature'
import { EditDetailsComponent } from '@modal/edit-details/edit-details.component'
import { FeatureService } from '@services/feature.service'
import { describeString } from '@utils/Objects'

@Component({
  selector: 'shared-edit-feature-custom-fields',
  templateUrl: './edit-feature-custom-fields.component.html',
  styleUrls: ['./edit-feature-custom-fields.component.css']
})
export class EditFeatureCustomFieldsComponent implements OnDestroy {
  private _subscriptions: Subscription[] = []
  public customFields: FeatureCustomField[] = []
  public retrievingCustomFields = false
  public firstTime = true
  public oldKey: string
  public oldValue: string

  constructor(
    private _featureService: FeatureService,
    @Inject(EditDetailsComponent) private editDetailsComponent: EditDetailsComponent
    ) {
    this._subscriptions.push(
      this._featureService.selectedFeature$.pipe(
        filter(feature => feature != null),
        switchMap(feature => {
          if (feature.customFields == null) {
            this.retrievingCustomFields = true

            return this._featureService.getFeaturesCustomFields(feature)
          } else {
            return of(feature.customFields)
          }
        })
      ).subscribe(customFields => {
        this.retrievingCustomFields = false
        if (this.customFields.length == 0 && this.firstTime) {
          this.firstTime = false
          this.customFields = customFields?.sort((a, b) => {
            const aKey: string = a.key?.toString().toLowerCase() || ''
            const bKey: string = b.key?.toString().toLowerCase() || ''
            if (aKey > bKey) return 1
            else if (aKey < bKey) return -1
            else return 0
          }) ?? []
        }
      })
    )
  }

  createCustomField() {
    const customField: FeatureCustomField = {
      featureID: this._featureService.selectedFeatureID,
      key: '',
      value: ''
    }

    this.editDetailsComponent.createCustomField(customField)
  }

  deleteCustomField(customField: FeatureCustomField) {
    this.editDetailsComponent.deleteCustomField(customField)
  }

  updateCustomField(newField: FeatureCustomField, editType: 'key' | 'value', value: string) {
    const oldField: FeatureCustomField = {
      id: newField.id,
      featureID: this._featureService.selectedFeatureID,
      key: newField.key,
      value: newField.value
    }

    if (editType == 'key') oldField.key = value
    else if (editType == 'value') oldField.value = value
    
    this.editDetailsComponent.updateCustomField(oldField, newField)
  }

  getType(customField: FeatureCustomField) {
    const stringDescription = describeString(customField?.value)

    if (stringDescription == 'null') {
      return ''
    } else {
      return stringDescription
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}