import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@classes/Feature';
import { Scene } from '@classes/Scene';

@Pipe({name: 'searchScenesSort'})
export class SearchScenesSortPipe implements PipeTransform {

  transform(scenes: Scene[], searchValue: string, featuresMap: Map<number, Feature[]>) {
    if (scenes === undefined) return
    
    const searchSceneForValue = (scene: Scene): boolean => scene.name.toLowerCase().includes(searchValue.toLowerCase())

    const searchScenesFeaturesForValue = (scene: Scene): boolean => featuresMap.get(scene.id).some(feat => feat.name.toLowerCase().includes(searchValue.toLowerCase()))

    const compareScenes = (a: Scene, b: Scene): number => {
      let comparisonScore = 0
      if (searchSceneForValue(a)) comparisonScore -= 2
      if (searchScenesFeaturesForValue(a)) comparisonScore -= 1
      if (searchSceneForValue(b)) comparisonScore += 2
      if (searchScenesFeaturesForValue(b)) comparisonScore += 1
      return comparisonScore
    } 

    let sortedScenes = scenes.slice() // Keep original order of array by sorting a copy
    if (searchValue == '' || searchValue === undefined) return sortedScenes
    return sortedScenes.sort((a, b) => compareScenes(a, b))
  }
}