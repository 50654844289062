import { Component } from '@angular/core'
import { ContextMenuService } from '@services/context-menu.service'
import { ClipboardContents, CopyService } from '@services/copy.service'
import { EnvironmentManagerService } from '@services/environment-manager.service'

@Component({
  selector: 'shared-map-menu',
  templateUrl: './map-menu.component.html',
  styleUrls: ['./map-menu.component.css']
})
export class MapMenuComponent {
  public currentPosition: [number, number] = [0, 0]
  public coordinates: [number, number][] = []

  get mapSpace() { return this.envManager.mapSpace }

  constructor(
    public contextMenuService: ContextMenuService,
    public copyService: CopyService,
    public envManager: EnvironmentManagerService,
  ) {
    this.currentPosition = [
      this.mapSpace?.mouseLngLat?.lng,
      this.mapSpace?.mouseLngLat?.lat
    ]

    this.copyService.getCoordinates().then(coordinates => this.coordinates = coordinates)
  }

  displayCoordinate(coordinate: [number, number]) {
    return `${parseFloat(coordinate[0]?.toString()).toFixed(5)}, ${parseFloat(coordinate[1]?.toString()).toFixed(5)}`
  }

  copyPosition() {
    this.copyService.getCoordinates()
      .then(coordinates => {
        coordinates.push(this.currentPosition)

        const contents = { type: 'coordinates', coordinates: coordinates } as ClipboardContents

        this.copyService.copyContentsToClipboard(contents)

        this.contextMenuService.closeContext()
      })
  }

  clear() {
    this.copyService.clearClipboard()
    this.contextMenuService.closeContext()
  }
}