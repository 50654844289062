import { Component, EventEmitter, Input, Output } from '@angular/core'
import { isRule, isRuleGroup, newRule, newRuleGroup, Rule, RuleGroup, RuleOperation, SearchableProperty } from '@classes/Rules'
import { FilterService } from '@services/filter.service'

const MAX_CHILDREN = 10
const MAX_DEPTH = 5

@Component({
  selector: 'shared-rule-group',
  templateUrl: './rule-group.component.html',
  styleUrls: ['./rule-group.component.css']
})
export class RuleGroupComponent {
  @Input() depth: number = 1
  @Input() ruleGroup: RuleGroup = newRuleGroup(false)
  @Input() searchableProperties: SearchableProperty[]
  @Output() edit: EventEmitter<any> = new EventEmitter()
  @Output() remove: EventEmitter<any> = new EventEmitter()

  public isRule(rule: Rule | RuleGroup) { return isRule(rule) }
  public isGroup(rule: Rule | RuleGroup) { return isRuleGroup(rule) }
  public emitEdit() { this.edit.emit({ ruleGroup: this.ruleGroup }) }
  public emitRemove(preserveChildren = false) { this.remove.emit({ remove: true, preserveChildren: preserveChildren }) }
  public prevIsGroup(i: number) { return this.ruleGroup.ruleObjects[i - 1] !== undefined && isRuleGroup(this.ruleGroup.ruleObjects[i - 1]) }
  public nextIsGroup(i: number) { return this.ruleGroup.ruleObjects[i + 1] !== undefined && isRuleGroup(this.ruleGroup.ruleObjects[i + 1]) }
  public isEmpty(ruleGroup: RuleGroup) { return ruleGroup.ruleObjects.length == 0 }
  public hasOnlyOneChild(ruleGroup: RuleGroup) { return ruleGroup.ruleObjects.length == 1 && this.parentIsNotRoot }

  public hoveredRule: number
  public hoveredRuleGroup: number
  public actionToggled: number

  get maxChildrenReached() { return this.ruleObjects.length == MAX_CHILDREN }
  get maxDepthReached() { return this.depth == MAX_DEPTH }
  get parentIsRoot() { return this.depth == 1 }
  get parentIsNotRoot() { return !this.parentIsRoot }
  get ruleObjects() { return this.ruleGroup.ruleObjects }
  get findChildRuleGroups() { return this.ruleGroup.ruleObjects.filter(r => isRuleGroup(r)) }
  constructor(public filterService: FilterService) { }

  /** Adds a new Rule as a child */
  public addNewRule(i: number) {
    if (this.maxChildrenReached == false) {
      this.ruleGroup.ruleObjects.splice(i + 1, 0, newRule(this.ruleGroup.operation))
      this.actionToggled = undefined
      this.emitEdit()
    }
  }

  /** Adds a new RuleGroup as a child */
  public addNewGroup() {
    if (this.maxChildrenReached == false && this.maxDepthReached == false) {
      this.actionToggled = undefined
      this.ruleGroup.ruleObjects.push(newRuleGroup())
      this.emitEdit()
    }
  }

  /** Moves Rule into a sibling RuleGroup's children.  */
  public moveIntoGroup(i: number, sibling: 'above' | 'below') {
    const siblingIndex = sibling == 'above' ? i - 1 : i + 1
    const siblingGroup = this.ruleGroup.ruleObjects[siblingIndex] as RuleGroup
    const children = siblingGroup.ruleObjects

    if (children.length < MAX_CHILDREN) {
      let currRule = this.ruleGroup.ruleObjects[i] as Rule
      siblingGroup.ruleObjects.push(currRule)
      this.ruleGroup.ruleObjects.splice(i, 1)

      // If moving Rule into sibling RuleGroup leaves the parent with only one child, flatten the RuleGroup's children into the parent
      const firstRuleObject = this.ruleGroup.ruleObjects[0]
      if (this.hasOnlyOneChild(this.ruleGroup) && this.isGroup(firstRuleObject)) this.flattenGroup(firstRuleObject as RuleGroup, 0)

      this.actionToggled = undefined
      this.emitEdit()
    }
  }

  /** Handles (edit) emission from child Rules. 
   * Updates the child with new data at the parent level
   */
  public updateRule(updatedRule: Rule, i: number) {
    this.actionToggled = undefined
    this.unhoverRule()

    let rule = { ...this.ruleGroup.ruleObjects[i] } as Rule
    rule.operator = updatedRule.operator
    rule.searchableProperty = updatedRule.searchableProperty
    rule.userInput = updatedRule.userInput
    this.ruleGroup.ruleObjects[i] = rule
    this.emitEdit()
  }

  public deleteRuleGroup() { this.emitRemove() }

  /** Replaces a group with its children in the parent array */
  private flattenGroup = (ruleGroup: RuleGroup, i: number) => {
    let children = ruleGroup.ruleObjects
    if (children.length > 0) this.ruleGroup.ruleObjects.splice(i, 1, ...children)
  }

  /** Handles (remove) emission from child RuleGroups. Removes a child rule object from the RuleGroup.*/
  public deleteRuleObject(event, i: number) {
    this.actionToggled = undefined

    const ruleObjectToDelete = this.ruleGroup.ruleObjects[i]
    const isRule = this.isRule(ruleObjectToDelete)
    const isGroup = this.isGroup(ruleObjectToDelete)
    const preserveChildren = event.preserveChildren

    if (isRule || !preserveChildren) this.ruleGroup.ruleObjects.splice(i, 1)
    if (isGroup && preserveChildren) this.flattenGroup(ruleObjectToDelete as RuleGroup, i)

    // Check the parent; if there is only one child left, then we should try to flatten it out
    if (this.hasOnlyOneChild(this.ruleGroup)) {
      this.emitRemove(true)
    } else this.emitEdit()
  }

  public updateRuleGroup(event, i: number) {
    this.ruleGroup.ruleObjects[i] = event.ruleGroup
    this.emitEdit()
  }

  public toggleOperation() {
    this.ruleGroup.operation = this.ruleGroup.operation == RuleOperation.AND ? RuleOperation.OR : RuleOperation.AND
    this.emitEdit()
  }

  public hoverRule(i: number) { this.hoveredRule = i }

  public unhoverRule() {
    this.hoveredRule = undefined
    this.actionToggled = undefined
  }
}