import { BehaviorSubject, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Component, Input, OnInit } from '@angular/core'
import { Model } from '@classes/Model'
import { ModelPermission } from '@classes/ModelPermission'
import { ModelService, selectedModel$ } from '@services/model.service'
import { SubscriptionService } from '@services/subscription.service'
import { TooltipService } from '@services/tooltip.service'
import { UserService } from '@services/user.service'

export enum EditPermissionState {
  UPDATE = 'update',
  DELETE = 'delete',
  ONE_USER_WARNING = 'oneUserWarning',
  ADD = 'add'
}

interface PermissionChanges {
  permission: ModelPermission
  element?: HTMLSelectElement
}

const messages = {
  update: (permission: ModelPermission) => `Change permissions for ${permission.user.name}? This will take effect immediately.`,
  delete: (permission: ModelPermission) => `Delete permissions for ${permission.user.name}?`,
  oneUserWarning: () => `Models need at least one owner.`,
  add: () => undefined
}

@Component({
  selector: 'shared-edit-model-permissions',
  templateUrl: './edit-model-permissions.component.html',
  styleUrls: ['./edit-model-permissions.component.css']
})
export class EditModelPermissionsComponent implements OnInit {
  @Input() showEmail: boolean = true

  private _editPermissionsState$ = new BehaviorSubject<EditPermissionState>(undefined)
  private _subscriptions: Subscription[] = []
  public editPermissionsState$ = this._editPermissionsState$.asObservable()
  public message: string = undefined
  public permissionChanges: PermissionChanges = undefined
  public permissions: ModelPermission[] = []
  public selectedModel$ = selectedModel$

  get currentUser() { return this.userService.currentUser }
  get currentUserPermission(): string {
    const permission: ModelPermission = this.permissions?.find((perm: ModelPermission) => perm.userID == this.currentUser?.id)
    if (permission) return permission.permission
    else return ''
  }
  get editPermissionsState() { return this._editPermissionsState$.getValue() }
  set editPermissionsState(state: EditPermissionState) {
    this._editPermissionsState$.next(state)
    if (state) this.message = messages[state](this.permissionChanges?.permission)
  }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get model() { return this.modelService.selectedModel }
  get selectedModel() { return this.modelService.selectedModel }

  constructor(
    private _subscriptionService: SubscriptionService,
    private _tooltipService: TooltipService,
    public modelService: ModelService,
    public userService: UserService,
  ) { }

  ngOnInit(): void {
    this._subscriptions.push(
      selectedModel$.pipe(
        filter(model => model !== undefined)
      ).subscribe((model: Model) => this.permissions = model.permissions)
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => this._tooltipService.intializeTooltips())
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  showUpdateConfirmation(permission: ModelPermission, element: HTMLSelectElement) {
    if (this.changesPresentForDifferentUser(permission)) this.resetChangedElementToOriginalValue()
    if (!this.canChangePermission(permission)) {
      element.value = permission.permission
      this.editPermissionsState = EditPermissionState.ONE_USER_WARNING
      return
    }
    if (element.value == permission.permission) {
      this.cancel()
      return
    }
    this.permissionChanges = { permission, element }
    this.editPermissionsState = EditPermissionState.UPDATE
  }

  showDeleteConfirmation(permission: ModelPermission) {
    this.resetChangedElementToOriginalValue()
    if (!this.canChangePermission(permission)) {
      this.editPermissionsState = EditPermissionState.ONE_USER_WARNING
      return
    }
    this.permissionChanges = { permission }
    this.editPermissionsState = EditPermissionState.DELETE
  }

  /** Checks if the permission change would leave the model without an owner.
   * @param permissionToChange original permission before change is applied
   * @returns `true` if valid change
  */
  canChangePermission(permissionToChange: ModelPermission) {
    const numberOfOriginalOwners = this.permissions.filter(perm => perm.permission === 'Owner').length
    const permissionToChangeIsNotOwner = permissionToChange.permission != 'Owner'
    return numberOfOriginalOwners >= 2 || permissionToChangeIsNotOwner
  }

  changesPresentForDifferentUser(attemptedChangePermission: ModelPermission) {
    return this.permissionChanges && this.permissionChanges.permission.userID != attemptedChangePermission.userID
  }

  confirmUpdate() {
    this.permissionChanges.permission.permission = this.permissionChanges.element.value
    this.modelService.updateModelPermission(this.permissionChanges.permission).subscribe(() => this.cancel())
  }

  confirmDelete() {
    this.modelService.deleteModelPermission(this.permissionChanges.permission).subscribe(() => this.cancel())
  }

  cancel() {
    if (this.permissionChanges?.element) this.resetChangedElementToOriginalValue()
    this.clearPermissionChanges()
    this._editPermissionsState$.next(undefined)
  }

  resetChangedElementToOriginalValue() {
    if (!this.permissionChanges?.element) return
    let originalPermissionValue = this.permissions.find(perm => perm.userID == this.permissionChanges.permission.userID).permission
    this.permissionChanges.element.value = originalPermissionValue
  }

  clearPermissionChanges() {
    this.permissionChanges = undefined
  }

  addPermission() {
    this.editPermissionsState = EditPermissionState.ADD
  }

  permissionAdded() {
    this.editPermissionsState = undefined
  }
}