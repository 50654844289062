import { BehaviorSubject, fromEvent, Observable } from 'rxjs'
import { distinctUntilKeyChanged, filter, skip, take } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { ActivationEnd, Params, Router } from '@angular/router'
import { Feature } from '@classes/Feature'
import { isRule, isRuleGroup, newRuleGroup, Rule, RuleGroup, RuleOperation, RuleOperator } from '@classes/Rules'
import { getStringType, getType } from '@utils/Objects'

import { AuthenticationService } from './authentication.service'
import { FeatureService } from './feature.service'
import { SceneService } from './scene.service'

type Filter = {
  rules: RuleGroup,
  hiddenIfInapplicable: boolean
}

const resultsSubject: BehaviorSubject<Feature[]> = new BehaviorSubject([])
const filterSubject: BehaviorSubject<RuleGroup> = new BehaviorSubject(newRuleGroup())
const scenesFilters = new Map<number, Filter>()

export const filterResults$: Observable<Feature[]> = resultsSubject.asObservable()
export const filtersChanged$: Observable<RuleGroup> = filterSubject.pipe(skip(1))

/** Actively tracks and applies RuleGroups between scenes in the Viewer. 
 * Updates the URL with active RuleGroup, and also reads the URL to build RuleGroup from params on fresh loads. 
 * Applies/removes RuleGroup on back/forward navigation.  */
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public results: Feature[] = []

  get filtering(): boolean { return this.rules.length > 0 }
  get hiddenIfInapplicable() { return scenesFilters.get(this.selectedSceneID)?.hiddenIfInapplicable ?? false }
  set hiddenIfInapplicable(hide: boolean) {
    const filter = scenesFilters.get(this.selectedSceneID)
    if (filter) filter.hiddenIfInapplicable = hide
  }
  get isViewer() { return this._authenticationService.currentModule == 'viewer' }
  get rootFilter(): RuleGroup { return scenesFilters.get(this.selectedSceneID)?.rules ?? newRuleGroup(false) }
  get rootFilterForURL(): Filter {
    return {
      rules: this.removeFeaturesFromRuleGroup(this.rootFilter) as RuleGroup ?? newRuleGroup(false),
      hiddenIfInapplicable: this.hiddenIfInapplicable
    }
  }
  get rules(): Array<Rule | RuleGroup> { return this.rootFilter.ruleObjects ?? [] }
  get selectedSceneID() { return this._sceneService.selectedSceneID }

  constructor(
    private _authenticationService: AuthenticationService,
    private _featureService: FeatureService,
    private _router: Router,
    private _sceneService: SceneService,
  ) {
    /** Applies filter url params when the going back in url history */
    this._router.events
      .pipe(filter(e => e instanceof ActivationEnd), take(1))
      .subscribe(() => this._checkFiltersInParams())

    // Handle back/forward navigation separately
    fromEvent(window, 'popstate').subscribe(() => this._checkFiltersInParams(true))

    /** Applies filter url params when the scene changes */
    this._sceneService.selectedScene$
      .pipe(distinctUntilKeyChanged('id'), skip(1))
      .subscribe(scene => {
        this.updateURL(scene.id, true)
        filterSubject.next(this.getRuleGroupBySceneID(scene.id))
      })
  }

  private _getUrlParams() {
    const url = new URL(document.URL)

    return url.searchParams
  }

  private _setUrlParams(queryParams: Params, addToHistory: boolean = true) {
    this._router.navigate([], { queryParams, queryParamsHandling: 'merge', replaceUrl: !addToHistory })
  }

  private _getFilterFromParams() {
    const params = this._getUrlParams()
    const filters = params.get('filter')
    return JSON.parse(filters ?? null) as Filter
  }


  private _getSceneIDFromParams() {
    const params = this._getUrlParams()

    return JSON.parse(params.get('scene') ?? null) as number
  }

  public setRuleGroup(ruleGroup: RuleGroup, options?: Partial<{ addToHistory?: boolean, hiddenIfInapplicable?: boolean }>) {
    scenesFilters.set(this.selectedSceneID, { rules: ruleGroup, hiddenIfInapplicable: options?.hiddenIfInapplicable ?? this.hiddenIfInapplicable })
    this.updateURL(this.selectedSceneID, options?.addToHistory)
    filterSubject.next(ruleGroup)
  }

  public getRuleGroupBySceneID(sceneID: number) {
    let ruleGroup = scenesFilters.get(sceneID)?.rules
    return ruleGroup ? ruleGroup : newRuleGroup(false)
  }

  public clearRules(sceneID: number) {
    this.setRuleGroup(newRuleGroup(false), { addToHistory: true })
    scenesFilters.delete(sceneID)
  }

  /** Meaning included in the Filter Results */
  public isFilteredIn(feature: Feature) {
    return !this.filtering || feature.ignoresFilters || this.results.some(result => result.id == feature.id)
  }

  /**
   * Applies the active RuleGroup to the features provided. 
   * @param features 
   * @returns features that meet the search criteria
   */
  public getFilterResults(features: Feature[]) {
    if (!this.filtering) {
      var results = features ?? []
    } else {
      var results = this._applyRules(features, this.rootFilter)
    }

    const resultsMap = new Map<number, Feature>()

    results.forEach(feature => {
      resultsMap.set(feature.id, feature)

      this._featureService.getAncestors(feature)
        .forEach(ancestor => resultsMap.set(ancestor.id, ancestor))

      if (feature.type == 'group') {
        this._featureService.getDescendents(feature)
          .forEach(descendent => resultsMap.set(descendent.id, descendent))
      }
    })

    this.results = Array.from(resultsMap.values())

    return this.results
  }

  /**
   * Recursive function that applies a RuleGroup to a list of features. Iterates over the RuleGroup's children RuleObjects. If child is a RuleGroup, it's ran recursively through this function. 
   * Applies the parent RuleGroup's operator to all its children.
   * @param features
   * @param ruleGroup
   * @returns features that meet the search criteria
   */
  private _applyRules = (features: Feature[], ruleGroup: RuleGroup) => {
    if (features == null || features.length == 0) {
      return []
    }

    const ruleObjects = ruleGroup.ruleObjects
    const parentOperator = ruleGroup.operation
    let filteredFeatures: Feature[] = []

    if (ruleObjects.length == 0) return features

    for (let i = 0; i < ruleObjects.length; i++) {
      const currentRule = ruleObjects[i]
      const previousRule = ruleObjects[i - 1]

      if (isRuleGroup(currentRule)) {
        currentRule.features = this._applyRules(features, currentRule)
      } else if (isRule(currentRule)) {
        currentRule.features = this._evaluateRule(currentRule, features)
      }

      if (previousRule) {
        currentRule.features = this._evaluateOperator(previousRule.features, currentRule.features, parentOperator)
      }

      filteredFeatures = currentRule.features
    }

    return filteredFeatures
  }

  /** Evaluates a feature against a rule. If passes the rule's requirements, return true; otherwise, false */
  private _evaluateRule(rule: Rule, items: Feature[]): Feature[] {
    const operator = rule.operator
    const filterTerm = rule.userInput
    const ruleName = rule.searchableProperty.name
    const ruleSearchType = rule.searchableProperty.valueType

    return items.filter(item => {
      const isGroup = item.type == 'group'
      const hasParent = item.parentID != null

      switch (ruleName) {
        case 'name':
          return this._compare(item.name, filterTerm, operator)
        case 'description':
          if (isGroup) { // IMPORTANT NOTE: Excluding Groups from certain rule checks to get expected results
            return false
          }

          return this._compare(item.description, filterTerm, operator)
        case 'parent group name':
          if (isGroup && !hasParent) { // IMPORTANT NOTE: Excluding Groups from certain rule checks to get expected results
            return false
          }

          const ancestors = this._featureService.getAncestors(item)
          const interestingAncestors = ancestors.filter(ancestor => ancestor.objectOfInterest)

          if (interestingAncestors.length > 0) {
            return interestingAncestors.some(ancestor => this._compare(ancestor.name, filterTerm, operator))
          } else { // If a Feature does not have a parent
            return this.hiddenIfInapplicable ? false : true
          }
        default:
          if (isGroup) { // IMPORTANT NOTE: Excluding Groups from certain rule checks to get expected results
            return false
          }

          const customField = item.customFields?.find(field => field.key == ruleName)

          if (customField) {
            return this._compare(customField.value, filterTerm, operator)
          } else { // If a Feature does not have a Custom Field
            return this.hiddenIfInapplicable ? false : true
          }
      }
    })
  }

  /** Compares user input against a source value using different operators */
  private _compare(itemValue: any, filterTerm: any, operator: RuleOperator): boolean {
    const type = getType(itemValue)

    if (type == 'boolean') {
      try {
        filterTerm = JSON.parse(filterTerm)
      } catch {
        filterTerm = null
      }
    } else if (type == 'number') {
      filterTerm = +filterTerm
    } else if (type == 'text') {
      const typeDescription = getStringType(itemValue)

      if (typeDescription == "boolean") {
        try {
          itemValue = JSON.parse(itemValue)
          filterTerm = JSON.parse(filterTerm)
        } catch {
          filterTerm = null
        }
      } else if (typeDescription == "number") {
        itemValue = +itemValue
        filterTerm = +filterTerm
      }
    }

    const operations = {
      'contains': () => (itemValue as string)?.includes(filterTerm),
      'does not contain': () => !(itemValue as string)?.includes(filterTerm),
      'equals': () => itemValue == filterTerm,
      'does not equal': () => itemValue != filterTerm,
      'greater than': () => itemValue > filterTerm,
      'greater than or equals': () => itemValue >= filterTerm,
      'less than': () => itemValue < filterTerm,
      'less than or equals': () => itemValue <= filterTerm,
    }

    try {
      return operations[operator]() as boolean
    } catch {
      return false
    }
  }

  /** Based on the operator provided, an intersection (AND) or union (OR) of two lists of features is returned. */
  private _evaluateOperator(featuresResult1: Feature[], featuresResult2: Feature[], operator: RuleOperation) {
    if (operator == RuleOperation.AND)
      return featuresResult1.filter(f => featuresResult2.includes(f))
    // TODO: Make the Set actually return unique values -- maybe make arrays of ids, the plug the features in once the set is made.
    else if (operator == RuleOperation.OR)
      return [...new Set([...featuresResult1, ...featuresResult2])]
  }

  /** Updates the URL with query params that include the active RuleGroup, stringified. */
  public updateURL(sceneID: number, addToHistory: boolean = false) {
    if (this.isViewer) {
      const urlParams = {
        filter: this._getFilterFromParams(),
        scene: this._getSceneIDFromParams(),
      }

      if (this.filtering) {
        var rules = this.removeFeaturesFromRuleGroup(this.rootFilter) as RuleGroup
      }

      /** If we are on a different scene or the filter has changed, update the url params */
      if (urlParams.scene != sceneID || urlParams.filter?.rules != rules || urlParams.filter?.hiddenIfInapplicable != this.hiddenIfInapplicable) {
        if (addToHistory) {
          if (rules?.ruleObjects?.length > 0) {
            const filter = JSON.stringify({ rules, hiddenIfInapplicable: this.hiddenIfInapplicable })

            this._setUrlParams({ scene: sceneID?.toString(), filter })
          } else {
            this._setUrlParams({ scene: sceneID?.toString(), filter: null })
          }
        } else {
          const queryParams = { scene: sceneID?.toString(), rules: JSON.stringify(rules) }
          const urlTree = this._router.createUrlTree([], { queryParams })
          const url = window.location.origin + this._router.serializeUrl(urlTree)

          history.replaceState(null, '', url)
        }
      }
    } else if (addToHistory) {
      this._setUrlParams({ scene: sceneID?.toString() })
    }
  }

  /** Recursively removes features from a Rule or RuleGroup and its children. */
  private removeFeaturesFromRuleGroup = (ruleObject: RuleGroup | Rule) => {
    delete ruleObject.features

    if (isRuleGroup(ruleObject)) {
      ruleObject.ruleObjects.forEach(this.removeFeaturesFromRuleGroup)
    }

    return ruleObject
  }

  /** 
   * Retrieves URL parameters related to Scene ID and filter. Emits the retrieved filter.
   * Clears filter from URL parameters if not in the "viewer".
   */
  private _checkFiltersInParams(fromPopState: boolean = false) {
    const sceneID = this._getSceneIDFromParams()
    const filter = this._getFilterFromParams()
    const rules = filter?.rules

    if (this.isViewer) {
      if (fromPopState && sceneID && sceneID != this.selectedSceneID) {
        this._sceneService.setScene(sceneID)
      }

      if (rules) {
        scenesFilters.set(sceneID, filter)
        filterSubject.next(rules)
      } else {
        scenesFilters.delete(sceneID)
        filterSubject.next(newRuleGroup(false))
      }
    } else {
      if (rules) {
        this._setUrlParams({ filter: null })
      }

      scenesFilters.delete(sceneID)
      filterSubject.next(newRuleGroup(false))
    }
  }
}