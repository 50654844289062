import * as THREE from 'three'
import { TextGeometry, TextGeometryParameters } from 'three/examples/jsm/geometries/TextGeometry'
import { Font } from 'three/examples/jsm/loaders/FontLoader'

import { createTagGeometry, createTagMaterial, createTextureFromSVG, createTextureFromText, TagOptions } from '@utils/TagFeatures'

export type Model = THREE.Object3D | THREE.Group

export type ModelChanges = {
    color?: THREE.ColorRepresentation
    font?: Font
    name?: string
    opacity?: number
    position?: [number, number, number]
    renderSide?: THREE.Side
    rotation?: [number, number, number]
    scale?: [number, number, number]
    text?: string
    visible?: boolean
    tagChanges?: TagOptions
}

export function isMesh(mesh: any): mesh is THREE.Mesh {
    return 'geometry' in mesh && 'material' in mesh
}

export function hasTexture(material: THREE.Material): material is THREE.MeshBasicMaterial {
    return 'map' in material
}

export function disposeGeometry(geometry: THREE.BufferGeometry) {
    if (geometry == null) return

    geometry.dispose()
}

/** Dispose of any Materials/Textures */
export function disposeMaterial(material: THREE.Material | THREE.Material[]) {
    const materials = [].concat(material) as THREE.Material[] // In case material is an array of materials

    for (const material of materials) {
        material.dispose()

        if (hasTexture(material)) {
            material.map?.dispose()
        }
    }
}

/** Dispose of an objects geometry & material as well as removing it from its parent */
export function disposeObject(model: Model) {
    if (model == null) return

    applyToMeshes(model, mesh => {
        disposeGeometry(mesh.geometry)
        disposeMaterial(mesh.material)
        if (model.userData.originalMaterial) disposeMaterial(model.userData.originalMaterial)
        if (model.userData.overrideMaterial) disposeMaterial(model.userData.overrideMaterial)
    })

    model.removeFromParent()
}

/** Disposes of all of the objects descendants as well as the object, removing it from its parent */
export function disposeDescendants(model: Model) {
    if (model == null) return

    model.children.forEach(child => disposeDescendants(child))

    disposeObject(model)
}

export function createTextGeometry(font: Font, text: string, parameters?: TextGeometryParameters) {
    return new TextGeometry(text, {
        font: font,
        size: parameters?.size ?? 5,
        height: parameters?.height ?? 0.1,
        curveSegments: parameters?.curveSegments ?? 12,
        bevelEnabled: parameters?.bevelEnabled ?? false,
        bevelThickness: parameters?.bevelThickness ?? 0.1,
        bevelSize: parameters?.bevelSize ?? 0.1,
        bevelOffset: parameters?.bevelOffset ?? 0,
        bevelSegments: parameters?.bevelSegments ?? 5
    })
}
/** Calculates the positions, UV coordinates, and indices needed to create a rounded plane geometry with rounded corners. 
 * @param radius determines the amount of corner "roundness".
 * @param smoothness determines the number of subdivisions around each corner, resulting in a smoother or more detailed appearance. 
 * @see https://hofk.de/main/discourse.threejs/2021/RoundedRectangle/RoundedRectangle.html
 * */

export function createRoundedPlaneGeometry(width: number, height: number, options?: { radius?: number, smoothness?: number }): THREE.BufferGeometry {
    const smoothness = options?.smoothness ?? 10

    const smallerDimension = Math.min(width, height)
    let radius = options?.radius ?? (smallerDimension / 4)

    /* Define initial positions of vertices in x,y,z coordinates */
    const innerWidth = width / 2 - radius
    const innerHeight = height / 2 - radius

    const topRightVertexPosition = [innerWidth, innerHeight, 0]
    const topLeftVertexPosition = [-innerWidth, innerHeight, 0]
    const bottomLeftVertexPosition = [-innerWidth, -innerHeight, 0]
    const bottomRightVertexPosition = [innerWidth, -innerHeight, 0]

    let positions = [...topRightVertexPosition, ...topLeftVertexPosition, ...bottomLeftVertexPosition, ...bottomRightVertexPosition]

    /* Define initial UV coordinates */
    const uLeft = radius / width
    const uRight = (width - radius) / width
    const vLow = radius / height
    const vHigh = (height - radius) / height

    const topRightUVCoordinate = [uRight, vHigh]
    const topLeftUVCoordinate = [uLeft, vHigh]
    const bottomRightUVCoordinate = [uLeft, vLow]
    const bottomLeftUVCoordinate = [uRight, vLow]

    let uvs = [...topRightUVCoordinate, ...topLeftUVCoordinate, ...bottomRightUVCoordinate, ...bottomLeftUVCoordinate]

    /* Define the indices of the vertices to form triangles */
    let n = [
        3 * (smoothness + 1) + 3, 3 * (smoothness + 1) + 4, smoothness + 4, smoothness + 5,
        2 * (smoothness + 1) + 4, 2, 1, 2 * (smoothness + 1) + 3,
        3, 4 * (smoothness + 1) + 3, 4, 0
    ]

    let indices = [
        n[0], n[1], n[2], n[0], n[2], n[3],
        n[4], n[5], n[6], n[4], n[6], n[7],
        n[8], n[9], n[10], n[8], n[10], n[11]
    ]

    /* Calculate the positions and UV coordinates for the rounded corners */
    /* For each corner of the plane */
    for (let i = 0; i < 4; i++) {
        let xc = i < 1 || i > 2 ? innerWidth : -innerWidth;
        let yc = i < 2 ? innerHeight : -innerHeight;

        let uc = i < 1 || i > 2 ? uRight : uLeft;
        let vc = i < 2 ? vHigh : vLow;

        /* Create a vertex along the rounded corner arc -- higher the smoothness, more vertices */
        for (let j = 0; j <= smoothness; j++) {
            let phi = Math.PI / 2 * (i + j / smoothness);
            let cos = Math.cos(phi);
            let sin = Math.sin(phi);

            /* Update the positions array by adding the x, y, and z coordinates for each vertex of the rounded corner.*/
            positions.push(xc + radius * cos, yc + radius * sin, 0);

            /* Update the uvs array by adding the UV coordinates for each vertex of the rounded corner. */
            uvs.push(uc + uLeft * cos, vc + vLow * sin);

            /* Update indices to form triangles between the vertices of the rounded corner. */
            if (j < smoothness) {
                let idx = (smoothness + 1) * i + j + 4;
                indices.push(i, idx, idx + 1);
            }
        }
    }

    const geometry = new THREE.BufferGeometry()
    geometry.setIndex(new THREE.BufferAttribute(new Uint32Array(indices), 1))
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3))
    geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2))

    return geometry
}

export function replaceGeometry(model: Model, geometry: THREE.BufferGeometry) {
    applyToMeshes(model, mesh => {
        mesh.geometry.dispose()
        mesh.geometry = geometry
    })
}

export function replaceMaterial(model: Model, material: THREE.Material) {
    applyToMaterials(model, mat => {
        disposeMaterial(mat)
        mat.copy(material)
    })
}

export function applyToMeshes(model: Model, callback: (mesh: THREE.Mesh) => any) {
    model.traverse(mesh => {
        if (isMesh(mesh)) {
            callback(mesh)
        }
    })
}

export function applyToMaterials(model: Model, callback: (material: THREE.Material) => any) {
    applyToMeshes(model, mesh => {
        const materials = [].concat(mesh.material) as THREE.Material[] // In case material is an array of materials

        materials.forEach(material => { callback(material); material.needsUpdate = true })
    })
}

export async function modifyModel(model: Model, changes: ModelChanges = {}) {
    if (model == null) {
        return
    }

    const { color, font, name, tagChanges, opacity, position, renderSide, rotation, scale, text, visible } = changes

    if (color != null) {
        applyToMaterials(model, (material: THREE.MeshStandardMaterial) =>
            material.color = new THREE.Color(color)
        )
    }

    if (font != null && text != null) {
        applyToMeshes(model, (mesh: THREE.Mesh<THREE.BufferGeometry>) =>
            replaceGeometry(mesh, createTextGeometry(font, text))
        )
    }

    if (tagChanges) {
        if (tagChanges.type == 'label') {
            var texture = await createTextureFromText(tagChanges)
        } else if (tagChanges.type == 'icon') {
            var texture = await createTextureFromSVG(tagChanges)
        }

        const tagMaterial = createTagMaterial(texture, { opacity: opacity, showOnTop: tagChanges?.showOnTop })

        if (changes.tagChanges.type == 'label' && allCharsBelowBaseline(changes.tagChanges.text)) {
            tagMaterial.map.offset.y = -0.1
        }
        else if (changes.tagChanges.type == 'label' && noCharsBelowBaseline(changes.tagChanges.text)) {
            tagMaterial.map.offset.y = 0.05
        }

        const textureHeight = 1
        const textureWidth = texture.image.width / texture.image.height
        const backgroundShape = tagChanges.backgroundShape

        const geometry = createTagGeometry(textureWidth, textureHeight, backgroundShape)
        geometry.computeBoundingSphere()
        replaceGeometry(model, geometry)
        replaceMaterial(model, tagMaterial)
        if (tagChanges?.showOnTop !== undefined) model.renderOrder = tagChanges.showOnTop ? 9999 : 0
    }


    if (name != null) {
        model.name = name
    }

    if (opacity != null) {
        applyToMaterials(model, material => {
            material.opacity = changes?.opacity
            material.transparent = changes?.opacity < 1
        })
    }

    if (renderSide != null) {
        applyToMaterials(model, material => material.side = renderSide)
    }

    if (visible != null) {
        model.visible = visible
    }

    if (position != null) {
        model.position.fromArray(position)
    }

    if (rotation != null) {
        model.rotation.fromArray(rotation)
    }

    if (scale != null) {
        model.scale.fromArray(scale)
    }
}

/** g, p, q, y */
export const charsBelowBaseline = [103, 112, 113, 121]

export function noCharsBelowBaseline(inputStr: string): boolean {
    for (const char of inputStr) {
        const charCode = char.charCodeAt(0)
        if (charsBelowBaseline.includes(charCode)) return false
    }
    return true
}

export function allCharsBelowBaseline(inputStr: string): boolean {
    for (const char of inputStr) {
        const charCode = char.charCodeAt(0)
        if (!charsBelowBaseline.includes(charCode)) return false
    }

    return true
}

export type rotateToCameraParams = {
    applyToChildren?: boolean
    isMap?: boolean
    lockAxis?: { x?: boolean, y?: boolean, z?: boolean }
}

export function rotateToFaceCamera(model: THREE.Object3D, camera: THREE.Camera, params: rotateToCameraParams = {}) {
    const originalRotation = model.rotation.copy(new THREE.Euler())

    if (params.isMap) {
        const camInverseProjection = new THREE.Matrix4().copy(camera.projectionMatrix).invert()
        var cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
    } else {
        var cameraPosition = camera.position.clone()
    }

    model.lookAt(cameraPosition) // Orient object to face the camera

    // Handle specific rotation for all inner meshes
    if (params.applyToChildren) {
        applyToMeshes(model, mesh => mesh.rotation.copy(mesh.userData?.originalRotation))
    }

    // Lock specific axes
    if (params.lockAxis?.x) model.rotation.x = originalRotation.x
    if (params.lockAxis?.y) model.rotation.y = originalRotation.y
    if (params.lockAxis?.z) model.rotation.z = originalRotation.z

    return model.rotation // Return the new rotation
}

export function lookAtNormal(model: THREE.Object3D, normal: THREE.Vector3, matrixWorld: THREE.Matrix4) {
    var normalMatrix = new THREE.Matrix3()
    normalMatrix.getNormalMatrix(matrixWorld)

    var lookAtDirection = normal.clone().applyMatrix3(normalMatrix)

    model.lookAt(model.position.clone().add(lookAtDirection))

    return lookAtDirection
}