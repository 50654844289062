import { Component } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { LoadingService } from '@services/loading.service'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent {
  get currentModule() { return this._authService.currentModule }
  get darken() { return this._loadingService.darken }
  get isBuilder() { return this.currentModule == 'builder' }
  get isViewer() { return this.currentModule == 'viewer' }
  get loaded() { return this._loadingService.loaded }
  get loadingMessage() {
    if (this.isBuilder) {
      if (this.sceneProcessing) {
        return "Processing Scene"
      } else if (this.sceneFailed) {
        return "Failed to Process Scene"
      } else {
        return "Building Scene"
      }
    }
  }
  get progress() { return this._loadingService.progress }
  get sceneFailed() { return (this.isBuilder || this.isViewer) && this.selectedScene?.state == "failed" }
  get sceneProcessing() { return (this.isBuilder || this.isViewer) && this.selectedScene?.state == "processing" }
  get sceneReady() { return (this.isBuilder || this.isViewer) && this.selectedScene?.state == "ready" }
  get selectedScene() { return this._sceneService.selectedScene }

  constructor(
    private _authService: AuthenticationService,
    private _loadingService: LoadingService,
    private _sceneService: SceneService
  ) { }
}