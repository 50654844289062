import * as Mapbox from 'mapbox-gl'

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { AssetField } from '@classes/AssetField'

@Component({
  selector: 'assets-edit-assets-field-value',
  templateUrl: './edit-assets-field-value.component.html',
  styleUrls: ['./edit-assets-field-value.component.css']
})
export class EditAssetsFieldValueComponent implements OnChanges {
  @Input() field: AssetField

  public value = new FormControl()

  get changes() {
    if (this.field.type == 'location' || this.field.type == 'chooseMultiple') {
      var value: any = JSON.stringify(this.value.value)
    } else {
      var value: any = this.value.value
    }

    return { fieldID: this.field.id, value }
  }
  get isChanged() { return this.value.value?.length > 0 }
  get isValid() { return this.field.type != 'location' || (this.validLongitude && this.validLatitude) }
  get validLongitude() { return this.value.value[0] >= -180 && this.value.value[0] <= 180 }
  get validLatitude() { return this.value.value[1] >= -90 && this.value.value[1] <= 90 }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.field.type == 'location') {
      this.value.reset([])
    }
  }

  handleMapClick(event: Mapbox.CameraOptions) {
    this.value.setValue([event.center['lng'], event.center['lat']])
  }

  reset() {
    if (this.field.type == 'location') {
      this.value.reset([])
    } else {
      this.value.reset()
    }
  }

  updateLngLat(value, index) {
    const newValue = this.value.value

    newValue[index] = +value

    this.value.setValue(newValue)
    this.value.markAsTouched()
  }

  updateTrueFalse(bool: "True" | "False") {
    this.value.setValue(bool)
  }
}