import { Component } from '@angular/core'
import { MeasurementController } from '@classes/MeasurementController'
import { Activity, EnvironmentManagerService } from '@services/environment-manager.service'

@Component({
  selector: 'shared-measurement-btn',
  templateUrl: './measurement-btn.component.html',
  styleUrls: ['./measurement-btn.component.css']
})
export class MeasurementBtnComponent {
  get enabled(): boolean { return this.measurementController?.enabled }
  get measurementController(): MeasurementController { return this.envManager?.measurementController }
  get units(): string { return this.envManager?.measurementController?.units }

  constructor(public envManager: EnvironmentManagerService) { }

  toggleMeasurementMode() {
    if (this.envManager.isMeasuring) {
      this.envManager.currentActivity = Activity.Editing
    } else {
      this.envManager.currentActivity = Activity.Measuring
    }
  }

  convertUnit(newUnit: "m" | "ft" | "mi" | "km") {
    this.envManager.measurementController.changeUnit(newUnit)
  }
}