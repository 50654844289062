import { FileReference } from './FileReference'

export type FeaturePropertyTypes = 'asset' | 'boolean' | 'color' | 'decimal' | 'image' | 'integer' | 'latlng' | 'string' | 'vector3' | 'customField'

export type FeaturePropertyOptions = {
    id?: number,
    featureID?: number,
    fileReferenceID?: number,
    fileReference?: FileReference
}

export class FeatureProperty {
    public id: number
    public featureID: number
    public fileReferenceID: number
    public fileReference: FileReference
    public type: FeaturePropertyTypes
    public key: string
    public value: any

    constructor(type: FeaturePropertyTypes, key: string, value: string, options?: FeaturePropertyOptions) {
        this.id = options?.id ? +options.id : undefined
        this.featureID = options?.featureID ? +options.featureID : undefined
        this.fileReferenceID = options?.fileReferenceID ? +options.fileReferenceID : undefined
        this.fileReference = options?.fileReference
            ? new FileReference(options.fileReference.id, options.fileReference.modelID, options.fileReference.file, options.fileReference)
            : undefined
        this.type = type
        this.key = key

        if (value == 'true') {
            this.value = true
        } else if (value == 'false') {
            this.value = false
        } else {
            this.value = value ?? ''
        }
    }

    public equals(property: FeatureProperty) {
        if (property === undefined) return false

        if (this.id != property.id ||
            this.featureID != property.featureID ||
            this.fileReferenceID != property.fileReferenceID ||
            this.type != property.type ||
            this.key != property.key ||
            this.value != property.value) return false

        if (this.fileReference != null && !this.fileReference.equals(property?.fileReference))
            return false

        return true
    }
}