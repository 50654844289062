<div class="d-flex align-items-center d-grid gap-2 alert alert-sm alert-primary alert-dismissible fade show p-2 m-0"
    role="alert">
    <i class="fa-solid fa-circle-info"></i>

    <div class="d-flex flex-column">
        <div class="h5 m-0">Unlock Pro Perks</div>
        <div class="h6 m-0 text-muted">Gain full access</div>
    </div>

    <button class='btn btn-sm btn-outline-primary' (click)="upgrade()">
        Upgrade
    </button>
</div>