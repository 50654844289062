import { AssetRow, AssetRowResult } from "@services/asset.service"
import { AssetField, AssetFieldResult } from "./AssetField"

export type AssetTypeResult = {
    id?: number
    organization_id: string
    name?: string
    description?: string
    fields?: AssetFieldResult[]
    rows?: AssetRowResult[]
    asset_count?: number
}

export type AssetTypeOptions = {
    id?: number
    name?: string
    description?: string
    fields?: AssetField[]
    rows?: AssetRow[]
    assetCount: number
}

export class AssetType {
    id: number
    name: string
    description: string
    fields: AssetField[]
    rows: AssetRow[]
    assetCount: number

    constructor(name: string, options?: Partial<AssetTypeOptions>) {
        this.name = name
        this.id = options?.id ?? null
        this.description = options?.description ?? ''
        this.fields = options?.fields ?? []
        this.rows = options?.rows ?? []
        this.assetCount = options?.assetCount ?? null
    }
}