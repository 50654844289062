<form [formGroup]="form" class="d-grid gap-2">
    <!-- Name Input -->
    <div>
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" formControlName="name" class="form-control"
            [class.is-invalid]="name.invalid && name.touched">
        <div class="invalid-feedback" *ngIf="name.invalid">Name is required.</div>
    </div>

    <!-- Image Input -->
    <div>
        <label for="image" class="form-label">Image</label>
        <input type="file" accept="image/*" id="image" class="form-control" (change)="setImage($event.target.files[0])"
            [class.is-invalid]="image.invalid && image.touched">
        <div class="invalid-feedback" *ngIf="image.errors?.required">Image is required.</div>
    </div>
</form>