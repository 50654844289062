<div class='feature-icon'>
    <i *ngIf="type == '3D'" class='far fa-cube p-2 fa-xs'></i>
    <i *ngIf="type == 'assets'" class='fas fa-list-check p-2 fa-xs'></i>
    <i *ngIf="type == 'cutOut'" class="fas fa-scissors p-2 fa-xs"></i>
    <i *ngIf="type == 'geojson'" class="fas fa-map-location-dot p-2 fa-xs"></i>
    <i *ngIf="type == 'group'" class="fa-solid fa-layer-group p-2 fa-xs"></i>
    <i *ngIf="type == 'icon'" class="fa-solid fa-tag p-2 fa-sm"></i>
    <i *ngIf="type == 'image'" class='far fa-image p-2 fa-xs'></i>
    <i *ngIf="type == 'label'" class='fas fa-text p-2 fa-xs'> </i>
    <i *ngIf="type == 'line'" class="fa-solid fa-dash p-2 fa-xs"></i>
    <i *ngIf="type == 'marker'" class="far fa-map-marker-alt p-2 fa-xs"></i>
    <i *ngIf="type == 'polygon'" class="fal fa-draw-polygon p-2 fa-xs"></i>
    <i *ngIf="type == 'rasterImage'" class="fa-solid fa-image-landscape p-2 fa-xs"></i>
    <i *ngIf="type == 'view'" class="fas fa-street-view p-2 fa-xs"></i>
</div>