import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { Component } from '@angular/core'
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { AuthenticationService } from '@services/authentication.service'
import { ToastService } from '@services/toast.service'

function uniqueOrgNameValidator(authenticationService: AuthenticationService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const regex = /^[a-z0-9_-]{3,50}$/

    // Immediately validate against regex
    if (!control.value || !regex.test(control.value)) {
      return of({ invalidFormat: true })
    }

    // Check for organization name's uniqueness
    return authenticationService.checkOrganizationName(control.value).pipe(
      map(({ available }) => available ? null : { invalidAsync: true })
    )
  }
}

@Component({
  selector: 'billing-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.css']
})
export class CreateOrganizationComponent {
  public organizationForm: FormGroup = new FormGroup({
    orgName: new FormControl('', { updateOn: 'blur', validators: Validators.required }),
    orgID: new FormControl('', {
      asyncValidators: uniqueOrgNameValidator(this._authenticationService),
      updateOn: 'blur',
      validators: Validators.required,
    }),
  })
  get orgID() { return this.organizationForm.get('orgID') }
  get orgName() { return this.organizationForm.get('orgName') }

  constructor(private _authenticationService: AuthenticationService, private _toastService: ToastService) { }

  /** Validates the organization's information and then opens the checkout panel. */
  async testValidity() {
    if (this.organizationForm.invalid) {
      if (this.orgID.value != '') {
        var organizationIdNotUnique = await this._authenticationService.checkOrganizationName(this.orgID.value).toPromise().then(response => !response.available)
      }

      this.organizationForm.markAllAsTouched()

      if (organizationIdNotUnique) {
        this._toastService.toast({ color: 'red', title: 'Organization Name Error', message: 'Name has already been taken' })
      } else {
        this._toastService.toast({ color: 'red', title: 'Organization Error', message: 'Organization Identifier & Display Name is required' })
      }

      return false
    } else {
      return true
    }
  }
}