<div class="bg-white"
    [ngClass]="{'d-flex flex-column justify-content-center align-items-center': (attachments$ | async).length == 0}">
    <h1 *ngIf='(attachments$ | async).length == 0' class="text-secondary p-2 display-5">No Attachments In Selected
        Connection</h1>
        <div style="max-height:400px; overflow-y: auto;">
            <div *ngFor="let attachment of attachments; index as i" class="mt-1 p-1 border bg-light">
                <shared-edit-connection-attachment 
                    [attachment]="attachment"
                    (showConnectionDataPicker)='toggleConnectionDataPicker($event, i)'
                    (delete)="deleteAttachment(attachment, i)">
                </shared-edit-connection-attachment>
                <shared-connection-data-picker 
                    *ngIf="showConnectionDataPicker && attachmentIndexForPicker == i" 
                    (close)='closeConnectionDataPicker()'
                    class="card mt-2 p-1 bg-light" style="max-height: 200px; overflow-y: scroll;">
                </shared-connection-data-picker>
        </div>
    </div>
    <a class="btn btn-warning btn-sm mt-1" [ngClass]="{'w-100': (attachments$ | async).length > 0}"
        (click)="addAttachment()">
        + Attachment
    </a>
</div>
