export interface AssetFieldResult {
    id?: number,
    asset_type_id?: number,
    page_id: number,
    name?: string,
    description?: string
    type?: AssetFieldType,
    required?: boolean,
    default_value?: string | string[] | number,
    archived?: boolean
    dropdown_options: string[]
    next_field_id: number
}

export type AssetFieldType = 'number' | 'text' | 'date' | 'chooseOne' | 'chooseMultiple' | 'location' | 'images' | 'trueFalse' | 'files'

export type AssetFieldOptions = {
    id?: number,
    assetTypeID?: number,
    pageID: number,
    name?: string,
    description?: string
    type?: AssetFieldType,
    required?: boolean,
    defaultValue?: string | string[] | number,
    archived?: boolean
    dropdownOptions: string[]
    nextFieldID: number
}
export class AssetField {
    id: number
    assetTypeID: number
    pageID: number
    name: string
    description: string
    type: AssetFieldType
    required: boolean
    defaultValue: string | string[] | number
    archived: boolean
    dropdownOptions: string[]
    nextFieldID: number

    constructor(name: string, description: string, type: AssetFieldType, options?: Partial<AssetFieldOptions>) {
        this.name = name
        this.description = description
        this.type = type
        this.id = options?.id ?? null
        this.assetTypeID = options?.assetTypeID ?? null
        this.pageID = options?.pageID ?? null
        this.required = options?.required ?? null
        if (type == 'location' && (options?.defaultValue == '' || options?.defaultValue == null)) {
            this.defaultValue = []
        } else {
            this.defaultValue = options?.defaultValue ?? null
        }
        this.archived = options?.archived ?? null
        this.dropdownOptions = options?.dropdownOptions ? [...options.dropdownOptions] : []
        this.nextFieldID = options?.nextFieldID ?? null
    }
}