<div class="main">
    <div *ngIf="state == 'selectAccount'" class="d-flex justify-content-end p-4">
        <img *ngIf="currentUser$ | async" [src]="(currentUser$ | async)?.picture"
            class="border rounded-circle thumbnail" role="button" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop" width="50px">
    </div>

    <div class="position-absolute top-50 start-50 translate-middle">
        <div class="body p-5 rounded-1 shadow">
            <header class="d-flex flex-column align-items-center">
                <img class="logo mb-4" id="prompt-logo-center" src="https://kartorium.app/assets/logo.png"
                    alt="Kartorium">

                <h1>Welcome</h1>

                <div *ngIf="state == 'loading'" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>

                <p *ngIf="state == 'selectAccount'" class="fs-6">
                    Select Account.
                </p>
            </header>

            <!-- Organization Body -->
            <div *ngIf="state == 'selectAccount'" class="d-flex flex-column gap-2">
                <input *ngIf="organizations.length > 3" class="form-control" type="search" [(ngModel)]="search"
                    placeholder="Search">

                <ul class="list-group">
                    <li *ngFor="let org of organizations | searchSortByKeys: ['name', 'display_name'] : search"
                        class="list-group-item list-group-item-action d-flex align-items-center d-grid gap-2"
                        type="button" (click)="accountLogin(org)">
                        <img class="logo-sm" [src]="org?.branding?.logo_url ?? defaultLogo" alt="Logo">
                        <span class="fw-bold">{{org.display_name}}</span>
                    </li>
                </ul>

                <li *ngIf="hasPersonalAccount" class="list-group list-group-item text-white scheme-bg-dark"
                    type="button" (click)="accountLogin()">
                    <i class="fas fa-user me-1"></i>
                    My Account
                </li>

                <small class="text-muted">
                    Having trouble? Contact <a href="mailto:support@kartorium.com">support@kartorium.com</a>
                </small>
            </div>
        </div>
    </div>
</div>