import * as THREE from 'three'

import { isMesh } from '@utils/ThreeJS'

/** Uses a CutOut feature to 'cut' a portion of a mesh away from the original for highlighting purposes. */
export function cutOut(cutOutBox: THREE.Group, ...targets: THREE.Object3D[]) {
    const cutOutBoundingBox = calculateBoundingBox(cutOutBox)
    const intersectingMeshes: THREE.Mesh[] = []
    const resultingMeshes: THREE.Mesh[] = []

    targets.forEach(model => {
        model.traverse(mesh => {
            if (isMesh(mesh) && intersectsWithCutOutBox(mesh, cutOutBoundingBox))
                intersectingMeshes.push(mesh)
        })
    })

    intersectingMeshes.forEach(mesh => {
        const clonedMesh = updateWorldPosition(mesh)

        clonedMesh.geometry = clonedMesh.geometry.toNonIndexed() // IMPORTANT -- We need to access this Geometry's data in non-indexed form.
        clonedMesh.geometry.computeBoundingSphere()

        const intersectingVerticeData = getIntersectingVertices(clonedMesh, cutOutBoundingBox)
        const resultingMesh = createMeshFromVerticeData(clonedMesh, intersectingVerticeData)

        resultingMeshes.push(resultingMesh)
    })

    /* Create a THREE.Group from all of the resulting meshes. `attach` instead of `add` because we want to keep the meshes' world positions/rotations/scales. */
    const group = new THREE.Group()

    const box = new THREE.Box3().setFromPoints(resultingMeshes.map(mesh => mesh.position))
    box.getCenter(group.position)

    resultingMeshes.forEach(mesh => group.attach(mesh))
    group.userData.isCutout = true
    return group
}

/** Calculate and use the world position of the CutOutBox */
function calculateBoundingBox(cutOutGroup: THREE.Group) {
    const cutOutBoxMesh = cutOutGroup.children.find(child => child instanceof THREE.Mesh) as THREE.Mesh
    cutOutBoxMesh.geometry.computeBoundingBox()
    const cutOutBoundingBox = cutOutBoxMesh.geometry.boundingBox.clone()
    cutOutBoundingBox.applyMatrix4(cutOutBoxMesh.matrixWorld)

    return cutOutBoundingBox
}

/** Calculates the bounding box of target mesh. Returns `true` if it intersects with the CutOut bounding box, else `false`. */
function intersectsWithCutOutBox(targetMesh: THREE.Mesh, cutOutBoundingBox: THREE.Box3) {
    targetMesh.geometry.computeBoundingBox()
    const targetBoundingBox = targetMesh.geometry.boundingBox.clone()
    targetBoundingBox.applyMatrix4(targetMesh.matrixWorld);

    if (cutOutBoundingBox.intersectsBox(targetBoundingBox)) return true
    else return false
}

function boundingBoxContainsVertex(vertex: THREE.Vector3, mesh: THREE.Mesh, boundingBox: THREE.Box3) {
    const vertexClone = vertex.clone()
    mesh.localToWorld(vertexClone)
    if (vertex) return boundingBox.containsPoint(vertexClone)
    else return false
}

/** Calculate and use the world position of the intersecting mesh */
function updateWorldPosition(mesh: THREE.Mesh) {
    const worldPos = new THREE.Vector3()
    const worldQuat = new THREE.Quaternion()
    const worldScal = new THREE.Vector3()
    const clonedMesh = mesh.clone()
    clonedMesh.position.copy(mesh.getWorldPosition(worldPos))
    clonedMesh.quaternion.copy(mesh.getWorldQuaternion(worldQuat))
    clonedMesh.scale.copy(mesh.getWorldScale(worldScal))
    clonedMesh.updateMatrix()

    return clonedMesh
}

/**
 * Iterates over BufferGeometry data to determine if any vertices fall in the bounding box.
 * @returns array of buffer arrays with vertice data: [positions, normals, UVs]
 * @param mesh 
 */
function getIntersectingVertices(mesh: THREE.Mesh, boundingBox: THREE.Box3): [number[], number[], number[]] {
    /** Buffer storing geometry's vertice position data; vertex represented by the values of 3 consecutive indices */
    const sourcePositions = mesh.geometry.getAttribute('position').array
    /** Buffer storing geometry's vertice normal data; vertex represented by the values of 3 consecutive indices */
    const sourceNormals = mesh.geometry.getAttribute('normal').array
    /** Buffer storing geometry's vertice uv data; vertex represented by the values of 2 consecutive indices */
    const sourceUVs = mesh.geometry.getAttribute('uv').array

    const newPositions = []
    const newNormals = []
    const newUVs = []

    /********************************************************************************************************************
    * NOTE: Geometry buffers (Position, Normal, and UV) contain the geometry's deconstructed vertice data.              *  
    * Vertices can be reconstructed with groups of 3 bits (not correct terminology, but not sure what to call them).    *
    * Ex: [ position[0], position[1], position[2] ] is referencing a single vertice stored in the buffer at index of 0. *
    *                                                                                                                   *
    * NOTE: Faces can be reconstructed from 3 sequential vertices.                                                      *    
    *                                                                                                                   * 
    * Ex:               _______   _______   _______                                                                     *
    * positionBuffer  [ 0, 1, 2,  3, 4, 5,  6, 7, 8, ... ]                                                              *
    *                  ^vertex0^ ^vertex1^ ^vertex2^                                                                    *                                                            
    *                  |---------- Face -----------|                                                                    *
    *                                                                                                                   *
    * ADDTIONAL NOTE: Position, Normal, and UV buffers are `parallel`, meaning that the same index in separate arrays   *
    * reference the same vertice                                                                                        *
    * Ex.  | position[0] | position[1] | position[2] |                                                                  *
    *      |  normal[0]  |  normal[1]  |  normal[2]  |                                                                  *
    *      |    uv[0]    |    uv[1]    |    uv[2]    |                                                                  *
    *                                                                                                                   *
    ********************************************************************************************************************/

    for (let i = 0; i <= sourcePositions.length - 1; i += 9) {
        /* Each iteration gathers 3 consecutive vertices (9 bits in the buffer) representing a face. */

        /* Vertex Position Data */
        const vertex0Positions = getVector3FromBufferData(sourcePositions, i)     // Vertex 0 --\
        const vertex1Positions = getVector3FromBufferData(sourcePositions, i + 3) // Vertex 1 -- - Represents 1 face  
        const vertex2Positions = getVector3FromBufferData(sourcePositions, i + 6) // Vertex 2 --/

        /* Vertex Normal Data */
        const vertex0Normals = getVector3FromBufferData(sourceNormals, i)         // Vertex 0
        const vertex1Normals = getVector3FromBufferData(sourceNormals, i + 3)     // Vertex 1
        const vertex2Normals = getVector3FromBufferData(sourceNormals, i + 6)     // Vertex 2

        /* Vertex UV Data */
        const uvIndex = (i / 9 * 6)
        const vertex0UVs = getVector2FromBufferData(sourceUVs, uvIndex)           // Vertex 0
        const vertex1UVs = getVector2FromBufferData(sourceUVs, uvIndex + 2)       // Vertex 1
        const vertex2UVs = getVector2FromBufferData(sourceUVs, uvIndex + 4)       // Vertex 2

        /* If bounding box contains vertex, write the face to an array. */
        if (boundingBoxContainsVertex(vertex0Positions, mesh, boundingBox) ||
            boundingBoxContainsVertex(vertex1Positions, mesh, boundingBox) ||
            boundingBoxContainsVertex(vertex2Positions, mesh, boundingBox)) {
            writeFaceDataToArray(newPositions, i, [vertex0Positions, vertex1Positions, vertex2Positions])
            writeFaceDataToArray(newNormals, i, [vertex0Normals, vertex1Normals, vertex2Normals])
            writeFaceDataToArray(newUVs, uvIndex, [vertex0UVs, vertex1UVs, vertex2UVs], true)
        }
    }

    return [newPositions, newNormals, newUVs]
}

/** Creates a Mesh from vertice data.  */
function createMeshFromVerticeData(originalMesh: THREE.Mesh, verticeData: any[][]) {
    /* New Buffer attribute data must be Typed Arrays */
    const positionBuffer = new Float32Array(verticeData[0])
    const normalBuffer = new Float32Array(verticeData[1])
    const uvBuffer = new Float32Array(verticeData[2])

    const geo = originalMesh.geometry.clone()

    geo.setAttribute('position', new THREE.BufferAttribute(positionBuffer, 3))
    geo.setAttribute('normal', new THREE.BufferAttribute(normalBuffer, 3))
    geo.setAttribute('uv', new THREE.BufferAttribute(uvBuffer, 2))
    geo.setIndex(null) // Ensure there is no index attribute, as it will try to map vertices to incorrectly specified faces

    geo.boundingSphere.radius = originalMesh.geometry.boundingSphere.radius // Workaround for NaN error

    const material = cloneMaterial(originalMesh.material, false)
    //@ts-ignore
    const resultingMesh = new THREE.Mesh(geo, material)

    resultingMesh.position.copy(originalMesh.position)
    resultingMesh.quaternion.copy(originalMesh.quaternion)
    resultingMesh.scale.set(originalMesh.scale.x * 1.0005, originalMesh.scale.y * 1.0005, originalMesh.scale.z * 1.0005)

    resultingMesh.updateMatrix()

    return resultingMesh
}

function cloneMaterial(material: THREE.Material | THREE.Material[], colorWrite: boolean) {
    if (material instanceof Array) {
        let materials = []
        material.forEach(m => {
            let mat = m.clone()
            mat.colorWrite = colorWrite
            materials.push(m)
        })
        return materials
    } else {
        let mat = material.clone()
        mat.colorWrite = colorWrite
        return mat
    }
}

function getVector2FromBufferData(data, startIndex: number): THREE.Vector2 {
    return new THREE.Vector2(
        parseFloat(data[startIndex + 0]),
        parseFloat(data[startIndex + 1])
    )
}

function getVector3FromBufferData(data, startIndex: number): THREE.Vector3 {
    return new THREE.Vector3(
        data[startIndex + 0],
        data[startIndex + 1],
        data[startIndex + 2]
    )
}

function writeVertexDataToArray(bufferArray: number[], startIndex: number, faceVertex: THREE.Vector3 | THREE.Vector2): void {
    bufferArray[startIndex] = faceVertex.x
    bufferArray[startIndex + 1] = faceVertex.y
    if (faceVertex instanceof THREE.Vector3) bufferArray[startIndex + 2] = faceVertex.z
}

function writeFaceDataToArray(bufferArray: number[], startIndex: number, faceVertices: THREE.Vector3[] | THREE.Vector2[], isUV = false): void {
    if (isUV) {
        writeVertexDataToArray(bufferArray, startIndex, faceVertices[0])
        writeVertexDataToArray(bufferArray, startIndex + 2, faceVertices[1])
        writeVertexDataToArray(bufferArray, startIndex + 4, faceVertices[2])
    } else {
        writeVertexDataToArray(bufferArray, startIndex, faceVertices[0])
        writeVertexDataToArray(bufferArray, startIndex + 3, faceVertices[1])
        writeVertexDataToArray(bufferArray, startIndex + 6, faceVertices[2])
    }
}