<div class="form-group py-1" style="user-select: none;">
    <div style='overflow-x: hidden;'>
        <table class="table table-responsive table-striped border">
            <thead>
                <tr>
                    <th scope="col">User</th>
                    <th *ngIf='showEmail' scope="col">Email</th>
                    <th scope="col">Permission</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let permission of permissions; index as i;">
                    <tr style="position: relative;">
                        <td style="vertical-align: middle;">{{permission.user.name}}</td>
                        <td *ngIf='showEmail' style="vertical-align: middle;">{{permission.user.email}}</td>

                        <!-- Edit users permissions -->
                        <td *ngIf="currentUserPermission == 'Owner'">
                            <select class="form-select form-select-sm"
                                (change)="showUpdateConfirmation(permission, $event.target)" style="cursor: pointer;">
                                <option value="Viewer" [selected]="permission.permission == 'Viewer'">
                                    Viewer
                                </option>
                                <option value="Editor" [selected]="permission.permission == 'Editor'">
                                    Editor
                                </option>
                                <option value="Owner" [selected]="permission.permission == 'Owner'">
                                    Owner
                                </option>
                            </select>
                        </td>

                        <!-- Display users permissions -->
                        <td *ngIf="currentUserPermission != 'Owner'" style="vertical-align: middle">
                            {{permission.permission}}
                        </td>

                        <!-- Info and Trash Buttons -->
                        <td style="vertical-align: middle;">
                            <div class='d-flex'>
                                <!-- Info (i) Icon in the Details page -->
                                <a class="btn btn-sm" data-bs-toggle="tooltip" title='
                                <div class="d-flex flex-column">
                                    <span class="me-1">Last Edited: {{permission?.lastEdited | date }}</span>
                                    <span>Last Accessed: {{permission?.lastAccessed | date}}</span>
                                </div>' data-bs-html="true" style="cursor: default;">
                                    <i class="permission-action fal fa-info-circle"></i>
                                </a>

                                <!-- Delete Permission trash button -->
                                <a *ngIf='currentUserPermission == "Owner" || currentUser?.id == permission.userID'
                                    class="btn btn-sm" (click)="showDeleteConfirmation(permission)">
                                    <i class="fa fa-trash" [ngClass]="{
                                        'permission-action': permissionChanges?.permission?.userID != permission.userID, 
                                        'permission-action-delete': permissionChanges?.permission?.userID == permission.userID && editPermissionsState == 'delete'
                                    }"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <ng-container [ngSwitch]="editPermissionsState$ | async">
        <ng-container *ngSwitchCase="'update'">
            <div class='p-1 d-flex flex-wrap border justify-content-between align-items-center'
                [style.backgroundColor]="'rgba(252, 255, 187, 0.577)'">
                <span class="text-center flex-grow-1 me-auto text-warning">{{message}}</span>
                <div class='flex-grow-1 text-center'>
                    <button class="me-1 btn btn-sm btn-warning text-white" (click)='confirmUpdate()'>
                        Update
                    </button>
                    <button class='btn btn-sm btn-secondary' (click)='cancel()'>Cancel</button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'delete'">
            <div class='p-1 d-flex flex-wrap border justify-content-between align-items-center'
                [style.backgroundColor]="'rgba(255, 187, 187, 0.577)'">
                <span class="text-center flex-grow-1 me-auto text-danger">{{message}}</span>
                <div class='flex-grow-1 text-center'>
                    <button class="me-1 btn btn-sm btn-danger" (click)='confirmDelete()'>
                        Delete
                    </button>
                    <button class='btn btn-sm btn-secondary' (click)='cancel()'>Cancel</button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'oneUserWarning'">
            <div id='oneOwnerWarning' class='p-1 d-flex border align-items-center'
                style='background-color: rgba(252, 255, 187, 0.577);'>
                <span class='text-warning text-center flex-grow-1 me-auto'>{{message}}</span>
                <button class='btn btn-sm btn-close' (click)='cancel()'></button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'add'">
            <shared-create-permission (onCreate)='permissionAdded()'>
            </shared-create-permission>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div *ngIf="currentUserPermission == 'Owner' && !inFreeTier" class="row pt-2 pe-3">
                <div class="col-auto ms-auto">
                    <a class="btn btn-sm btn-success" [ngClass]="{'disabled': inFreeTier}" (click)="addPermission()">
                        <i class="fas fa-plus fa-fw"></i>
                    </a>
                    <i *ngIf='inFreeTier' class="ms-1 text-secondary fas fa-lock fa-fw"></i>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>