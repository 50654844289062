import { EMPTY, Observable } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import * as THREE from 'three'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader'

import { Injectable } from '@angular/core'
import { AuthenticationService } from '@services/authentication.service'
import { FileReferenceService } from '@services/file-reference.service'
import { ToastService } from '@services/toast.service'

import { FileReference } from './FileReference'

/** Strips '.obj' and '.mtl' from filenames. Returns filename without ext. */
function stripObjMtlExt(fn: string) {
    if (!fn.endsWith('.mtl') && !fn.endsWith('.obj')) throw new Error(`Filename ${fn} was not .obj or .mtl.`)
    return fn.slice(0, -4)
}

@Injectable({
    providedIn: 'root'
})
export class FileReferenceLoader {
    private _dracoLoader: DRACOLoader = new DRACOLoader()
        .setCrossOrigin('*')
        .setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/jsm/libs/draco/')
        .preload()

    constructor(
        private _authenticationService: AuthenticationService,
        private _fileReferenceService: FileReferenceService,
        private _toastService: ToastService,
    ) { }

    /**
     * Given some FileReferences, for each obj file, load the files and emit the groups.
     * @returns An Observable which emits a THREE.Group each time an obj file is loaded
     * and completes when there are no more obj files to load.
     * */
    public objLoader$(files: FileReference[], modelOrProjectID: number) {
        return this._newManager(files, modelOrProjectID,
            { regex: /\.dds$/i, loader: new DDSLoader() },
            { regex: /\.tga$/i, loader: new TGALoader() }
        ).pipe(
            switchMap(manager => {
                let objLoader = new OBJLoader(manager)

                // IMPORTANT NOTE: Not handling FileReferences with duplicate filenames (including their extensions, of course)
                const objFiles = files.filter(f => f.extension == '.obj')
                const mtlFiles = files.filter(f => f.extension == '.mtl')
                const unpairedObjs = objFiles.filter(obj => !mtlFiles.some(mtl => stripObjMtlExt(mtl.filename) == stripObjMtlExt(obj.filename)))

                return new Observable<THREE.Group>((obs) => {
                    if (unpairedObjs.length == 0) {
                        obs.complete()
                        return
                    }

                    const defaultMaterial = new THREE.MeshPhongMaterial({
                        color: 0x808080,
                        shininess: 100,
                        side: THREE.DoubleSide
                    })

                    // Load objs WITHOUT mtls
                    for (let i = 0; i < unpairedObjs.length; i++) {
                        objLoader.load(unpairedObjs[i].filename, (group) => {
                            group.traverse(child => {
                                if (child.type == 'Mesh') {
                                    let mesh: THREE.Mesh = child as THREE.Mesh
                                    mesh.material = defaultMaterial
                                    child.receiveShadow = true
                                }
                            })

                            group.name = unpairedObjs[i].filename
                            obs.next(group)
                            if (i == unpairedObjs.length - 1) obs.complete()
                        }, () => { }, (err) => { obs.error(err) })
                    }
                })
            }),
            catchError((err) => {
                this._toastService.toast({ title: 'Error', message: 'Model missing file: ' + err, color: 'red' })
                return EMPTY
            })
        )
    }

    /**
     * Given some FileReferences, for each .mtl file with corresponding .obj file,
     * load the file pairs and emit the groups.
     * @returns An Observable which emits a THREE.Group each time a file pair is loaded
     * and completes when there are no more pairs to load.
     * */
    public mtlObjLoader$(files: FileReference[], modelOrProjectID: number) {
        return this._newManager(files, modelOrProjectID,
            { regex: /\.dds$/i, loader: new DDSLoader() },
            { regex: /\.tga$/i, loader: new TGALoader() }
        ).pipe(
            switchMap(manager => {
                let mtlLoader = new MTLLoader(manager)

                // IMPORTANT NOTE: Not handling FileReferences with duplicate filenames (including their extensions, of course)
                const objFiles = files.filter(f => f.extension == '.obj')
                const mtlFiles = files.filter(f => f.extension == '.mtl')
                let objMtlPairs: { obj: FileReference, mtl: FileReference }[] = []

                mtlFiles.forEach(mtl => {
                    let obj = objFiles.find(obj => stripObjMtlExt(obj.filename) == stripObjMtlExt(mtl.filename))
                    if (obj) objMtlPairs.push({ obj: obj, mtl: mtl })
                })

                return new Observable<THREE.Group>((obs) => {
                    if (objMtlPairs.length == 0) {
                        obs.complete()
                        return
                    }

                    // Load mtls WITH objs
                    for (let i = 0; i < objMtlPairs.length; i++) {
                        let obj = objMtlPairs[i].obj
                        let mtl = objMtlPairs[i].mtl

                        mtlLoader.load(mtl.filename, (materials) => {
                            materials.preload()
                            new OBJLoader(manager).setMaterials(materials).load(obj.filename, (group) => {
                                group.name = obj.filename
                                obs.next(group)
                                if (i == objMtlPairs.length - 1) obs.complete()
                            }, () => { }, (err) => { obs.error(err) })
                        }, () => { }, (err) => { obs.error(err) })
                    }
                })
            }),
            catchError((err) => {
                this._toastService.toast({ title: 'Error', message: 'Model missing file: ' + err, color: 'red' })
                return EMPTY
            })
        )
    }

    /**
     * Given some FileReferences, for each .gltf or .glb file, load the files and emit the groups.
     * @returns An Observable which emits a THREE.Group each time a file is loaded
     * and completes when there are no more files to load.
     * */
    public gltfLoader$(files: FileReference[], modelOrProjectID: number) {
        return this._newManager(files, modelOrProjectID).pipe(
            switchMap(manager => {
                const gltfLoader: GLTFLoader = new GLTFLoader(manager).setDRACOLoader(this._dracoLoader)
                const gltfFiles = files.filter(({ extension: ext }) => ext == '.gltf' || ext == '.glb')

                return new Observable<THREE.Group>((obs) => {
                    if (gltfFiles.length == 0) {
                        obs.complete()
                        return
                    }

                    for (let i = 0; i < gltfFiles.length; i++) {
                        gltfLoader.load(gltfFiles[i].filename, (gltfOrGLB) => {
                            let parent = new THREE.Group()
                            gltfOrGLB.scenes.forEach(scene => parent.add(scene))
                            parent.name = gltfFiles[i].filename
                            obs.next(parent)
                            if (i == gltfFiles.length - 1) obs.complete()
                        }, () => { }, (err) => { obs.error(err) })
                    }
                })
            }),
            catchError((err) => {
                this._toastService.toast({ title: 'Error', message: 'Model missing file: ' + err, color: 'red' })
                return EMPTY
            })
        )
    }

    /**
     * A manager takes a loaders request to a file and formats it as a call to our API.
     * @param handlers We are not sure we even need handlers.
     * We do not know how they work. They came from an example long ago.
     */
    private _newManager(fileReferences: FileReference[], modelOrProjectID: number, ...handlers: { regex: RegExp, loader: THREE.Loader }[]) {
        return this._authenticationService.getToken().pipe(
            map(token => {
                const manager = new THREE.LoadingManager().setURLModifier(filename => {
                    if (filename.startsWith('blob')) {
                        return filename
                    } else {
                        // Ensure, if an absolute path is asked for, only the file name is used
                        if (filename.indexOf("/") != -1) filename = filename.split("/").pop()
                        if (filename.indexOf("\\") != -1) filename = filename.split("\\").pop()
                    }
                    // Now, use the filename to find the file reference id
                    // IMPORTANT NOTE: Assuming all of the filenames are unique for this model
                    const fileReference = fileReferences.find(fileReference => fileReference.filename == filename)

                    if (!fileReference) throw filename

                    const { id: fileReferenceID, hash } = fileReference

                    return this._fileReferenceService.getUrlForFileDownload(fileReferenceID, modelOrProjectID, hash, token)
                })

                handlers.forEach(handler => {
                    handler.loader.manager = manager
                    manager.addHandler(handler.regex, handler.loader)
                })

                return manager
            })
        )
    }
}