<ng-container *ngIf="literalSubstrings; else number">
    <ng-container *ngFor="let substring of literalSubstrings; index as i;">
        <a (mouseenter)="hovering = i" (mouseleave)="hovering = undefined">
            <span [ngClass]="hovering == i ? 'interactive-text-hover' : 'interactive-text'"
                (click)="select(substring, i)">{{substring}}</span>
            <span *ngIf='i < literalSubstrings.length - 1 && literalSubstrings.length > 1' class="colon-text"> {{', '}}</span>
        </a>
    </ng-container>
</ng-container>
<ng-template #number>
    <a (mouseenter)="hovering = true" (mouseleave)="hovering = undefined">
        <span [ngClass]="hovering ? 'interactive-text-hover' : 'interactive-text'"
            (click)="select(literal)">{{literal}}</span>
    </a>
</ng-template>