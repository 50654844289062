import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService, ModuleType } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'shared-navbar-menu-mobile',
  templateUrl: './navbar-menu-mobile.component.html',
  styleUrls: ['./navbar-menu-mobile.component.css']
})
export class NavbarMenuMobileComponent {
  get currentModule() { return this._authenticationService.currentModule }
  get hasUserAdminAccess() { return this._authenticationService.hasUserAdminAccess }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get isLegacy() { return this._subscriptionService.isLegacy }
  get isBillingDashboard() { return this.currentModule == "billing" }
  get isModelManager() { return this.currentModule == "models" }
  get isAssetManager() { return this.currentModule == "assets" }
  get isOrganizationDashboard() { return this.currentModule == "organization" }
  get isProjectDashboard() { return this.currentModule == "projects" }

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
  ) { }

  setDashboard(dashboard: ModuleType) {
    this._router.navigate([`/${dashboard}`])
  }
}