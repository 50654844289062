<form [formGroup]="organizationForm">
    <div class="d-flex gap-2 flex-column">
        <div>
            <label for="orgName" class="stripe-label stripe-text">
                Organization Display Name
            </label>
            <input type="text" class="form-control stripe-input stripe-text"
                [ngClass]="{'is-valid': orgName.valid, 'is-invalid': orgName.invalid && orgName.touched }" id="orgName"
                formControlName="orgName" placeholder="Acme Inc.">
            <label for="orgName" class="m-1 stripe-label stripe-text text-muted" style="font-size: small;">
                User-friendly organization name to display.
            </label>
        </div>
        <div>
            <label for="orgID" class="stripe-label stripe-text">
                Unique Organization Identifier
            </label>
            <input type="text" class="form-control stripe-input stripe-text" min="3" max="50"
                [ngClass]="{'is-valid': orgID.valid, 'is-invalid': orgID.invalid && orgID.touched }" id="orgID"
                formControlName="orgID" placeholder="acme_inc_identifier">
            <label for="orgID" class="m-1 stripe-label stripe-text text-muted" style="font-size: small;">
                May contain lowercase alphabetical characters, numbers, underscores (_), and dashes
                (-). Can start with a number. Must be unique and between 3 and 50 characters.
            </label>
        </div>
    </div>
</form>