import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'create-interaction-html-field',
  templateUrl: './html-field.component.html',
  styleUrls: ['./html-field.component.css']
})
export class HtmlFieldComponent {
  @Input() formParent: UntypedFormGroup

  constructor() {}

}
