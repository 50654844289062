import { Modal } from 'bootstrap'
import { Subject, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { FeatureCustomField } from '@classes/Feature'
import { newRule, RuleGroup, RuleOperation, SearchableProperty } from '@classes/Rules'
import { FeatureService } from '@services/feature.service'
import { FilterService } from '@services/filter.service'
import { ModalBody } from '@services/modal.service'
import { SceneService } from '@services/scene.service'
import { getType } from '@utils/Objects'

/**
 * Container for building logic within a top-level RuleGroup. Updates the FilterService when the RuleGroup is changed.
 */
@Component({
  selector: 'shared-modal-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css'],
})
export class AdvancedSearchComponent implements ModalBody, OnChanges, OnDestroy {
  @Input() events: EventEmitter<'close' | 'submit'>
  @Input() modal: Modal

  private _cancelRequest$ = new Subject<void>()
  private _subscriptions: Subscription[] = []

  public activeRuleGroup: RuleGroup = { ruleObjects: [], operation: RuleOperation.AND }
  public createFilterForm: UntypedFormGroup
  public hiddenIfInapplicable
  public retrievingCustomFields = false
  public searchableProperties: SearchableProperty[]

  get resultsCount() { return this.filterService.results.length }
  get ruleObjects() { return this.activeRuleGroup.ruleObjects }

  constructor(public featureService: FeatureService, public filterService: FilterService, private sceneService: SceneService) {
    this.retrievingCustomFields = true

    this.sceneService.getScenesCustomFields(this.sceneService.selectedScene, this._cancelRequest$)
      .subscribe(customFields => {
        const features = this.featureService.features
        const customFieldFeatureMap = new Map()

        for (const customField of customFields) {
          if (!customFieldFeatureMap.has(customField.featureID)) {
            customFieldFeatureMap.set(customField.featureID, [customField])
          } else {
            customFieldFeatureMap.get(customField.featureID).push(customField)
          }
        }

        for (const feature of features) {
          if (customFieldFeatureMap.get(feature.id)) feature.customFields = customFieldFeatureMap.get(feature.id)
          else if (feature.customFields) customFields.push(...feature.customFields)
        }

        this.featureService.updateFeaturesLocally(...features)
        this.createSearchablePropertyList(customFields)
        this.retrievingCustomFields = false
      })

    this.activeRuleGroup = this.filterService.rootFilter
    this.hiddenIfInapplicable = this.filterService.hiddenIfInapplicable
  }

  ngOnChanges() {
    this._subscriptions.push(
      this.events.pipe(filter(event => event == 'submit')).subscribe(() => this.submit())
    )
  }

  cancel() {
    this._cancelRequest$.next()
    this.events.emit('close')
  }

  submit(): boolean {
    if (this.activeRuleGroup.ruleObjects) {
      this.filterService.setRuleGroup(this.activeRuleGroup, { addToHistory: true, hiddenIfInapplicable: this.hiddenIfInapplicable })
    }
    return true
  }

  /** Adds a new blank rule to an empty top level RuleGroup */
  addRule() {
    this.activeRuleGroup.ruleObjects.push(newRule())
  }

  /**
   * Handles change events from the component's RuleGroup and its children. 
   * @param event 
   */
  updateRuleGroup(event) {
    this.activeRuleGroup = event.ruleGroup
  }

  /**
   * Generates list of searchable properties from name, description, and custom fields. 
   * Searchable properties can be selected and passed along with the Rule. 
   */
  createSearchablePropertyList(customFields: FeatureCustomField[]) {
    // Default searchable properties
    const nameProperty: SearchableProperty = { name: 'name', valueType: 'text' }
    const descriptionProperty: SearchableProperty = { name: 'description', valueType: 'text' }
    const parentGroupNameProperty: SearchableProperty = { name: 'parent group name', valueType: 'text' }

    let searchableProperties = [nameProperty, descriptionProperty, parentGroupNameProperty]

    // Add more searchable properties if the feature has customFields 
    customFields.forEach(customField => {
      if (customField.key.length > 0) {
        const searchableProperty = { name: customField.key, valueType: getType(customField.value) as 'boolean' | 'text' | 'number' }
        if (!searchableProperties.some(prop => prop.name == searchableProperty.name))
          searchableProperties.push(searchableProperty)
      }
    })

    this.searchableProperties = searchableProperties
  }

  ngOnDestroy(): void {
    this._cancelRequest$.next()
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}