import { Modal } from 'bootstrap'

import { Component } from '@angular/core'
import { ToastColor } from '@services/toast.service'

@Component({
  selector: 'shared-type-to-confirm',
  templateUrl: './type-to-confirm.component.html',
  styleUrls: ['./type-to-confirm.component.css']
})
export class TypeToConfirmComponent {
  public cancelText = 'Cancel'
  public confirmWord: string = 'DELETE'
  public message = 'Modal Message'
  public modal: Modal
  public submitColor: ToastColor = 'green'
  public submitText = 'Submit'
  public title = 'Modal Title'
  public typedWord: string = ''

  public onCancel: CallableFunction
  public onSubmit: CallableFunction

  constructor() { }

  _cancel() {
    if (this.onCancel) {
      this.onCancel()
    }

    this.modal?.hide()
  }

  _submit() {
    if (this.typedWord == this.confirmWord) {
      if (this.onSubmit) {
        this.onSubmit()
      }

      this.modal?.hide()
    }
  }
}