<div class="row gap-2">
    <label class="fs-4 fw-bold">Addons</label>

    <div *ngFor="let addon of addons" class="col-sm-auto col-md-4 mb-4">
        <div class="card text-white bg-primary d-flex flex-column gap-1">
            <div class="card-body d-flex flex-column" [style.height]="'225px'">
                <h4 class="card-title">{{addon.product.name}}</h4>

                <p>
                    <strong>{{addon.unit_amount_decimal / 100 | currency}}/{{interval}}</strong>
                </p>

                <p class="card-text h-100" [title]="addon.product.description" style="overflow-y: auto">
                    {{addon.product.description}}
                </p>

                <ng-container *ngIf="items?.length > 0">
                    <a *ngIf="!hasAddon(addon.product)" class="ms-auto" [href]="mailtoLink(addon.product)"
                        target="_blank">
                        <button class="btn btn-light btn-sm mt-auto">Request Add-On</button>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</div>