import { Observable } from 'rxjs'
import { distinctUntilKeyChanged, filter } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { modelLoadMap, modelScenesLoaded$, sceneLoadMap, scenesLoaded$ } from '@classes/SceneManager'

import { ModelService, selectedModel$ } from './model.service'
import { SceneService, selectedScene$ } from './scene.service'
import { VirtualTourService } from './virtual-tour.service'

type BackdropOptions = {
  darken?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _backdropOptions: BackdropOptions = { darken: true }
  private _development: boolean
  private _loaded: boolean = false
  private _loading: Array<Promise<any> | Observable<any>> = []
  private _interval: any
  private _completeSignal: boolean = false
  public progress = 0

  get darken() { return this._backdropOptions?.darken }
  get loaded() { return this._development ?? this._loaded }
  get selectedModel() { return this._modelService.selectedModel }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _modelService: ModelService,
    private _sceneService: SceneService,
    private _virtualTourService: VirtualTourService
  ) {
    this._activatedRoute.queryParams.subscribe(
      params => this._development = JSON.parse(params.development ?? null)
    )

    selectedScene$.pipe(
      distinctUntilKeyChanged("id")
    ).subscribe(scene => {
      if (sceneLoadMap.has(scene.id)) {
        this.setLoaded(sceneLoadMap.get(scene.id).loaded)
      } else this.setLoaded(false)
    })

    modelScenesLoaded$.pipe(
      filter(modelLoad => modelLoad.model.id == this.selectedModel?.id)
    ).subscribe(modelLoad => this.setLoaded(modelLoad.loaded))

    selectedModel$.pipe(
      filter(a => a !== undefined)
    ).subscribe(model => {
      const modelLoad = modelLoadMap.get(model.id)
      if (modelLoad) this.setLoaded(modelLoad.loaded)
      else this.setLoaded(false)
    })

    scenesLoaded$.pipe(
      filter(load => load.sceneData.id == this._sceneService.selectedSceneID)
    ).subscribe(load => this.setLoaded(load.loaded))

    this._virtualTourService.texturesLoaded$.subscribe(loaded => {
      this.setLoaded(loaded, { darken: false })
    })
  }

  await(...asyncActions: Array<Promise<any> | Observable<any>>) {
    this.setLoaded(false)

    asyncActions.forEach(asyncAction => {
      if (asyncAction instanceof Observable) {
        var promise = asyncAction.toPromise()
      } else {
        var promise = asyncAction
      }

      this._loading.push(
        promise.finally(() => {
          const index = this._loading.indexOf(promise)

          this._loading.splice(index, 1)

          if (this._loading.length == 0) {
            this.setLoaded(true)
          }
        })
      )
    })
  }

  public setLoaded(loaded: boolean, options: BackdropOptions = {}) {
    options.darken = options.darken ?? true

    this._backdropOptions = options
    this._loaded = loaded
  }

  startProjectLoadingProgressBar(featuresCount: number) {
    const estimatedTimeMs = (featuresCount * 0.0058 + 0.9944) * 1000
    this.progress = 0
    this._completeSignal = false
    const progressIncrement = 100 / (estimatedTimeMs / 100) // Calculate increment to nearly complete in the given time

    this._interval = setInterval(() => {
      if (this.progress >= 90 && !this._completeSignal) {
        // Slow down as it nears completion without the signal
        this.progress += progressIncrement / 10 // Slow increment
      } else {
        this.progress += progressIncrement
      }
      if (this.progress > 95) this.progress = 95 // Ensure it caps before 100%
      if (this._completeSignal) this.progress += 5 // Final push to 100 on signal
      if (this.progress >= 100) {
        clearInterval(this._interval)
        this.progress = 100 // Cap at 100%
      }
    }, 100)
  }

  completeProjectLoadingProgress() {
    this._completeSignal = true
  }
}