import { CacheLocation } from "@auth0/auth0-spa-js";

export const environment = {
    production: false,
    api: 'https://dev.kartorium.app/api/v1',
    logoutRedirect: 'https://dev.kartorium.app/projects',
    auth: {
        authorizationParams: {
            audience: "https://kartorium.app",
            redirect_uri: window.location.origin,
        },
        domain: "kartorium-dev.us.auth0.com",
        clientId: "GePu7RXfPWzwq9rk79oTDuzRyokIZCzY",
        roles: {
            'account manager': 'rol_ar5bI4gWBr2prkem',
            'editor': 'rol_Hu9XvHNpuXyZcnz5',
            'read only': 'rol_52WjOGlJrztGaytm',
            'super user': 'rol_O59Qp1Dz85USnevv',
            'supervisor': 'rol_VpMAa1813EkHVKIi',
            'user manager': 'rol_r5CciDH5il5SYYhU'
        },
        cacheLocation: 'localstorage' as CacheLocation
    },

    mapboxAccessToken: 'pk.eyJ1IjoianJieWFtIiwiYSI6ImNraGM5YnZuZzAwb2IycHFyZjRxbm9oazMifQ.fVb5WvHq5g63luEPpPvtjw',
    stripePublishableKey: 'pk_test_51L9C22EDkjKP1V8EOMr7AxTEqm0YLwoJOXdV3VExBRlz773FSnEIbdnQ16Mln9tP6tZwmbM5OgGhMVY0PnbFtdEm00l1P5JQ0e'
}