<!-- Expand/Fold Carets -->
<a *ngIf='isExpanded' (click)="isExpanded = false" class="me-1 caret"><i class="fa-solid fa-caret-down"></i></a>
<a *ngIf='!isExpanded' (click)="isExpanded = true" class="me-1 caret"><i class="fa-solid fa-caret-right"></i></a>

<!-- Beginning of the object: '{' or '[' -->
<span class="pe-2" [ngClass]='array ? "square-bracket-text": "curly-bracket-text"'>{{array ? '[': '{'}}</span>

<!-- If expanded, show content; otherwise show '...' -->
<ng-container *ngIf="isExpanded; else collapse">
    <!-- IF ARRAY -->
    <ng-container *ngIf="array; else isObject">
        <div class="ms-2">
            <!-- Since Array, no property name, just values -->
            <div *ngFor="let item of array; index as i;" [ngSwitch]="item | typeof">
                <ng-container *ngSwitchCase="'array'">
                    <!-- Array Index -->
                    <a (click)="picked(i, item, (isLastItem(i, array) ? -1 : i) + '')" class="mx-2" type="button"
                        style="width: 28px;">
                        {{i}}
                    </a>

                    <shared-json-object [json]='json' [array]="item" [isExpanded]="i == 0"
                        (pick)="picked($event.key, $event.value, (isLastItem(i, array) ? -1 : i) + $event.path)">
                    </shared-json-object>
                    <span *ngIf='i != array.length - 1'>{{','}}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'object'">
                    {{i + 1}}
                    <shared-json-object [json]="item" [isExpanded]="i == 0 || (json | equals : item)"
                        (pick)="picked($event.key, $event.value, (isLastItem(i, array) ? -1 : i) + '/' + $event.path)">
                    </shared-json-object>
                    <span *ngIf='i != array.length - 1'>{{','}}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <!-- Array Index -->
                    <a (click)="picked(i, item, (isLastItem(i, array) ? -1 : i))" class="mx-2" type="button"
                        style="width: 28px;">
                        {{i}}
                    </a>

                    <shared-json-literal [literal]="item"
                        (pick)='picked($event.key, $event.value, (isLastItem(i, array) ? -1 : i))'>
                    </shared-json-literal>
                    <span class='value-text' *ngIf='i < array.length - 1'>{{','}}</span>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <!-- IF OBJECT -->
    <ng-template #isObject>
        <div class="ms-2">
            <div *ngFor="let prop of properties; index as i;" [ngSwitch]="prop.value | typeof">
                <!-- Pickable Property Name -->
                <a (mouseenter)="hovering = i" (mouseleave)="hovering = undefined">
                    <span [ngClass]="hovering == i ? 'property-text-hover' : 'property-text'"
                        (click)="select(prop)">{{prop.propertyName}}</span>
                    <span class="me-2 colon-text"> {{':'}}</span>
                </a>
                <!-- Property Values -->
                <ng-container *ngSwitchCase="'array'">
                    <shared-json-object [array]="prop.value" [isExpanded]="i == 0"
                        (pick)='picked($event.key, $event.value, prop.propertyName + "/" + $event.path)'>
                    </shared-json-object>
                </ng-container>

                <ng-container *ngSwitchCase="'object'">
                    <shared-json-object [json]="prop.value" [isExpanded]="i == 0"
                        (pick)='picked($event.key, $event.value, prop.propertyName + "/" + $event.path)'>
                    </shared-json-object>
                    <span *ngIf='i != properties.length - 1' class="value-text">{{','}}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <shared-json-literal [literal]="prop.value"
                        (pick)='picked($event.key, $event.value, prop.propertyName + "/" + $event.path)'>
                    </shared-json-literal>
                </ng-container>
            </div>
            <!-- Just a literal -->
            <div *ngIf="properties.length == 0">
                {{json}}
            </div>
        </div>
    </ng-template>
</ng-container>

<ng-template #collapse class="value-text">{{'...'}}</ng-template>

<!-- End of the object: '}' or ']' -->
<span class="ps-2" [ngClass]='array ? "square-bracket-text": "curly-bracket-text"'>{{array ? ']': '}'}}</span>