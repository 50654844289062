import { Modal } from 'bootstrap'

import { ComponentRef, EventEmitter, Injectable, Type, ViewContainerRef } from '@angular/core'
import { ModalComponent } from '@modal/modal/modal.component'

export interface ModalBody {
  events: EventEmitter<'close' | 'submit'>
  modal: Modal
}

export type ModalOptions = {
  title: string,
  bodyInputs?: Object,
  closeOnSubmit?: boolean
  closeText?: string
  destroyOnClose?: boolean
  showDetails?: boolean
  size?: string,
  submitBtnColor?: 'green' | 'red'
  submitText?: string,
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _appViewContainer: ViewContainerRef // Important Note: Must be set by AppComponent
  private _modalInstances: Map<string, ModalComponent> = new Map<string, ModalComponent>()

  constructor() { }

  initialize(container: ViewContainerRef) {
    this._appViewContainer = container
  }

  /** Inserts the Component as the body of a Modal with a configurable header and footer */
  insertIntoModal(componentBody: any, options: ModalOptions) {
    const name = componentBody.name

    if (!this._modalInstances.has(name)) {
      const modalComponentRef = this._appViewContainer.createComponent<ModalComponent>(ModalComponent)
      var modalComponent = modalComponentRef.instance

      // Configure the modal with the options passed in
      modalComponent.componentBody = componentBody
      modalComponent.modal = new Modal(modalComponent.elementRef.nativeElement.children[0], { focus: false })
      modalComponent.options = options
      options.closeOnSubmit = options.closeOnSubmit ?? true

      this._modalInstances.set(name, modalComponent)

      modalComponent.modal.show()

      const subscription = modalComponent.events.subscribe(event => {
        if (event != 'submit' || options.closeOnSubmit) {
          modalComponent.modal.hide()

          if (event == 'submit' || (event == 'close' && options.destroyOnClose)) {
            this._modalInstances.delete(name)

            subscription.unsubscribe()

            setTimeout(() => modalComponentRef.destroy())
          }
        }
      })
    } else {
      var modalComponent = this._modalInstances.get(name)

      modalComponent.modal.show()
    }

    return modalComponent
  }

  /** Shows the components view in a Modal */
  showAsModal<T>(component: Type<T>, modalOptions?: { focus?: boolean, backdrop?: boolean, destroyOnClose?: boolean }) {
    const componentRef = this._appViewContainer.createComponent(component)
    const [modalElement] = componentRef.location.nativeElement.children
    const modal = new Modal(modalElement, { focus: modalOptions?.focus ?? true, backdrop: modalOptions?.backdrop ?? true })

    //@ts-ignore
    componentRef.instance.modal = modal

    if (modalOptions?.destroyOnClose) {
      modalElement.addEventListener('hidden.bs.modal', () => componentRef.destroy())
    }

    modal.show()

    return new Promise<ComponentRef<T>>(
      (resolve) => setTimeout(() => resolve(componentRef))
    )
  }
}