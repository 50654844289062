import { Organization } from 'auth0'
import { filter, switchMap, tap } from 'rxjs/operators'

import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@services/authentication.service'
import { SharedModule } from '@shared/shared.module'

@Component({
  imports: [CommonModule, SharedModule],
  selector: 'shared-login',
  standalone: true,
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public defaultLogo = 'logo.png'
  public hasPersonalAccount = false
  public organizations: Organization[]
  public search = ''
  public state: 'loading' | 'selectAccount' = 'loading'
  get currentUser$() { return this._authenticationService.user$ }

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
  ) {
    this._authenticationService.isAuthenticated$.pipe(
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.navigateToAuthLogin()
        }
      }),
      filter(isAuthenticated => isAuthenticated),
      switchMap(() => this._authenticationService.getUserAccounts()),
    ).subscribe(({ organizations, hasFreeTrial }) => {
      this.hasPersonalAccount = hasFreeTrial
      this.organizations = organizations.sort((a, b) => a.display_name.localeCompare(b.display_name))

      if (this.organizations.length == 1 && !this.hasPersonalAccount) {
        this.accountLogin(this.organizations[0])
      } else if (this.organizations.length > 0) {
        this.state = 'selectAccount'
      } else if (this.hasPersonalAccount) {
        this.accountLogin()
      } else {
        this.navigateToSignup()
      }
    })
  }

  /** Login to personal or organization account */
  accountLogin(organization?: Organization) {
    const orgID = organization?.id
    const storedOrigin = sessionStorage.getItem('authRedirectOrigin')
    const target = storedOrigin ? storedOrigin : 'projects'
    sessionStorage.removeItem('authRedirectOrigin')

    sessionStorage.setItem('redirectDashboard', 'true')
    this._authenticationService.loginWithRedirect({ appState: { target }, authorizationParams: { organization: orgID } })
  }

  navigateToAuthLogin() {
    this._authenticationService.loginWithRedirect({ appState: { target: 'login' }, authorizationParams: { screen_hint: 'login' } })
  }

  navigateToSignup(): void {
    this._router.navigate(['signup'])
  }
}