<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="close()"></a>
            </div>

            <form class="container modal-body p-4 d-grid gap-3" [formGroup]="form">
                <div>
                    <label for="name" class="form-label">Name *</label>
                    <input id="name" formControlName="name" class="form-control" maxlength="255"
                        [ngClass]="{'is-valid': name.valid, 'is-invalid': name.invalid && name.touched }">
                </div>

                <div>
                    <label class="form-label">Description</label>
                    <textarea formControlName="description" class="form-control"></textarea>
                </div>

                <div class="d-flex flex-column gap-1" formArrayName="fields">
                    <div class="form-label">Fields *</div>

                    <div *ngFor="let field of formFields.controls; index as fieldIndex" [formGroupName]="fieldIndex"
                        class="accordion-item border rounded container d-flex flex-column gap-2 p-2">
                        <div class="accordion-header d-flex gap-2" #fieldHeaderButton type="button">
                            <a>
                                <i class="fa-solid" [ngClass]="{
                                        'fa-caret-up': fieldBody.classList.contains('show'),
                                        'fa-caret-down': !fieldBody.classList.contains('show')
                                    }">
                                </i>
                            </a>

                            <label class="form-label collapsed" type="button">
                                Field {{fieldIndex + 1}}
                                {{field.value.name ? ' - ' + field.value.name : ''}}
                            </label>

                            <!-- Remove Field -->
                            <a *ngIf="formFields.controls.length > 1" class="btn btn-close btn-sm ms-auto"
                                (click)="removeField(fieldIndex)"></a>
                        </div>

                        <div class="accordion-collapse collapse" #fieldBody
                            [makeCollapsible]="{button: fieldHeaderButton, shown: true}">
                            <div class="d-grid gap-2">
                                <div class="row g-3">
                                    <!-- Name -->
                                    <div class="col-md-6">
                                        <label class="form-label">Name *</label>
                                        <input formControlName="name" class="form-control" maxlength="255" [ngClass]="{
                                            'is-valid': field.get('name').valid, 
                                            'is-invalid': field.get('name').invalid && field.get('name').touched
                                        }">
                                    </div>

                                    <!-- Description -->
                                    <div class="col-md-6">
                                        <label class="form-label">Description</label>
                                        <input formControlName="description" class="form-control">
                                    </div>
                                </div>

                                <!-- Type -->
                                <div>
                                    <label for="type" class="form-label">Input Type *</label>

                                    <select formControlName="type" class="form-select">
                                        <option value="text">Text</option>
                                        <option value="number">Number</option>
                                        <option value="trueFalse">True/False</option>
                                        <option value="date">Date</option>
                                        <option value="location">Location</option>
                                        <option value="files">File(s)</option>
                                        <option value="images">Image(s)</option>
                                        <option value="chooseOne">Choose One</option>
                                        <option value="chooseMultiple">Choose Multiple</option>
                                    </select>
                                </div>

                                <!-- Choose One/Multiple Options -->
                                <div class="accordion-item"
                                    *ngIf="field.value.type == 'chooseOne' || field.value.type == 'chooseMultiple'">
                                    <div class="accordion-header d-flex gap-2" #optionsButton type="button">
                                        <a>
                                            <i class="fa-solid" [ngClass]="{
                                                'fa-caret-up': optionsDropdown.classList.contains('show'),
                                                'fa-caret-down': !optionsDropdown.classList.contains('show')
                                            }"></i>
                                        </a>
                                        <label class="form-label collapsed" type="button">
                                            Options *
                                        </label>
                                    </div>

                                    <div class="accordion-collapse collapse" #optionsDropdown
                                        [makeCollapsible]="{button: optionsButton, shown: true}">
                                        <div class="accordion-body d-flex flex-column gap-1 pt-0">
                                            <div *ngFor="let option of field.get('dropdownOptions').controls; index as dropdownIndex"
                                                class="input-group d-flex">
                                                <span class="input-group-text">{{dropdownIndex + 1}}</span>
                                                <input type="text" [formControl]="option" class="form-control">
                                                <button *ngIf="field.value.dropdownOptions.length > 2"
                                                    class='btn btn-secondary btn-sm'
                                                    (click)="removeDropdownOption(fieldIndex, dropdownIndex)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>

                                            <button (click)="addDropdownOption(fieldIndex)"
                                                class="btn btn-sm btn-success w-25 align-self-center">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Default Values for each type -->
                                <div *ngIf="field.value.type != 'files' && field.value.type != 'images'">
                                    <label class="form-label">Default Value</label>

                                    <!-- Text Type  -->
                                    <input *ngIf="field.value.type == 'text'" [formControl]="field.get('defaultValue')"
                                        class="form-control">

                                    <!-- Number Type  -->
                                    <input *ngIf="field.value.type == 'number'"
                                        [formControl]="field.get('defaultValue')" class="form-control" type="number">

                                    <!-- True False Type  -->
                                    <select *ngIf="field.value.type == 'trueFalse'"
                                        [formControl]="field.get('defaultValue')" class="form-select">
                                        <option>True</option>
                                        <option>False</option>
                                    </select>

                                    <!-- Date Type  -->
                                    <input *ngIf="field.value.type == 'date'" [formControl]="field.get('defaultValue')"
                                        class="form-control" type="date">

                                    <!-- Location Type  -->
                                    <div *ngIf="field.value.type == 'location'" class="row g-3">
                                        <shared-mini-map
                                            [center]="{lng: '-94.22473927523083', lat: '37.68426163908313'}" [zoom]="3"
                                            [markerLocations]="[{ location: {lng: field.get('longitude').value, lat: field.get('latitude').value} }]"
                                            (mapClick)="handleMapClick($event, field)">
                                        </shared-mini-map>
                                    </div>

                                    <!-- Choose One Type -->
                                    <select *ngIf="field.value.type == 'chooseOne'"
                                        [formControl]="field.get('defaultValue')" class="form-select">
                                        <option *ngFor='let option of field.value.dropdownOptions'>
                                            {{option}}
                                        </option>
                                    </select>

                                    <!-- Choose Multiple Type -->
                                    <select *ngIf="field.value.type == 'chooseMultiple'"
                                        [formControl]="field.get('defaultValue')" class="form-select" multiple>
                                        <option *ngFor='let option of field.value.dropdownOptions'>
                                            {{option}}
                                        </option>
                                    </select>
                                </div>

                                <!-- Is Required Switch -->
                                <div class="d-flex align-items-center gap-2">
                                    <label class="form-label m-0" [for]="'requiredCheckbox' + fieldIndex"
                                        type="button">Required</label>
                                    <div class="form-check form-switch d-flex align-items-center">
                                        <input [formControl]="field.get('required')"
                                            [id]="'requiredCheckbox' + fieldIndex" class="form-check-input"
                                            type="checkbox" style="cursor: pointer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end mt-2">
                    <button class='btn btn-primary' (click)="addField()">+ Add Field</button>
                </div>
            </form>

            <div class="modal-footer">
                <button [disabled]="isSubmitting" type="button" class="btn" [ngClass]="{
                    'btn-primary': submitColor == 'blue' || submitColor == null,
                    'btn-success': submitColor == 'green',
                    'btn-danger': submitColor == 'red',
                    'btn-warning': submitColor == 'yellow'
                }" (click)="submit()">{{submitText}}</button>
                <button type="button" class="btn btn-secondary" (click)="close()">{{ cancelText }}</button>
            </div>
        </div>
    </div>
</div>