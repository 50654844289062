<div class="main" style="overflow-y: auto;">
    <div *ngIf="state == 'collectInfo'" class="d-flex justify-content-end p-4">
        <img *ngIf="currentUser$ | async" [src]="(currentUser$ | async)?.picture"
            class="border rounded-circle thumbnail" role="button" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop" width="50px">
    </div>
    <div class="body d-flex flex-column align-items-center gap-4">
        <ng-container *ngIf="state == 'collectInfo'">
            <billing-select-tier #selectTier [selectable]="['free', 'pro']" [selected]="'free'"
                (selectTier)="chooseTier($event)"></billing-select-tier>

            <billing-create-organization #createOrg [@unfoldVertical]="selectedTier == 'pro' ? 'visible' : 'hidden'"
                class="px-1">
            </billing-create-organization>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" [disabled]="state == 'submitting'"
                    (click)="proceedToPayment()">
                    {{selectedTier == 'free' ? 'Start Free Trial' : 'Proceed to Payment'}}
                </button>
            </div>
        </ng-container>

        <!-- Submitting -->
        <h1 *ngIf="state == 'submitting' && selectedTier == 'free'" class="fw-bold text-secondary fs-1">
            Submitting your Information...
        </h1>
        <h1 *ngIf="state == 'submitting' && selectedTier != 'free'" class="fw-bold text-secondary fs-1">
            Submitting Payment Information...
        </h1>

        <!-- Success -->
        <ng-container *ngIf="state == 'success'">
            <billing-view-invoice [invoice]="invoice" [title]="'Registration Successful'"
                [subtitle]="'Welcome to Kartorium!'"></billing-view-invoice>

            <div>
                <button class="btn text-white scheme-bg-dark rounded-1 py-2 w-100" (click)="goToDashboard()">
                    Go to Dashboard
                </button>
            </div>
        </ng-container>

        <!-- Failed -->
        <div *ngIf="state == 'failed'"
            class="d-flex flex-column align-items-center bg-light rounded-1 shadow gap-3 p-5">
            <span class="text-danger">
                {{error}}
            </span>
            <span class="text-dark">
                There was a problem during signup. If this issue keeps happening, please contact us at
                <a href="mailto:support@kartorium.com">support@kartorium.com</a>
            </span>
        </div>
    </div>
</div>

<!-- Right panel -->
<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="right-panel"
    aria-labelledby="right-panel-label">
    <div class="offcanvas-header">
        <h1 class="offcanvas-title" id="right-panel-label">Confirm Details</h1>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
        </button>
    </div>

    <div class="offcanvas-body d-flex flex-column">
        <billing-view-products [products]="selectedProducts" [priceMultiplier]="priceMultiplier">
        </billing-view-products>

        <!-- Checkout button -->
        <div class="d-flex justify-content-center ">
            <button #submitButton (click)="finishSignup()" class="btn btn-success" type="button"
                data-bs-dismiss="offcanvas" aria-label="Close">
                Finish Signup
            </button>
        </div>
    </div>
</div>