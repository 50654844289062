<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <div class="ms-auto d-flex gap-1">
                    <shared-beta-pill [size]="8"></shared-beta-pill>
                    <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
                </div>
            </div>

            <div class="modal-body">
                <shared-edit-interactions></shared-edit-interactions>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="_cancel()">Close</button>
            </div>
        </div>
    </div>
</div>