export type ActionOptions = {
    id?: number,
    interactionID?: number,
    previousID?: number,
    nextID?: number
}

export type ActionKeys =
    'move'
    | 'rotate'
    | 'scale'
    | 'focus'
    | 'moveTo'
    | 'hide'
    | 'show'
    | 'toggleHidden'
    | 'showDetails'
    | 'openScene'
    | 'openTab'
    | 'disableInteractions'
    | 'enableInteractions'
    | 'toggleInteractions'
    | 'loadOut'
    | 'loadIn'
    | 'toggleLoad'

export class Action {
    public id: number
    public interactionID: number
    public previousID: number
    public nextID: number
    public key: ActionKeys
    public value: any

    constructor(key: ActionKeys, value: any, options?: ActionOptions) {
        this.id = options && options.id ? +options.id : null
        this.previousID = options ? options.previousID : null
        this.nextID = options ? options.nextID : null
        this.interactionID = options && options.interactionID ? +options.interactionID : null
        this.key = key
        try {
            this.value = JSON.parse(value)
        } catch (e) {
            this.value = value ?? {}
        }
    }

    public equals(action: Action) {
        if (action === undefined) return false
        if (this.id != action.id &&
            this.interactionID != action.interactionID &&
            this.nextID != action.nextID &&
            this.key != action.key &&
            this.value != action.value) return false
        return true
    }
}