<ng-container *ngIf="canUseActions">
    <div class='text-center bg-white border-top'>
        <label class='form-label text-dark mt-2' for='right-sidepanel-btns'>
            {{rightSidebarService.tab}} Actions
        </label>
        <div id='right-sidepanel-btns' class="btn-group btn-group-sm d-flex mb-2 ms-2 me-2 bg-light" role="group">
            <button *ngIf="canCopy" type="button" class="btn btn-outline-success flex-fill" (click)="duplicate()">
                <i class="me-1 fa fa-plus fa-fw"></i>
                Duplicate
            </button>
            <button *ngIf="canCopy" type="button" class="btn btn-outline-secondary flex-fill" (click)="copy()">
                <i class="me-1 fa fa-copy fa-fw"></i>
                Copy
            </button>
            <button *ngIf="canDelete && this.rightSidebarService.tab != 'Scene'" type="button" class="btn btn-outline-danger flex-fill" (click)="delete()">
                <i class="me-1 fa fa-trash fa-fw"></i>
                Delete
            </button>
        </div>
        <div id='right-sidepanel-btns' class="btn-group btn-group-sm d-flex mb-2 ms-2 me-2 bg-light" role="group">
            <button *ngIf="canDelete && this.rightSidebarService.tab == 'Scene'" type="button" class="btn btn-outline-danger flex-fill" (click)="delete()">
                <i class="me-1 fa fa-trash fa-fw"></i>
                Delete
            </button>
        </div>
    </div>
</ng-container>