import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Scene } from '@classes/Scene'
import { SceneProperty } from '@classes/SceneProperty'
import { SceneService } from '@services/scene.service'
import { CreateSceneBody } from '@shared/create-scene/create-scene.component'

@Component({
  selector: 'shared-create-three-sixty-scene',
  templateUrl: './create-three-sixty-scene.component.html',
  styleUrls: ['./create-three-sixty-scene.component.css']
})
export class CreateThreeSixtySceneComponent implements CreateSceneBody {
  public form = new FormGroup({
    name: new FormControl<string>('New 360 Image Scene', [Validators.required]),
    image: new FormControl<File>(null, [Validators.required]),
  })

  get image() { return this.form.get('image') as FormControl<File> }
  get name() { return this.form.get('name') as FormControl<string> }

  constructor(private _sceneService: SceneService) { }

  createScene$(projectID: number) {
    const scene = this.getScene(projectID)

    return this._sceneService.create360Scene$(scene, this.image.value)
  }

  getScene(projectID?: number) {
    return new Scene(projectID, this.name.value, '360 Image')
  }

  setImage(file: File) {
    this.image.setValue(file)
    this.image.markAsTouched()
  }
}