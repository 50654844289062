import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], sortBy: string): any[] {
    /** Sorts items by their names and takes into account numbers in their numbers */
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    return array?.sort((a, b) => collator.compare(a[sortBy], b[sortBy]))
  }
}