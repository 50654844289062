import * as THREE from 'three'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'

export type CSSObjectPosition = "top" | "bottom" | "left" | "right" | "center" | "none"

export type CSSObjectOptions = {
  element?: HTMLElement
  className?: string
  content?: string
  visible?: boolean
  offsetPosition?: CSSObjectPosition
  vectorPosition?: THREE.Vector3
}

export class CSSObject {
  private _htmlElement: HTMLElement
  private _position: CSSObjectPosition
  private _cssObject: CSS2DObject
  private _attached: boolean = false

  set visible(show: boolean) { this._htmlElement.hidden = !show }
  set content(content: string) {
    let children = this._htmlElement.children
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      if (element.id == 'content') {
        element.textContent = content
        return
      }
    }
    this._htmlElement.textContent = content
  }


  /** IMPORTANT NOTE: left and right currently not supported */
  set position(position: CSSObjectPosition) {
    this._position = position
    this._recalculateCSSObjectPosition()
  }

  set vectorPosition(position: THREE.Vector3) {
    this._cssObject.position.copy(position)
    if (!this._attached) {
      this.group.attach(this._cssObject)
      this._attached = true
    }
  }

  defaultElement(): HTMLElement {
    let defaultElement = document.createElement('div')
    let innerElement = document.createElement('div')
    innerElement.id = 'content'
    defaultElement.appendChild(innerElement)
    return defaultElement
  }

  constructor(
    public group: THREE.Group | THREE.Mesh,
    public options: CSSObjectOptions,
  ) {
    this.group = group
    this._htmlElement = options && options.element ? options.element : this.defaultElement()
    this._htmlElement.className = options && options.className ? options.className : '';
    this.content = options && options.content ? options.content : '';
    this.visible = options && options.visible ? options.visible : false
    this._cssObject = new CSS2DObject(this._htmlElement)
    if (options && options.vectorPosition) this.vectorPosition = options.vectorPosition
    else this.position = options && options.offsetPosition ? options.offsetPosition : 'top'
  }

  private _recalculateCSSObjectPosition() {
    // Remove cssObject so that it is not taken into acount when getting the bounding box
    this.group.remove(this._cssObject)

    const featureBox = new THREE.Box3()
    featureBox.setFromObject(this.group)
    const featureSize = featureBox.getSize(new THREE.Vector3())

    const newPosition = new THREE.Vector3()

    // IMPORTANT NOTE: The pivot of a Feature is at the bottom center of the model, not the very center.
    // TODO: Find the actual center of the feature using some matrix math
    let groupWP = new THREE.Vector3()
    this.group.getWorldPosition(groupWP)
    if (this._position == 'top') newPosition.set(groupWP.x, groupWP.y + featureSize.y + 1, groupWP.z)
    else if (this._position == 'center') newPosition.set(groupWP.x, groupWP.y + (featureSize.y / 2), groupWP.z)
    else if (this._position == 'none') newPosition.set(groupWP.x, groupWP.y, groupWP.z)
    else if (this._position == 'bottom') newPosition.set(groupWP.x, groupWP.y - 1, groupWP.z)
    else {
      console.warn('CSSObject position', this._position, 'not supported. Defaulted to "top"')
      newPosition.set(0, this.group.position.y + featureSize.y + 1, 0)
    }
    /** 
     * TODO: 
     * We need a better solution for LEFT and RIGHT positions for CSSObjects; if we base a position 
     * entirely on an object's position and rotation, then LEFT and RIGHT are no longer accurate definitions of the cssObject's position 
     * if the camera is moved, or if the object's rotation is modified.
     */

    this._cssObject.position.copy(newPosition)
    this.group.attach(this._cssObject)
  }

  public dispose() {
    this.group.remove(this._cssObject)
    this._htmlElement.remove()
  }
}
