import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'shared-custom-fields-undo-redo-btns',
  templateUrl: './custom-fields-undo-redo-btns.component.html',
  styleUrls: ['./custom-fields-undo-redo-btns.component.css']
})
export class CustomFieldsUndoRedoBtnsComponent {
  @Output() undo = new EventEmitter<boolean>()
  @Output() redo = new EventEmitter<boolean>()
  @Input() undoStackSize: number = 0
  @Input() redoStackSize: number = 0

  get canUndo(): boolean { return this.undoStackSize > 0 }
  get canRedo(): boolean { return this.redoStackSize > 0 }

  constructor() { }

  emitUndo() {
    this.undo.emit(true)
  }

  emitRedo() {
    this.redo.emit(true)
  }
}