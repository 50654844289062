<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <div class="modal-body" style="overflow-y: auto;">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <span>Date</span>
                            </th>
                            <th>
                                <span>Description</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let entry of history; index as i;">
                            <td>{{entry.timestamp}}</td>
                            <td>{{entry.description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{cancelText}}</button>
            </div>
        </div>
    </div>
</div>