import { Subscription } from 'rxjs'
import { filter, startWith } from 'rxjs/operators'

import { Component, OnDestroy } from '@angular/core'
import { ConnectionProperty } from '@classes/Connection'
import { newRule, Rule, RuleGroup, SearchableProperty } from '@classes/Rules'
import { ConnectionService, selectedConnection$ } from '@services/connection.service'

/** Allows user to build a query to filter features/connections based on API responses. */
@Component({
  selector: 'shared-filter-connections',
  templateUrl: './filter-connections.component.html',
  styleUrls: ['./filter-connections.component.css']
})
export class FilterConnectionsComponent implements OnDestroy {
  private _subscriptions: Subscription[] = []
  public filterProperty: ConnectionProperty
  public ruleGroup
  public searchableProperties: SearchableProperty[]

  constructor(public connectionService: ConnectionService) {
    this._subscriptions.push(
      selectedConnection$.pipe(
        startWith(this.connectionService.selectedConnection),
        filter(conn => conn !== undefined),
      ).subscribe(cnxn => {
        this.filterProperty = cnxn.properties.find(cp => cp.key == 'filter')
        this.ruleGroup = JSON.parse(this.filterProperty.value) as RuleGroup
        this.createSearchablePropertyList()
      })
    )
  }

  updateRuleGroup(event) {
    this.filterProperty.value = JSON.stringify(event.ruleGroup)
    this.connectionService.updateConnectionProperty(this.filterProperty).subscribe()
  }

  addRule() {
    let rule: Rule = newRule()
    let filterObjects = this.ruleGroup.ruleObjects.slice()
    filterObjects.push(rule)
    this.ruleGroup.ruleObjects = filterObjects
    this.filterProperty.value = JSON.stringify(this.ruleGroup)
    this.connectionService.updateConnectionProperty(this.filterProperty).subscribe()
  }

  /** 
    * Generates list of searchable properties from name, description, and custom fields. 
    * Searchable properties can be selected and passed along with the Rule. 
   */
  createSearchablePropertyList() {
    // Default searchable properties
    const nameProperty: SearchableProperty = { name: 'name', valueType: 'text' }
    const descriptionProperty: SearchableProperty = { name: 'description', valueType: 'text' }

    let searchableProperties = [nameProperty, descriptionProperty]

    /** Add more searchable properties if the feature has customFields */
    this.connectionService.selectedConnection.properties.forEach(property => {
      if (property.type == 'customField' && property.key.length > 0) {
        let customFieldValue = JSON.parse(property.value)
        let valueType = customFieldValue.valueType == 'path' ? this.getValueTypeFromPath(customFieldValue.value) : customFieldValue.valueType

        const searchableProperty = { name: property.key, valueType: valueType }
        if (!searchableProperties.some(prop => prop.name == searchableProperty.name))
          searchableProperties.push(searchableProperty)
      }
    })

    this.searchableProperties = searchableProperties
  }

  /** Given an object, returns the type of object retrieved at the end of a path. If not found, returns 'text' */
  private getValueTypeFromPath(path: string): any {
    let pathArray = path?.split('/') ?? []
    let data = this.connectionService.connectionResponse.dataFromRoot
    let value
    if (data instanceof Array) value = data[0]
    else value = { ...data }
    try {
      for (let attr of pathArray) {
        value = value[attr]
      }
    } catch {
      return 'text'
    }
    if (value === undefined) return null
    if (typeof value == 'string') return 'text'
    return typeof value
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}