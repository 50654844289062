import { startWith } from 'rxjs/operators'

import { Component, Input } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { features$ } from '@services/feature.service'
import { scenes$, SceneService } from '@services/scene.service'

@Component({
  selector: 'create-interaction-id-picker',
  templateUrl: './id-picker.component.html',
  styleUrls: ['./id-picker.component.css']
})
export class IdPickerComponent {
  @Input() formParent: UntypedFormGroup
  @Input() isScene: boolean = false

  get scenes() { return this.sceneService.scenes }
  get selectedSceneID() { return this.sceneService.selectedSceneID }

  public scenes$ = scenes$.pipe(startWith(this.scenes))

  constructor(public sceneService: SceneService) { }
}