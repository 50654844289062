<div #panel class="offcanvas offcanvas-end right-sidepanel" tabindex="-1" id="offcanvas"
  aria-labelledby="offcanvasLabel">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title" id="offcanvasLabel">Edit Subscription Items</h2>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>

  <div class="offcanvas-body" style="overflow-y: hidden;">
    <billing-edit-subscription></billing-edit-subscription>
  </div>
</div>