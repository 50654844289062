<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="_cancel()"></a>
            </div>

            <div class="modal-body" style="overflow-y: auto;">
                <ng-container *ngIf="fileType == 'files'">
                    <div class="list-group list-group-flush">
                        <div *ngFor="let file of fileReferences; index as i"
                            class="d-flex align-items-center justify-content-center file-hover list-group-item" (click)="openFile(file)">
                            <span class="px-3 me-auto text-truncate ">
                                {{ file.filename }}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="fileType == 'images'">
                    <div class="d-flex gap-1 flex-wrap">
                        <div *ngFor='let img of fileReferences; index as i;' class="d-flex">
                            <img [id]='"img" + assetFieldValue.id.toString() + i.toString()'
                                [src]="fileURLMap[img?.id] | async" alt="" class="m-3 card" style="max-height: 100px; cursor:pointer;"
                                (click)="openImageInNewTab(img)">
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="_cancel()">{{cancelText}}</button>
            </div>
        </div>
    </div>
</div>