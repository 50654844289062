import { Subscription } from 'rxjs'
import { distinctUntilKeyChanged } from 'rxjs/operators'

import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { EnvironmentManagerService } from '@services/environment-manager.service'
import { FeatureService } from '@services/feature.service'
import { SceneService } from '@services/scene.service'
import { TooltipService } from '@services/tooltip.service'
import { VirtualTourService } from '@services/virtual-tour.service'

@Component({
  selector: 'shared-viewpoint-btn',
  templateUrl: './viewpoint-btn.component.html',
  styleUrls: ['./viewpoint-btn.component.css']
})
export class ViewpointBtnComponent implements AfterViewInit, OnDestroy {
  public subscription: Subscription
  get enabled(): boolean { return this.virtualTourService.viewpointMode }
  get nearestViewpoint() {
    const cameraPosition = this.envManager.modelSpace.camera.position.clone()
    const targetPosition = this.envManager.modelSpace.orbitControls.target.clone()

    const direction = targetPosition.sub(cameraPosition).normalize()
    const oneMeterDirection = direction.multiplyScalar(1) // 1 represents 1 meter
    const newPosition = cameraPosition.add(oneMeterDirection)

    return this.virtualTourService.getNearestViewPoint(newPosition)
  }
  get isVirtualTour() { return this.virtualTourService.isVirtualTour }

  constructor(
    public envManager: EnvironmentManagerService,
    public featureSerivce: FeatureService,
    public sceneService: SceneService,
    public tooltipService: TooltipService,
    public virtualTourService: VirtualTourService,
  ) {
    this.subscription = this.sceneService.selectedScene$
      .pipe(distinctUntilKeyChanged('id'))
      .subscribe(() => {
        if (this.enabled) this.virtualTourService.exitViewpoint(true)
      })
  }

  ngAfterViewInit(): void {
    this.tooltipService.intializeTooltips()
  }

  toggle() {
    if (this.virtualTourService.viewpointMode) this.virtualTourService.exitViewpoint()
    else this.virtualTourService.enterViewpoint(this.nearestViewpoint, { levelOutCamera: true })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}