<ul class="list-group list-group-flush bg-white position-absolute h-100 w-100 z-3">
    <li class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action"
        [ngClass]="{'fw-bold list-group-item-primary': isProjectDashboard}" (click)="setDashboard('projects')">
        <i class="fa-duotone fa-layer-group fa-xl text-center"></i>

        <span class="fs-3">Projects</span>
    </li>

    <li class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action"
        [ngClass]="{'fw-bold list-group-item-primary': isModelManager}" (click)="setDashboard('models')">
        <i class="fa-duotone fa-cubes fa-xl text-center"></i>

        <span class="fs-3">Models</span>
    </li>

    <li class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action"
        [ngClass]="{'fw-bold list-group-item-primary': isAssetManager, 'disabled': inFreeTier}"
        (click)="setDashboard('assets')">
        <i class="fa-duotone fa-list-check fa-xl text-center"></i>

        <span class="fs-3">
            Assets <i *ngIf="inFreeTier" class="ms-1 fa-solid fa-lock"></i>
        </span>
    </li>

    <li *ngIf='hasUserAdminAccess || inFreeTier'
        class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action"
        [ngClass]="{'fw-bold list-group-item-primary': isOrganizationDashboard, 'disabled': inFreeTier}"
        (click)="setDashboard('organization')">
        <i class="fa-duotone fa-users-gear fa-xl text-center"></i>

        <span class="fs-3">
            Organization <i *ngIf="inFreeTier" class="ms-1 fa-solid fa-lock"></i>
        </span>
    </li>

    <li *ngIf='(hasUserAdminAccess || inFreeTier) && !isLegacy'
        class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action"
        [ngClass]="{'fw-bold list-group-item-primary': isBillingDashboard}" (click)="setDashboard('billing')">
        <i class="fa-duotone fa-receipt fa-xl text-center"></i>

        <span class="fs-3">Billing</span>
    </li>

    <li class="list-group-item d-flex align-items-center py-3 gap-3 list-group-item-action" role="button"
        data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        <i class="fa-duotone fa-circle-user fa-xl text-center"></i>

        <span class="fs-3">Account</span>
    </li>
</ul>