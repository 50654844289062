import { EMPTY, merge, Observable } from 'rxjs'
import { distinctUntilChanged, distinctUntilKeyChanged, tap } from 'rxjs/operators'

import { Injectable } from '@angular/core'

import { Command, CreateCommand, UpdateCommand } from './command.service'
import { selectedModel$ } from './model.service'
import { SceneService, selectedScene$ } from './scene.service'
import { ToastService } from './toast.service'

@Injectable({
  providedIn: 'root'
})
export class UndoService {
  private _undoStack: Command[] = []
  private _redoStack: Command[] = []
  private _history: Command[] = []

  get undoLen(): number { return this._undoStack.length }
  get redoLen(): number { return this._redoStack.length }

  constructor(public sceneService: SceneService, public toastService: ToastService) {
    /** 
     * Clear the stack when the scene changes, a model is selected, 
     * or a model is deselected. 
     */
    merge(
      selectedScene$.pipe(distinctUntilKeyChanged('id')),
      selectedModel$.pipe(
        distinctUntilChanged((prev, curr) => prev?.id == curr?.id))
    ).subscribe(() => this.clear())
  }

  clear() {
    this._undoStack = []
    this._redoStack = []
  }

  stack(command: Command) {
    this._history.push(command)
    this._undoStack.push(command)
    this._redoStack = []
  }

  undo(): Observable<any> {
    if (this._undoStack.length == 0) return EMPTY
    let command = this._undoStack.pop()
    this._redoStack.push(command)

    // TODO: handle if (command instanceof DeleteCommand)

    return command.undo()
  }

  redo(): Observable<any> {
    if (this._redoStack.length == 0) return EMPTY
    const command = this._redoStack.pop()
    this._undoStack.push(command)

    if (command instanceof CreateCommand)
      return command.execute().pipe(
        tap(() => {
          this._redoStack
            .filter(c => c instanceof UpdateCommand && c.type == command.type) // || Delete
            .filter(c => c.id == command.previousID)
            .forEach(c => c.id = command.id) // Update the ID
        }))
    else return command.execute()
  }
}