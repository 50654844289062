<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true" id="add-modal">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h2 class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="cancel()"></a>
            </div>

            <!-- Modal Body -->
            <div *ngIf="assetPages" class="modal-body">
                <form *ngIf="!showSummaryPage" [formArray]="assetPages">
                    <div *ngFor="let page of assetPages.controls; index as i" class="d-grid gap-4" [formArray]="page">
                        <ng-container *ngIf="i == currentPageIndex">
                            <div *ngFor="let field of page.controls" [formGroup]="field" class="d-grid gap-2">
                                <span class="fs-6 text-dark">
                                    <span class="fw-bold">
                                        {{field.value.name}}{{field.value.required ? '*' : ''}}
                                    </span>

                                    <span *ngIf="field.value.description" class="text-secondary">
                                        - {{field.value.description}}
                                    </span>
                                </span>

                                <!-- Choose One/Multiple Fields -->
                                <select
                                    *ngIf="field.value.type === 'chooseOne' || field.value.type === 'chooseMultiple'"
                                    formControlName="value" class="form-select"
                                    [multiple]="field.value.type === 'chooseMultiple'"
                                    [ngClass]="{'is-invalid': field.touched && field.invalid }">
                                    <option *ngIf="field.value.type === 'chooseOne'" value=""></option>

                                    <option *ngFor="let option of field.value.dropdownOptions" [value]="option">
                                        {{option}}
                                    </option>
                                </select>

                                <!-- True False Fields -->
                                <div *ngIf="field.value.type === 'trueFalse'" class="btn-group" role="group">
                                    <input type="radio" class="btn-check" formControlName="value"
                                        [id]="'true-option-' + field.value.fieldID" [value]="'True'"
                                        (click)="updateTrueFalse('True', field.get('value'))">
                                    <label class="btn btn-outline-success" [for]="'true-option-'+ field.value.fieldID">
                                        True
                                    </label>

                                    <input type="radio" class="btn-check" formControlName="value"
                                        [id]="'false-option-' + field.value.fieldID" [value]="'False'"
                                        (click)="updateTrueFalse('False', field.get('value'))">
                                    <label class="btn btn-outline-danger" [for]="'false-option-'+ field.value.fieldID">
                                        False
                                    </label>
                                </div>

                                <!-- Location Fields -->
                                <div *ngIf="field.value.type == 'location'" class="d-flex flex-column gap-2">
                                    <shared-mini-map
                                        [center]="{lng: previousLocation[0] ?? field.value.longitude, lat: previousLocation[1] ?? field.value.latitude}"
                                        [markerLocations]="[{ location: {lng: field.value.longitude, lat: field.value.latitude} }]"
                                        [height]="'60vh'" [zoom]="previousZoom"
                                        (mapClick)="handleMapClick($event, field)">
                                    </shared-mini-map>

                                    <div class="row g-3">
                                        <!-- Longitude -->
                                        <div class="col-md-6">
                                            <input type="number" formControlName="longitude"
                                                [ngClass]="{'is-invalid': field.get('longitude').touched && field.get('longitude').invalid }"
                                                class="form-control" min="-180" max="180" placeholder="Longitude"
                                                title="Longitude">

                                            <div class="invalid-feedback">
                                                Longitude must be between -180 and 180
                                            </div>
                                        </div>

                                        <!-- Latitude -->
                                        <div class="col-md-6">
                                            <input type="number" formControlName="latitude"
                                                [ngClass]="{'is-invalid': field.get('latitude').touched && field.get('latitude').invalid }"
                                                class="form-control" min="-90" max="90" placeholder="Latitude"
                                                title="Latitude">

                                            <div class="invalid-feedback">
                                                Latitude must be between -90 and 90
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Image Fields -->
                                <div *ngIf="field.value.type == 'images'">
                                    <div *ngIf="field.value.files?.length > 0">
                                        <ul class="nav nav-tabs" style="user-select: none;">
                                            <li class="nav-item bg-light">
                                                <a class="nav-link active" style="color: black;">
                                                    Images *
                                                </a>
                                            </li>
                                        </ul>

                                        <div class="container border border-top-0 p-2">
                                            <div style="overflow-y: auto; max-height: 300px;">
                                                <!-- Adjust height as needed -->
                                                <ul *ngFor="let file of field.value.files; index as i"
                                                    class="list-group">
                                                    <li
                                                        class="mt-1 list-group-item d-flex align-items-center justify-content-between">
                                                        <span class="px-3 text-truncate">
                                                            {{ file.name }}
                                                        </span>
                                                        <a class="btn btn-sm btn-close"
                                                            (click)="clearFile(field, i)"></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <label [for]="'imageInput' + i"
                                        class="w-100 btn btn-success btn-sm mt-1 d-flex align-items-center justify-content-center">
                                        <span class="me-1">+ Add Images</span>
                                        <i class="fas fa-camera"></i>
                                    </label>
                                    <input type="file" accept="image/*" [id]="'imageInput' + i"
                                        (change)="addFiles($event, field)" multiple class="hide-input">
                                </div>

                                <!-- File Fields -->
                                <div *ngIf="field.value.type == 'files'">
                                    <div *ngIf="field.value.files?.length > 0">
                                        <ul class="nav nav-tabs" style="user-select: none;">
                                            <li class="nav-item bg-light">
                                                <a class="nav-link active" style="color: black;">
                                                    Files
                                                </a>
                                            </li>
                                        </ul>

                                        <div class="container border border-top-0 p-2">
                                            <div style="overflow-y: auto; max-height: 300px;">
                                                <!-- Adjust height as needed -->
                                                <ul *ngFor="let file of field.value.files; index as i"
                                                    class="list-group">
                                                    <li
                                                        class="mt-1 list-group-item d-flex align-items-center justify-content-between">
                                                        <span class="px-3 text-truncate">
                                                            {{ file.name }}
                                                        </span>
                                                        <a class="btn btn-sm btn-close"
                                                            (click)="clearFile(field, i)"></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <label [for]="'fileInput' + i"
                                        class="w-100 btn btn-success btn-sm mt-1 d-flex align-items-center justify-content-center">
                                        <span class="me-1">+ Add Files</span>
                                        <i class="fas fa-files"></i>
                                    </label>
                                    <input type="file" [id]="'fileInput' + i" (change)="addFiles($event, field)"
                                        multiple class="hide-input">
                                </div>

                                <!-- Date, Number, and Text Fields -->
                                <input *ngIf="['date', 'number', 'text'].includes(field.value.type)"
                                    formControlName="value" class="form-control" [type]="field.value.type"
                                    [ngClass]="{'is-invalid': field.touched && field.invalid }">
                            </div>
                        </ng-container>
                    </div>
                </form>

                <div *ngIf="showSummaryPage" class="h-100 d-flex flex-column">
                    <h2>Summary</h2>
                    <div *ngFor="let page of assetPages.controls; let i = index" class="mb-2 me-auto">
                        <div *ngFor="let fieldGroup of page.controls">
                            <strong class="me-1">{{ fieldGroup.value.name }}</strong>

                            <!-- Display value based on field type -->
                            <ng-container [ngSwitch]="fieldGroup.value.type">
                                <div
                                    [ngClass]="{'text-danger': fieldGroup.value.value == '' || fieldGroup.value.value == null}">
                                    <span *ngSwitchCase="'text'">
                                        {{ fieldGroup.value.value || 'Not provided' }}
                                    </span>

                                    <span *ngSwitchCase="'chooseOne'">
                                        {{ fieldGroup.value.value || 'Not provided' }}
                                    </span>

                                    <span *ngSwitchCase="'chooseMultiple'">
                                        {{ fieldGroup.value?.value?.join(', ') ?? 'Not provided' }}
                                    </span>

                                    <span *ngSwitchCase="'trueFalse'">
                                        {{ fieldGroup.value.value || 'Not provided' }}
                                    </span>

                                    <span *ngSwitchCase="'location'">
                                        {{
                                        fieldGroup.value.longitude || fieldGroup.value.latitude ?
                                        ('Longitude: ' + (fieldGroup.value.longitude || 'Not provided') +
                                        ', Latitude: ' + (fieldGroup.value.latitude || 'Not provided'))
                                        : 'Not provided'
                                        }}
                                    </span>

                                    <span *ngSwitchCase="'images'">
                                        <div *ngFor="let file of fieldGroup.value.files; let j = index">
                                            {{ file.name }}
                                        </div>
                                        <span *ngIf="fieldGroup.value.files?.length == 0">Not provided</span>
                                    </span>

                                    <span *ngSwitchCase="'files'">
                                        <div *ngFor="let file of fieldGroup.value.files; let j = index">
                                            {{ file.name }}
                                        </div>
                                        <span *ngIf="fieldGroup.value.files?.length == 0">Not provided</span>
                                    </span>

                                    <span *ngSwitchDefault>
                                        {{
                                        fieldGroup.value.value === '' ||
                                        fieldGroup.value.value === null ? 'Not provided' :
                                        fieldGroup.value.value
                                        }}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Footer -->
            <div *ngIf="assetPages" class="modal-footer d-flex flex-column">
                <!-- Progress and Step Indicator -->
                <button *ngIf="showSummaryPage" type="button"
                    class="btn btn-success w-100 d-flex align-items-center justify-content-center" (click)="submit()">
                    <span *ngIf="!saving">Add</span>

                    <div *ngIf="saving" class="d-flex gap-1 align-items-center">
                        <span>Adding</span>
                        <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                    </div>
                </button>

                <div *ngIf='!showSummaryPage' class="w-100 mb-2">
                    <div class="progress" style="height: 8px;"> <!-- Thinner progress bar -->
                        <div class="progress-bar" role="progressbar" [style.width]="getProgress()"
                            aria-valuenow="getProgress()" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <span class="d-block text-center mt-1 text-muted">
                        Page {{currentPageIndex + 1}} of {{assetPages.controls.length}}
                    </span>
                </div>

                <!-- Navigation and Action Buttons -->
                <div class="d-flex justify-content-center align-items-center w-100 gap-1">
                    <button type="button" class="btn btn-outline-secondary w-100" (click)="goToPreviousStep()"
                        [disabled]="currentPageIndex === 0 || saving">
                        <i class="fas fa-chevron-left"></i>
                        Prev
                    </button>

                    <button *ngIf="currentPageIndex < assetPages.controls.length - 1" type="button"
                        class="btn btn-primary w-100" (click)="goToNextStep()">
                        Next
                        <i class="fas fa-chevron-right"></i>
                    </button>

                    <button *ngIf="currentPageIndex === assetPages.controls.length - 1" type="button"
                        class="btn btn-success w-100 me-1" (click)="goToNextStep()">
                        Finish
                        <i class="fas fa-flag"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>