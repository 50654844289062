import { Component, Input } from '@angular/core'
import { Scene } from '@classes/Scene'
import { SceneService } from '@services/scene.service'

@Component({
  selector: 'shared-scene-icon',
  templateUrl: './scene-icon.component.html',
  styleUrls: ['./scene-icon.component.css']
})
export class SceneIconComponent {
  @Input() scene: Scene
  public selectedScene$ = this._sceneService.selectedScene$

  constructor(private _sceneService: SceneService) { }
}