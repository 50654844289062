<div class="btn-group btn-group-sm" style="background: rgb(255, 255, 255, 0.2)" role="group">
    <input id="measurementMode" type="checkbox" class="btn-check" name="btn-check" autocomplete="off"
        (click)="toggleMeasurementMode()" [checked]="enabled">
    <label class="btn btn-outline-light text-dark" for="measurementMode" data-bs-toggle="tooltip"
        title="Toggle Measurement Mode"><i class="far fa-ruler"></i></label>

    <button *ngIf="enabled" class="btn btn-light btn-outline-light text-dark fst-italic dropdown-toggle" type="button"
        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        {{ units }}
    </button>

    <ul *ngIf="enabled" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li class="dropdown-item" (click)="convertUnit('ft')" title="feet">ft</li>
        <li class="dropdown-item" (click)="convertUnit('mi')" title="miles">mi</li>
        <div class="dropdown-divider"></div>
        <li class="dropdown-item" (click)="convertUnit('m')" title="meters">m</li>
        <li class="dropdown-item" (click)="convertUnit('km')" title="kilometers">km</li>
    </ul>
</div>