import { Component } from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { Model } from '@classes/Model'
import { Scene } from '@classes/Scene'
import { models$, ModelService } from '@services/model.service'
import { SceneService } from '@services/scene.service'
import { CreateSceneBody } from '@shared/create-scene/create-scene.component'

export function validateFileExtensions(allowedExtensions: string[]): ValidatorFn {
  return (control: FormControl<File>): { [key: string]: any } | null => {
    const file = control.value

    if (file) {
      const extension = file.name.split('.').pop().toLowerCase()

      if (!allowedExtensions.includes(extension)) { // Validation failed
        return { invalidFileType: true }
      }
    } else { // Validation passed
      return null
    }
  }
}

@Component({
  selector: 'shared-create-virtual-tour',
  templateUrl: './create-virtual-tour.component.html',
  styleUrls: ['./create-virtual-tour.component.css']
})
export class CreateVirtualTourComponent implements CreateSceneBody {
  public form = new FormGroup({
    file: new FormControl<File>(null, [Validators.required, validateFileExtensions(['e57'])]),
    model: new FormControl<number>(null, [Validators.required]),
    name: new FormControl<string>('New Virtual Tour Scene', [Validators.required]),
  })
  public models$ = models$

  get file() { return this.form.get('file') as FormControl<File> }
  get model() { return this.form.get('model') as FormControl<number> }
  get name() { return this.form.get('name') as FormControl<string> }

  constructor(private _modelService: ModelService, private _sceneService: SceneService,) {
    this._modelService.getUsersModels().subscribe()
  }

  getScene(projectID?: number) {
    return new Scene(projectID, this.name.value, 'Virtual Tour')
  }

  setModel(model: Model) {
    this.model.setValue(model.id)
  }

  setFile(file: File) {
    this.file.setValue(file)
    this.file.markAsTouched()
  }

  createScene$(projectID: number) {
    const { file, model } = this.form.value
    const scene = this.getScene(projectID)

    return this._sceneService.createVirtualTour$(scene, file, model)
  }
}