import { EMPTY, Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { EditAssetsComponent } from '@assets/edit-assets/edit-assets.component'
import { AssetField } from '@classes/AssetField'
import { ShowAssetFilesComponent } from '@modal/show-asset-files/show-asset-files.component'
import { ShowAssetHistoryComponent } from '@modal/show-asset-history/show-asset-history.component'
import { AssetPaginationOptions, AssetRow, AssetService, assetTableRows$, changesListener$ } from '@services/asset.service'
import { AuthenticationService } from '@services/authentication.service'
import { ModalService } from '@services/modal.service'
import { RightSidebarService } from '@services/right-sidebar.service'
import { UiService } from '@services/ui.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'shared-view-asset-entries-table',
  templateUrl: './view-asset-entries-table.component.html',
  styleUrls: ['./view-asset-entries-table.component.css'],
})
export class ViewAssetEntriesTableComponent implements OnChanges {
  @Input() assetTypeID: number
  @Input() interactable: boolean = true

  private _scrollTimeout: any
  private _subscriptions: Subscription[] = []
  public assetFields: AssetField[] = []
  public loadingMore: boolean = false
  public offset: number = 0
  public size: number = 100
  public sortByFieldID: number

  get assetEntryCount() { return this._assetService.selectedAssetType?.assetCount }
  get assetTableRows() { return this._assetService.assetTableRows }
  set assetTableRows(rows) { this._assetService.assetTableRows = rows }
  get hasEditAccess() { return this._authenticationService.hasBuilderAccess }
  get isMobile() { return this._assetService.isMobile }
  get filterMap() { return this._assetService.filterMap }
  set filterMap(map: Map<number, string>) { this._assetService.filterMap = map }
  get filterFieldIDs() {
    const fieldIDs: number[] = []
    for (let key of this.filterMap.keys()) {
      fieldIDs.push(key)
    }
    return fieldIDs
  }
  get filterValues() {
    const fieldValues: string[] = []
    for (let value of this.filterMap.values()) {
      fieldValues.push(value)
    }
    return fieldValues
  }
  get loading() { return this._assetService.loading }
  set loading(loading: boolean) { this._assetService.loading = loading }
  get selectedAssets() { return this._assetService.selectedAssets }
  get sortOrder() { return this._assetService.sortOrder }
  set sortOrder(sortOrder: "ASC" | "DESC") { this._assetService.sortOrder = sortOrder }

  constructor(
    private _assetService: AssetService,
    private _authenticationService: AuthenticationService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _modalService: ModalService,
    private _rightSidebarService: RightSidebarService,
    private _uiService: UiService,
  ) {
    this._subscriptions.push(
      assetTableRows$.subscribe(() => this._changeDetectionRef.markForCheck()),
      changesListener$.subscribe(() => this.loadAllAssets())
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    const savedFilter = this._assetService.savedFilters.get(this.assetTypeID)

    this.filterMap = savedFilter ?? new Map()

    this.getAssetTypeData(this.assetTypeID).subscribe((assetType) => {
      this._assetService.selectedAssetType = assetType
      this.assetFields = assetType.fields
      this.assetTableRows = assetType.rows
    })
  }

  filter(field: AssetField, search: string) {
    if (search == '') {
      this.filterMap.delete(field.id)
    } else {
      this.filterMap.set(field.id, search)
    }

    this._assetService.savedFilters.set(this.assetTypeID, this.filterMap)
    this.offset = 0

    this.getAssetTypeData(this.assetTypeID).subscribe(assetType => this.assetTableRows = assetType.rows)
  }

  getAssetTypeData(assetTypeID: number, options?: Partial<AssetPaginationOptions>) {
    if (this.assetTypeID && !this.loading) {
      this.loading = true

      return this._assetService.getAssetTypeData(assetTypeID, {
        size: options?.size ?? this.size,
        offset: options?.offset ?? this.offset,
        sortByFieldID: options?.sortByFieldID ?? this.sortByFieldID,
        sortOrder: options?.sortOrder ?? this.sortOrder,
        filterFieldIDs: options?.filterFieldIDs ?? this.filterFieldIDs,
        filterValues: options?.filterValues ?? this.filterValues
      }).pipe(tap(() => this.loading = false))
    } else {
      return EMPTY
    }
  }

  getValueType(valueIndex: number) {
    return this.assetFields[valueIndex]?.type
  }

  loadMoreAssets() {
    this.offset += this.size
    const rows = this.assetTableRows

    this.getAssetTypeData(this.assetTypeID).subscribe(results => {
      this.assetTableRows = rows.concat(results.rows)
      this.loadingMore = false
    })
  }

  loadAllAssets() {
    this.offset = 0
    this.size = null

    this.getAssetTypeData(this.assetTypeID).subscribe(assetType => {
      this.assetTableRows = assetType.rows
      this.offset = assetType.rows.length
      this.size = 100
    })
  }

  openEditAssetPanel(assetID: number) {
    if (this.hasEditAccess && this.interactable) {
      this._assetService.setSelectedAssetByID(assetID)
        .subscribe(() => this._rightSidebarService.openPanel(`Edit Asset`))
    }
  }

  openEditAssetsPanel() {
    if (this.hasEditAccess && this.interactable) {
      if (this._rightSidebarService.open || this._uiService.rightPanel.isOpen) this._rightSidebarService.closePanel()

      this._uiService.openRightPanel(EditAssetsComponent, { title: 'Edit Assets' })
    }
  }

  openFilesInModal(event, row: AssetRow, valueIndex: number) {
    if (this.interactable) {
      event.stopPropagation()

      const assetID = row.assetID
      const fieldID = row.fieldIDs[valueIndex]
      const field = this.assetFields.find(f => f.id == fieldID)

      this._assetService.getAssetFieldValue(assetID, fieldID).subscribe(assetFieldValue => {
        this._modalService.showAsModal(ShowAssetFilesComponent).then(ref => {
          ref.instance.assetFieldValue = assetFieldValue
          ref.instance.title = `Asset ${row.assetID} ${field.name}`
          ref.instance.fileType = field.type as 'images' | 'files'
        })
      })
    }
  }

  selectAllAssets(isChecked: boolean) {
    if (isChecked) {
      this.assetTableRows.forEach(row => this.selectedAssets.add(row.assetID))

      this.openEditAssetsPanel()
    } else {
      this.selectedAssets.clear()
      this._uiService.closeRightPanel()
    }
  }

  selectAsset(assetID: number, isSelected: boolean) {
    if (isSelected) {
      this.selectedAssets.add(assetID)

      if (!this.isMobile) {
        this.openEditAssetsPanel()
      }
    } else {
      this.selectedAssets.delete(assetID)

      if (this.selectedAssets.size == 0) {
        this._uiService.closeRightPanel()
      }
    }
  }

  sortByField(field: AssetField) {
    if (this.sortByFieldID == field.id) {
      this.toggleSortOrder()
    } else {
      this.sortOrder = 'ASC'
      this.sortByFieldID = field.id
      this.offset = 0
    }

    this.getAssetTypeData(this.assetTypeID).subscribe(assetType => this.assetTableRows = assetType.rows)
  }

  onTableScroll(event) {
    if (this.assetTableRows.length < this._assetService.selectedAssetType.assetCount && !this.loadingMore) {
      const target = event.target
      const position = target.scrollTop + target.clientHeight + 20
      const bottom = target.scrollHeight

      const atBottomOfTable = position >= bottom

      if (atBottomOfTable && !this._scrollTimeout) {
        this.loadingMore = true
        this._scrollTimeout = setTimeout(() => {
          this.loadMoreAssets()
          this._scrollTimeout = null
        }, 300)
      }
    }
  }

  showAssetHistory(event, assetID: number) {
    if (this.interactable) {
      event.stopPropagation()

      this._assetService.getAssetHistory(assetID).subscribe(history => {
        this._modalService.showAsModal(ShowAssetHistoryComponent).then(ref => {
          ref.instance.history = history
          ref.instance.title = `Asset ${assetID} History`
        })
      })
    }
  }

  toggleSortByAssetID() {
    if (this.sortByFieldID) {
      this.sortByFieldID = undefined
      this.sortOrder = 'ASC'
    } else {
      this.toggleSortOrder()
    }

    this.offset = 0

    this.getAssetTypeData(this.assetTypeID).subscribe(assetType => this.assetTableRows = assetType.rows)
  }

  toggleSortOrder() {
    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC'
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}