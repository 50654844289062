import Stripe from 'stripe'

import { AfterViewInit, Component, Input } from '@angular/core'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'billing-edit-subscription-item, [edit-item]',
  templateUrl: './edit-subscription-item.component.html',
  styleUrls: ['./edit-subscription-item.component.css']
})
export class EditSubscriptionItemComponent implements AfterViewInit {
  @Input() item: Stripe.SubscriptionItem

  public quantity: number

  get canDecrement() { return this.message == '' }
  get changed() { return this.item.quantity != this.quantity }
  get interval() { return this.item.price.recurring.interval }
  get message() {
    if (this.quantity == 0) {
      return 'Quantity cannot be less than zero'
    } else if (this.quantity == this.usage) {
      return 'Quantity cannot be less than the amount in use'
    } else if (this.name == 'Admin Seat' && this.quantity <= 1) {
      return 'Minimum quantity for Admin Seat is 1'
    } else if (this.tiers && this.name != 'Admin Seat' && this.quantity <= (this.tiers[0].up_to || 0)) {
      return `Minimum quantity for ${this.name} is ${this.tiers[0].up_to || 0}`
    } else {
      return ''
    }
  }
  get name() { return this.product.name }
  get product() { return this.item.price.product as Stripe.Product }
  get quantityDifference() { return this.quantity - this.item.quantity }
  get tiers() { return this.item.price.tiers }
  get usage() {
    if (!this._subscriptionService.usageAndLimits) {
      return 0
    } else if (this.name == 'Admin Seat') {
      return this._subscriptionService.seats.admin.usage
    } else if (this.name == 'Editor Seat') {
      return this._subscriptionService.seats.editor.usage
    } else if (this.name == 'Read-Only Seat') {
      return this._subscriptionService.seats.read_only.usage
    } else if (this.name == 'Models') {
      return this._subscriptionService.resources.models.usage
    } else if (this.name == '360° Images') {
      return this._subscriptionService.resources['360_images'].usage
    } else if (this.name == 'Documents') {
      return this._subscriptionService.resources.documents.usage
    }
  }

  constructor(private _subscriptionService: SubscriptionService) { }

  ngAfterViewInit() {
    this.quantity = this.item.quantity
  }

  /** In cents */
  getCost(quantity: number) {
    // If the item is 'Admin Seat', return the cost based on unit_amount
    if (this.name == 'Admin Seat') {
      return (quantity - 1) * this.tiers[1].unit_amount
    } else if (this.tiers && this.name != 'Admin Seat') { // If there are tiers and the name isn't 'Admin Seat'
      // IMPORTANT NOTE: It is plausible that someone could have a quantity that is not exactly equal to a tiers `up_to`
      // Find the tier the quantity currently falls into
      const currentTier = this.tiers.find(tier => quantity == tier.up_to)

      // If unit_amount exists, return the calculated cost
      if (currentTier?.unit_amount || currentTier?.unit_amount_decimal) {
        return quantity * (currentTier.unit_amount ?? +currentTier.unit_amount_decimal)
      } else if (currentTier?.flat_amount || currentTier?.flat_amount_decimal) { // If flat_amount exists, return that value
        return currentTier.flat_amount ?? +currentTier.flat_amount_decimal
      }
    } else {
      return quantity * this.item.price.unit_amount
    }

    // Fallback to zero if no conditions met
    return 0
  }

  decrement() {
    if (this.canDecrement) {
      if (this.tiers && this.name != 'Admin Seat') {
        const tierIndex = this.tiers.findIndex(tier => this.quantity == tier.up_to)

        this.quantity = this.tiers[tierIndex - 1]?.up_to ?? this.quantity
      } else {
        this.quantity -= 1
      }
    }
  }

  increment() {
    if (this.tiers && this.name != 'Admin Seat') {
      const tierIndex = this.tiers.findIndex(tier => this.quantity == tier.up_to)

      this.quantity = this.tiers[tierIndex + 1]?.up_to ?? this.quantity
    } else {
      this.quantity += 1
    }
  }

  clear() {
    this.quantity = this.item.quantity
  }
}