import { Component } from '@angular/core'

@Component({
  selector: 'shared-navbar-mobile',
  templateUrl: './navbar-mobile.component.html',
  styleUrls: ['./navbar-mobile.component.css']
})
export class NavbarMobileComponent {
  public showMenu = false

  constructor() { }

  toggleMenu() {
    this.showMenu = !this.showMenu
  }
}