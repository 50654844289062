<nav *ngIf="!isMobile" class="navbar navbar-toggleable-sm navbar-expand-sm navbar-dark  scheme-bg-dark me-0">
    <div class="container-fluid">
        <!-- Logo -->
        <div class="navbar-brand">
            <shared-logo [link]="'/projects'"></shared-logo>
        </div>

        <!-- TABS -->
        <shared-navigation-tabs *ngIf="showTabs"></shared-navigation-tabs>

        <!-- Profile Picture -->
        <shared-profile-picture class="ms-auto"></shared-profile-picture>
    </div>
</nav>

<shared-navbar-mobile *ngIf="isMobile"></shared-navbar-mobile>