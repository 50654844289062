import { Feature } from './Feature'

export enum RuleOperation {
    AND = 'and',
    OR = 'or'
}

export enum RuleOperator {
    EQ = 'equals',
    DNE = 'does not equal',
    GT = 'greater than',
    GTE = 'greater than or equals',
    LT = 'less than',
    LTE = 'less than or equals',
    C = 'contains',
    NE = 'not equal',
    DNC = 'does not contain',
}

export const booleanRuleTypes: readonly RuleOperator[] = [RuleOperator.EQ, RuleOperator.DNE]
export const numberRuleTypes: readonly RuleOperator[] = [RuleOperator.EQ, RuleOperator.DNE, RuleOperator.GT, RuleOperator.GTE, RuleOperator.LT, RuleOperator.LTE]
export const textRuleTypes: readonly RuleOperator[] = [RuleOperator.C, RuleOperator.DNC, RuleOperator.EQ, RuleOperator.DNE, RuleOperator.GT, RuleOperator.GTE, RuleOperator.LT, RuleOperator.LTE]

export type Rule = {
    searchableProperty: SearchableProperty,
    operator: RuleOperator,
    userInput: string
    features?: Feature[],
    operation?: RuleOperation
}

export const newRule = (parentOperator?: RuleOperation): Rule => {
    return {
        searchableProperty: { name: 'name', valueType: 'text' },
        operator: RuleOperator.C,
        userInput: '',
        operation: parentOperator ?? undefined,
        features: []
    }
}

export type RuleGroup = {
    ruleObjects: Array<Rule | RuleGroup>
    operation?: RuleOperation,
    features?: Feature[]
}

export const newRuleGroup = (addRules: boolean = true): RuleGroup => {
    return {
        ruleObjects: addRules ? [newRule(RuleOperation.AND), newRule(RuleOperation.AND)] : [],
        operation: RuleOperation.AND,
        features: []
    }
}

export interface SearchableProperty {
    name: string,
    valueType: 'boolean' | 'number' | 'text'
}

export const isRule = (rule: any): rule is Rule => 'searchableProperty' in rule
export const isRuleGroup = (ruleGroup: any): ruleGroup is RuleGroup => 'ruleObjects' in ruleGroup
