import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FeatureTypes } from '@classes/Feature'

@Component({
  selector: 'shared-feature-icon',
  templateUrl: './feature-icon.component.html',
  styleUrls: ['./feature-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureIconComponent {
  @Input() type: FeatureTypes | 'assets' | 'geojson'
}