import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

/**
 * Recursive component that displays objects, arrays, and/or properties visually to match a JSON structure.
 */
@Component({
  selector: 'shared-json-object',
  templateUrl: './json-object.component.html',
  styleUrls: ['./json-object.component.css']
})
export class JSONObjectComponent implements OnInit {
  @Output() pick: EventEmitter<any> = new EventEmitter()
  @Input() json: Object = undefined
  @Input() array: Array<any> = undefined
  @Input() isExpanded: boolean = true

  public properties: Array<Object> = []
  public hovering: number = undefined

  constructor() { }

  ngOnInit(): void {
    /* Takes the properties from a json object and moves them into an array, so we can *ngFor them in the HTML. */
    if (this.json) {
      for (var prop in this.json) {
        this.properties.push({ propertyName: prop, value: this.json[prop] })
      }
    }
  }

  /* When a property name is clicked, it emits to the component's parent to handle it appropriately. */
  picked(key: string, value: any, path: string) {
    this.pick.emit({ key: key, value: value, path: path })
  }

  select(property: { propertyName: string, value: any }) {
    const key = property.propertyName
    const value = property.value
    const path = property.propertyName

    this.picked(key, value, path)
  }

  isLastItem(index: number, array: any[]) {
    return index == array.length - 1 && array.length > 1
  }
}