<div class="card p-1" style="background-color: rgba(255, 255, 255, 0.902);">
    <button class="position-absolute top-0 end-0 m-2 btn btn-light btn-close" (click)="closePicker()"></button>

    <shared-json-object [json]="jsonToShow" [array]="array" [isExpanded]="true"
        (pick)="picked($event.key, $event.value, $event.path)">
    </shared-json-object>

    <div *ngIf="isMore" role="button" class="d-flex flex-column align-items-center p-2" (click)="showMore()">
        See more
        <i class="fa-light fa-chevron-down"></i>
    </div>
</div>