import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService, ModuleType } from '@services/authentication.service'
import { SubscriptionService } from '@services/subscription.service'

@Component({
  selector: 'shared-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.css']
})
export class NavigationTabsComponent {
  get currentModule() { return this._authenticationService.currentModule }
  get hasUserAdminAccess() { return this._authenticationService.hasUserAdminAccess }
  get inFreeTier() { return this._subscriptionService.inFreeTier }
  get isLegacy() { return this._subscriptionService.isLegacy }
  get isBillingDashboard() { return this.currentModule == "billing" }
  get isModelManager() { return this.currentModule == "models" }
  get isAssetManager() { return this.currentModule == "assets" }
  get isOrganizationDashboard() { return this.currentModule == "organization" }
  get isProjectDashboard() { return this.currentModule == "projects" }

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _subscriptionService: SubscriptionService
  ) { }

  handleClick(event: MouseEvent, dashboard: ModuleType) {
    if (event.button === 0 && !event.ctrlKey) {
      event.preventDefault()
      this._router.navigate([`/${dashboard}`])
    }
  }
}