import { Component } from '@angular/core'
import { UndoService } from '@services/undo.service'

@Component({
  selector: 'shared-undo-redo-btns',
  templateUrl: './undo-redo-btns.component.html',
  styleUrls: ['./undo-redo-btns.component.css']
})
export class UndoRedoBtnsComponent {
  get canUndo(): boolean { return this._undoService.undoLen > 0 }
  get canRedo(): boolean { return this._undoService.redoLen > 0 }

  constructor(private _undoService: UndoService) { }

  undo() {
    this._undoService.undo().subscribe()
  }

  redo() {
    this._undoService.redo().subscribe()
  }
}