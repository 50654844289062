import * as saveAs from 'file-saver'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import * as THREE from 'three'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FileReference } from '@classes/FileReference'

import { AuthenticationService } from './authentication.service'
import { project$, ProjectService } from './project.service'
import { EndpointOptions, RequestService } from './request.service'
import { ToastService } from './toast.service'

@Injectable({
  providedIn: 'root'
})
export class FileReferenceService {

  constructor(
    private _authenticationService: AuthenticationService,
    private _http: HttpClient,
    private _projectService: ProjectService,
    private _toastService: ToastService,
    private _requestService: RequestService
  ) { }

  /** 
   * Based on the loaded module and/or if the project is public
   * @returns The url for downloading a file
   */
  getUrlForFileDownload(fileReferenceID: number, modelProjectOrAssetID: number, hash: string, token?: string) {
    const isForModels = this._authenticationService.currentModule == 'model-editor' || this._authenticationService.currentModule == 'models'
    const isViewer = this._authenticationService.currentModule == 'viewer'
    const isPublic = this._projectService.currentProject?.publicStatus
    const isForAssets = this._authenticationService.currentModule == 'assets'
    var context = 'project'

    if (isViewer && isPublic) {
      context = 'public'
    } else if (isForModels) {
      context = 'model'
    } else if (isForAssets) {
      context = 'asset'
    }

    return `${environment.api}/file/${context}/${fileReferenceID}/${modelProjectOrAssetID}/${hash}?token=${token}`
  }

  createAssetFieldValueFileReferences(fieldValueID: number, files: File[], options: { label?: string, title?: string, autohide?: boolean } = {}) {
    var url = `${environment.api}/files/assetFieldValue/${fieldValueID}`
    const formData: FormData = new FormData()

    if (options?.label) formData.append('label', options.label.toString())
    files.forEach(file => formData.append('file', file, file.name))

    const endpointOptions: EndpointOptions = {
      progressBar: {
        color: 'blue',
        autohide: options?.autohide ?? false,
        title: options?.title ?? `Uploading Files`
      },
      successToast: {
        color: 'green',
        autohide: true,
        title: 'File Reference Created'
      },
      error: {
        operation: 'Create File Reference',
        toast: true
      }
    }
    return this._requestService.create<FileReference[]>(url, formData, endpointOptions)

  }


  createFileReferences(files: File[], options: { modelID?: number, label?: string, title?: string, autohide?: boolean } = {}) {
    if (options?.modelID) var url = `${environment.api}/files/model`
    else var url = `${environment.api}/files`
    const formData: FormData = new FormData()

    if (options?.modelID) formData.append('modelID', options.modelID.toString())
    if (options?.label) formData.append('label', options.label.toString())
    files.forEach(file => formData.append('file', file, file.name))

    const endpointOptions: EndpointOptions = {
      progressBar: {
        color: 'blue',
        autohide: options?.autohide ?? false,
        title: options?.title ?? `Uploading Files`
      },
      successToast: {
        color: 'green',
        autohide: true,
        title: 'File Reference Created'
      },
      error: {
        operation: 'Create File Reference',
        toast: true
      }
    }

    return this._requestService.create<FileReference[]>(url, formData, endpointOptions)
  }


  updateFileReference(fileReference: FileReference) {
    if (fileReference.modelID) var url = `${environment.api}/files/model`
    else var url = `${environment.api}/files`
    const formData: FormData = new FormData()

    formData.append('file', fileReference?.file, fileReference?.file?.name)
    formData.append('fileReference', JSON.stringify(fileReference))

    const options: EndpointOptions = {
      progressBar: {
        color: 'blue',
        autohide: false,
        title: `Uploading File`
      },
      successToast: {
        color: 'green',
        message: 'Updated File Reference Successfully',
        title: 'File Reference Updated'
      },
      error: {
        operation: 'Update File Reference',
        toast: true
      },
    }

    return this._requestService.update<FileReference>(url, formData, options)
  }

  downloadFile(fileReference: FileReference, modelProjectOrAssetID: number, headers: { [header: string]: string | string[] } = {}) {
    if (!fileReference) return EMPTY

    return this._authenticationService.getToken().pipe(
      switchMap(token => {
        const { id: fileReferenceID, hash } = fileReference
        const endpoint = this.getUrlForFileDownload(fileReferenceID, modelProjectOrAssetID, hash, token)

        return this.createBlob(endpoint, { authorization: `Bearer ${token}` }).pipe(
          filter(blob => blob !== undefined),
          tap(blob => saveAs(blob, fileReference.filename))
        )
      })
    )
  }

  createBlob(url: string, headers: { [header: string]: string | string[] } = {}) {
    return this._http.get(url, { headers, responseType: 'blob' }).pipe(
      catchError(() => this._http.get(url, { responseType: 'blob' })),
      map(blob => URL.createObjectURL(blob))
    )
  }

  blobFromFileReference(fileReference: FileReference, interrupt$: Observable<any>) {
    return this._authenticationService.getToken().pipe(
      withLatestFrom(project$),
      switchMap(([token, project]) => {
        const { id: fileReferenceID, hash } = fileReference
        const projectID = project.id
        const fileURL = this.getUrlForFileDownload(fileReferenceID, projectID, hash)


        if (interrupt$) return this.loadFileWithInterrupt(fileURL, token, interrupt$)
        else return new Observable<string>(observer =>
          new THREE.FileLoader()
            .setResponseType('blob')
            .setRequestHeader({ 'Authorization': `Bearer ${token}` })
            .load(fileURL, (response: any) => {
              const blobUrl: string = URL.createObjectURL(response)
              observer.next(blobUrl)
              observer.complete()
            })
        )
      })
    )
  }

  loadFileWithInterrupt(fileURL: string, token: string, interrupt$: Observable<boolean>): Observable<string> {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.open('GET', fileURL, true)
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response
          const blobURL = URL.createObjectURL(blob)
          observer.next(blobURL)
          observer.complete()
          interruptSubscription.unsubscribe()
        } else {
          observer.error('Load failed with status: ' + xhr.status)
        }
      }

      xhr.onerror = () => {
        observer.error('Network error')
      }

      xhr.send()

      const interruptSubscription = interrupt$.subscribe(_ => {
        xhr.abort()
        observer.error('Request aborted')
        interruptSubscription.unsubscribe()
      })

    })
  }


  openFile(fileReference: FileReference, modelProjectOrAssetID: number): Observable<any> {
    const imageTypes = ['.jpg', '.jpeg', '.png']
    const fileIsImage = imageTypes.includes(fileReference.extension)
    const fileIsPDF = fileReference.extension == '.pdf'

    if (fileIsImage) {
      return this.openImageInNewTab(fileReference, modelProjectOrAssetID)
    } else if (fileIsPDF) {
      return this.openPdfInNewTab(fileReference, modelProjectOrAssetID)
    } else {
      return this.downloadFile(fileReference, modelProjectOrAssetID)
    }
  }

  openImageInNewTab(fileReference: FileReference, modelProjectOrAssetID: number) {
    return this.getUrlForFileReference(fileReference, modelProjectOrAssetID).pipe(
      tap(url => {
        // Create a new window/tab
        const newWindow = window.open('', '_blank')

        // Write content to the new window/tab
        newWindow.document.write(`<html><head><title>${fileReference.filename}</title></head><body><img src="${url}" style='max-width:500px;'></body></html>`)

        // Close the document to allow interaction
        newWindow.document.close()
      })
    )
  }

  getUrlForFileReference(fileReference: FileReference, modelProjectOrAssetID: number) {
    return this._authenticationService.getToken().pipe(
      map(token => {
        return this.getUrlForFileDownload(fileReference.id, modelProjectOrAssetID, fileReference.hash, token)
      })
    )
  }

  openPdfInNewTab(fileReference: FileReference, modelProjectOrAssetID: number) {
    return this.getUrlForFileReference(fileReference, modelProjectOrAssetID).pipe(
      switchMap(url => {
        return this._http.get(url, { responseType: 'blob' }).pipe(
          map(res => new Blob([res], { type: 'application/pdf' })),
          catchError(error => {
            console.error('Error fetching PDF:', error)
            return EMPTY
          })
        )
      }),
      tap(blob => {
        const blobURL = URL.createObjectURL(blob)
        window.open(blobURL, '_blank')
      })
    )
  }

  async resizeImage(inputImage: HTMLImageElement, imageFile: File): Promise<File> {
    // Create a canvas element
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = inputImage.width
    canvas.height = inputImage.height

    // Set the canvas dimensions to the new size
    if (canvas.width == canvas.height) {
      canvas.width = 1024
      canvas.height = 1024
    } else if (canvas.width > canvas.height) {
      let ratio = canvas.height / canvas.width
      canvas.width = 1024
      canvas.height = 1024 * ratio
    } else if (canvas.height > canvas.width) {
      let ratio = canvas.width / canvas.height
      canvas.width = 1024 * ratio
      canvas.height = 1024
    }

    // Draw the image on the canvas with the new dimensions
    context.drawImage(inputImage, 0, 0, canvas.width, canvas.height)

    // Return the resized image as a canvas element
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          // Create a File from the Blob
          const file = new File([blob], imageFile.name, { type: imageFile.type })

          // Resolve the Promise with the resized File
          resolve(file)
        }
      }, imageFile.type, 0.8)
    })
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {

      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the model error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}