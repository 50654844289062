import { Component, OnDestroy  } from '@angular/core';
import { ConnectionProperty } from '@classes/Connection';
import { ConnectionService, selectedConnection$ } from '@services/connection.service';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'shared-connection-custom-fields-editor',
  templateUrl: './connection-custom-fields-editor.component.html',
  styleUrls: ['./connection-custom-fields-editor.component.css']
})
export class ConnectionCustomFieldsEditorComponent implements OnDestroy {
  private _subscriptions: Subscription[] = []
  public showConnectionDataPicker: boolean = false
  public customFieldIndexForPicker = undefined
  public customFields$ = selectedConnection$.pipe(
    startWith(this._connectionService.selectedConnection),
    filter(conn => conn !== undefined),
    map(connection => connection.customFields)
  )
  public customFields: ConnectionProperty[]
  
  constructor(private _connectionService: ConnectionService) { 
    this._subscriptions.push(
      this.customFields$.subscribe(customFields => this.customFields = customFields)
    )
  }

  public addCustomField() {
    const customField = new ConnectionProperty('customField', '', JSON.stringify({ valueType: 'text', value: '' }), { connectionID: this._connectionService.selectedConnection.id })
    this._connectionService.createConnectionProperty(customField).subscribe()
  }

  /** Shows/hides ConnectionDataPicker on button click
   * @param updateCustomFieldCallback function provided by a child custom-field component to update itself on data selection in ConnectionDataPicker
   * @param customFieldIndexForPicker index of customField to know where a ConnectionDataPicker instance should show in the view
  */
  toggleConnectionDataPicker(updateCustomFieldCallback: CallableFunction, customFieldIndexForPicker: number) {
    if (!this._connectionService.connectionResponse) return

    if (this.showConnectionDataPicker && this.customFieldIndexForPicker == customFieldIndexForPicker) {
      this.closeConnectionDataPicker()
    } else {
      this._connectionService.setConnectionDataPickerCallback((key, value, path) => {
        updateCustomFieldCallback(key, value, path)
        this.closeConnectionDataPicker()
      })
      this.showConnectionDataPicker = true
      this.customFieldIndexForPicker = customFieldIndexForPicker
    }
  }

  closeConnectionDataPicker() {
    this.customFieldIndexForPicker = undefined
    this.showConnectionDataPicker = false
    this._connectionService.clearConnectionDataPickerCallback()
  }

  deleteCustomField(customField: ConnectionProperty, i: number) {
    this.closeConnectionDataPicker()
    this.customFields.splice(i, 1)
    this._connectionService.deleteConnectionProperty(customField, 'Custom Field Deleted').subscribe()
  }
  
  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}
