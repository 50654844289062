<ul class="nav nav-tabs py-2 px-2 border-bottom-0" style="margin-bottom: -1.5em;">
    <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link" href="/projects" (click)="handleClick($event, 'projects')"
            [ngClass]="{'active text-dark': isProjectDashboard, 'text-white': !isProjectDashboard}">
            Projects
        </a>
    </li>
    <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link" href="/models" (click)="handleClick($event, 'models')"
            [ngClass]="{'active text-dark': isModelManager, 'text-white': !isModelManager}">
            Models
        </a>
    </li>
    <li class="nav-item">
        <a style="cursor: pointer;" class="nav-link" href="/assets" (click)="handleClick($event, 'assets')"
            [ngClass]="{'active text-dark': isAssetManager, 'text-white': !isAssetManager, 'disabled': inFreeTier}">
            Assets <i *ngIf="inFreeTier" class="ms-1 fa-solid fa-lock"></i>
        </a>
    </li>
    <li *ngIf='hasUserAdminAccess || inFreeTier' class="nav-item">
        <a style="cursor: pointer;" class="nav-link" href="/organization" (click)="handleClick($event, 'organization')"
            [ngClass]="{'active text-dark': isOrganizationDashboard, 'text-white': !isOrganizationDashboard, 'disabled': inFreeTier}"
            [disabled]="inFreeTier">
            Organization <i *ngIf="inFreeTier" class="ms-1 fa-solid fa-lock"></i>
        </a>
    </li>
    <li *ngIf='(hasUserAdminAccess || inFreeTier) && !isLegacy' class="nav-item">
        <a style="cursor: pointer;" class="nav-link" href="/billing" (click)="handleClick($event, 'billing')"
            [ngClass]="{'active text-dark': isBillingDashboard, 'text-white': !isBillingDashboard}">
            Billing
        </a>
    </li>
</ul>