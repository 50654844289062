import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core'
import { Feature } from '@classes/Feature'
import { Model } from '@classes/Model'
import { Scene } from '@classes/Scene'

import { EnvironmentManagerService } from './environment-manager.service'

type ClickInfo = {
  type: 'feature' | 'scene' | 'model'
  data: Feature | Scene | Model
}

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  public clickInfo: ClickInfo
  /** Set by app.component.ts */
  public container: ViewContainerRef

  constructor(
    public componentFactory: ComponentFactoryResolver,
    public envManager: EnvironmentManagerService
  ) { }

  closeContext() {
    this.container.clear()
  }

  changeContextMenu<T>(component: Type<T>, event: PointerEvent, info?: ClickInfo) {
    event.preventDefault()
    this.container.clear()
    this.clickInfo = info

    const componentCreated = this.container.createComponent(
      this.componentFactory.resolveComponentFactory(component)
    )
    const element = componentCreated.location.nativeElement
    element.style.zIndex = '1100'

    this.setPosition(event, element)

    return componentCreated
  }

  setPosition(event: PointerEvent, element: HTMLElement) {
    element.style.position = "absolute"

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const { width, height } = element.getBoundingClientRect()
    let x = event.clientX
    let y = event.clientY

    if (x + width > vw) x = x - width
    if (y + height > vh) y = y - height

    element.style.left = x + 'px'
    element.style.top = y + 'px'
  }
}