<div *ngIf="!isScene" class="form-group" [formGroup]="formParent">
    <label>Feature</label>
    <select class="form-control form-select mb-2" aria-label=".form-select-sm example" [formControlName]="'id'">
        <option class="fw-bold" [value]="featureService.selectedFeatureID">This Feature</option>
        <ng-container *ngFor="let feature of features$ | async">
            <option *ngIf='feature.type != "button"' [value]="feature.id">
                {{feature.name}}
            </option>
        </ng-container>
    </select>
</div>
<div *ngIf="isScene" class="form-group" [formGroup]="formParent">
    <label>Scene</label>
    <select class="form-control form-select mb-2" aria-label=".form-select-sm example" [formControlName]="'id'">
        <option [value]="selectedSceneID">This Scene</option>
        <option *ngFor="let scene of scenes$ | async" [value]="scene.id">
            {{scene.name}}
        </option>
    </select>
</div>