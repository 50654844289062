import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'shared-json-literal',
  templateUrl: './json-literal.component.html',
  styleUrls: ['./json-literal.component.css']
})
export class JSONLiteralComponent implements OnInit {
  @Input() literal: string | number
  @Output() pick: EventEmitter<any> = new EventEmitter()
  public literalSubstrings: string[]
  public hovering: number = undefined

  constructor() { }

  ngOnInit(): void {
    /** Attempts to split the string into substrings assuming a comma delimiter.  */
    if (typeof this.literal == 'string') {
      this.literalSubstrings = this.literal.split(',')
      for (let i = 0; i < this.literalSubstrings.length; i++) {
        const substring = this.literalSubstrings[i]
        this.literalSubstrings[i] = substring.trim()
      }
    }
  }

  /** When a literal is clicked, it emits to the component's parent to handle it appropriately. */
  select(substring: string, index?: number) {
    const value = substring
    const key = index ?? 0
    const path = index ?? 0

    this.pick.emit({ key: key, path: path, value: value })
  }
}