<div class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="TitleLabel" aria-hidden="true"
    style="user-select: none;">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="{
            'modal-lg' : modalSize == 'medium',  
            'modal-xl' : modalSize == 'large',  
            'modal-fullscreen' : modalSize == 'fullscreen'
        }" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 *ngIf='options.title' class="modal-title" id="TitleLabel" style="margin-bottom:0;">
                    {{options.title}}
                </h2>
                <a class="btn btn-close" aria-label="Close" (click)="close()"></a>
            </div>

            <!-- These all extend ModalBodyComponent, so that's how we select them -->
            <div *ngIf="componentBody" class="modal-body modal-details" style="overflow-y: auto;">
                <ng-container *ngComponentOutlet="componentBody; inputs: inputs"></ng-container>
            </div>

            <div class="modal-footer">
                <a *ngIf="options.submitText" (click)="submit()" class="btn"
                    [ngClass]="{'btn-success': color == 'green', 'btn-danger' : color == 'red'}">
                    {{options.submitText}}
                </a>

                <a class="btn btn-secondary" (click)="close()">
                    {{options.closeText ?? 'Close'}}
                </a>
            </div>
        </div>
    </div>
</div>