import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { SharedModule } from '@shared/shared.module'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

const allowedList = [
  { uri: `${environment.api}/asset*` },
  { uri: `${environment.api}/auth*` },
  { uri: `${environment.api}/action*` },
  { uri: `${environment.api}/builder*` },
  { uri: `${environment.api}/connection*` },
  { uri: `${environment.api}/copy*` },
  { uri: `${environment.api}/feature*` },
  { uri: `${environment.api}/files*` },
  { uri: `${environment.api}/interaction*` },
  { uri: `${environment.api}/job*` },
  { uri: `${environment.api}/model*` },
  { uri: `${environment.api}/org*` },
  { uri: `${environment.api}/permission*` },
  { uri: `${environment.api}/project*` },
  { uri: `${environment.api}/s3*` },
  { uri: `${environment.api}/scene*` },
  { uri: `${environment.api}/sign-up*` },
  { uri: `${environment.api}/subscription*` },
  { uri: `${environment.api}/user*` },
  { uri: `${environment.api}/viewer/project*` },
]

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList,
      },
    }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }