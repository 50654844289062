import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'typeof' })
export class TypeOfPipe implements PipeTransform {
    transform(item: any) {
        if (Array.isArray(item)) {
            return "array"
        } else if (item == null) {
            return "undefined"
        } else {
            return typeof item as "boolean" | "number" | "object" | "string"
        }
    }
}